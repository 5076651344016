import React, { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import orderAxios from './../../../shared/orderEaxios';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import LoadingSpinner from '../../../Components/LoadingSpinner/LoadingSpinner';

export default function PaymentForm(props) {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const [disable, setDisable] = useState(false);
    const [message, setMessage] = useState(null);

    useEffect(() => {
        elements.getElement(CardElement) != null &&
        elements.getElement(CardElement)
            .on('change', function(event) {
                if (event.complete) {
                    setDisable(true);
                    setMessage(null);
                } else if (event.error) {
                    setMessage(event.error.message);
                    setDisable(false);
                }
            }, []);
    });

    const handleSubmit = async (ev) => {
        ev.preventDefault();
        setLoading(true);
        setDisable(false);
        const orderData = {
            amount: props.amount,
            currency: props.currency,
            orderId: props.orderId,
        };
        orderAxios.post('createPaymentIntent', orderData)
            .then((response) => {
                elements.getElement(CardElement) != null ?
                    handleConfirm(response.data.message) :
                    props.handlePaymentIntentError(message);
            })
            .catch(() => {
                setDisable(true);
                setLoading(false);
                props.handlePaymentIntentError(message);
            });
    };

    const handleConfirm = async (secret) => {
        const payload = await stripe.confirmCardPayment(secret, {
            payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                    name: props.name,
                    email: props.email
                },
            },
        });
        if (payload.error) {
            setDisable(true);
            setLoading(false);
            props.handlePaymentFailedError(payload.error);
        } else {
            setLoading(false);
            setDisable(false);
            props.onSucess(payload.paymentIntent.id);
        }
    };

    const options = {
        hidePostalCode: true
    };

    return (
        <form onSubmit={handleSubmit}>
            {loading && <span className='text-center'><LoadingSpinner /></span>}
            <CardElement options={options} className='form-control' />
            <div className='row mt-4 text-center'>
                <div className='col-sm-12'>
                    <span className='btn btn-secondary mr-2 btn-darkBlue' onClick={props.onClose}>Return</span>
                    <Button variant='primary mr-3' type='submit' disabled={disable ? false : true}>{'Submit'}</Button>
                </div>
            </div>
        </form>
    );
}

PaymentForm.propTypes = {
    currency: PropTypes.any,
    amount: PropTypes.any,
    orderId: PropTypes.any,
    onClose: PropTypes.any,
    name: PropTypes.any,
    email: PropTypes.any,
    handlePaymentIntentError: PropTypes.func,
    handlePaymentFailedError: PropTypes.func,
    onSucess: PropTypes.func,
};