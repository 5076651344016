import { CART } from '../actions';

const initialState = {
    cartItem: [],
    deliveryInstructions: null,
    coupon: [],
    driverTip: 0,
    grandTotal: 0,
    isOpen: null,
    isDelivery: null,
    shopType: null,
    shopId: null,
    currency_symbol: null,
    currency_position: null,
    only_collection: null
};

const handleCart = (state, {cartItem, deliveryInstructions, coupon, driverTip, grandTotal, isOpen, isDelivery, shopType, shopId, currency_symbol, currency_position, only_collection}) => {
    return {
        ...state,
        cartItem,
        deliveryInstructions,
        coupon,
        driverTip,
        grandTotal,
        isOpen,
        isDelivery,
        shopType,
        shopId,
        currency_symbol,
        currency_position,
        only_collection
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case CART:
        return handleCart(state, action);
    default:
        return state;
    }
};

export default reducer;
