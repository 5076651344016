import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { SERVERFOLDER } from '../../Constants';

class ErrorPage extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    state = {}
    render() {
        return (
            <div>
				<div className="commonBanner">
					<div className="container">
						<div className="row">
							<div className="col-12">

							</div>
						</div>
					</div>
				</div>
				<div className="contentPan">
					<div className="container">
						<div className="row">
							<div className="col-12">

								<div className="errorPan" style={{ margin: '50px', 'textAlign': 'center', height: '400px' }}>
									<div className="errorCircle">
										404
									</div>
									<h4>Page not found</h4>
									<p>Maybe this page moved, got deleted or does not exist.<br />
									Let&apos;s back to <Link to={SERVERFOLDER + '/'}>home</Link>.</p>
								</div>


							</div>
						</div>
					</div>
				</div>
			</div>
        );
    }
}

export default ErrorPage;
