import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import {
	Row,
	Col,
	Button,
	Modal,
} from 'react-bootstrap';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import defaultBanner from '../../../assets/images/banner.jpg';
import defaultRestLogo from '../../../assets/images/no-img.svg';
import allgrocery from '../../../assets/images/allgrocery.png';
import { ImStarFull } from 'react-icons/im';
import { FaPhoneAlt } from 'react-icons/fa';
//import { ImLocation } from 'react-icons/im';
import { GoPlus } from 'react-icons/go';
//import { GoDash } from 'react-icons/go';
import { MdClose } from 'react-icons/md';
//import { GiChiliPepper } from 'react-icons/gi';
import { RiArrowRightSLine } from 'react-icons/ri';
import axios from './../../../shared/eaxios';
import { IMAGEURL, SERVERFOLDER } from './../../../Constants';
import ItemPerPage from '../../../Components/ItemsPerPage/ItemsPerPage';
import AppLoader from '../../../Components/AppLoader/AppLoader';
import Pagination from 'react-js-pagination';
import { connect } from 'react-redux';
import { handleCart } from './../../../redux/actions/cart';
import LoadingSpinner from '../../../Components/LoadingSpinner/LoadingSpinner';
//import serviceImg1 from '../../../assets/images/service-img1.png';
//import serviceImg2 from '../../../assets/images/service-img2.png';
//import serviceImg3 from '../../../assets/images/service-img3.png';
//import batchOffer from '../../../assets/images/batch-offer.png';
import * as Utils from '../../../Utils';
//import ReadMoreReact from 'read-more-react';
import ReactReadMoreReadLess from 'react-read-more-read-less';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { scroller } from 'react-scroll';
import { IoMdCart } from 'react-icons/io';

class Grocery extends Component {
	state = {
		selectedCategry: '',
		product_category: [],
		groceryProducts: [],
		groceryInfo: [],
		groceryId: this.props.match.params.id,
		productid: this.props.match.params.productid ? this.props.match.params.productid : '',
		activePage: 1,
		totalCount: 0,
		itemsPerPage: 12,
		getDataerrorMessge: null,
		getDataLoader: false,
		pagingLoader: false,
		searchString: '',
		show: false,
		productEach: [],
		AddonItemList: false,
		AddonItemListByProduct: [],
		AddonItemListError: null,
		selectedSize: null,
		selectedSizeId: null,
		selectedSizePrice: 0,
		//distance: '',
		allowGoogleLocation: false,
		googleLocationMessage: null,
		showLocationDeniedModal: false,
		showComboModal: false,
		showComboModalText: '',
		deliveryAvailable: null,
		addonItemLoader: false,
	}

	_isMounted = false;

	showWarningModal = message => {
		this.setState({
			showWarningModal: true,
			errorMessage: message
		});
	};
	closeWarningModal = () => {
		this.setState({
			showWarningModal: false,
		});
	};

	closeModal = () => {
		this.setState({
			show: false
		});
	};

	displayError = (e) => {
		let errorMessge = '';
		try {
			errorMessge = e.data.message ? e.data.message : e.data.error_description;
		} catch (e) {
			errorMessge = 'Unknown error!';
		}
		return errorMessge;
	}

	grocery_info_fetch = () => {
		const {
			searchString,
		} = this.state;

		this.setState({
			getDataLoader: true
		}, () => {
			axios.get(`groceryDetails/${this.state.groceryId}?lat=${this.props.geolocation.latitude}&lng=${this.props.geolocation.longitude}&searchString=${searchString}`)
				.then(res => {
					//let distance = this.distance(res.data.data.grocery_lat, res.data.data.grocery_lng, this.props.geolocation.latitude, this.props.geolocation.longitude);

					const dumy = { id: '', name: 'All' };
					let arr = [];
					arr.push(dumy);

					this.setState({
						getDataLoader: false,
						product_category: [
							...arr,
							...res.data.data.grocery_product_category,
						],
						selectedCategry: '',
						groceryInfo: res.data.data,
						//distance
					}, () => {
						this.state.groceryInfo.delivery_area !== '' &&
							this.checkDeliveryStatus(this.state.groceryInfo.delivery_area);
					});
				})
				.catch(e => {
					let errorMsg = this.displayError(e);
					this.setState({
						getDataerrorMessge: errorMsg,
						getDataLoader: false
					}, () => { });

					setTimeout(() => {
						this.setState({ getDataerrorMessge: null });
					}, 5000);
				});
		});
	};

	distance(lat1, lon1, lat2, lon2) {
		if ((lat1 == lat2) && (lon1 == lon2)) {
			return 0;
		} else {
			const radlat1 = Math.PI * lat1 / 180;
			const radlat2 = Math.PI * lat2 / 180;
			const theta = lon1 - lon2;
			const radtheta = Math.PI * theta / 180;
			let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
			if (dist > 1) {
				dist = 1;
			}
			dist = Math.acos(dist);
			dist = dist * 180 / Math.PI;
			dist = dist * 60 * 1.1515;
			dist = dist * 1.609344;

			console.log('dist=', dist);
			return dist;
		}
	}

	setCategory = (val) => {
		console.log(val);

		this.setState({
			selectedCategry: val,
		}, () =>
			this.customerProductListByCategory(),
		);
		this.scrollToSlider();
	};

	scrollToSlider = () => {
		scroller.scrollTo('bannerSlider', {
			duration: 800,
			delay: 0,
			smooth: 'easeInOutQuart',
		});
	};

	customerProductListByCategory = () => {
		const {
			selectedCategry,
			itemsPerPage,
			activePage,
		} = this.state;

		this.setState({
			pagingLoader: true
		}, () => {
			axios.get(`customerGroceryProductListByCategory?category=${selectedCategry}&veg_only=&spicy_only=&grocery_id=${this.state.groceryId}&page=${activePage}&pageSize=${itemsPerPage}&product_specific=${this.state.productid}`)
				.then(res => {
					if (this._isMounted) {

						this.setState({
							groceryProducts: res.data.data.data,
							totalCount: res.data.data.total,
						});

						let products = [...this.state.groceryProducts];

						let data = products.map((e) => {
							{
								e.selectedSize = e.product_size[0].size_name;
								e.selectedSizeId = e.product_size[0].size_id;
								e.selectedSizeName = e.product_size[0].size_name;
								e.selectedSizePrice = e.product_size[0].amount;
								e.selectedQuantity = 1;
								e.selctedAddon = null;
								e.specialInstructions = null;
								e.deliveryInstructions = null;
								e.totalProductCount = this.totalProduct(e);
							}

							return e;
						});

						const groceryProducts = data;

						groceryProducts.length > 0 && groceryProducts.map(eachproduct => {
							eachproduct.product_option &&
								eachproduct.product_option.length > 0 &&
								eachproduct.product_option.map(each => {
									return each.product_variant_details &&
										each.product_variant_details.length > 0 &&
										each.product_variant_details.map(variant => {
											return variant.selected = false;
										});
								});
						});

						this.setState({
							pagingLoader: false,
							groceryProducts: data,
						}, () => {
							console.log('groceryProducts=====>', this.state.groceryProducts);
						});

					}
				})
				.catch(e => {
					let errorMsg = this.displayError(e);
					this.setState({
						getDataerrorMessge: errorMsg,
						pagingLoader: false
					}, () => {

					});

					setTimeout(() => {
						this.setState({ getDataerrorMessge: null });
					}, 5000);
				});
		});
	};

	/*getLocation() {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(this.getLocationData, this.handelLocationError);
		} else {
			console.log('Geolocation is not supported by this browser.');
		}
	}

	getLocationData = (position) => {
		if (position) {
			// this.setState({ allowGoogleLocation: true });
			sessionStorage.setItem('latitude', position.coords.latitude);
			sessionStorage.setItem('longitude', position.coords.longitude);
		}
	}*/

	handelLocationError = (error) => {
		this.setState({
			allowGoogleLocation: false,
			// showLocationDeniedModal: true
		});
		switch (error.code) {
			case error.PERMISSION_DENIED:
				console.log('User denied the request for Geolocation.');
				this.setState({ googleLocationMessage: 'User denied the request for Geolocation.' });
				break;
			case error.POSITION_UNAVAILABLE:
				console.log('Location information is unavailable.');
				this.setState({ googleLocationMessage: 'Location information is unavailable.' });
				break;
			case error.TIMEOUT:
				console.log('The request to get user location timed out.');
				this.setState({ googleLocationMessage: 'The request to get user location timed out.' });
				break;
			case error.UNKNOWN_ERROR:
				console.log('An unknown error occurred.');
				this.setState({ googleLocationMessage: 'An unknown error occurred.' });
				break;
		}
	}

	closeLocationDeniedModal = () => {
		this.setState({ showLocationDeniedModal: false });
		window.location.reload();
	}

	closeComboModal = () => {
		this.setState({ showComboModal: false, showComboModalText: '' });
	}

	componentDidMount() {

		if (this.props.user.id !== null) {

			window.scrollTo(0, 0);
			//this.getLocation();
			this._isMounted = true;
			this.grocery_info_fetch();
			this.customerProductListByCategory();

			this.scroll();

		} else {
			this.props.history.push(`${SERVERFOLDER + '/login'}`);
		}
	}


	scroll() {

		let elem = document.getElementById('catSlider');
		//let divPos = elem.offset().top;
		let divPos = elem.getBoundingClientRect();
		//console.log('divPos1', divPos);

		window.onscroll = function () {

			let scrollPos = this.scrollY;

			// 	console.log('divPos', divPos);
			// 	console.log('scrollPos', scrollPos);
			// 	//this.oldScroll = this.scrollY;
			if (divPos.top <= scrollPos) {
				document.body.classList.add('staticSlider');
			}
			else {
				document.body.classList.remove('staticSlider');
			}
		};
	}

	componentDidUpdate(prevProps) {
		if (this.props.categoryId !== prevProps.categoryId) {

			let data = this.props.cart;
			let cartItem = this.props.cartItem;

			cartItem = [];
			data.cartItem = cartItem;
			data.driverTip = 0;
			data.grandTotal = 0;
			this.props.handleCart(data);

			this.setState({
				cartItem
			});

			this.props.history.push(`${SERVERFOLDER + '/'}`);
		}
	}

	checkDeliveryStatus = (area) => {
		const deliveryArea = JSON.parse(area);
		const polygon = new window.google.maps.Polygon({ paths: deliveryArea });
		const currentCoordinates = new window.google.maps.LatLng(this.props.geolocation.latitude, this.props.geolocation.longitude);
		//const currentCoordinates = new window.google.maps.LatLng(22.740406456758326, 88.29145431518555);
		const resultPath = window.google.maps.geometry.poly.containsLocation(currentCoordinates, polygon);
		this.setState({ deliveryAvailable: resultPath }, () => {
			console.log('deliveryAvailable=>>', resultPath);
		});
	}

	change_product_size(size, price, size_id, indexProd) {
		console.log('name =', size);
		console.log('price =', price);
		console.log('id=', size_id);
		console.log(indexProd);

		let groceryProductsTemp = [...this.state.groceryProducts];

		let obj = { ...groceryProductsTemp[indexProd] };
		obj.selectedSize = size;
		obj.selectedSizeId = size_id;
		obj.selectedSizePrice = price;
		obj.selectedSizeName = size;

		groceryProductsTemp[indexProd] = obj;

		this.setState({
			groceryProducts: [...groceryProductsTemp]
		}, () => {
			//console.log('LOCAL', JSON.stringify(groceryProductsTemp));
		});
	}

	addComboItem = (item, i) => {

		item[i].selected = !item[i].selected;

		let productEach = this.state.productEach;

		if (item[i].selected === true) {
			let totalSelected = 0;
			let selectedData = [];
			item.map(data => {
				if (data.selected === true) {
					totalSelected = totalSelected + (data.selected === true ? 1 : 0);
					selectedData.push(data);
				}
			});

			/*item.map(data => {
				selectedData.length > 0 && selectedData.map(sdata => {
					if (data.id !== sdata.id) {
						data.selected = false;
					}
				});
			});*/

		}

		let selctedCombo = [];
		let combo = [];

		productEach.product_option.map(data => {
			let userSelected = false;
			data.product_variant_details.map(data1 => {
				if (data1.selected === true) {
					selctedCombo.push(data1.product);
					combo.push(data1);
					userSelected = true;
				}
			});
			data.userClicked = userSelected;
			//console.log('userClicked=', userSelected);
		});

		productEach.selctedCombo = selctedCombo;
		productEach.combo = combo;

		let price = 0;
		productEach.combo && productEach.combo.length > 0 && productEach.combo.map(eachPrice => {
			price = price + eachPrice.price;
		});

		productEach.comboPrice = price;
		this.setState({ productEach }, () => { this.addTotal(this.state.productEach); });
	};

	addAddonItem = (addon, i, max_select, cat) => {
		addon[i].selected = !addon[i].selected;
		let productEach = this.state.productEach;
		let addonItemListByProduct = this.state.AddonItemListByProduct;

		if (addon[i].selected === true) {
			let totalSelected = 0;
			let selectedData = [];
			addon.map(data => {
				if (data.selected === true) {
					totalSelected = totalSelected + (data.selected === true ? 1 : 0);
					selectedData.push(data);
				}
			});

			if (max_select !== 0 && totalSelected > max_select) {
				addon.map(data => {
					selectedData.length > 0 && selectedData.map(sdata => {
						if (data.id !== sdata.id) {
							data.selected = false;
						}
					});
				});
			}

			if (max_select != 0 && totalSelected > max_select) {
				this.setState({
					showComboModal: true,
					showComboModalText: 'Alert ! Maximum ' + max_select + ' items can be selected.'
				});
			}
		}

		let selctedAddon = [];
		let selctedAddon2 = [];
		let addons = [];

		addonItemListByProduct.map(data => {
			let userSelected = false;
			data.addon_items.map(data1 => {
				if (data1.selected === true) {
					data1.catName = cat;
					selctedAddon.push(data1.itemdetail);
					selctedAddon2.push(data1.itemdetail);
					addons.push(data1);
					userSelected = true;
				}
			});
			data.userClicked = userSelected;
		});

		productEach.selctedAddon = selctedAddon;
		productEach.selctedAddon2 = selctedAddon2;
		productEach.addons = addons;

		let price = 0;
		productEach.selctedAddon && productEach.selctedAddon.length > 0 && productEach.selctedAddon.map(eachPrice => {
			price = price + eachPrice.price;
		});

		productEach.addonPrice = price;
		productEach.categories = addonItemListByProduct;

		this.setState({ productEach, addonItemListByProduct }, () => { this.addTotal(this.state.productEach); });
	}

	addTotal = (productEach) => {

		productEach.totalSizePrice = productEach.selectedSizePrice +
			(productEach.addonPrice ? productEach.addonPrice : 0) +
			(productEach.comboPrice ? productEach.comboPrice : 0);

		//console.log('TOTAL=>', (productEach.selectedSizePrice + '+' + (productEach.addonPrice ? productEach.addonPrice : 0) + '+' + (productEach.comboPrice ?  productEach.comboPrice : 0 ) + '=' + productEach.totalSizePrice ));

		this.setState({ productEach });
	}

	addCartItem = (productEach) => {

		//console.log('cart AddonItemListByProduct', AddonItemListByProduct);
		//console.log('cart product_option', productEach.product_option);

		productEach.totalProductCount = productEach.totalProductCount + 1;

		productEach.min_order = this.state.groceryInfo.min_order;
		productEach.franchisee_id.currency = this.state.groceryInfo.franchisee.currency;
		productEach.franchisee_id.currency_symbol = this.state.groceryInfo.franchisee.currency_symbol.currency_symbol;

		let showComboModal = false;

		//Combo
		if (productEach.product_option && productEach.product_option.length > 0) {

			productEach.product_option.slice(0).reverse().map(data => {

				data.product_variant_details.slice(0).reverse().map(dataPV => {

					if (dataPV.product) {
						if (data.required == 1 && !data.userClicked) {
							this.setState({
								showComboModal: true,
								showComboModalText: data.name.toLowerCase()
							});

							showComboModal = true;
						}
					}
				});
			});
		}

		/*if (AddonItemListByProduct.length > 0) {

			AddonItemListByProduct.slice(0).reverse().map(data => {

				if (data.required == 1 && !data.userClicked) {
					this.setState({
						showComboModal: true,
						showComboModalText: data.categorydetail.name.toLowerCase()
					});

					showComboModal = true;
				}
			});
		}*/

		if (showComboModal === false) {
			let data = this.props.cart;
			let cartItem = this.props.cartItem;

			//console.log('productEach modal=',productEach);

			productEach.totalProductCount = productEach.totalProductCount + 1;

			let addedProduct = [...this.props.cartItem];
			if (addedProduct == undefined) {
				addedProduct = [];
			}

			//console.log('addedProduct=',addedProduct);					

			if (addedProduct.some((e) => e.id == productEach.id)) {

				//console.log('PRESENT', productEach.product_name);
				let sameObj = false;
				let presenetIndex = -1;

				addedProduct.map((el, demoIndex) => {

					let sameAddons = true;
					if (productEach.addons && productEach.addons.length > 0 && el.addons && el.addons.length > 0) {
						sameAddons =
							el.addons.length === productEach.addons.length &&
							el.addons.every(
								(e, i) =>
									e.addon_item_id === productEach.addons[i].addon_item_id,
							);
					}

					let sameCombo = true;
					if (productEach.combo && productEach.combo.length > 0 && el.combo && el.combo.length > 0) {
						sameCombo =
							el.combo.length === productEach.combo.length &&
							el.combo.every(
								(e, i) =>
									e.product_id === productEach.combo[i].product_id,
							);
					}

					if (
						el.id == productEach.id &&
						el.selectedSizeId == productEach.selectedSizeId &&
						sameAddons &&
						sameCombo
					) {
						sameObj = true;
						presenetIndex = demoIndex;
					}
				});

				if (sameObj) {

					let dataSame = this.props.cart;
					let cartItem = this.props.cartItem;

					cartItem[presenetIndex].selectedQuantity = cartItem[presenetIndex].selectedQuantity + 1;

					console.log('cartItem after=', cartItem);

					let totalPrice = 0;
					cartItem.map((data, j) => {
						totalPrice = totalPrice + Number(data.selectedQuantity * data.totalSizePrice);
						console.log(j + '=' + data.product_name + '=', data.selectedQuantity + 'x' + data.totalSizePrice + '=' + Number(data.selectedQuantity * data.totalSizePrice));
					});

					dataSame.cartItem = cartItem;
					dataSame.grandTotal = totalPrice;
					this.props.handleCart(dataSame);
					this.closeModal();
				} else {

					productEach.selectedQuantity = 1;
					cartItem.push(this.state.productEach);

					data.cartItem = cartItem;
					let totalPrice = 0;
					data.cartItem.map(data => {
						totalPrice = totalPrice + (data.selectedQuantity * data.totalSizePrice);
					});
					data.grandTotal = totalPrice;
					this.props.handleCart(data);
					this.closeModal();
				}

			} else {
				productEach.selectedQuantity = 1;
				cartItem.push(this.state.productEach);

				data.cartItem = cartItem;
				let totalPrice = 0;
				data.cartItem.map(data => {
					totalPrice = totalPrice + (data.selectedQuantity * data.totalSizePrice);
				});
				data.grandTotal = totalPrice;
				this.props.handleCart(data);
				this.closeModal();
			}

			this.setToast(this.state.productEach.product_name);
			this.customerProductListByCategory();
		}
	}

	totalProduct = (item) => {
		let cartItem = this.props.cartItem;

		if (cartItem.length > 0) {
			let count = 0;

			cartItem.map(product => {
				if (product.id === item.id) {
					count = count + product.selectedQuantity;
				}
			});
			return count;
		} else {
			return 0;
		}
	};

	clearCart = () => {
		let data = this.props.cart;
		let cartItem = this.props.cartItem;

		cartItem = [];
		data.cartItem = cartItem;
		data.driverTip = 0;
		data.grandTotal = 0;
		this.props.handleCart(data);
	}

	openModal = (data) => {
		data.totalSizePrice = data.selectedSizePrice;
		//console.log('productEach=>', data);
		this.setState({
			productEach: data,
			addonItemLoader: true
		}, () => {
			const productEach = this.state.productEach;

			if (productEach && productEach.product_option.length === 0) {
				let data = this.props.cart;
				let cartItem = this.props.cartItem;

				console.log('productEach=', productEach);

				productEach.totalProductCount = productEach.totalProductCount + 1;

				let addedProduct = [...this.props.cartItem];
				if (addedProduct == undefined) {
					addedProduct = [];
				}

				console.log('addedProduct=', addedProduct);

				if (addedProduct.some((e) => e.id == productEach.id)) {

					console.log('PRESENT', productEach.product_name);
					let sameObj = false;
					let presenetIndex = -1;

					addedProduct.map((el, demoIndex) => {

						let sameAddons = true;
						if (productEach.addons && productEach.addons.length > 0 && el.addons && el.addons.length > 0) {
							sameAddons =
								el.addons.length === productEach.addons.length &&
								el.addons.every(
									(e, i) =>
										e.addon_item_id === productEach.addons[i].addon_item_id,
								);
						}

						let sameCombo = true;
						if (productEach.combo && productEach.combo.length > 0 && el.combo && el.combo.length > 0) {
							sameCombo =
								el.combo.length === productEach.combo.length &&
								el.combo.every(
									(e, i) =>
										e.product_id === productEach.combo[i].product_id,
								);
						}

						if (
							el.id == productEach.id &&
							el.selectedSizeId == productEach.selectedSizeId &&
							sameAddons &&
							sameCombo
						) {
							sameObj = true;
							presenetIndex = demoIndex;
						}

					});

					if (sameObj) {

						let dataSame = this.props.cart;
						let cartItem = this.props.cartItem;

						cartItem[presenetIndex].selectedQuantity = cartItem[presenetIndex].selectedQuantity + 1;

						let totalPrice = 0;
						cartItem.map((data, j) => {
							totalPrice = totalPrice + Number(data.selectedQuantity * data.totalSizePrice);
							console.log(j + '=' + data.product_name + '=', data.selectedQuantity + 'x' + data.totalSizePrice + '=' + Number(data.selectedQuantity * data.totalSizePrice));
						});

						dataSame.cartItem = cartItem;
						dataSame.grandTotal = totalPrice;
						this.props.handleCart(dataSame);

					} else {

						console.log('combo not PRESENT', productEach.product_name);

						productEach.selectedQuantity = 1;

						cartItem.push(this.state.productEach);

						data.cartItem = cartItem;
						let totalPrice = 0;
						data.cartItem.map(data => {
							totalPrice = totalPrice + (data.selectedQuantity * data.totalSizePrice);
						});
						data.grandTotal = totalPrice;
						this.props.handleCart(data);

					}

				} else {
					console.log('NOT PRESENT', productEach.product_name);

					productEach.selectedQuantity = 1;

					cartItem.push(this.state.productEach);

					data.cartItem = cartItem;
					let totalPrice = 0;
					data.cartItem.map(data => {
						totalPrice = totalPrice + (data.selectedQuantity * data.totalSizePrice);
					});
					data.grandTotal = totalPrice;
					this.props.handleCart(data);

				}



				/*if (cartItem && cartItem.length === 0) {  // For Empty Cart
					cartItem.push(this.state.productEach);
					data.cartItem = cartItem;
					let totalPrice = 0;
					data.cartItem.map(data => {
						totalPrice = totalPrice + (data.selectedQuantity * data.totalSizePrice);
					});
					data.grandTotal = totalPrice;
					this.props.handleCart(data);
					console.log('1111');
					//this.props.history.push(`${SERVERFOLDER + '/cart'}`);
				} else {   // For Not Empty Cart
					console.log('22222');
					if (cartItem && cartItem[0].grocery_id === this.state.productEach.grocery_id) {
						cartItem.push(this.state.productEach);
						data.cartItem = cartItem;
						let totalPrice = 0;
						data.cartItem.map(data => {
							totalPrice = totalPrice + (data.selectedQuantity * data.totalSizePrice);
						});
						data.grandTotal = totalPrice;
						this.props.handleCart(data);
						//this.props.history.push(`${SERVERFOLDER + '/cart'}`);
					} else {
						cartItem = [];
						cartItem.push(this.state.productEach);
						data.cartItem = cartItem;
						data.driverTip = 0;
						let totalPrice = 0;
						data.cartItem.map(data => {
							totalPrice = totalPrice + (data.selectedQuantity * data.totalSizePrice);
						});
						data.grandTotal = totalPrice;
						this.props.handleCart(data);
						//this.props.history.push(`${SERVERFOLDER + '/cart'}`);
					}
				}*/

				this.setToast(this.state.productEach.product_name);

				this.setState({
					show: false,
					AddonItemList: false,
					addonItemLoader: false,
				});
			} else {
				this.setState({
					show: true,
					AddonItemList: false,
					addonItemLoader: false,
				});
			}

		});
	};

	setToast = (val) => {
		toast.success(val + ' added to your cart!', {
			theme: 'dark'
		});
	};

	handleChangeItemPerPage = e => {
		this.setState({ itemsPerPage: e.target.value }, () => {
			this.customerProductListByCategory();
		});
	};

	handlePageChange = pageNumber => {
		this.setState({ activePage: pageNumber }, () => {
			this.customerProductListByCategory();
		});
	};

	handleRestaurantInfo = () => {
		this.props.history.push(`${SERVERFOLDER + '/restaurant-info'}`);
	}

	vegToggle = (v) => {
		console.log('=====', v);
		/*if (this.state.selected_product_category.id == -99) {
			let newval = !this.state.isVeg;
			this.setState({isVeg: newval}, () => this.customerProductListByCategory());
		} else {
			let newval = !this.state.isVeg;
			this.setState({isVeg: newval}, () =>
				this.getDish(this.state.selected_product_category.id),
			);
		}*/
	};

	validateCombo = (each) => {
		let validateCombo = false;
		each.map((item) => {
			if (item.product) {
				validateCombo = true;
			}
		});
		console.log('validateCombo=', validateCombo);
		return validateCombo;
	}


	render() {
		const {
			getDataLoader,
			pagingLoader,
			selectedCategry,
			product_category,
			groceryProducts,
			groceryInfo,
			productEach,
			AddonItemListByProduct,
			googleLocationMessage,
			showComboModalText,
			deliveryAvailable,
			addonItemLoader
		} = this.state;

		let settings = {
			dots: false,
			infinite: true,
			speed: 500,
			slidesToShow: product_category && product_category.length > 0 ? product_category.length > 6 ? 6 : product_category.length : 0,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 1199,
					settings: {
						slidesToShow: 5,
						slidesToScroll: 1,
					}
				},
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 1,
					}
				},
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 2,
					}
				},
				{
					breakpoint: 540,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		};

		return (
			<div>
				{/* {allowGoogleLocation ? null : <span className='loaderStyle'><LoadingSpinner /></span>} */}
				{getDataLoader ?
					<div className="" style={{ height: '72vh' }}><AppLoader /></div> :

					<div className="main">

						{/* Grocery info */}
						<div className={`innerBannerPan ${groceryInfo.isOpen == 'open' ? null : 'closeClass'}`} style={{ backgroundImage: groceryInfo.grocery_banner ? `url(${IMAGEURL}grocery/banner/${groceryInfo.grocery_banner})` : `url(${defaultBanner})` }}>
							<div className="container bannerContainer">
								<div className="restroInfo">
									<div className="restroLogo">
										<img src={groceryInfo.grocery_logo ? `${IMAGEURL}grocery/${groceryInfo.grocery_logo}` : `${defaultRestLogo}`} alt='Grocery' />
									</div>
									<div className="restroDetails">
										<h2>{groceryInfo.grocery_name}
											{groceryInfo && groceryInfo.grocery_phone && <span>
												<a className='text-decoration-none' target="_blank" rel="noopener noreferrer">
													<FaPhoneAlt className='text-lightblue' /> {'0' + groceryInfo.grocery_phone}
												</a>
											</span>}
										</h2>
										{groceryInfo.own_delivery == 1 ?
											<p className="location">Grocery Own Driver</p>
											: null
										}
										{groceryInfo.grocery_addr ?
											<p className="location">{groceryInfo.grocery_addr + ' ' + groceryInfo.grocery_city + ' ' + groceryInfo.grocery_state + ' ' + groceryInfo.grocery_country + ' ' + groceryInfo.grocery_zip}</p>
											: null
										}
										<p className="ratingPan">
											{groceryInfo.avg_rating && groceryInfo.avg_rating.length > 0 ?
												<span className="rating"><ImStarFull />
													{groceryInfo.avg_rating && groceryInfo.avg_rating.length > 0 && groceryInfo.avg_rating[0].aggregate} (
													{groceryInfo.rating_count && groceryInfo.rating_count.length > 0 && groceryInfo.rating_count[0].totalCount + ' ratings)'}
												</span>
												:
												<span className="rating">
													No rating
												</span>
											}
											{/*<span className="distance"><ImLocation /> {Number(this.state.distance).toFixed(2)} kms</span>*/}

											{groceryInfo.min_order ?
												<span className="minValue" title='Minimum Order Value'><IoMdCart /> Minimum Order :{Utils.setCurrency(groceryInfo, groceryInfo.min_order)}

												</span>
												: null
											}
										</p>
									</div>
								</div>
							</div>
						</div>

						{/* category slider */}
						<div className={`categorySlidePan ${groceryInfo.isOpen == 'open' ? null : 'closeClass'}`}>
							<div className="container">
								<div className="bannerSlider categorySlider">
									<h2 className="mb-0">Categories</h2>
									<div id="catSlider">
										<div className='sliderWrap'>
											<Slider {...settings}>
												{
													product_category
													&& product_category.length > 0
													&&
													product_category.map(category => (
														<div className="slideItem" key={category.id} >
															<div className={`slideBox ${selectedCategry == category.id ? 'selectClass' : null}`} onClick={() => this.setCategory(category.id)}>
																<div className="slideImg">
																	<img src={category.id == '' ? `${allgrocery}` : category.image ? `${IMAGEURL}grocery_product_category/${category.image}` : `${defaultRestLogo}`} alt='Restro' />
																</div>
																<span>{category.name}</span>
															</div>
														</div>
													))
												}
											</Slider>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* product list */}
						<div className="nearPan">
							<div className="container">
								<h2><strong>Available</strong> Products </h2>
								{pagingLoader ?
									<span className='loaderStyle'><LoadingSpinner /></span> :
									<div className="row">
										{
											groceryProducts && groceryProducts.length > 0 ?

												groceryProducts.map((each, indexProd) => (
													<div className="col-md-6" key={each.id} >
														<div className={`card nearCard foodCard ${groceryInfo.isOpen == 'open' ? null : 'closeClass'}`}>
															<div className="card-body withImgPan">
																<h5 className="card-title">{each.product_name} <span>{Utils.setCurrency(groceryInfo, each.selectedSizePrice)}</span></h5>


																<div className="cardImg">
																	<img src={each.product_image ? `${IMAGEURL}grocery_product/${each.product_image}` : each.product_category ? `${IMAGEURL}grocery_product_category/${each.product_category[0].image}` : `${defaultRestLogo}`} alt='Restro' />
																</div>

																<div className="cardDetails">
																	<div className="location desc">{/*<ReadMoreReact text={each.product_description} />*/}<ReactReadMoreReadLess charLimit={100} readMoreText={'Read More ▼'} readLessText={'Read Less ▲'}>{each.product_description}</ReactReadMoreReadLess></div>
																	<p className="location">
																		{each && each.product_ingredient && each.product_ingredient.map((data, indexI) => {
																			return <span key={indexI}>{data.ingredient_name}, </span>;
																		})}
																	</p>
																	<p className="size mt-2">
																		{each && each.product_size && each.product_size.map((data, indexS) => {
																			return <span className={data.size_id === each.selectedSizeId ? 'selected' : ''}
																				key={indexS}
																				onClick={() => this.change_product_size(data.size_name, data.amount, data.size_id, indexProd)}>
																				<a style={{ cursor: 'pointer' }}>{data.size_name}</a>
																			</span>;
																		})}
																	</p>
																	{groceryInfo.isOpen == 'open' ? deliveryAvailable === true ?
																		<a className="btn playBtn" style={{ cursor: 'pointer' }} onClick={() => this.openModal(each)}><GoPlus /></a>
																		: groceryInfo.supports_collection === 1 ?
																			<a className="btn playBtn" style={{ cursor: 'pointer' }} onClick={() => this.openModal(each)}><GoPlus /></a>
																			: null
																		: null
																	}
																</div>
															</div>
														</div>
													</div>

												))
												:
												<div className='col-12'><h5 className="alert alert-warning">Nothing found near you.</h5></div>
										}
									</div>
								}
							</div>
						</div>

						{/* Pagination */}
						<div className="container paginationPan">
							{this.state.totalCount ? (
								<Row>
									<Col md={6}>
										<ItemPerPage
											handleChangeItemsPerPage={this.handleChangeItemPerPage}
											itemsPerPage={this.state.itemsPerPage}
										/>
									</Col>
									<Col md={6} className="paginationCount">
										<div className="paginationOuter text-right">
											<Pagination
												activePage={this.state.activePage}
												itemsCountPerPage={this.state.itemsPerPage}
												totalItemsCount={this.state.totalCount}
												onChange={this.handlePageChange}
											/>
										</div>
									</Col>
								</Row>
							) : null}
						</div>

						<div>
							<ToastContainer
								position="top-right"
								autoClose={5000}
								newestOnTop={true}
								closeOnClick
								rtl={false}
								pauseOnFocusLoss
								draggable={false}
							/>
						</div>

						{/* add to cart modal */}
						<Modal show={this.state.show} onHide={this.closeModal} size="lg">
							<Modal.Header>
								<Modal.Title style={{ display: 'flex', width: '100%' }}>
									<div style={{ display: 'flex', width: '100%' }}>
										<strong>{productEach.product_name} </strong>
										<span style={{ whiteSpace: 'pre', marginLeft: 'auto' }}>{Utils.setCurrency(groceryInfo, Number(productEach.totalSizePrice).toFixed(2))}</span>
									</div>
								</Modal.Title>
								<span className="modalClose" onClick={() => this.closeModal()} style={{ position: 'relative', bottom: '6px' }}><MdClose /></span>
							</Modal.Header>
							<Modal.Body>
								{addonItemLoader && <LoadingSpinner />}
								{AddonItemListByProduct.length > 0 &&
									AddonItemListByProduct.map((addon) => (
										<div key={addon.id} className='mb-3'>
											<h4>{addon.categorydetail.name}</h4>
											<div style={{ overflow: 'auto' }}>
												<ul className="addOnList 1">
													{addon.addon_items.map((option, i) =>
														<li className={`${option.selected === true ? 'selected' : null}`}
															key={i}
															onClick={() => this.addAddonItem(addon.addon_items, i, addon.max_select, addon.categorydetail.name)}>
															{option.itemdetail.name}
															<span>{Utils.setCurrency(groceryInfo, option.itemdetail.price)}</span> <GoPlus className="plusIcon" />
														</li>
													)}
												</ul>
											</div>
										</div>
									))
								}

								{productEach.product_option && productEach.product_option.length > 0 &&
									productEach.product_option.map(each => (
										this.validateCombo(each.product_variant_details) &&
										<div key={each.id} className='mb-3'>
											<h4>{each.name} {each.required == 1 &&
												<span style={{ color: '#ff0000' }}>*</span>}</h4>
											<div style={{ overflow: 'auto' }}>
												<ul className="addOnList 2">
													{each.product_variant_details.map((item, j) =>
														item.product &&
														<li
															className={`${item.selected === true ? 'selected' : null}`}
															key={j}
															onClick={() => this.addComboItem(each.product_variant_details, j)}>
															{item.product && item.product.product_name}
															<span>{this.state.groceryInfo && this.state.groceryInfo.franchisee && this.state.groceryInfo.franchisee.currency_symbol && this.state.groceryInfo.franchisee.currency_symbol.currency_symbol}{item.price}</span> <GoPlus className="plusIcon" />
														</li>
													)}
												</ul>
											</div>
										</div>
									))
								}

							</Modal.Body>
							<Modal.Footer>
								{!addonItemLoader && <Button className="addCartBtn btn"
									onClick={() => {
										this.addCartItem(productEach, AddonItemListByProduct);
									}}
								>Add to Cart <RiArrowRightSLine /></Button>}
							</Modal.Footer>
						</Modal>

						{/* showWarningModal */}
						<Modal show={this.state.showWarningModal} onHide={this.closeWarningModal} className="WarningModal">
							<Row>
								<Col md={12} className="text-center">
									<h6 className={'text-danger my-4'}> {this.state.errorMessage}</h6>
								</Col>
							</Row>
							<div className="text-center mb-4">
								<Button className="but-gray" type="button" onClick={this.closeWarningModal}>Return</Button>
							</div>
						</Modal>

						{/* select combo modal */}
						<Modal show={this.state.showComboModal} onHide={this.closeComboModal} className="payOptionPop">
							<Modal.Body className='bg-white text-dark'>
								<Row>
									<Col md={12} className="text-center">
										<h5>{showComboModalText}</h5>
									</Col>
								</Row>
							</Modal.Body>
							<div className="text-center my-2">
								<Button type="button" onClick={this.closeComboModal}>
									Ok
								</Button>
							</div>
						</Modal>

						<Modal show={this.state.showLocationDeniedModal} onHide={this.closeLocationDeniedModal} className="payOptionPop">
							<Modal.Body className='bg-white text-dark'>
								<Row>
									<Col md={12} className="text-center">
										<h5>To continue, turn on device location, which uses Google&apos;s location service.</h5>
										<p>For details, go to the location settings.</p>
										<p className="text-danger">{googleLocationMessage && googleLocationMessage}</p>
									</Col>
								</Row>
							</Modal.Body>
							<Modal.Footer>
								<div className="text-center my-2">
									<Button type="button" onClick={this.closeLocationDeniedModal}>
										Return
									</Button>
								</div>
							</Modal.Footer>
						</Modal>

					</div>

				}
			</div>
		);
	}
}

Grocery.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			id: PropTypes.string,
			productid: PropTypes.string
		})
	}),
	handleCart: PropTypes.func,
	cartItem: PropTypes.any,
	history: PropTypes.any,
	coupon: PropTypes.any,
	deliveryInstructions: PropTypes.any,
	driverTip: PropTypes.any,
	grandTotal: PropTypes.any,
	cart: PropTypes.any,
	categoryId: PropTypes.any,
	geolocation: PropTypes.any,
	user: PropTypes.object,
};

const mapStateToProps = (state) => {
	return {
		cartItem: state.cart.cartItem,
		coupon: state.cart.coupon,
		deliveryInstructions: state.cart.deliveryInstructions,
		driverTip: state.cart.driverTip,
		grandTotal: state.cart.grandTotal,
		cart: state.cart,
		user: state.user,
		categoryId: state.servicecategory.categoryId,
		geolocation: state.geolocation
	};
};

const mapDispatchToProps = dispatch => {
	return {
		handleCart: (data) => dispatch(handleCart(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Grocery);
