import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { MdPlayArrow } from 'react-icons/md';
import { connect } from 'react-redux';
import { accessToken, logoutMsg } from '../../../redux/actions/auth';
import { handelUser } from '../../../redux/actions/user';
import { handleCart } from './../../../redux/actions/cart';
import LoadingSpinner from '../../../Components/LoadingSpinner/LoadingSpinner';
// import google from '../../../assets/images/google.jpg';
// import facebook from '../../../assets/images/facebook.jpg';
// import mail from '../../../assets/images/mail.jpg';
// import phone from '../../../assets/images/phone.jpg';
import { SERVERFOLDER } from './../../../Constants';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import axios from './../../../shared/eaxios';
//import { FaAt } from 'react-icons/fa';
import { FaFacebookF, FaMobileAlt } from 'react-icons/fa';

class AllLogin extends Component {
	state = {
		errorMsg: '',
		loader: false,
		user_type_id: null,
		cardSubmitLoader: false,
	}

	componentDidUpdate(prevProps) {
		if (this.props.categoryId !== prevProps.categoryId) {

			let data = this.props.cart;
			let cartItem = this.props.cartItem;

			cartItem = [];
			data.cartItem = cartItem;
			data.driverTip = 0;
			data.grandTotal = 0;
			this.props.handleCart(data);

			this.setState({
				cartItem
			});

			this.props.history.push(`${SERVERFOLDER + '/'}`);
		}
	}

	responseFacebook = (response) => {
		console.log('facebook-response', response);
		if (!response.error) {
			const formData = new window.FormData();
			formData.append('email', response.email);
			formData.append('first_name', response.first_name);
			formData.append('last_name', response.last_name);
			formData.append('provider_user_id', response.id);
			formData.append('provider', 'Facebook');
			//formData.append('device_token', response.accessToken);
			//formData.append('token', '');
			//console.log(formData);
			axios.post('socialMediaLogin', formData)
				.then(res => {
					console.log('res', res);
					if (res.data.success === true) {
						let timeStamp = new Date().getTime();
						let user = {};

						user.order_token = res.data.order_token;
						user.first_name = res.data.user.user_detail.first_name;
						user.last_name = res.data.user.user_detail.last_name;
						user.timeStamp = timeStamp;
						user.id = res.data.user.id;
						user.user_type_name = this.state.user_type_name;
						user.profile_pic = res.data.user.user_detail.profile_pic;
						user.gender = res.data.user.user_detail.gender;
						user.dob = res.data.user.user_detail.dob;
						user.stripe_customer_id = res.data.user.stripe_customer_id;
						user.email = res.data.user.email;
						user.about = res.data.user.user_detail.about;
						user.phone = res.data.user.phone;
						user.register_with = res.data.user.register_with;

						this.props.handelUser(user);
						this.props.sessionTokenUpdate(res.data.token, null);
						this.props.cartItem && this.props.cartItem.length > 0 ?
							this.props.history.push(`${SERVERFOLDER + '/checkout'}`) :
							this.props.history.push(`${SERVERFOLDER + '/'}`);
					} else {
						let errorMessage = '';
						try {
							errorMessage = res.data.message ? res.data.message : res.data.error_description;
						} catch (err) {
							errorMessage = 'No records found.';
						}
						this.setState({
							loader: false,
							errorMsg: errorMessage,
						});
						setTimeout(() => {
							this.setState({ errorMsg: null });
						}, 5000);
					}
				})
				.catch(err => {
					console.log('err', err);
					let errorMessage = '';
					try {
						errorMessage = err.data.message ? err.data.message : err.data.error_description;
					} catch (err) {
						errorMessage = 'No records found.';
					}
					this.setState({
						loader: false,
						errorMsg: errorMessage,
					});
					setTimeout(() => {
						this.setState({ errorMsg: null });
					}, 5000);
				});

		}
	}

	responseGoogle = (response) => {
		console.log('google-response from AllLogin', response);
		if (!response.error) {

			const formData = new window.FormData();
			formData.append('email', response.profileObj.email);
			formData.append('first_name', response.profileObj.givenName);
			formData.append('last_name', response.profileObj.familyName);
			formData.append('provider_user_id', response.profileObj.googleId);
			formData.append('provider', 'Google');
			//formData.append('device_token', response.accessToken);
			formData.append('token', '');

			axios.post('socialMediaLogin', formData)
				.then((res) => {
					if (res.data.success === true) {
						let timeStamp = new Date().getTime();
						let user = {};

						user.order_token = res.data.order_token;
						user.first_name = res.data.user.user_detail.first_name;
						user.last_name = res.data.user.user_detail.last_name;
						user.timeStamp = timeStamp;
						user.id = res.data.user.id;
						user.user_type_name = this.state.user_type_name;
						user.profile_pic = res.data.user.user_detail.profile_pic;
						user.gender = res.data.user.user_detail.gender;
						user.dob = res.data.user.user_detail.dob;
						user.stripe_customer_id = res.data.user.stripe_customer_id;
						user.email = res.data.user.email;
						user.about = res.data.user.user_detail.about;
						user.phone = res.data.user.phone;
						user.register_with = res.data.user.register_with;

						this.props.handelUser(user);
						this.props.sessionTokenUpdate(res.data.token, null);
						this.props.cartItem && this.props.cartItem.length > 0 ?
							this.props.history.push(`${SERVERFOLDER + '/checkout'}`) :
							this.props.history.push(`${SERVERFOLDER + '/'}`);
					} else {
						let errorMessage = '';
						try {
							errorMessage = res.data.message ? res.data.message : res.data.error_description;
						} catch (err) {
							errorMessage = 'No records found.';
						}
						this.setState({
							loader: false,
							errorMsg: errorMessage,
						});
						setTimeout(() => {
							this.setState({ errorMsg: null });
						}, 5000);
					}
				})
				.catch(err => {
					console.log('err', err);
					let errorMessage = '';
					try {
						errorMessage = err.data.message ? err.data.message : err.data.error_description;
					} catch (err) {
						errorMessage = 'No records found.';
					}
					this.setState({
						loader: false,
						errorMsg: errorMessage,
					});
					setTimeout(() => {
						this.setState({ errorMsg: null });
					}, 5000);
				});
		}
	}

	displayError = (e) => {
		let errorMessge = '';
		try {
			errorMessge = e.data.message ? e.data.message : e.data.error_description;
		} catch (e) {
			errorMessge = 'Unknown error!';
		}
		return errorMessge;
	}


	render() {

		return (
			<div className="position-relative">
				{this.state.loader ?
					<span className='loaderStyle'><LoadingSpinner /></span>
					: null
				}
				<div className="welcomeOuter">
					<div className="regWhiteOuter">
						<Container>
							<div className="welcomePage">
								<Row className="show-grid">
									<Col md={12}>
										<div className="welcomeIntro mt-2">
											<div className="login position-relative">
												<h4 className='text-center'>Welcome......</h4>
												<h6 className='text-sm-center mb-4 text-secondary'>Please Create an Account to proceed</h6>
												{this.state.errorMsg ?
													<p className="alert alert-danger text-center" role="alert"> {this.state.errorMsg} </p>
													: null
												}
												<div className="col-md-12">
													<div className="card googleCard" >
														<div className="card-body">
															<GoogleLogin
																clientId="309165283057-6co9ojqu3cj0r81tlq1ujlc4519c0m81.apps.googleusercontent.com"
																onSuccess={this.responseGoogle}
																idpId={false}
																icon={<MdPlayArrow />}
																buttonText={'Sign in with Google'}
																className={'playBtn'}
																onFailure={this.responseGoogle}
																cookiePolicy={'single_host_origin'}
															/>
														</div>
													</div>
												</div><br></br>

												<div className="col-md-12">
													<div className="card facebookCard">
														<div className="card-body">
															<FacebookLogin
																appId="1131659344036923"
																fields="first_name,last_name,email,picture"
																onClick={this.compountClicked}
																callback={this.responseFacebook}
																cssClass={'playBtn'}
																icon={<FaFacebookF />}
																isMobile={false}
																disableMobileRedirect={false}
															/>
														</div>
													</div>
												</div>

												<div className='text-center text-secondary mt-3 mb-3'><strong>OR</strong></div>

												{/*<div className="col-md-12">
													<div className="card iconCard">
														<div className="card-body">
															<NavLink to={SERVERFOLDER + '/signup'}>
																Sign Up with Email
																<FaAt />
															</NavLink>
														</div>
													</div>
												</div><br></br>*/}

												<div className="col-md-12">
													<div className="card iconCard" >
														<div className="card-body">
															<NavLink to={SERVERFOLDER + '/mobilesignup'}>
																Sign Up with Mobile
																<FaMobileAlt />
															</NavLink>
														</div>
													</div>
												</div><br></br>

												<div className='row mt-2 text-center'>
													<div className='col-sm-12'>
														<div>
															<span className="text-muted center">Already on Delivery Guys? <Link to={SERVERFOLDER + '/login'} className='text-orange-primary mb-3'>Sign in</Link></span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</Col>
								</Row>
							</div>
						</Container>
					</div>
				</div>
			</div>
		);
	}
}
AllLogin.propTypes = {
	location: PropTypes.object,
	history: PropTypes.object,
	sessionTokenUpdate: PropTypes.func,
	handelUser: PropTypes.func,
	token: PropTypes.any,
	authlogoutMsg: PropTypes.string,
	resetLogoutMsg: PropTypes.func,
	userType: PropTypes.string,
	cartItem: PropTypes.any,
	handleCart: PropTypes.func,
	categoryId: PropTypes.any,
	cart: PropTypes.any,
};

const mapStateToProps = (state) => {
	return {
		token: state.auth.token,
		authlogoutMsg: state.auth.logoutMsg,
		userType: state.user.user_type_name,
		cartItem: state.cart.cartItem,
		cart: state.cart,
		categoryId: state.servicecategory.categoryId,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		sessionTokenUpdate: (token) => dispatch(accessToken(token)),
		handelUser: (user) => dispatch(handelUser(user)),
		resetLogoutMsg: data => dispatch(logoutMsg(data)),
		handleCart: (data) => dispatch(handleCart(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AllLogin);
