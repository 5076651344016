import React from 'react';
import Loader from 'react-loader-spinner';
import './LoadingSpinner.scss';
const LoadingSpinner = () => (
	<div className="loadingSpinner">
		<Loader 
            type='ThreeDots'
            color='#F15824'
            height='100'	
            width='100'
        />
	</div>
);

export default LoadingSpinner;
