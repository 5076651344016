import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Col, Row, Modal, Container } from 'react-bootstrap';
import axios from '../../../shared/eaxios';
import LoadingSpinner from '../../../Components/LoadingSpinner/LoadingSpinner';
import { AiFillCheckCircle } from 'react-icons/ai';
import { SERVERFOLDER } from '../../../Constants';
import { Formik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';

const initialValues = {
	new_password: '',
	confirm_password: '',
	chk_confirm: ''
};

const schema = Yup.object().shape({
	new_password:
		Yup
			.string()
			.trim('Please remove whitespace')
			.strict()
			.required('Please enter new password'),
	confirm_password:
		Yup
			.string()
			.trim('Please remove whitespace')
			.strict()
			.required('Please enter confirm password')
			.oneOf([Yup.ref('new_password')], 'Passwords does not match'),
	chk_confirm:
		Yup
			.boolean()
			.required('Please accept Terms & Conditions')
});

const getErrorStyles = (errors, touched, fieldName) => {
	if (getIn(errors, fieldName) && getIn(touched, fieldName)) {
		return {
			border: '1px solid red'
		};
	}
};

class ResetPassword extends Component {
	state = {
		token: this.props.match.params.id,
		loader: false,
		succesSubmitModal: false,
		showWarningModal: false,
		errorMsg: null,
		verified: false
	}

	componentDidMount() {
		this.handleVerifyToken();
	}

	handleSubmit = (values) => {
		this.setState({ loader: true }, () => {
			axios.post(`resetPassword?password=${values.new_password}&token=${this.state.token}&password_confirmation=${values.confirm_password}`)
				.then(() => {
					this.setState({
						loader: false,
					}, () => {
						this.showSuccesSubmitModal();
					});
				})
				.catch(err => {
					console.log('err', err);
					let errorMessage = '';
					try {
						errorMessage = err.data.message ? err.data.message : err.data.error_description;
					} catch (err) {
						errorMessage = 'No records found.';
					}
					this.setState({
						loader: false,
						errorMsg: errorMessage,
					});
					setTimeout(() => {
						this.setState({ errorMsg: null });
					}, 2500);
				});
		});
	}

	handleVerifyToken = () => {
		this.setState({ loader: true }, () => {
			axios.get(`forgotPasswordVerify/${this.state.token}`)
				.then((res) => {
					if (res.data.success === true) {
						this.setState({
							loader: false,
							verified: true
						});
					} else {
						this.setState({
							loader: false,
							verified: false,
							showWarningModal: true,
							errorMsg: res.data.message,
						});
					}
				})
				.catch(err => {
					let errorMessage = '';
					try {
						errorMessage = err.data.message ? err.data.message : err.data.error_description;
					} catch (err) {
						errorMessage = 'No records found.';
					}
					this.setState({
						loader: false,
						verified: false,
						showWarningModal: true,
						errorMsg: errorMessage,
					});
				});
		});
	}

	showSuccesSubmitModal = () => {
		this.setState({ succesSubmitModal: true });
	};

	closeSuccesSubmitModal = () => {
		this.setState({ succesSubmitModal: false });
		this.props.history.push(`${SERVERFOLDER + '/login'}`);
	};

	closeWarningModal = () => {
		this.setState({ showWarningModal: false });
		this.props.history.push(`${SERVERFOLDER + '/'}`);
	}

	render() {
		return (
			<div className="position-relative">
				{this.state.loader ?
					<span className='loaderStyle'><LoadingSpinner /></span>
					: null
				}
				{this.state.verified === true ?
					<div className="welcomeOuter">
						<div className="regWhiteOuter">
							<Container>
								<div className="welcomePage">
									<Row className="show-grid">
										<Col md={12}>
											<div className="welcomeIntro mt-4">
												<div className="login position-relative">
													<h4 className='text-center'>Reset Password</h4>
													{this.state.errorMsg ?
														<p className="alert alert-danger text-center" role="alert"> {this.state.errorMsg} </p>
														: null
													}
													<Formik
														initialValues={initialValues}
														validationSchema={schema}
														onSubmit={this.handleSubmit}
													>
														{({
															errors,
															touched,
														}) => {
															return (<Form className='text-dark'>
																<div className="position-relative">
																	<div className="form-group">
																		<label className="form-label font-weight-bold">New Password <span className="text-danger">*</span></label>
																		<Field
																			style={getErrorStyles(
																				errors,
																				touched,
																				'new_password'
																			)}
																			type="password"
																			name="new_password"
																			placeholder="New Password"
																			className="form-control"
																		/>
																		{errors.new_password && touched.new_password ? <span className="errorMsg text-danger">{errors.new_password}</span> : null}
																	</div>
																	<div className="form-group">
																		<label className="form-label font-weight-bold">Confirm Password <span className="text-danger">*</span></label>
																		<Field
																			style={getErrorStyles(
																				errors,
																				touched,
																				'confirm_password'
																			)}
																			type="password"
																			name="confirm_password"
																			placeholder="Confirm Password"
																			className="form-control"
																		/>
																		{errors.confirm_password && touched.confirm_password ? <span className="errorMsg text-danger">{errors.confirm_password}</span> : null}
																	</div>
																	<div className="form-group">
																		<Field
																			style={getErrorStyles(
																				errors, touched,
																				'chk_confirm'
																			)}
																			type="checkbox"
																			name="chk_confirm"
																			className="tc_check"
																		/> I agree to all <Link to={SERVERFOLDER + '/termsofservice'} className='text-orange-primary mb-3'>Terms &amp; Conditions</Link>
																		<br />
																		{errors.chk_confirm ? <span className="errorMsg text-danger">{errors.chk_confirm}</span> : null}
																	</div>

																	<div className='row mt-2 text-center'>
																		<div className='col-sm-12'>
																			<Button variant="primary mr-3" type="submit" className="btnStylePrimary mt-3">
																				<span className="font-weight-normal">Submit</span>
																			</Button>

																		</div>
																	</div>
																</div>
															</Form>);
														}}
													</Formik>

												</div>
											</div>
										</Col>
									</Row>
								</div>
							</Container>
						</div>

					</div>
					: null
				}

				<Modal
					show={this.state.succesSubmitModal}
					onHide={this.closeSuccesSubmitModal}
					className="payOptionPop"
				>
					<Modal.Body className='bg-white text-dark'>
						<Row>
							<Col md={12} className="text-center">
								<span className='successIcon'><AiFillCheckCircle /></span>
							</Col>
						</Row>
						<Row>
							<Col md={12} className="text-center">
								<h5>Your password has been successfully changed</h5>
							</Col>
						</Row>
					</Modal.Body>
					<div className="text-center my-4">
						<Button
							type="button"
							onClick={this.closeSuccesSubmitModal}
						>
							Return
						</Button>
					</div>
				</Modal>

				<Modal
					show={this.state.showWarningModal}
					onHide={this.closeWarningModal}
					className="payOptionPop"
				>
					<Modal.Body className="WarningModal bg-white">
						<Row>
							<Col md={12} className="text-center">
								<h5 className='text-danger'> {this.state.errorMsg}</h5>
							</Col>
						</Row>
					</Modal.Body>
					<div className="text-center mb-4">
						<Button
							className="but-gray"
							type="button"
							onClick={this.closeWarningModal}
						>
							Return
						</Button>
					</div>

				</Modal>

			</div>
		);
	}
}

ResetPassword.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			id: PropTypes.string
		})
	}),
	location: PropTypes.object,
	history: PropTypes.any,
};

export default ResetPassword;
