import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Col, Container, Row, Modal } from 'react-bootstrap';
//import { Link } from 'react-router-dom';
import { Formik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import axios from '../../../shared/eaxios';
import LoadingSpinner from '../../../Components/LoadingSpinner/LoadingSpinner';
import { handleCart } from './../../../redux/actions/cart';
import { AiFillCheckCircle } from 'react-icons/ai';
import { SERVERFOLDER } from './../../../Constants';
import ReCAPTCHA from 'react-google-recaptcha';
const CAPTCHA_KEY = process.env.REACT_APP_CAPTCHA_KEY;

const initialValues = {
    type: 'Enquiry',
    full_name: '',
    email: '',
    phone: '',
    question: '',
    captcha_value: ''
};

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const ContactUsSchema = Yup.object().shape({
    type: 
        Yup
            .string()
            .required('Please select first')
            .nullable(),
    full_name:
        Yup
            .string()
            .trim('Please remove whitespace')
            .strict()
            .required('Please enter your full name.'),
    email:
        Yup
            .string()
            .trim('Please remove whitespace')
            .email('Email must be valid')
            .strict()
            .required('Please enter your email.'),
    phone:
        Yup
            .string()
            .trim('Please remove whitespace')
            .strict()
            .matches(phoneRegExp, 'Phone number is not valid')
            .required('Please enter your mobile number.'),
    question: Yup
        .string()
        .min(30, 'Minimum 30 character are accepted.')
        .trim('Please remove whitespace')
        .strict()
        .required('Please enter your message')
        .nullable(),
    captcha_value: 
        Yup
            .string()
            .required('Are you Robot?'),

});

const getErrorStyles = (errors, touched, fieldName) => {
    if (getIn(errors, fieldName) && getIn(touched, fieldName)) {
        return {
            border: '1px solid red'
        };
    }
};

class ContactUs extends Component {
    state = {
        errorMsg: '',
        loader: false,
        user_type_id: null,
        succesSubmitModal: false,
    }

    handleCaptcha = (value) => {
        console.log('Captcha value:', value);
    }

    componentDidUpdate(prevProps) {
        if (this.props.categoryId !== prevProps.categoryId) {

            let data = this.props.cart;
            let cartItem = this.props.cartItem;

            cartItem = [];
            data.cartItem = cartItem;
            //data.coupon = [];
            data.driverTip = 0;
            data.grandTotal = 0;
            this.props.handleCart(data);

            this.setState({
                cartItem
            });

            this.props.history.push(`${SERVERFOLDER + '/'}`);
        }
    }

    handleSubmit = (values) => {
        console.log('Inside handel submit');
        console.log(values.email);
        values.user_type_id = '2';
        this.setState({ loader: true }, () => {
            axios.post(`contactUs?&email=${values.email}&phone=${values.phone}&question=${values.question}&full_name=${values.full_name}&question=${values.question}&type=${values.type}`)
                .then(() => {
                    this.setState({
                        loader: false,
                    }, () => {
                        this.showSuccesSubmitModal();
                    });
                })
                .catch(err => {
                    console.log('err', err);
                    let errorMessage = '';
                    try {
                        errorMessage = err.data.message ? err.data.message : err.data.error_description;
                    } catch (err) {
                        errorMessage = 'No records found.';
                    }
                    this.setState({
                        loader: false,
                        errorMsg: errorMessage,
                    });
                    setTimeout(() => {
                        this.setState({ errorMsg: null });
                    }, 2500);
                });
        });
    }

    showSuccesSubmitModal = () => {
        this.setState({ succesSubmitModal: true });
    };

    closeSuccesSubmitModal = () => {
        this.setState({ succesSubmitModal: false },
            () => {
                this.props.history.push(`${SERVERFOLDER + '/'}`);
            }
        );
    };

    render() {
        return (
            <div className="position-relative">
                {this.state.loader ?
                    <span className='loaderStyle'><LoadingSpinner /></span>
                    : null
                }
                <div className="welcomeOuter">
                    <div className="regWhiteOuter">
                        <Container>
                            <div className="welcomePage">
                                <Row className="show-grid">
                                    <Col md={12}>
                                        <div className="welcomeIntro mt-4">
                                            <div className="login position-relative">
                                                <h4 className='text-center'>Contact Us</h4>
                                                <h6 className='text-sm-center text-secondary mb-4'>Whether you’re curious about features—we are ready to answer any and all questions.</h6>
                                                {this.state.errorMsg ?
                                                    <p className="alert alert-danger text-center" role="alert"> {this.state.errorMsg} </p>
                                                    : null
                                                }
                                                <Formik
                                                    initialValues={initialValues}
                                                    validationSchema={ContactUsSchema}
                                                    onSubmit={this.handleSubmit}
                                                >
                                                    {({
                                                        errors,
                                                        touched,
                                                        setFieldValue
                                                    }) => {
                                                        return (<Form className='text-dark'>
                                                            <div className="position-relative">
                                                                <div className="form-group">
                                                                    
                                                                    <Field
                                                                        style={getErrorStyles(
                                                                            errors,
                                                                            touched,
                                                                            'type'
                                                                        )}
                                                                        name="type"
                                                                        component="select"
                                                                        className={'form-control'}
                                                                        autoComplete="nope"
                                                                        placeholder="select"
                                                                    >
                                                                        <option value="Enquiry" key="1"> Enquiry</option>
                                                                        <option value="Complaint" key="0">Complaint</option>
                                                                    </Field>
                                                                    {errors.type && touched.type ?
                                                                        <span className="errorMsg text-danger">{errors.type}</span>
                                                                        : null}
                                                                </div>
                                                                <div className="form-group">
                                                                    <label className="form-label font-weight-bold">Full Name <span className="text-danger">*</span></label>
                                                                    <Field
                                                                        style={getErrorStyles(
                                                                            errors,
                                                                            touched,
                                                                            'full_name'
                                                                        )}
                                                                        type="input"
                                                                        name="full_name"
                                                                        placeholder="Full Name"
                                                                        className="form-control"
                                                                    />
                                                                    {errors.full_name && touched.full_name ? <span className="errorMsg text-danger">{errors.full_name}</span> : null}
                                                                </div>
                                                                <div className="form-group">
                                                                    <label className="form-label font-weight-bold">Email ID <span className="text-danger">*</span></label>
                                                                    <Field
                                                                        style={getErrorStyles(
                                                                            errors,
                                                                            touched,
                                                                            'email'
                                                                        )}
                                                                        type="email"
                                                                        name="email"
                                                                        placeholder="Email ID"
                                                                        className="form-control"
                                                                    />
                                                                    {errors.email && touched.email ? <span className="errorMsg text-danger">{errors.email}</span> : null}
                                                                </div>
                                                                <div className="form-group">
                                                                    <label className="form-label font-weight-bold">Mobile Number <span className="text-danger">*</span></label>
                                                                    <Field
                                                                        style={getErrorStyles(
                                                                            errors,
                                                                            touched,
                                                                            'phone'
                                                                        )}
                                                                        type="input"
                                                                        name="phone"
                                                                        placeholder="Mobile Number"
                                                                        className="form-control"
                                                                    />
                                                                    {errors.phone && touched.phone ? <span className="errorMsg text-danger">{errors.phone}</span> : null}
                                                                </div>
                                                                <div className="form-group">
                                                                    <label className="form-label font-weight-bold">Message <span className="text-danger">*</span></label>
                                                                    <Field
                                                                        style={getErrorStyles(
                                                                            errors,
                                                                            touched,
                                                                            'question'
                                                                        )}
                                                                        component={'textarea'}
                                                                        name="question"
                                                                        placeholder="Enter Your Message"
                                                                        className="form-control"
                                                                    />
                                                                    {errors.question && touched.question ? <span className="errorMsg text-danger">{errors.question}</span> : null}
                                                                </div>

                                                                <div className="form-group">
                                                                    <label></label>
                                                                    <ReCAPTCHA
                                                                        sitekey={CAPTCHA_KEY}
                                                                        theme='light'
                                                                        size='normal'
                                                                        onChange={(response) => { setFieldValue('captcha_value', response); }}
                                                                    />
                                                                    {errors.captcha_value && touched.captcha_value ? <span className="errorMsg text-danger">{errors.captcha_value}</span> : null}
                                                                
                                                                </div>

                                                                <div className='row mt-2 text-center'>
                                                                    <div className='col-sm-12'>
                                                                        <Button variant="primary mr-3" type="submit" className="btnStylePrimary mt-3">
                                                                            <span className="font-weight-normal">Submit</span>
                                                                        </Button>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Form>);
                                                    }}
                                                </Formik>

                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </div>

                </div>

                <Modal
                    show={this.state.succesSubmitModal}
                    onHide={this.closeSuccesSubmitModal}
                    className="payOptionPop"
                >
                    <Modal.Body className='bg-white text-dark'>
                        <Row>
                            <Col md={12} className="text-center">
                                <span className='successIcon'><AiFillCheckCircle /></span>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className="text-center">
                                <h5>Your feeback submitted successfully.</h5>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <div className="text-center my-4">
                        <Button

                            type="button"
                            onClick={this.closeSuccesSubmitModal}
                        >
                            Return
                        </Button>
                    </div>
                </Modal>

            </div>
        );
    }
}
ContactUs.propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
    handleCart: PropTypes.func,
    cartItem: PropTypes.any,
    categoryId: PropTypes.any,
    cart: PropTypes.any,
};


const mapStateToProps = (state) => {
    return {
        cartItem: state.cart.cartItem,
        cart: state.cart,
        categoryId: state.servicecategory.categoryId,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handleCart: (data) => dispatch(handleCart(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
