import React from 'react';
import PropTypes from 'prop-types';

const ItemsPerPage = ({ itemsPerPage, handleChangeItemsPerPage })=>{
    return(
        <div className="pageItemWrap d-flex flex-row">
            <span className="mr-2 mt-2 font-weight-500">Items per page</span>
            <select
                id={itemsPerPage}
                className="form-control truncatefloat-left w-90"
                onChange={handleChangeItemsPerPage}
                value={itemsPerPage}>
                <option value='12'>12</option>     
                <option value='30'>30</option>     
            </select>
        </div>
    );
};

ItemsPerPage.propTypes = {
    itemsPerPage: PropTypes.any,
    handleChangeItemsPerPage: PropTypes.func,
};

export default ItemsPerPage;