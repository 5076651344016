import React, { Component } from 'react';
import { Modal, Button, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { FaAngleLeft, FaPlusCircle, FaRegCheckCircle } from 'react-icons/fa';
import { MdEdit, MdClose, MdClear } from 'react-icons/md';
import { RiArrowRightSLine } from 'react-icons/ri';
import { SERVERFOLDER } from './../../../Constants';
import nocoupon from '../../../assets/images/baby_crying.png';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from './../../../shared/eaxios';
import orderAxios from './../../../shared/orderEaxios';
import LoadingSpinner from '../../../Components/LoadingSpinner/LoadingSpinner';
import { Formik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { AiFillCheckCircle } from 'react-icons/ai';
import valid from 'card-validator';
import InputMask from 'react-input-mask';
import { handleCart } from './../../../redux/actions/cart';
import Moment from 'moment';

import PaymentForm from './PaymentForm.jsx';
import * as Utils from '../../../Utils';

const initialValues = {
    type: 1,
    tag: 'home',
    addr: '',
    addr2: '',
    country: '',
    state: '',
    city: '',
    zip: '',
    lat: '',
    lng: ''
};

const schema = Yup.object().shape({
    addr: Yup.string()
        .strict()
        .required('Please type address'),
    addr2: Yup.string()
        .strict(),
    country: Yup.string()
        .strict()
        .required('Please type country'),
    state: Yup.string()
        .strict()
        .required('Please type state'),
    city: Yup.string()
        .strict()
        .required('Please type city'),
    zip: Yup.string()
        .strict()
        .required('Please type zip')
});

const getErrorStyles = (errors, touched, fieldName) => {
    if (getIn(errors, fieldName) && getIn(touched, fieldName)) {
        return {
            border: '1px solid red'
        };
    }
};

const initialValuesCard = {
    nameOnCard: '',
    cardNumber: '',
    expdate: '',
    cvc: ''
};

const createCardSchema = Yup.object().shape({
    nameOnCard: Yup.string()
        .required('Please type card holder name'),
    cardNumber: Yup.string()
        .test('test-cardNumber', 'Credit/Debit Card number is invalid', value => valid.number(value).isValid)
        .required('Please type card number'),
    expdate: Yup.string()
        .test('test-expdate', 'Expiration date is invalid', value => valid.expirationDate(value).isValid)
        .required('Expiration date is required'),
    cvc: Yup.string()
        .test('test-cvc', 'CVC is invalid', value => valid.cvv(value).isValid)
        .required('Please type your cvc number'),
});

class Checkout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showItem: true,
            addAddrsShow: false,
            additionalCharges: null,
            addressErrorMessge: false,
            addressList: [],
            addressLoader: false,
            addrShow: false,
            cardErrorMessge: null,
            cardList: [],
            cardLoader: false,
            cardShow: false,
            cartItem: [],
            checkDistanceErrorMessge: null,
            checkDistanceLoader: false,
            cod: false,
            card: false,
            deliveryAvailable: null,
            editData: null,
            errorMsg: null,
            finalConfirmation: false,
            getAditionalChargesMessge: null,
            getrestaurantErrorMessge: null,
            checkoutLoader: false,
            grandTotal: parseFloat(0).toFixed(2),
            isEdit: false,
            loader: false,
            notSelectMessage: null,
            openNotSelectedModal: false,
            openValidationModal: false,
            validationMessage: null,
            paymentLoader: false,
            restaurantInfo: null,
            searchString: '',
            selectedAddress: null,
            selectedCard: null,
            serviceableLocation: null,
            submitErrorMsg: null,
            submitLoader: false,
            submitOrderErrorMsg: null,
            succesSubmitModalCard: false,
            totalPrice: parseFloat(0).toFixed(2),

            collectionMode: this.props.cart.only_collection == 0 && this.props.cart.isDelivery == 'open' ? 'delivery' : 'pickup',
            currencyPostion: '',
            currencySymbol: '',

            freeShiping: false,

            promoDiscount: parseFloat(0).toFixed(2),
            coupon: null,
            couponList: [],
            couponErrorMessge: null,

            show3DModal: false,
            lockPhase: false,
            orderId: null,
        };
    }

    fetchCouponList = () => {
        let url;
        this.setState({ couponLoader: true }, () => {

            if (this.props.cart.shopType == 'restaurant') {
                url = `customerCouponList?user_id=${this.props.user.id}&restaurant_id=${this.props.cart.shopId}`;
            } else {
                url = `customerCouponList?user_id=${this.props.user.id}&grocery_id=${this.props.cart.shopId}`;
            }

            axios.get(url)
                .then(response => {
                    this.setState({
                        couponLoader: false,
                        couponList: response.data.data
                    });
                    //console.log('CouponList=>>', this.state.couponList);
                })
                .catch(e => {
                    let errorMsg = this.displayError(e);
                    this.setState({
                        couponErrorMessge: errorMsg,
                        couponLoader: false
                    });
                    setTimeout(() => {
                        this.setState({ couponErrorMessge: null });
                    }, 5000);
                });
        });
    }

    fetchCurrentlocation = () => {
        const location = window.navigator && window.navigator.geolocation;
        if (location) {
            location.getCurrentPosition((position) => {
                this.setState({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                });
            }, () => {
                this.setState({ latitude: 'err-latitude', longitude: 'err-longitude' });
            });
        }
    }

    redeemCoupon = (data) => {
        let url;

        if (this.props.cart.shopType == 'restaurant') {
            url = `applyCoupon?user_id=${this.props.user.id}&restaurant_id=${this.props.cart.shopId}&apply=0&coupon_id=${data.id}&item_total=${this.state.totalPrice}`;
        } else {
            url = `applyCoupon?user_id=${this.props.user.id}&grocery_id=${this.props.cart.shopId}&apply=0&coupon_id=${data.id}&item_total=${this.state.totalPrice}`;
        }

        axios.post(url)
            .then(response => {
                if (response.data.success == true) {

                    let cartdata = this.props.cart;
                    cartdata.cartItem = this.state.cartItem;
                    this.props.handleCart(cartdata);
                    this.setState({ showApplyCouponModal: false });


                    this.setState({
                        couponErrorMessge: null,
                        redemCoupns: data,
                        coupon: data
                    }, () => {
                        this.calculateDiscount(data);
                    });

                } else {
                    this.setState({
                        couponErrorMessge: response.message,
                    });
                }
            })
            .catch(e => {
                let errorMsg = this.displayError(e);
                this.setState({
                    couponErrorMessge: errorMsg,
                    couponLoader: false
                });
                setTimeout(() => {
                    this.setState({ couponErrorMessge: null });
                }, 5000);
            });
    };

    calculateDiscount = (coupon) => {

        console.log('coupon=>>', coupon);

        let product = this.props.cartItem;
        let discount_off = 0;
        //let free_shiping = false;

        coupon.coupon_action.type && coupon.coupon_action.type.length > 0 && coupon.coupon_action.type.map((element) => {

            if (element == 'per_line_item') {

                if (coupon.coupon_action.per_line_item.type == 'flat_percent') {
                    let amount = coupon.coupon_action.per_line_item.amount;

                    product.forEach((product) => {
                        //console.log('each product=>', product);
                        let totalPrice =
                            parseFloat(product.addonPrice) +
                            parseFloat(product.comboPrice) +
                            parseFloat(product.selectedSizePrice);

                        let discount = (totalPrice * amount) / 100;

                        //console.log('DISC', discount);
                        discount_off = discount_off + discount;
                    });
                }

                if (coupon.coupon_action.per_line_item.type == 'flat_rate') {
                    let amount = coupon.coupon_action.per_line_item.amount;

                    discount_off = parseFloat(product.length * amount).toFixed(2);

                    /*product.forEach((product) => {
                      let totalPrice = product.totalPrice * product.selectedQuantity;
                      let discount = totalPrice - amount;
                      discount_off = discount_off + discount;
                    });*/
                }
            }

            if (element == 'whole_order') {
                if (coupon.coupon_action.whole_order.type == 'flat_percent') {
                    let amount = coupon.coupon_action.whole_order.amount;
                    
                    discount_off = (
                        (parseFloat(amount) / 100) *
                        parseFloat(this.state.totalPrice)
                    ).toFixed(2);

                    //console.log('whole_order discount_off', discount_off);
                }

                if (coupon.coupon_action.whole_order.type == 'flat_rate') {
                    let amount = coupon.coupon_action.whole_order.amount;

                    discount_off = discount_off + amount;
                }
            }

            if (element == 'free_shipping') {
                this.setState({ freeShiping: true });
            } else {
                this.setState({ freeShiping: false });
            }
        });

        //console.log('discount_off=>>', discount_off);

        if (coupon.max_discount != 0) {
            discount_off = discount_off > coupon.max_discount ? coupon.max_discount : discount_off;
            //console.log('max_discount=>>', discount_off);
        }


        this.setState({
            promoDiscount: parseFloat(discount_off).toFixed(2),
        },
            () => this.calculateTotal(), this.showSuccesCouponModal()
        );
    };

    deleteCoupon = () => {

        let data = this.props.cart;
        data.coupon = '';
        this.props.handleCart(data);

        let coupon = '';
        let grandTotal = this.state.grandTotal;
        let promoDiscount = this.state.promoDiscount;

        if (this.state.freeShiping) {
            let additionalCharges = this.state.additionalCharges;
            additionalCharges.delivery_charge = additionalCharges.driver_delivery_charge;

            this.setState({
                additionalCharges: additionalCharges,
                freeShiping: false
            }, () => {
                console.log('after delete Coupon calculateAdditionalCharges', this.state.additionalCharges);
            });

            grandTotal = grandTotal + Number(promoDiscount) + this.state.additionalCharges.delivery_charge;
        } else {
            grandTotal = grandTotal + Number(promoDiscount);
        }

        promoDiscount = 0;

        console.log('deleteCoupon promoDiscount=>', this.state.promoDiscount);
        console.log('deleteCoupon grandTotal=>', grandTotal);

        this.setState({ grandTotal, promoDiscount, coupon });
    }

    updateDiscount = () => {

        let grandTotal = this.state.grandTotal;
        let promoDiscount = this.state.promoDiscount;

        grandTotal = grandTotal - Number(promoDiscount);

        //console.log('promoDiscount=>', this.state.promoDiscount);
        console.log('grandTotal=>', grandTotal);

        this.setState({
            grandTotal
        },
            () => this.showSuccesCouponModal(),
        );
    }

    componentDidMount() {
        console.log('collectionMode=>', this.state.collectionMode);
        if (this.props.cartItem && this.props.cartItem.length > 0) {
            this.fetchAddressList();
            this.fetchCouponList();

            let totalPrice = 0;
            this.props.cartItem.map(data => {
                totalPrice = totalPrice + (data.selectedQuantity * data.totalSizePrice);
            });
            this.setState({
                cartItem: this.props.cartItem,
                totalPrice,
                grandTotal: totalPrice + Number(this.props.driverTip),
            });
            let cartItem = this.props.cartItem;
            cartItem.map(data => {
                data.addons && data.addons.length > 0 && data.addons.map(adata => {
                    if (adata.selected === true) {
                        adata.iselected = 1;
                    }
                });
                data.categories && data.categories.length > 0 && data.categories.map(cdata => {
                    cdata.addon_items && cdata.addon_items.length > 0 && cdata.addon_items.map(aidata => {
                        if (aidata.selected === true) {
                            aidata.iselected = 1;
                        }
                    });
                });
            });
            let data = this.props.cart;
            data.cartItem = cartItem;
            this.props.handleCart(data);



        } else {
            this.props.history.push(`${SERVERFOLDER + '/cart'}`);
        }
    }

    /* componentWillMount() {
         if (this.props.cart.isDelivery == 'close') {
             this.setState({
                 openNotSelectedModal: true,
                 notSelectMessage: 'Only Click & Collect avaliable.'
             });
         }
     }*/

    componentDidUpdate(prevProps) {
        if (this.props.categoryId !== prevProps.categoryId) {

            let data = this.props.cart;
            let cartItem = this.props.cartItem;

            cartItem = [];
            data.cartItem = cartItem;
            data.driverTip = 0;
            data.grandTotal = 0;
            this.props.handleCart(data);

            this.setState({
                cartItem
            });

            this.props.history.push(`${SERVERFOLDER + '/'}`);
        }
    }

    //step 1
    fetchAddressList = () => {
        this.setState({ addressLoader: true }, () => {
            axios.get(`addressListv2?user_id=${this.props.user.id}`)
                .then(response => {
                    let addressList = response.data.data.data;

                    if (addressList.length > 0 && addressList.some((e) => e.is_default == 1)) {
                        addressList.length > 0 && addressList.map((data) => {
                            data.is_default === 1 ? data.selected = true : data.selected = false;
                        });
                    } else {
                        addressList.length > 0 && addressList.map((data, i) => {
                            i === 0 ? data.selected = true : data.selected = false;
                        });
                    }

                    const selectedAddress = addressList.length > 0 && addressList.filter((data) => {
                        return data.selected === true;
                    });
                    //console.log('Current Selected Address', selectedAddress[0]);
                    this.setState({
                        addressLoader: false,
                        addressList,
                        selectedAddress: selectedAddress && selectedAddress[0]
                    }, () => this.fetchInfo());
                })
                .catch(e => {
                    let errorMsg = this.displayError(e);
                    this.setState({
                        addressErrorMessge: errorMsg,
                        addressLoader: false
                    });
                    setTimeout(() => {
                        this.setState({ addressErrorMessge: null });
                    }, 5000);
                });
        });
    }

    //step 2
    fetchInfo = () => {
        let url;
        const { searchString } = this.state;

        if (this.props.categoryId == 1) {
            url = `restaurantDetails/${this.props.cart.shopId}?lat=${this.props.geolocation.latitude}&lng=${this.props.geolocation.longitude}&searchString=${searchString}`;
        } else {
            url = `groceryDetails/${this.props.cart.shopId}?lat=${this.props.geolocation.latitude}&lng=${this.props.geolocation.longitude}&searchString=${searchString}`;
        }

        this.setState({
            checkoutLoader: true
        }, () => {
            axios.get(url)
                .then(res => {
                    if (res.data.success == true) {
                        this.setState({
                            checkoutLoader: false,
                            shopInfo: res.data.data,
                        }, () => {
                            this.state.shopInfo.delivery_area !== '' &&
                                this.checkDeliveryStatus(this.state.shopInfo.delivery_area);
                        });
                    } else {
                        this.setState({
                            showWarningModal: true,
                            errorMessage: res.data.message,
                            pastLoader: false
                        });
                        setTimeout(() => {
                            this.setState({ errorMessage: null, showWarningModal: false, });
                        }, 5000);
                    }
                })
                .catch(e => {
                    let errorMsg = this.displayError(e);
                    this.setState({
                        submitOrderErrorMsg: errorMsg,
                        checkoutLoader: false
                    });
                    setTimeout(() => {
                        this.setState({ submitOrderErrorMsg: null });
                    }, 5000);
                });
        });
    };

    //step 3
    checkDeliveryStatus = (area) => {
        const deliveryArea = JSON.parse(area);
        const polygon = new window.google.maps.Polygon({ paths: deliveryArea });

        const currentCoordinates = new window.google.maps.LatLng(`${this.state.selectedAddress.lat}`, `${this.state.selectedAddress.lng}`);

        const resultPath = window.google.maps.geometry.poly.containsLocation(currentCoordinates, polygon);

        this.setState({
            deliveryAvailable: resultPath
        }, () => {

            console.log('deliveryAvailable=>>', resultPath);

            if (this.state.collectionMode == 'pickup') {
                this.setState({ serviceableLocation: true }, () => this.calculateAditionalCharges(this.state.selectedAddress.lat, this.state.selectedAddress.lng));
            } else {

                this.checkDistance(this.state.selectedAddress.lat, this.state.selectedAddress.lng);
            }
        });
    }

    //step 4
    checkDistance = (latitude, longitude) => {

        const formData = new window.FormData();

        if (this.props.cart.shopType == 'restaurant') {
            formData.append('restaurant_id', this.props.cart.shopId);
        } else {
            formData.append('grocery_id', this.props.cart.shopId);
        }

        formData.append('customer_id', this.props.user.id);
        formData.append('order_lat', latitude);
        formData.append('order_lng', longitude);

        this.setState({ checkDistanceLoader: true, checkDistanceErrorMessge: null }, () => {
            orderAxios.post('checkDistance', formData)
                .then(response => {
                    //console.log('response=>>', response.data);
                    if (response.data.success) {
                        this.setState({
                            checkDistanceLoader: false,
                            serviceableLocation: true,
                        }, () => {
                            //console.log('checkDistance:serviceableLocation', this.state.serviceableLocation);
                            this.calculateAditionalCharges(latitude, longitude);
                        });
                    } else {
                        this.setState({
                            checkDistanceLoader: false,
                            serviceableLocation: false,
                        }, () => {
                            console.log('checkDistance:serviceableLocation', this.state.serviceableLocation);
                        });
                    }
                })
                .catch(e => {
                    let errorMsg = this.displayError(e);
                    this.setState({
                        checkDistanceLoader: false,
                        serviceableLocation: false,
                        checkDistanceErrorMessge: errorMsg,
                    }, () => {
                        console.log('checkDistance:serviceableLocation', this.state.serviceableLocation);
                    });
                    /*setTimeout(() => {
                        this.setState({ checkDistanceErrorMessge: null });
                    }, 20000);*/
                });
        });
    }

    //step 5
    calculateAditionalCharges = (latitude, longitude) => {
        this.setState({
            loader: true
        }, () => {
            const cartItem = this.props.cartItem;
            const list = [];
            list.push(cartItem);

            const formData = new window.FormData();

            if (this.props.cart.shopType == 'restaurant') {
                formData.append('restaurant_id', this.props.cartItem[0].restaurant_id);
            } else {
                formData.append('grocery_id', this.props.cartItem[0].grocery_id);
            }
            formData.append('customer_id', this.props.user.id);
            formData.append('order_lat', latitude);
            formData.append('order_lng', longitude);
            formData.append('collection_only', this.state.collectionMode == 'pickup' ? 1 : 0);

            if (this.props.cart.shopType == 'restaurant') {
                formData.append('product', list);
            } else {
                formData.append('grocery_product', list);
            }

            orderAxios.post('calculateAdditionalCharges', formData)
                .then(res => {
                    if (res.data.success) {

                        this.setState({
                            loader: false,
                            additionalCharges: res.data,
                        }, () => {
                            console.log('calculateAdditionalCharges', this.state.additionalCharges);
                            this.calculateTotal();
                        });
                    }
                })
                .catch(e => {
                    let errorMsg = this.displayError(e);
                    this.setState({
                        getAditionalChargesMessge: errorMsg,
                        loader: false
                    });
                    setTimeout(() => {
                        this.setState({ getAditionalChargesMessge: null });
                    }, 5000);
                });
        });
    };

    //step 6
    calculateTotal = () => {
        this.setState({
            loader: true
        }, () => {
            let netTotal = Number(this.state.totalPrice);
            let promoDiscount = this.state.promoDiscount;

            console.log('netTotal=>', netTotal);
            console.log('promoDiscount=>', this.state.promoDiscount);

            let total = Number(netTotal) - Number(promoDiscount);

            console.log('after discount=>', total);

            if (this.state.freeShiping) {
                let additionalCharges = this.state.additionalCharges;
                additionalCharges.delivery_charge = 0;
                this.setState({
                    additionalCharges: additionalCharges,
                }, () => {
                    console.log('after apply freeShiping calculateAdditionalCharges', this.state.additionalCharges);
                });
            } else {
                let additionalCharges = this.state.additionalCharges;

                if (additionalCharges.delivery_charge == 0) { // if already freeShiping applied

                    additionalCharges.delivery_charge = additionalCharges.driver_delivery_charge;

                    this.setState({
                        additionalCharges: additionalCharges,
                        freeShiping: false
                    }, () => {
                        console.log('after no freeShiping calculateAdditionalCharges', this.state.additionalCharges);
                    });
                }
            }

            if (this.state.collectionMode == 'pickup') {
                total = total + Number(this.state.additionalCharges.delivery_charge) + Number(this.state.additionalCharges.service_charge);
            } else {
                total = total + Number(this.state.additionalCharges.delivery_charge) + Number(this.state.additionalCharges.service_charge) + Number(this.props.driverTip);
            }


            //console.log('netTotal=>', total);

            this.setState({
                grandTotal: total,
                loader: false,
            }, () => {
                console.log('grandTotal=>', total);
            });

        });
    };

    // step mode chage 1
    setDeliveryMode = (e) => {
        e.persist();
        //console.log('collectionMode=>', e.target.value);
        this.setState({ lockPhase: false });

        if (e.target.value == 'pickup') {
            this.setState({ collectionMode: e.target.value, serviceableLocation: true }, () => this.setOtherCharges());
        } else {

            if (this.props.cart.isDelivery == 'close') {
                this.setState({
                    openNotSelectedModal: true,
                    notSelectMessage: 'Only Click & Collect avaliable.'
                });
            } else {
                this.setState({ collectionMode: e.target.value, serviceableLocation: null }, () => this.setOtherCharges());
            }

        }
    }

    //step mode chage 2
    setOtherCharges = () => {
        if (this.state.collectionMode == 'pickup') {
            this.calculateAditionalCharges(this.props.geolocation.latitude, this.props.geolocation.longitude);
        } else {
            if (this.state.selectedAddress) {
                this.checkDistance(this.state.selectedAddress.lat, this.state.selectedAddress.lng);
            }
        }
    };

    //step 7
    validatecartProduct = () => {

        let product = [...this.props.cartItem];

        //console.log('=======ORGINAAL BODY', JSON.stringify(product));

        const newColumns = product.map((item) => {
            delete item.product_size;
            delete item.selectedSize;
            const { selectedSizeId: selectedSize, ...rest } = item;
            return { selectedSize, ...rest };
        });

        //console.log('=======BEFORE PRODUCT VALIDATION', JSON.stringify(newColumns));

        const formData = new window.FormData();

        if (this.props.cart.shopType == 'restaurant') {
            formData.append('restaurant_id', this.props.cart.shopId);
        } else {
            formData.append('grocery_id', this.props.cart.shopId);
        }

        formData.append('customer_id', this.props.user.id);
        formData.append('product', JSON.stringify(newColumns));

        orderAxios.post('checkoutValidation', formData)
            .then(res => {
                //console.log('=======AFTER PRODUCT VALIDATION', JSON.stringify(res.data));

                let response = res.data;

                if (response.success == true) {
                    if (response.data.allErrors.length == 0 && response.data.availableProduct == response.data.cartProduct) {
                        this.sendOrderNow();
                    } else {
                        let errorString = '';
                        if (response.data.allErrors.length > 0) {
                            response.data.allErrors.map((item, index) => {
                                //console.log('item=', JSON.stringify(item));
                                if (index == 0) {
                                    errorString = errorString + item;
                                } else {
                                    errorString = errorString + '\n' + item;
                                }
                            });
                        }
                        this.setState({
                            openValidationModal: true,
                            validationMessage: 'Your added cart element is unavailable or price changed.'
                        });
                    }
                }
            })
            .catch(e => {
                let errorMsg = this.displayError(e);
                this.setState({
                    submitOrderErrorMsg: errorMsg,
                    checkoutLoader: false
                });
                setTimeout(() => {
                    this.setState({ submitOrderErrorMsg: null });
                }, 5000);
            });
    }

    //step 8
    sendOrderNow = () => {
        console.log('collectionMode=>', this.state.collectionMode);

        if (this.state.collectionMode === null) {
            this.setState({
                openNotSelectedModal: true,
                notSelectMessage: 'To continue, Please select either Delivery or Click & Collect.'
            });
        }

        if (this.state.collectionMode === 'delivery') { //Delivery
            if (this.state.cod === false && this.state.card === false) {
                this.setState({
                    openNotSelectedModal: true,
                    notSelectMessage: 'To continue, Please select either card or cash on delivery for payment.'
                });
            } else if (this.state.selectedAddress === false) {
                this.setState({
                    openNotSelectedModal: true,
                    notSelectMessage: 'Please add a delivery address first.'
                });
            } else {
                this.createOrderStart();
            }
        } else if (this.state.collectionMode === 'pickup') { //Click & Collect
            if (this.state.cod === false && this.state.card === false) {
                this.setState({
                    openNotSelectedModal: true,
                    notSelectMessage: 'To continue, please select either card or cash on pickup for payment.'
                });
            } else {
                this.createOrderStart();
            }
        }
    };

    createOrderStart = () => {

        if (this.state.lockPhase === true) {
            return false;
        }

        let cartItem = this.props.cartItem;
        let productItem = [];
        cartItem && cartItem.length > 0 && cartItem.map(data => {
            let addon_category = [];
            data.categories && data.categories.length > 0 && data.categories.map(cdata => {
                cdata.addon_items.length > 0 && cdata.addon_items.map(adata => {
                    adata.selected === true && addon_category.push(cdata.categorydetail && cdata.categorydetail.name);
                });
            });
            let product = {};

            if (this.props.cart.shopType == 'restaurant') {
                product.product_id = data.id;
            } else {
                product.grocery_product_id = data.id;
            }
            product.product_name = data.product_name;
            product.product_discount = data.product_discount ? data.product_discount : 0;
            product.size_name = data.selectedSizeName;
            product.addon_category = addon_category;
            product.addon_item = data.addons;
            product.quantity = data.selectedQuantity;
            product.size_price = data.selectedSizePrice;
            product.product_option = data.combo;
            product.product_note = data.specialInstructions;
            productItem.push(product);
        });

        let orderData = {};

        if (this.props.cart.shopType == 'restaurant') {
            orderData.restaurant_id = cartItem[0].restaurant_id;
        } else {
            orderData.grocery_id = cartItem[0].grocery_id;
        }

        orderData.payment_method = this.state.card === true ? 'card' : 'cod';
        orderData.collection_only = this.state.collectionMode == 'pickup' ? 1 : 0;

        orderData.franchisee_id = cartItem[0].franchisee_id.franchisee_id;
        orderData.customer_id = this.props.user.id;
        orderData.grand_total = this.state.grandTotal;
        orderData.driver_tip_amount = this.state.collectionMode == 'pickup' ? 0 : this.props.driverTip;
        orderData.subtotal = this.state.totalPrice;
        orderData.tax_amount = 0;
        orderData.order_mode = 'web';
        orderData.service_charge = this.state.additionalCharges.service_charge;
        orderData.delivery_charge = this.state.collectionMode == 'pickup' ? 0 : this.state.additionalCharges.delivery_charge;
        orderData.driver_delivery_charge = this.state.collectionMode == 'pickup' ? 0 : this.state.additionalCharges.driver_delivery_charge;
        orderData.tax_rates = this.state.additionalCharges.taxrates;
        orderData.delivary_instruction = this.props.deliveryInstructions;
        orderData.coupon_id = this.state.coupon ? this.state.coupon.id : '';
        orderData.coupon_discount = this.state.promoDiscount;

        if (this.state.collectionMode === 'delivery' && this.state.addressList.length > 0) {
            this.state.addressList.map(address => {
                if (address.selected === true) {
                    orderData.addr = address.addr;
                    orderData.addr2 = address.addr2;
                    orderData.country = address.country;
                    orderData.state = address.state;
                    orderData.city = address.city;
                    orderData.zip = address.zip;
                }
            });
        } else {
            orderData.addr = '';
            orderData.addr2 = '';
            orderData.country = '';
            orderData.state = '';
            orderData.city = '';
            orderData.zip = '';
        }

        orderData.lat = this.state.selectedAddress.lat;
        orderData.lng = this.state.selectedAddress.lng;

        orderData.product = productItem;

        //console.log(orderData);

        this.setState({ lockPhase: true }, () => {
            orderAxios.post('createOrderStart', orderData)
                .then((res) => {
                    //console.log('createOrderStart=', res.data);
                    if (res.data.success === true) {

                        if (this.state.card === true) {
                            this.setState({ orderId: res.data.orderId }, () => {
                                this.payment();
                            });
                        } else {
                            this.setState({
                                //lockPhase: true,
                                finalConfirmation: true
                            });
                        }
                    } else {
                        this.setState({
                            lockPhase: false,
                            submitOrderErrorMsg: res.data.message,
                        });
                        setTimeout(() => {
                            this.setState({ submitOrderErrorMsg: null });
                        }, 5000);
                    }
                })
                .catch(err => {
                    let errorMsg = this.displayError(err);
                    this.setState({
                        lockPhase: false,
                        submitOrderErrorMsg: errorMsg,
                    });
                    setTimeout(() => {
                        this.setState({ submitOrderErrorMsg: null });
                    }, 5000);
                });
        });
    }

    payment = () => {
        this.setState({ show3DModal: true });
    }

    close3DModal = () => {
        this.setState({
            lockPhase: false,
            show3DModal: false,
        }, () => {
            this.handleOrderError('Customer denied card payment.');
        });
    }

    handleOrderError = (err) => {
        console.log('OrderError', err);
        const data = {
            payment_error: err,
            orderId: this.state.orderId,
        };
        orderAxios.post('createOrderError', data)
            .then(() => {
                this.setState({
                    paymentErrorMsg: err != null ? err : 'Payment Failed!',
                });
                setTimeout(() => {
                    this.setState({ paymentErrorMsg: null });
                }, 5000);
            })
            .catch(e => {
                let errorMsg = this.displayError(e);
                this.setState({
                    lockPhase: false,
                    paymentErrorMsg: errorMsg,
                });
                setTimeout(() => {
                    this.setState({ paymentErrorMsg: null });
                }, 5000);
            });
    }

    handlePaymentIntentError = (err) => {
        console.log('handlePaymentIntentError', err);
        const paymentErrorMsg = err != null ? err.message : 'Payment Intent Failed!';
        this.handleOrderError(paymentErrorMsg);
    }

    handlePaymentFailedError = (err) => {
        console.log('handlePaymentFailedError', err);
        const paymentErrorMsg = err != null ? err.message : 'Payment Failed!';
        this.handleOrderError(paymentErrorMsg);
    }

    handleSuccessStripe = (id) => {
        const data = {
            payment_intent: id,
            orderId: this.state.orderId,
        };
        orderAxios.post('createOrderUpdate', data)
            .then((res) => {
                if (res.data.success === true) {
                    this.setState({ show3DModal: false }, () => {
                        this.setState({
                            lockPhase: false,
                            finalConfirmation: true
                        });
                    });
                }
            })
            .catch(e => {
                let errorMsg = this.displayError(e);
                this.setState({
                    lockPhase: false,
                    paymentErrorMsg: errorMsg,
                });
                setTimeout(() => {
                    this.setState({ paymentErrorMsg: null, show3DModal: false });
                }, 5000);
            });
    }

    orderNow = (payment, paymentId, paymentStatus) => {
        let cartItem = this.props.cartItem;
        let productItem = [];
        cartItem && cartItem.length > 0 && cartItem.map(data => {
            let addon_category = [];
            data.categories && data.categories.length > 0 && data.categories.map(cdata => {
                cdata.addon_items.length > 0 && cdata.addon_items.map(adata => {
                    adata.selected === true && addon_category.push(cdata.categorydetail && cdata.categorydetail.name);
                });
            });
            let product = {};

            if (this.props.cart.shopType == 'restaurant') {
                product.product_id = data.id;
            } else {
                product.grocery_product_id = data.id;
            }
            product.product_name = data.product_name;
            product.product_discount = data.product_discount ? data.product_discount : 0;
            product.size_name = data.selectedSizeName;
            product.addon_category = addon_category;
            product.addon_item = data.addons;
            product.quantity = data.selectedQuantity;
            product.size_price = data.selectedSizePrice;
            product.product_option = data.combo;
            product.product_note = data.specialInstructions;
            productItem.push(product);
        });

        let orderData = {};

        //orderData.payment_method = payment.payment_method_details ? payment.payment_method_details.type : null;
        //orderData.payment_card_type = payment.payment_method_details ? payment.payment_method_details.card.funding : null;
        //orderData.payment_card_country = payment.payment_method_details ? payment.payment_method_details.card.country : null;

        orderData.payment_method = payment ? payment : null;
        orderData.collection_only = this.state.collectionMode == 'pickup' ? 1 : 0;

        if (this.props.cart.shopType == 'restaurant') {
            orderData.restaurant_id = cartItem[0].restaurant_id;
        } else {
            orderData.grocery_id = cartItem[0].grocery_id;
        }

        orderData.franchisee_id = cartItem[0].franchisee_id.franchisee_id;
        orderData.customer_id = this.props.user.id;
        orderData.grand_total = this.state.grandTotal;
        orderData.driver_tip_amount = this.state.collectionMode == 'pickup' ? 0 : this.props.driverTip;
        orderData.subtotal = this.state.totalPrice;
        orderData.tax_amount = 0;
        orderData.order_mode = 'web';
        orderData.service_charge = this.state.additionalCharges.service_charge;
        orderData.delivery_charge = this.state.collectionMode == 'pickup' ? 0 : this.state.additionalCharges.delivery_charge;
        orderData.driver_delivery_charge = this.state.collectionMode == 'pickup' ? 0 : this.state.additionalCharges.driver_delivery_charge;
        orderData.tax_rates = this.state.additionalCharges.taxrates;
        orderData.payment_id = paymentId;
        orderData.payment_status = paymentStatus;
        orderData.delivary_instruction = this.props.deliveryInstructions;
        orderData.coupon_id = this.state.coupon ? this.state.coupon.id : '';
        orderData.coupon_discount = this.state.promoDiscount;

        if (this.state.collectionMode === 'delivery' && this.state.addressList.length > 0) {
            this.state.addressList.map(address => {
                if (address.selected === true) {
                    orderData.addr = address.addr;
                    orderData.addr2 = address.addr2;
                    orderData.country = address.country;
                    orderData.state = address.state;
                    orderData.city = address.city;
                    orderData.zip = address.zip;
                }
            });
        } else {
            orderData.addr = '';
            orderData.addr2 = '';
            orderData.country = '';
            orderData.state = '';
            orderData.city = '';
            orderData.zip = '';
        }

        orderData.lat = this.state.selectedAddress.lat;
        orderData.lng = this.state.selectedAddress.lng;

        orderData.product = productItem;

        //console.log(orderData);

        this.setState({ checkoutLoader: true }, () => {
            orderAxios.post('orderAdd', orderData)
                .then(() => {

                    let data = {};
                    data.isOpen = this.props.cart.isOpen;
                    this.props.handleCart(data);

                    this.setState({
                        checkoutLoader: false,
                        finalConfirmation: true
                    });
                })
                .catch(err => {
                    let errorMsg = this.displayError(err);
                    this.setState({
                        checkoutLoader: false,
                        submitOrderErrorMsg: errorMsg,
                    });
                    setTimeout(() => {
                        this.setState({ submitOrderErrorMsg: null });
                    }, 2500);
                });
        });
    }

    handleCardSelect = (event) => {

        const target = event.target;
        //console.log('target=>', target.checked);

        if (target.checked) {
            this.setState({
                card: true,
                cod: false,
            });
        } else {
            this.setState({
                card: false,
                lockPhase: false
            });
        }
    }

    handleCodChange = (event) => {

        const target = event.target;
        //console.log('target=>', target.checked);

        if (target.checked) {
            this.setState({
                cod: true,
                card: false,
            });
        } else {
            this.setState({
                cod: false,
                lockPhase: false
            });
        }
    }

    openModalAddr = () => {
        this.setState({
            addrShow: true
        });
    };

    openModalAddCard = () => {
        this.setState({
            cardShow: true
        });
    };

    openModalAddAddrs = () => {
        this.setState({
            addrShow: false,
        });
        this.props.history.push(`${SERVERFOLDER + '/address'}`);
    };

    closeNotSelectedtModal = () => {
        this.setState({
            openNotSelectedModal: false,
            notSelectMessage: null
        });
    }

    closeValidationModal = () => {
        this.setState({
            openValidationModal: false,
            validationMessage: null
        }, () => {
            this.props.history.push(`${SERVERFOLDER + '/cart/valid'}`);
        });
    }

    closeModal = () => {
        this.setState({
            addrShow: false,
            cardShow: false,
            addAddrsShow: false,
            finalConfirmation: false
        });
    };

    closeOrderSuccessModal = () => {
        this.setState({
            finalConfirmation: false
        }, () => {
            let data = {};
            data.isOpen = this.props.cart.isOpen;
            this.props.handleCart(data);
            this.props.history.push(`${SERVERFOLDER + '/order-history'}`);
        });
    }

    closeApplyCouponModal = () => {
        this.setState({
            showApplyCouponModal: false,
        });
    };

    openApplyCouponModal = () => {
        if (this.state.collectionMode === null) {
            this.setState({
                openNotSelectedModal: true,
                notSelectMessage: 'To continue, Please select either Delivery or Click & Collect.'
            });
        } else {

            this.setState({
                showApplyCouponModal: true
            });
        }
    }

    displayError = (e) => {
        let errorMessge = '';
        try {
            errorMessge = e.data.message ? e.data.message : e.data.error_description;
        } catch (e) {
            errorMessge = 'Unknown error!';
        }
        return errorMessge;
    }

    paymentXXX = () => {
        const formData = new window.FormData();
        formData.append('user_id', this.props.user.id);
        formData.append('card_no', this.state.selectedCard.id);
        formData.append('amount', this.state.grandTotal);
        formData.append('currency', this.state.shopInfo && this.state.shopInfo.franchisee && this.state.shopInfo.franchisee.currency);
        formData.append('description', 'Payment Intent Charge');
        this.setState({ paymentLoader: true }, () => {
            orderAxios.post('pay', formData)
                .then((res) => {
                    if (res.data.success === true) {
                        this.setState({
                            paymentLoader: false,
                            paymentData: res.data
                        }, () => {
                            this.orderNow(res.data.message, res.data.payment_id, res.data.payment_status);
                        });
                    } else {
                        this.setState({ paymentErrorMsg: 'Payment failed', paymentLoader: false });
                        setTimeout(() => {
                            this.setState({ paymentErrorMsg: null });
                        }, 2500);
                    }
                })
                .catch(err => {
                    let errorMsg = this.displayError(err);
                    this.setState({
                        paymentLoader: false,
                        paymentErrorMsg: errorMsg,
                    });

                    this.setState({
                        openNotSelectedModal: true,
                        notSelectMessage: errorMsg
                    });

                    setTimeout(() => {
                        this.setState({ paymentErrorMsg: null });
                    }, 2500);
                });
        });
    }

    createCard = () => {
        const formData = new window.FormData();
        formData.append('user_id', this.props.user.id);
        formData.append('email', this.props.user.email);
        formData.append('number', '4242424242424242');
        formData.append('exp_month', '01');
        formData.append('exp_year', '22');
        formData.append('cvc', '123');
        formData.append('name', 'Customer Dow');
        formData.append('address_line1', '');
        formData.append('address_line2', '');
        formData.append('address_city', '');
        formData.append('address_state', '');
        formData.append('address_country', '');
        formData.append('address_zip', '');

        this.setState({ cardSubmitLoader: true }, () => {
            axios.post('createStripeUser', formData)
                .then(() => {
                    this.setState({
                        cardSubmitLoader: false,
                    }, () => {
                        // this.showSuccesSubmitModal();
                    });
                })
                .catch(err => {
                    let errorMsg = this.displayError(err);
                    this.setState({
                        cardSubmitLoader: false,
                        cardSubmitErrorMsg: errorMsg,
                    });
                    setTimeout(() => {
                        this.setState({ cardSubmitErrorMsg: null });
                    }, 2500);
                });
        });
    }

    fetchCardList = () => {
        this.setState({ cardLoader: true }, () => {
            orderAxios.get(`creditCardLists?user_id=${this.props.user.id}`)
                .then(response => {
                    let cardList = response.data.message.data;
                    cardList && cardList.length > 0 && cardList.map(data => {
                        data.selected = false;
                    });
                    this.setState({
                        cardLoader: false,
                        cardList
                    });
                })
                .catch(e => {
                    let errorMsg = this.displayError(e);
                    this.setState({
                        cardErrorMessge: errorMsg,
                        cardLoader: false
                    });
                    setTimeout(() => {
                        this.setState({ cardErrorMessge: null });
                    }, 5000);
                });
        });
    }

    handleSelectAddress = (addressList, j) => {
        let addresslist = addressList;
        addresslist[j].selected = true;
        for (let i = 0; i < addresslist.length; i++) {
            if (i !== j) {
                addresslist[i].selected = false;
            }
        }
        this.setState({
            addressList: addresslist
        }, () => {
            const selectedAddress = this.state.addressList.length > 0 && this.state.addressList.filter((data) => {
                return data.selected === true;
            });
            //console.log('Changed Selected Address', selectedAddress[0]);
            this.setState({
                selectedAddress: selectedAddress && selectedAddress[0]
            }, () => {
                this.fetchInfo();
                this.closeModal();
            });
        });
    }

    handleSubmitAddress = (values) => {
        values.lat = this.state.latitude;
        values.lng = this.state.longitude;
        this.setState({ submitLoader: true }, () => {
            axios.post(this.state.isEdit === true ? `addressUpdate/${values.id}` : 'addressAdd', values)
                .then(() => {
                    this.setState({
                        submitLoader: false,
                    }, () => {
                        this.showSuccesSubmitModal();
                    });
                })
                .catch(err => {
                    let errorMsg = this.displayError(err);
                    this.setState({
                        submitLoader: false,
                        submitErrorMsg: errorMsg,
                    });
                    setTimeout(() => {
                        this.setState({ submitErrorMsg: null });
                    }, 2500);
                });
        });
    }

    showSuccesSubmitModal = () => {
        this.setState({ succesSubmitModal: true, addAddrsShow: false });
    };

    closeSuccesSubmitModal = () => {
        this.setState({ succesSubmitModal: false, isEdit: false, addrShow: true });
        this.fetchAddressList();
    };

    showSuccesCouponModal = () => {
        this.setState({ succesCouponModal: true });
    };

    closeSuccesCouponModal = () => {
        this.setState({ succesCouponModal: false });
    };

    openModalEditAddrs = (data) => {
        this.setState({
            addrShow: false,
            addAddrsShow: true,
            isEdit: true,
            editData: data
        });
    }

    handleSubmitCreateStripe = (values) => {
        const formData = new window.FormData();
        formData.append('user_id', this.props.user.id);
        formData.append('email', this.props.user.email);
        formData.append('number', values.cardNumber);
        formData.append('exp_month', values.expdate.substring(0, 2));
        formData.append('exp_year', values.expdate.substring(3));
        formData.append('cvc', values.cvc);
        formData.append('name', values.nameOnCard);
        formData.append('addr1', '');
        formData.append('addr2', '');
        formData.append('city', '');
        formData.append('state', '');
        formData.append('country', '');
        formData.append('zip', '');
        this.setState({ loader: true }, () => {
            orderAxios.post('createStripeUser', formData)
                .then(() => {
                    this.setState({
                        loader: false,
                    }, () => {
                        this.showSuccesSubmitModal();
                    });
                })
                .catch(err => {
                    let errorMsg = this.displayError(err);
                    this.setState({
                        loader: false,
                        errorMsg: errorMsg,
                    });
                    setTimeout(() => {
                        this.setState({ errorMsg: null });
                    }, 2500);
                });
        });
    }

    handleSubmitCard = (values) => {
        const formData = new window.FormData();
        formData.append('user_id', this.props.user.id);
        formData.append('number', values.cardNumber);
        formData.append('exp_month', values.expdate.substring(0, 2));
        formData.append('exp_year', values.expdate.substring(3));
        formData.append('cvc', values.cvc);
        formData.append('name', values.nameOnCard);
        formData.append('addr1', '');
        formData.append('addr2', '');
        formData.append('city', '');
        formData.append('state', '');
        formData.append('country', '');
        formData.append('zip', '');
        this.setState({ loader: true }, () => {
            orderAxios.post('createCard', formData)
                .then(() => {
                    this.setState({
                        loader: false,
                    }, () => {
                        this.showSuccesSubmitModalCard();
                    });
                })
                .catch(err => {
                    let errorMsg = this.displayError(err);
                    this.setState({
                        loader: false,
                        errorMsg: errorMsg,
                    });
                    setTimeout(() => {
                        this.setState({ errorMsg: null });
                    }, 2500);
                });
        });
    }

    showSuccesSubmitModalCard = () => {
        this.setState({ succesSubmitModalCard: true, cardShow: false });
    };

    closeSuccesSubmitModalCard = () => {
        this.setState({
            succesSubmitModalCard: false
        }, () => {
            this.fetchCardList();
        });
    };

    handleCardNumber = (e, setFieldValue) => {
        let formattedText = e.target.value.split(' ').join('');
        if (formattedText.length > 0) {
            formattedText = formattedText.match(new RegExp('.{1,4}', 'g')).join(' ');
        }
        setFieldValue('cardNumber', formattedText);
        return formattedText;
    }

    handleSelectedCard = (cardList, i) => {
        let card = cardList;
        cardList[i].selected = !cardList[i].selected;
        for (let j = 0; j < cardList.length; j++) {
            if (i !== j) {
                cardList[j].selected = false;
            }
        }
        this.setState({ cardList: card });
        if (cardList[i].selected === true) {
            this.setState({ selectedCard: cardList[i] });
        } else {
            this.setState({ selectedCard: null });
        }

    }

    applycoupon = (code) => {
        this.setState({ coupon: code });
    }

    render() {
        const {
            totalPrice,
            grandTotal,
            shopInfo,
            checkoutLoader,
            deliveryAvailable,
            cod,
            collectionMode,
            additionalCharges,
            loader,
            addressList,
            addressLoader,
            isEdit,
            submitOrderErrorMsg,
            errorMsg,
            serviceableLocation,
            checkDistanceErrorMessge,
            openNotSelectedModal,
            openValidationModal,
            notSelectMessage,
            validationMessage,
            paymentLoader,
            paymentErrorMsg,
            promoDiscount,
            coupon,
            couponList,
            couponErrorMessge
        } = this.state;
        return (
            <div>

                {checkoutLoader ?
                    <div className="" style={{ height: '72vh' }}><LoadingSpinner /></div> :
                    <div className="bodyPan">
                        <div className="container cartBg">

                            <div className="titlePan">
                                <NavLink to={SERVERFOLDER + '/cart'} className="backArrow"><FaAngleLeft /> Back to Cart</NavLink>
                                <h2>Checkout</h2>
                                <div onChange={this.setDeliveryMode} className="row">
                                    {shopInfo && shopInfo.isDelivery === 'open' && shopInfo.only_collection === 0 &&
                                        <div className={deliveryAvailable === true ? 'col-12 col-md-6 col-lg-2' : 'col-12 col-md-6 col-lg-2'}>
                                            <h5><label><input type="radio" value="delivery" name="collectionMode" disabled={serviceableLocation === false ? true : false} required defaultChecked={this.state.collectionMode == 'delivery'} /> Delivery</label></h5>
                                        </div>
                                    }

                                    <div className="col-12 col-md-6 col-lg-4">
                                        {shopInfo && shopInfo.supports_collection === 1 &&
                                            <h5><label><input type="radio" value="pickup" name="collectionMode" required defaultChecked={this.state.collectionMode == 'pickup'} /> Click &#38; Collect</label></h5>
                                        }
                                    </div>

                                </div>
                            </div>


                            <div className="restroInfo">
                                <div className="restroDetails p-2">
                                    <h4><strong>Delivery</strong> Address <span className="editLink" onClick={() => this.openModalAddr(addressList)}><MdEdit /></span></h4>
                                    {addressLoader ? <LoadingSpinner /> :
                                        addressList && addressList.length > 0 && addressList.map(data => {
                                            return data.selected === true &&
                                                <p className="location" key={data.id}>{data.addr}<br />{data.addr2}<br />{data.city} {data.state} {data.country} {data.zip}</p>;
                                        })}
                                </div>
                            </div>

                            <div className="row">

                                <div className='col-12 col-lg-7'>

                                    <h3><strong>Payment</strong> Method</h3>

                                    <div className="card nearCard visaCard">
                                        <label className="customChk checkBox visaCheck">
                                            <input name="card" type="checkbox" className="form-check" checked={this.state.card}
                                                onChange={this.handleCardSelect} />
                                            <span>Card</span>
                                        </label>
                                    </div>

                                    {shopInfo && shopInfo.supports_cash_payment === 1 &&
                                        <div className="card nearCard visaCard">
                                            <label className="customChk checkBox visaCheck">
                                                <input name="cod" type="checkbox" className="form-check" checked={this.state.cod} onChange={this.handleCodChange} />
                                                <span>{collectionMode == 'pickup' ? 'Cash on Pickup' : 'Cash on Delivery'}</span>
                                            </label>
                                        </div>
                                    }

                                    <div className={cod === false ? 'mb-4' : 'mb-4'}>
                                        {coupon ?

                                            <div className='appliedCode'>
                                                <FaRegCheckCircle />
                                                <div>
                                                    <em className=''>Code <strong>{coupon.coupon}</strong> applied!</em>
                                                    {coupon.name}
                                                </div>
                                                <span onClick={() => this.openApplyCouponModal()} style={{ cursor: 'pointer', color: '#ed592b', marginLeft: 'auto', textAlign: 'right' }}> Change offer</span>
                                            </div>

                                            :
                                            <a className={`addMore ${collectionMode === 'pickup' || (serviceableLocation == true && deliveryAvailable == true) ? null : 'disableClass'}`} onClick={() => collectionMode === 'pickup' || (serviceableLocation == true && deliveryAvailable == true) ? this.openApplyCouponModal() : null}><FaPlusCircle /> Select a promo code</a>

                                        }

                                    </div>
                                </div>

                                <div className="col-12 col-lg-5">
                                    {coupon &&
                                        <div className="discountPan">
                                            <div className="discountTxt">
                                                <strong>{coupon.name}</strong>
                                                Valid up to : {Moment(coupon.end_date).format('MM-DD-YYYY')}
                                            </div>
                                            <div className="discountCode">CODE: {coupon.coupon}</div>
                                            <em className="deleteCoupon" onClick={() => this.deleteCoupon()}><MdClear /></em>
                                        </div>
                                    }
                                    <div className="grandTotalPan">
                                        <h3>Summary</h3>
                                        {loader ? <LoadingSpinner /> :
                                            <table className="table"><tbody>
                                                <tr>
                                                    <td className="subTotal">Sub Total :</td>
                                                    <td className="ammount">{Utils.setCurrency(shopInfo, parseFloat(totalPrice).toFixed(2))}</td>
                                                </tr>
                                                {coupon &&
                                                    <tr>
                                                        <td className="subTotal" style={{ color: '#4ec0d4' }}>Promo ({coupon.coupon}) :</td>
                                                        <td className="ammount">-{Utils.setCurrency(shopInfo, parseFloat(promoDiscount).toFixed(2))}</td>
                                                    </tr>
                                                }
                                                <tr>
                                                    <td className="subTotal">Service Charges :</td>
                                                    <td className="ammount">{Utils.setCurrency(shopInfo, parseFloat(additionalCharges ? additionalCharges.service_charge : 0).toFixed(2))}</td>
                                                </tr>
                                                {collectionMode === 'pickup' ? null :
                                                    <tr>
                                                        <td className="subTotal">Delivery Charges :</td>
                                                        <td className="ammount">{Utils.setCurrency(shopInfo, parseFloat(additionalCharges ? additionalCharges.delivery_charge : 0).toFixed(2))}</td>
                                                    </tr>
                                                }
                                                {collectionMode === 'pickup' ? null :
                                                    <tr>
                                                        <td className="subTotal">Driver Tip :</td>
                                                        <td className="ammount">{Utils.setCurrency(shopInfo, parseFloat(this.props.driverTip).toFixed(2))}</td>
                                                    </tr>
                                                }
                                                <tr>
                                                    <td className="subTotal2">Grand Total :</td>
                                                    <td className="ammount2">{Utils.setCurrency(shopInfo, parseFloat(grandTotal).toFixed(2))}
                                                    </td>
                                                </tr>
                                            </tbody>
                                            </table>
                                        }
                                    </div>
                                    <a className={`addCartBtn btn w-100 ${collectionMode === 'pickup' || (serviceableLocation == true && deliveryAvailable == true) ? null : 'disableClass'} ${this.state.lockPhase === true ? 'locked' : 'open'}`} onClick={() => collectionMode === 'pickup' || (serviceableLocation === true && deliveryAvailable == true) ? this.validatecartProduct() : null}> Send Order Now! <RiArrowRightSLine />
                                    </a>
                                </div>
                            </div>

                        </div>

                        {/* Apply Coupon  */}
                        <Modal show={this.state.showApplyCouponModal} onHide={this.closeApplyCouponModal} className="WarningModal" backdrop="static">
                            <Modal.Header>
                                <Modal.Title>Coupons</Modal.Title>
                                <span className="modalClose text-right" onClick={() => this.closeApplyCouponModal()}><MdClose /></span>
                            </Modal.Header>
                            <Modal.Body>
                                <Formik>
                                    {() => {
                                        return (
                                            <Form className='text-dark'>
                                                {couponErrorMessge ?
                                                    <p className="alert alert-danger text-center" role="alert"> {couponErrorMessge} </p>
                                                    : null
                                                }
                                                <div className="position-relative">

                                                    {couponList && couponList.length > 0 ?
                                                        couponList.map(data => {
                                                            return <Button className="discountPan w-100" key={data.id}
                                                                onClick={() => this.redeemCoupon(data)} title="Apply">
                                                                <div className="discountTxt">
                                                                    <strong>{data.name}</strong>
                                                                    <span>{data.description}</span>
                                                                    <br />
                                                                    <span className='green'>Valid up to : {Moment(data.end_date).format('MM-DD-YYYY')}</span>
                                                                </div>
                                                                <div className="discountCode">CODE: {data.coupon}</div>
                                                            </Button>;
                                                        }) :
                                                        <div>
                                                            <div className="noCouponPan">
                                                                <img src={nocoupon} alt='No Coupon' />
                                                                <p>We are Sorry. There are no offers available at this moment. Please check back later for more.</p>
                                                            </div>
                                                        </div>
                                                    }

                                                </div>
                                            </Form>);
                                    }}
                                </Formik>
                            </Modal.Body>
                        </Modal>
                        {/* Apply Coupon  */}

                        {/* Select Address  */}
                        <Modal show={this.state.addrShow}
                            onHide={this.closeModal}>
                            <Modal.Header>
                                <Modal.Title>Select the delivery address</Modal.Title>
                                <span className="modalClose" onClick={() => this.closeModal()}><MdClose /></span>
                            </Modal.Header>
                            <Modal.Body>
                                {addressList && addressList.length > 0 && addressList.map((data, i) => {
                                    return <div key={data.id} className="card nearCard addCard">
                                        <div className="card-body">
                                            <h4>Address {i + 1} <span>{data.tag}</span></h4>
                                            <p>{data.addr}<br />{data.addr2}<br />{data.city} {data.state} {data.country} {data.zip}</p>
                                        </div>
                                        <div className="card-footer">
                                            <label className="customChk radioBox">
                                                <input
                                                    type="radio"
                                                    name="address"
                                                    checked={data.selected}
                                                    value={data.id}
                                                    onChange={() => this.handleSelectAddress(addressList, i)}
                                                    className="form-check" />
                                                <span>Select the Address</span>
                                            </label>
                                            {/*<a className="editLink" style={{ cursor: 'pointer' }} onClick={() => this.openModalEditAddrs(data)}> <MdEdit /> Edit</a>*/}
                                        </div>
                                    </div>;
                                })}
                                <a className="addMore" onClick={() => this.openModalAddAddrs()}><FaPlusCircle /> Add New Address</a>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button className="addCartBtn" variant="primary" onClick={() => this.closeModal()}>Save <RiArrowRightSLine /></Button>
                            </Modal.Footer>
                        </Modal>
                        {/* Select Address  */}

                        {/* Add Card  */}
                        <Modal show={this.state.cardShow} onHide={this.closeModal}>
                            <Modal.Header>
                                <Modal.Title><strong>Add Card</strong></Modal.Title>
                                <span className="modalClose" onClick={() => this.closeModal()}><MdClose /></span>
                            </Modal.Header>
                            <Modal.Body>
                                <p className="mb-4">We accept Credit and Debit Cards from Visa, Revolut, Mastercard, American Express and Maestro.</p>
                                <div>
                                    {loader ?
                                        <span className='loaderStyle'><LoadingSpinner /></span>
                                        : null
                                    }
                                    {errorMsg ?
                                        <p className="alert alert-danger text-center" role="alert"> {errorMsg} </p>
                                        : null
                                    }
                                    <Formik
                                        initialValues={initialValuesCard}
                                        validationSchema={createCardSchema}
                                        onSubmit={this.props.user.stripe_customer_id === null ? this.handleSubmitCreateStripe : this.handleSubmitCard}
                                    >
                                        {({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleBlur
                                        }) => {
                                            return (<Form className='text-dark'>
                                                <div className="position-relative">
                                                    <div className="form-group">
                                                        <Field
                                                            style={getErrorStyles(errors, touched, 'nameOnCard')}
                                                            type="text"
                                                            name="nameOnCard"
                                                            value={values.nameOnCard || ''}
                                                            placeholder="Card holder name"
                                                            className="form-control"
                                                        />
                                                        {errors.nameOnCard && touched.nameOnCard ?
                                                            <span className="errorMsg text-danger">{errors.nameOnCard}</span>
                                                            : null}
                                                    </div>

                                                    <div className="form-group">
                                                        <InputMask
                                                            style={getErrorStyles(errors, touched, 'cardNumber')}
                                                            mask="9999 9999 9999 9999"
                                                            maskChar={null}
                                                            type="text"
                                                            name="cardNumber"
                                                            placeholder="Card Number"
                                                            className={'form-control'}
                                                            value={values.cardNumber || ''}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        {errors.cardNumber && touched.cardNumber ?
                                                            <span className="errorMsg text-danger">{errors.cardNumber}</span>
                                                            : null}
                                                    </div>

                                                    <div className="form-group">
                                                        <InputMask
                                                            style={getErrorStyles(errors, touched, 'expdate')}
                                                            mask="99/99"
                                                            maskChar={null}
                                                            type="text"
                                                            name="expdate"
                                                            placeholder="Expiry Date"
                                                            className={'form-control'}
                                                            value={values.expdate || ''}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        {errors.expdate && touched.expdate ?
                                                            <span className="errorMsg text-danger">{errors.expdate}</span>
                                                            : null}
                                                    </div>

                                                    <div className="form-group">
                                                        <InputMask
                                                            style={getErrorStyles(errors, touched, 'cvc')}
                                                            mask="999"
                                                            maskChar={null}
                                                            type="password"
                                                            name="cvc"
                                                            value={values.cvc || ''}
                                                            placeholder="Cvv"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        {errors.cvc && touched.cvc ?
                                                            <span className="errorMsg text-danger">{errors.cvc}</span>
                                                            : null}
                                                    </div>

                                                    <div className='row mt-2 text-center'>
                                                        <div className='col-sm-12'>
                                                            <Button variant="primary mr-3" type="submit" className="addCartBtn mt-3">
                                                                {'Save Card'}
                                                                <RiArrowRightSLine />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>);
                                        }}
                                    </Formik>
                                </div>
                            </Modal.Body>
                        </Modal>
                        {/* Add Card  */}

                        <Modal show={this.state.addAddrsShow}
                            onHide={this.closeModal}>
                            <Modal.Header>
                                <Modal.Title>
                                    <h2 className='text-orange-primary mb-3'>{this.state.isEdit === true ? 'Edit' : 'Add'} Address</h2></Modal.Title>
                                <span className="modalClose" onClick={() => this.closeModal()}><MdClose /></span>
                            </Modal.Header>
                            <Modal.Body>
                                <div>
                                    {this.state.submitLoader ?
                                        <span className='loaderStyle'><LoadingSpinner /></span>
                                        : null
                                    }
                                    {this.state.submitErrorMsg ?
                                        <p className="alert alert-danger text-center" role="alert"> {this.state.submitErrorMsg} </p>
                                        : null
                                    }
                                    <Formik
                                        initialValues={this.state.isEdit === true ? this.state.editData : initialValues}
                                        validationSchema={schema}
                                        onSubmit={this.handleSubmitAddress}
                                    >
                                        {({
                                            values,
                                            errors,
                                            touched,
                                        }) => {
                                            return (<Form className='text-dark'>
                                                <div className="position-relative">
                                                    <div className="form-group">
                                                        <Field
                                                            style={getErrorStyles(errors, touched, 'addr')}
                                                            type="text"
                                                            name="addr"
                                                            value={values.addr || ''}
                                                            placeholder="Address Line 1"
                                                            className="form-control"
                                                        />
                                                        {errors.addr && touched.addr ?
                                                            <span className="errorMsg text-danger">{errors.addr}</span>
                                                            : null}
                                                    </div>
                                                    <div className="form-group">
                                                        <Field
                                                            style={getErrorStyles(errors, touched, 'addr2')}
                                                            type="text"
                                                            name="addr2"
                                                            value={values.addr2 || ''}
                                                            placeholder="Address Line 2"
                                                            className="form-control"
                                                        />
                                                        {errors.addr2 && touched.addr2 ?
                                                            <span className="errorMsg text-danger">{errors.addr2}</span>
                                                            : null}
                                                    </div>
                                                    <div className="form-group">
                                                        <Field
                                                            style={getErrorStyles(errors, touched, 'city')}
                                                            type="text"
                                                            name="city"
                                                            value={values.city || ''}
                                                            placeholder="City"
                                                            className="form-control"
                                                        />
                                                        {errors.city && touched.city ?
                                                            <span className="errorMsg text-danger">{errors.city}</span>
                                                            : null}
                                                    </div>
                                                    <div className="form-group">
                                                        <Field
                                                            style={getErrorStyles(errors, touched, 'state')}
                                                            type="text"
                                                            name="state"
                                                            value={values.state || ''}
                                                            placeholder="State"
                                                            className="form-control"
                                                        />
                                                        {errors.state && touched.state ?
                                                            <span className="errorMsg text-danger">{errors.state}</span>
                                                            : null}
                                                    </div>
                                                    <div className="form-group">
                                                        <Field
                                                            style={getErrorStyles(errors, touched, 'country')}
                                                            type="text"
                                                            name="country"
                                                            value={values.country || ''}
                                                            placeholder="Country"
                                                            className="form-control"
                                                        />
                                                        {errors.country && touched.country ?
                                                            <span className="errorMsg text-danger">{errors.country}</span>
                                                            : null}
                                                    </div>
                                                    <div className="form-group">
                                                        <Field
                                                            style={getErrorStyles(errors, touched, 'zip')}
                                                            type="text"
                                                            name="zip"
                                                            value={values.zip || ''}
                                                            placeholder="ZIP"
                                                            className="form-control"
                                                        />
                                                        {errors.zip && touched.zip ?
                                                            <span className="errorMsg text-danger">{errors.zip}</span>
                                                            : null}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>
                                                            <Field
                                                                style={getErrorStyles(errors, touched, 'tag')}
                                                                type="radio"
                                                                name="tag"
                                                                value={'home'}
                                                                checked={values.tag == 'home'}
                                                                className="ml-3"
                                                            />
                                                            &nbsp;Home
                                                        </label>
                                                        <label>
                                                            <Field
                                                                style={getErrorStyles(errors, touched, 'tag')}
                                                                type="radio"
                                                                name="tag"
                                                                value={'work'}
                                                                checked={values.tag == 'work'}
                                                                className="ml-3"
                                                            />
                                                            &nbsp;Work
                                                        </label>
                                                        <label>
                                                            <Field
                                                                style={getErrorStyles(errors, touched, 'tag')}
                                                                type="radio"
                                                                name="tag"
                                                                value={'hotel'}
                                                                checked={values.tag == 'hotel'}
                                                                className="ml-3"
                                                            />
                                                            &nbsp;Hotel
                                                        </label>
                                                        <label>
                                                            <Field
                                                                style={getErrorStyles(errors, touched, 'tag')}
                                                                type="radio"
                                                                name="tag"
                                                                value={'other'}
                                                                checked={values.tag == 'other'}
                                                                className="ml-3"
                                                            />
                                                            &nbsp;Other
                                                        </label>
                                                        <br />
                                                    </div>

                                                    <div className='row mt-2 text-center'>
                                                        <div className='col-sm-12'>
                                                            <Button variant="primary mr-3" type="submit" className="addCartBtn mt-3">
                                                                {isEdit ? 'Update' : 'Save'} Address<RiArrowRightSLine />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>);
                                        }}
                                    </Formik>
                                </div>
                            </Modal.Body>
                        </Modal>
                        {/* Add Address  Modal*/}

                        <Modal show={this.state.succesSubmitModal} onHide={this.closeSuccesSubmitModal} className="payOptionPop" >
                            <Modal.Body className='bg-white text-dark'>
                                <Row>
                                    <Col md={12} className="text-center">
                                        <span className='successIcon'><AiFillCheckCircle /></span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className="text-center">
                                        {isEdit ? <h5>Address has been Updated Successfully</h5> :
                                            <h5>Address has been Successfully Saved</h5>}
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <div className="text-center my-4">
                                <Button type="button" onClick={this.closeSuccesSubmitModal}>Return</Button>
                            </div>
                        </Modal>

                        {/* Final Confirmation  */}
                        <Modal show={this.state.finalConfirmation} onHide={this.closeOrderSuccessModal} size="md" className="confirmationModal" backdrop="static">
                            <Modal.Body>
                                <div className="vectorCloud">
                                    <div className="confirmationTxt">
                                        Your order is successful!
                                        <span>Now sit back and<br /> let us do the rest!</span>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button className="addCartBtn w-100" variant="primary" onClick={() => this.closeOrderSuccessModal()}>Track<RiArrowRightSLine /></Button>
                            </Modal.Footer>
                        </Modal>
                        {/* Final Confirmation  */}

                        {/* card confirmation */}
                        <Modal show={this.state.succesSubmitModalCard} onHide={this.closeSuccesSubmitModalCard} className="payOptionPop" >
                            <Modal.Body className='bg-white text-dark'>
                                <Row>
                                    <Col md={12} className="text-center">
                                        <span className='successIcon'><AiFillCheckCircle /></span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className="text-center">
                                        <h5>Card details has been Successfully Saved</h5>
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <div className="text-center my-4">
                                <Button
                                    type="button"
                                    onClick={this.closeSuccesSubmitModalCard}
                                >Return</Button>
                            </div>
                        </Modal>

                        <Modal show={openNotSelectedModal} onHide={this.closeNotSelectedtModal} className="payOptionPop" >
                            <Modal.Body className='bg-white text-dark'>
                                <Row>
                                    <Col md={12} className="text-center">
                                        <h5>{notSelectMessage}</h5>
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <div className="text-center my-4">
                                <Button
                                    type="button"
                                    onClick={this.closeNotSelectedtModal}
                                >Return</Button>
                            </div>
                        </Modal>

                        <Modal show={openValidationModal} onHide={this.closeValidationModal} className="payOptionPop" >
                            <Modal.Body className='bg-white text-dark'>
                                <Row>
                                    <Col md={12} className="text-center">
                                        <h5>{validationMessage}</h5>
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <div className="text-center my-4">
                                <Button
                                    type="button"
                                    onClick={this.closeValidationModal}
                                >Return</Button>
                            </div>
                        </Modal>

                        {/* 3D Secure  */}
                        <Modal
                            show={this.state.show3DModal}
                            onHide={this.close3DModal}
                            backdrop="static"
                        >
                            <Modal.Header>
                                <Modal.Title><h2 className='text-orange-primary mb-3'>{'Card Details'}</h2></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className="mb-4">We accept Credit and Debit Cards from Visa, Revolut, Mastercard, American Express and Maestro.</p>
                                <div>
                                    {this.state.errorMsg ?
                                        <p className="alert alert-danger text-center" role="alert"> {this.state.errorMsg} </p>
                                        : null
                                    }
                                    {this.state.loader ? <span className='text-center'><LoadingSpinner /></span> :
                                        <PaymentForm
                                            amount={(parseFloat(this.state.grandTotal).toFixed(2) * 100)}
                                            currency={this.state.shopInfo && this.state.shopInfo.franchisee
                                                && this.state.shopInfo.franchisee.currency.toLowerCase()}
                                            orderId={this.state.orderId}
                                            onClose={() => this.close3DModal()}
                                            onSucess={(e) => this.handleSuccessStripe(e)}
                                            handlePaymentFailedError={(e) => this.handlePaymentFailedError(e)}
                                            name={this.props.user.first_name}
                                            email={this.props.user.email}
                                            handlePaymentIntentError={(e) => this.handlePaymentIntentError(e)}
                                        />
                                    }
                                </div>
                            </Modal.Body>
                        </Modal>

                        {/* Coupon Apply Success  */}
                        <Modal show={this.state.succesCouponModal} onHide={this.closeSuccesCouponModal} className="payOptionPop celebrationPop " >
                            <Modal.Body className='bg-white text-dark'>
                                <Row>
                                    <Col md={12} className="text-center">
                                        <span className='successIcon'><AiFillCheckCircle /></span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className="text-center wowPan">
                                        <h3>WOW</h3>
                                        <div className='appliedPan'>{coupon && coupon.coupon} applied!</div>
                                        <p><strong>You saved {Utils.setCurrency(shopInfo, parseFloat(promoDiscount).toFixed(2))}</strong></p>
                                        {this.state.freeShiping &&
                                            <p>You also got free shipping </p>
                                        }
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <div className="text-center py-4 modalFooter">
                                <Button type="button" onClick={this.closeSuccesCouponModal}>Return</Button>
                            </div>
                        </Modal>

                    </div>


                }
                {submitOrderErrorMsg ?
                    <p className="alertNotificationPan alert alert-danger text-center" role="alert"> {submitOrderErrorMsg} </p>
                    : null
                }
                {collectionMode === 'delivery' && checkDistanceErrorMessge ?
                    <p className="alertNotificationPan alert alert-danger text-center" role="alert"> {checkDistanceErrorMessge} </p>
                    : null
                }
                {collectionMode === 'delivery' && deliveryAvailable === false ?
                    <p className="alertNotificationPan alert alert-danger text-center" role="alert"> {'The Restaurant is not serviceable at your location'} </p>
                    : null
                }
                {paymentLoader ?
                    <div className=""><LoadingSpinner /></div> : null
                }
                {paymentErrorMsg ?
                    <p className="alertNotificationPan alert alert-danger text-center" role="alert"> {paymentErrorMsg} </p>
                    : null
                }
            </div>
        );
    }
}

Checkout.propTypes = {
    handleCart: PropTypes.func,
    cartItem: PropTypes.any,
    coupon: PropTypes.any,
    deliveryInstructions: PropTypes.any,
    driverTip: PropTypes.any,
    grandTotal: PropTypes.any,
    token: PropTypes.any,
    user: PropTypes.object,
    history: PropTypes.any,
    cart: PropTypes.any,
    categoryId: PropTypes.any,
    geolocation: PropTypes.any,
};

const mapStateToProps = (state) => {
    return {
        cartItem: state.cart.cartItem,
        coupon: state.cart.coupon,
        deliveryInstructions: state.cart.deliveryInstructions,
        driverTip: state.cart.driverTip,
        grandTotal: state.cart.grandTotal,
        token: state.auth.token,
        user: state.user,
        cart: state.cart,
        categoryId: state.servicecategory.categoryId,
        geolocation: state.geolocation
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handleCart: (data) => dispatch(handleCart(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
