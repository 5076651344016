import {
    AUTH_START,
    AUTH_SUCCESS,
    AUTH_FAIL,
    //AUTH_LOGOUT,
    ACCESS_TOKEN,
    AUTH_ALL_DATA_UPDATE,
    USER,
    AUTH_LOGOUT_MSG
} from './actionTypes';

//import axios from './../../shared/eaxios';


export const authenticationStart = () => {
    return {
        type: AUTH_START
    };
};

export const authenticationFail = (error) => {
    return {
        type: AUTH_FAIL,
        error
    };
};

export const authenticationSuccess = (token, id) => {
    return {
        type: AUTH_SUCCESS,
        token,
        id
    };
};

export const logout = () => {
    return dispatch => {
        // dispatch({
        //     type: AUTH_LOGOUT,
        //     payload: ''
        // });

        dispatch({
            type: USER,
            payload: {
                order_token: null,
                first_name: null,
                last_name: null,
                timeStamp: null,
                id: null,
                profile_pic: null,
                gender: null,
                dob: null,
                user_type_name: null,
                stripe_customer_id: null,
                email: null,
                phone: null,
                about: null,
                register_with: null
            }
        });

        //axios.post('logout');
        
    };
};

export const logoutMsg = (data) => {
    return {
        type: AUTH_LOGOUT_MSG,
        logoutMsg: data
    };
};

export const accessToken = (
    token
) => {
    return {
        type: ACCESS_TOKEN,
        token,

    };
};

export const authDataUpdate = (data) => {
    return {
        type: AUTH_ALL_DATA_UPDATE,
        data
    };
};

export const onAuthenticationProcess = () => {
    return dispatch => {
        dispatch(authenticationStart());
        // Process Request
        // success
        // failure
    };
};
