import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
	Row,
	Col,
	Modal,
	Button,
	Dropdown
} from 'react-bootstrap';
import logo from './../../../assets/images/logo.png';
import profileImg from './../../../assets/images/avatar.png';
import notificationAudio from './../../../assets/audio/bell-notification.wav';
import './Header.scss';
import { FaBell } from 'react-icons/fa';
import { BiCurrentLocation } from 'react-icons/bi';
import { ImLocation2 } from 'react-icons/im';
import { AiFillCheckCircle } from 'react-icons/ai';
import { IoMdCart } from 'react-icons/io';
import { SERVERFOLDER, IMAGEURL } from './../../../Constants';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { handleCart } from './../../../redux/actions/cart';
import { accessToken } from './../../../redux/actions/auth';
import { handelUser } from './../../../redux/actions/user';
import { handleServiceCategory } from './../../../redux/actions/servicecategory';
import Profile from './../../PostAuth/Profile/Profile';
import axios from './../../../shared/eaxios';
import Sound from 'react-sound';
import Pusher from 'pusher-js';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import LoadingSpinner from '../../../Components/LoadingSpinner/LoadingSpinner';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { handleGeoLocation } from './../../../redux/actions/geolocation';
import { withRouter } from 'react-router-dom';
import { FaApple, FaGooglePlay } from 'react-icons/fa';

import Geocode from 'react-geocode';
const PUSHER_APP_KEY = process.env.REACT_APP_PUSHER_APP_KEY;
const GOOGLEAPIKEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;


Pusher.logToConsole = true;

let pusher = new Pusher(PUSHER_APP_KEY, {
	cluster: 'eu'
});

const MAX_LENGTH = 40;

class Header extends Component {
	state = {
		actionModal: false,
		stepRegister: false,
		profileEditConfirmMessage: false,
		detailMessageModal: false,
		notificationTitle: null,
		notificationMsg: null,
		notificationLoader: false,
		notificationList: [],
		notificationSound: false,
		logoutModal: false,
		logoutError: null,
		success: false,
		locationModal: false,
		latitude: null,
		longitude: null,
		description: 'Select Location',
		currentLocation: false
	}

	allAudio = new window.Audio(`${process.env.REACT_APP_ASSET_FILES_URL}soft-tone.mp3`);

	handleProfileConfirMmsgClose = () => {
		this.setState({ profileEditConfirmMessage: false });
	}

	handleProfileConfirMmsg = () => {
		this.setState({ profileEditConfirmMessage: true, actionModal: false });
	}

	openActionModal = () => {
		this.setState({
			actionModal: true
		});
	}

	closeActionModalxxx = () => {
		if (this.props.user.id !== null && this.props.user.email !== null) {
			this.setState({
				actionModal: false
			});
		}
	}

	closeActionModal = () => {
		// disable closing profile modal 
		console.log('closeActionModal==', this.props.user);
		if ( this.props.user.id !== null && ( this.props.user.email === null || this.props.user.email === '' ||  this.props.user.phone === null || this.props.user.phone === '') ) {
			
			this.setState({
				actionModal: true
			});
		} else {
			this.setState({
				actionModal: false
			});
		}
	}

	storeServiceCategory = (id) => {
		this.props.handleServiceCategory(id);
	}

	clearCart = () => {
		let data = this.props.cart;
		let cartItem = this.props.cartItem;

		cartItem = [];
		data.cartItem = cartItem;
		data.driverTip = 0;
		data.grandTotal = 0;
		this.props.handleCart(data);
	}

	handleMessageModal = (title, message) => {
		this.setState({
			detailMessageModal: true,
			notificationTitle: title,
			notificationMsg: message
		});
	}

	displayError = (e) => {
		let errorMessge = '';
		try {
			errorMessge = e.data.message ? e.data.message : e.data.error_description;
		} catch (e) {
			errorMessge = 'Unknown error!';
		}
		return errorMessge;
	}

	getNotifications = () => {
		this.setState({
			notificationLoader: true,
		}, () => {
			axios.get(`notifications?customer_id=${this.props.user.id}&pageSize=200`)
				.then(res => {
					this.setState({
						notificationList: res.data.data.data,
						notificationLoader: false,
					});
				})
				.catch(e => {
					let errorMsg = this.displayError(e);
					this.setState({
						notificationError: errorMsg,
						notificationLoader: false
					});
					setTimeout(() => {
						this.setState({ notificationError: null });
					}, 5000);
				});
		});
	}

	getPusherNotification = () => {
		let channel = pusher.subscribe('notification-customer-' + process.env.REACT_APP_MODE + '-' + this.props.user.id);
		channel.bind('notifications', data => {
			if (data.notificationLog.id) {
				const { notificationList } = this.state;
				const response = notificationList;
				const filteredData = response.filter((obj) => {
					if (obj.id != data.notificationLog.id) {
						return obj;
					}
				});
				this.setState({
					notificationList: [data.notificationLog, ...filteredData],
				});
				//this.allAudio.play();
				this.setState({
					notificationSound: true
				}, () => {
					setTimeout(() => {
						this.setState({ notificationSound: false });
					}, 5000);
				});
			}
		});
	}

	readNotification = (id) => {
		let obj = { id: id, message_checked: '1' };
		axios.post('notifications/checkMessage', obj);
		const response = this.state.notificationList;
		const filteredData = response.filter((obj) => {
			if (obj.id != id) {
				return obj;
			}
		});
		this.setState({
			notificationList: filteredData
		});
	}

	closeMessageModal = () => {
		this.setState({
			detailMessageModal: false
		});
	}

	componentDidMount() {
		if (this.props.user.id !== null) {

			console.log('componentDidMount==', this.props.user);
		
			this.getNotifications();
			this.getPusherNotification();

			if ( this.props.user.id !== null && ( this.props.user.email === null || this.props.user.email === '' ||  this.props.user.phone === null || this.props.user.phone === '') ) {

				this.setState({
					actionModal: true,
					stepRegister: true
				});
			}
		}

		if (this.props.geolocation.address == '' || this.props.geolocation.address == null) {
			this.setState({
				locationModal: true
			});
		}

		this.hideLocation();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.user.id !== this.props.user.id) {

			console.log('componentDidUpdate==', this.props.user);

			this.getNotifications();
			this.getPusherNotification();

			if ( this.props.user.id !== null && ( this.props.user.email === null || this.props.user.email === '' ||  this.props.user.phone === null || this.props.user.phone === '') ) {

				this.setState({
					actionModal: true,
					stepRegister: true
				});
			}
		}

		if (prevProps.cartItem !== this.props.cartItem) {
			this.getNotifications();
			this.getPusherNotification();
		}
	}

	handleLogoutClose = () => {
		this.setState({ logoutModal: false, logoutError: null, success: false });
	}

	openLocationModal = () => {
		this.setState({
			locationModal: true
		});
	}

	closeLocationModal = () => {
		if (this.props.geolocation.address) {
			this.setState({
				locationModal: false
			});
		}
	}

	handleLngLon = (description) => {
		geocodeByAddress(description)
			.then(results => getLatLng(results[0]))
			.then(({ lat, lng }) =>
				this.setState({
					latitude: lat,
					longitude: lng,
					description
				}, () => {
					let geodata = this.props.geolocation;
					geodata.latitude = lat;
					geodata.longitude = lng;
					geodata.address = description;
					geodata.refresh = true;

					//console.log('Header:handleLngLon=>', geodata);

					this.props.handleGeoLocation(geodata);
					this.setState({
						locationModal: false,
						currentLocation: false
					});
				})
			);
	}

	handleResetLocation = () => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(this.handelLocationHead, this.handelLocationError);
		} else {
			console.log('Geolocation is not supported by this browser.');
		}
	}

	handelLocationHead = (position) => {
		let geodata = this.props.geolocation;

		geodata.refresh = true;
		geodata.latitude = position.coords.latitude;
		geodata.longitude = position.coords.longitude;

		this.props.handleGeoLocation(geodata);
		this.positionHead(position.coords.latitude, position.coords.longitude);

		this.setState({
			locationModal: false,
			currentLocation: true
		});
	}

	positionHead = async (lat, lng) => {
		this.setState({ loadingmap: true });
		Geocode.setApiKey(GOOGLEAPIKEY);
		Geocode.fromLatLng(lat, lng).then(
			response => {
				const formatted_address = response.results[0].formatted_address;

				let geodata = this.props.geolocation;
				geodata.address = formatted_address;

				this.props.handleGeoLocation(geodata);
				console.log('result for ' + lat + '/' + lng, formatted_address);
			});
	}

	handelLocationError = (error) => {
		this.setState({
			allowGoogleLocation: false,
			// showLocationDeniedModal: true
		});
		switch (error.code) {
			case error.PERMISSION_DENIED:
				console.log('User denied the request for Geolocation.');
				// this.setState({ googleLocationMessage: 'User denied the request for Geolocation.' });
				break;
			case error.POSITION_UNAVAILABLE:
				console.log('Location information is unavailable.');
				// this.setState({ googleLocationMessage: 'Location information is unavailable.' });
				break;
			case error.TIMEOUT:
				console.log('The request to get user location timed out.');
				// this.setState({ googleLocationMessage: 'The request to get user location timed out.' });
				break;
			case error.UNKNOWN_ERROR:
				console.log('An unknown error occurred.');
				// this.setState({ googleLocationMessage: 'An unknown error occurred.' });
				break;
		}
	}

	handelLogout = () => {
		let user = {};
		user.first_name = null;
		user.last_name = null;
		user.timeStamp = null;
		user.id = null;
		user.user_type_name = null;
		user.profile_pic = null;
		user.gender = null;
		user.dob = null;
		user.stripe_customer_id = null;
		user.email = null;
		user.about = null;
		user.phone = null;
		user.register_with = null;
		axios.post('logout')
			.then((res) => {
				if (res.data.success === true) {
					this.clearCart();
					this.props.handelUser(user);
					this.props.sessionTokenUpdate(null, null);
					this.setState({ logoutModal: true, success: true, stepRegister: false });
				} else {
					this.setState({ logoutModal: true, success: false });
				}
			})
			.catch(e => {
				let errorMsg = this.displayError(e);
				this.setState({
					logoutError: errorMsg,
					logoutModal: true,
					success: false
				});
			});
		sessionStorage.removeItem('restaurantId');
	}

	hideLocation = () => {
		let appParam = this.props.location.pathname;
		//console.log('location=', this.props.location.pathname);
		if (appParam === '/driver-terms-condtions-app/app') {
			this.setState({
				locationModal: false
			});
		}
		if (appParam === '/privacy-policy-app/app') {
			this.setState({
				locationModal: false
			});
		}
		if (appParam === '/termsofservice-app/app') {
			this.setState({
				locationModal: false
			});
		}
	}

	render() {
		//console.log('location=',this.props.location.pathname);
		const { notificationList, notificationSound, description, currentLocation, stepRegister } = this.state;
		return (
			<div className="header" id="header">
				{notificationSound &&
					<Sound
						url={notificationAudio}
						playStatus={Sound.status.PLAYING}
					/>
				}
				<div className="container">
					<div className='headerWrap'>



						<div className='logoPan'>
							<Link to={SERVERFOLDER + '/'}>
								<figure className="logo"><img src={logo} alt="" onClick={() => this.clearCart()} /></figure>
							</Link>
						</div>
						<div className="menuPan">
							<ul className="topMenu">
								{this.props.location.pathname != '/' &&
									<li><Link to={SERVERFOLDER + '/'}>Home</Link></li>
								}
								<li><a className={`slideBox ${this.props.categoryId == 1 ? 'badge' : null}`} href="#" onClick={() => this.storeServiceCategory(1)}>Food</a></li>
								<li><a className={`slideBox ${this.props.categoryId == 2 ? 'badge' : null}`} href="#" onClick={() => this.storeServiceCategory(2)}>Grocery</a></li>
								<li><a href="https://apps.apple.com/us/app/the-delivery-guys/id1606717988" className="btn"><FaApple className="apple" /> iPhone</a> <a href="https://play.google.com/store/apps/details?id=com.tdg.customer" className="btn"><FaGooglePlay /> Android</a></li>

								{/*<li><a className={`slideBox ${this.props.categoryId == 3 ? 'badge' : null}`} href="#" onClick={() => this.storeServiceCategory(3)}>Parcel</a></li>*/}
							</ul>
						</div>
						<div className="headerRightCol">
							<ul>
								{this.props.location.pathname == '/' &&
									<li className="offerLi"
										style={{ cursor: 'pointer' }}
										onClick={this.openLocationModal}
									>
										{' '} {this.props.geolocation.address ? (this.props.geolocation.address && this.props.geolocation.address.substring(0, 20)) : description.length > 20 ? description.substring(0, 20) : description}
									</li>
								}
								{/*<li className="offerLi"><AiFillGift /> Offer</li>*/}

								{this.props.cartItem && this.props.cartItem.length > 0 &&
									<li>
										<div className="notificationPan">
											<Dropdown>
												<Dropdown.Toggle>
													<Link to={SERVERFOLDER + '/cart'}>
														<span className="num">{this.props.cartItem.length}</span>
														<IoMdCart className='cartIcon' />
													</Link>
												</Dropdown.Toggle>
											</Dropdown>
										</div>
									</li>
								}

								{this.props.user.id !== null &&
									<li>
										<div className="notificationPan">
											<Dropdown>
												<Dropdown.Toggle>
													{notificationList && notificationList.length > 0 &&
														<span className="num">{notificationList.length}</span>
													}
													<FaBell />
												</Dropdown.Toggle>
												{notificationList && notificationList.length > 0 &&
													<Dropdown.Menu>
														<div className="dropdownWrap">
															<ul>
																{notificationList.map((notification) => (
																	<li
																		key={notification.id}
																		onClick={
																			() => {
																				this.handleMessageModal(notification.title, notification.message);
																				this.readNotification(notification.id);
																			}
																		}
																	>
																		<div>
																			<h6>{notification.title}</h6>
																			<div>{`${notification.message.substring(0, MAX_LENGTH)}...`}</div>
																		</div>
																	</li>
																))}
															</ul>
														</div>
													</Dropdown.Menu>
												}
											</Dropdown>
										</div>
									</li>
								}

								<li className="profileLi">

									<Dropdown>
										<Dropdown.Toggle id="dropdown-basic" className="profileWrap">
											<img src={this.props.user.profile_pic ? `${IMAGEURL}profile/${this.props.user.profile_pic}` : profileImg} alt="" />
											${process.env.AWS_DEFAULT_REGION}
										</Dropdown.Toggle>
										<Dropdown.Menu>
											{this.props.user.id !== null &&
												<Dropdown.Item onClick={this.openActionModal}>Profile</Dropdown.Item>}
											{this.props.user.id !== null &&
												<Dropdown.Item as={Link} to={SERVERFOLDER + '/checkout'}>Checkout Now</Dropdown.Item>}
											{this.props.user.id !== null &&
												<Dropdown.Item as={Link} to={SERVERFOLDER + '/address'}>Delivery Address</Dropdown.Item>}
											{this.props.user.id !== null &&
												<Dropdown.Item as={Link} to={SERVERFOLDER + '/order-history'}>Order History</Dropdown.Item>}
											{this.props.user.register_with === 'Email' ? this.props.user.id !== null &&
												<Dropdown.Item as={Link} to={SERVERFOLDER + '/changepassword'}>Change Password</Dropdown.Item>
												: null
											}
											{this.props.user.id !== null ?
												<Dropdown.Item onClick={() => {
													let user = {};
													user.first_name = null;
													user.last_name = null;
													user.timeStamp = null;
													user.id = null;
													user.user_type_name = null;
													user.profile_pic = null;
													user.gender = null;
													user.dob = null;
													user.stripe_customer_id = null;
													user.email = null;
													user.about = null;
													user.phone = null;
													user.register_with = null;

													this.clearCart();
													this.props.handelUser(user);
													this.props.sessionTokenUpdate(null, null);
													this.setState({ logoutModal: true, success: true });

													/*axios.post('logout')
													.then((res) => {
														if (res.data.success === true) {
															this.props.handelUser(user);
															this.props.sessionTokenUpdate(null, null);
															this.setState({ logoutModal: true, success: true });
														} else {
															this.setState({ logoutModal: true, success: false });
														}
													})
													.catch(e => {
														let errorMsg = this.displayError(e);
														this.setState({
															logoutError: errorMsg,
															logoutModal: true,
															success: false
														});
													});*/
														
													sessionStorage.removeItem('restaurantId');
												}} href={'#'}>Logout</Dropdown.Item> :
												<Dropdown.Item as={Link} to={SERVERFOLDER + '/login'}>Sign in</Dropdown.Item>}
											{this.props.user.id === null &&
												<Dropdown.Item as={Link} to={SERVERFOLDER + '/alllogin'}>Sign Up</Dropdown.Item>}
										</Dropdown.Menu>
									</Dropdown>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<Modal
					show={this.state.detailMessageModal}
					onHide={this.closeMessageModal}
					className="notificationModal"
				>
					<Modal.Body className="text-center bg-white modal-body">
						<div className="modalHeader text-orange-primary notificationTitle">
							<h5>{this.state.notificationTitle}</h5>
						</div>
						<div className="notificationMsg">
							{this.state.notificationMsg}
						</div>
						<div className="text-center mb-4">
							<Button
								className="but-gray"
								type="button"
								onClick={this.closeMessageModal}
							>
								Return
							</Button>
						</div>
					</Modal.Body>
				</Modal>

				{/*********User Profile********/}
				<Modal
					show={this.state.actionModal}
					onHide={this.closeActionModal}
					className="right half noPadding slideModal"
				>
					<Modal.Header className={'customeClose'} closeButton></Modal.Header>
					<Modal.Body style={{ backgroundColor: '#e4cac3' }}>
						<div className="modalHeader border-bottom-0">
							<div className="row">
								<Col md={12}>
									<h1 className="form-label font-weight-bold">{stepRegister ? 'Complete Your Profile' : 'Update Profile'}</h1>
								</Col>
							</div>
						</div>
						<div className="modalBody content-body noTabs border-0">
							<Profile
								handleProfileConfirMmsg={this.handleProfileConfirMmsg}
								closeActionModal={this.closeActionModal}
								handelLogout={this.handelLogout}
								stepRegister={stepRegister}
							/>
						</div>
					</Modal.Body>
				</Modal>

				{/*********Location********/}
				<Modal
					show={this.state.locationModal}
					size='sm'
					onHide={this.closeLocationModal}
					className="right half noPadding slideModal locationModal"
				>
					<Modal.Header className={'customeClose'} closeButton></Modal.Header>
					<Modal.Body>
						<div className="modalBody content-body noTabs border-0">

							<h3>A New version is available ! Please update your mobile app before you place any new order!</h3>
							<div className="defaultAddress">
								<ImLocation2 /> {currentLocation ? this.props.geolocation.address : description}
							</div>


							<div className="">
								<GooglePlacesAutocomplete
									onSelect={({ description }) => (this.handleLngLon(description))}
									placeholder='Search for area, street name...'
									inputClassName={'form-control shadow'}
									loader={<LoadingSpinner />}
								/>
							</div>
							<div className="getLocation" onClick={this.handleResetLocation}>
								<BiCurrentLocation />
								Get current location
								<span>Using GPS</span>
							</div>
						</div>
					</Modal.Body>
				</Modal>

				{/**********User logout Popup******** */}
				<Modal
					show={this.state.logoutModal}
					onHide={this.handleLogoutClose}
					className="payOptionPop"
				>
					<Modal.Body>
						<Row>
							<Col md={12} className="text-center">
								<span className='successIcon'><AiFillCheckCircle /></span>
							</Col>
						</Row>
						<Row>
							<Col md={12} className="text-center">
								{this.state.success === true ?
									<h5>You have successfully logged out</h5> :
									this.state.logoutError === null ?
										<h5>Logout not success due to technical issue, please try again</h5> :
										<h5>{this.state.logoutError}</h5>
								}
							</Col>
						</Row>
						<Row>
							<Col md={12} className="text-center">
								<Button
									onClick={this.handleLogoutClose}
									className="but-gray"
								>
									Return
								</Button>
							</Col>
						</Row>
					</Modal.Body>
				</Modal>

			</div >
		);
	}
}

Header.propTypes = {
	handleCart: PropTypes.func,
	cartItem: PropTypes.any,
	user: PropTypes.object,
	sessionTokenUpdate: PropTypes.func,
	handelUser: PropTypes.func,
	categoryId: PropTypes.any,
	handleServiceCategory: PropTypes.func,
	history: PropTypes.object,
	cart: PropTypes.any,
	geolocation: PropTypes.any,
	handleGeoLocation: PropTypes.func,
	location: PropTypes.object,
	match: PropTypes.shape({
		params: PropTypes.shape({
			page: PropTypes.string
		})
	})
};

const mapStateToProps = (state) => {
	return {
		cartItem: state.cart.cartItem,
		user: state.user,
		categoryId: state.servicecategory.categoryId,
		cart: state.cart,
		geolocation: state.geolocation
	};
};

const mapDispatchToProps = dispatch => {
	return {
		handleCart: (data) => dispatch(handleCart(data)),
		sessionTokenUpdate: (token) => dispatch(accessToken(token)),
		handelUser: (user) => dispatch(handelUser(user)),
		handleServiceCategory: (data) => dispatch(handleServiceCategory(data)),
		handleGeoLocation: (data) => dispatch(handleGeoLocation(data))
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
