import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Col, Row, Modal } from 'react-bootstrap';
import axios from '../../../shared/eaxios';
import LoadingSpinner from '../../../Components/LoadingSpinner/LoadingSpinner';
import { AiFillCheckCircle } from 'react-icons/ai';
import { SERVERFOLDER } from '../../../Constants';

class VerifyAccount extends Component {
    state = {
        token: this.props.match.params.id,
        loader: false,
        succesSubmitModal: false,
        showWarningModal: false,
        errorMsg: null
    }

    componentDidMount() {
        this.handleSubmit();
    }

    handleSubmit = () => {
        this.setState({ loader: true }, () => {
            axios.get(`verifyAccount/${this.state.token}`)
                .then((res) => {
                    if (res.data.success === true) {
                        this.setState({
                            loader: false,
                        }, () => {
                            this.showSuccesSubmitModal();
                        });
                    } else {
                        this.setState({
                            loader: false,
                            showWarningModal: true,
                            errorMsg: res.data.message,
                        });
                    }

                })
                .catch(err => {
                    let errorMessage = '';
                    try {
                        errorMessage = err.data.message ? err.data.message : err.data.error_description;
                    } catch (err) {
                        errorMessage = 'No records found.';
                    }
                    this.setState({
                        loader: false,
                        showWarningModal: true,
                        errorMsg: errorMessage,
                    });
                });
        });
    }

    showSuccesSubmitModal = () => {
        this.setState({ succesSubmitModal: true });
    };

    closeSuccesSubmitModal = () => {
        this.setState({ succesSubmitModal: false });
        this.props.history.push(`${SERVERFOLDER + '/login'}`);
    };

    closeWarningModal = () => {
        this.setState({ showWarningModal: false });
        this.props.history.push(`${SERVERFOLDER + '/'}`);
    }

    render() {
        return (
            <div className="position-relative">
                {this.state.loader ?
                    <div><h5>Account is verifying... </h5><LoadingSpinner /></div>
                    : null
                }
                <Modal
                    show={this.state.succesSubmitModal}
                    onHide={this.closeSuccesSubmitModal}
                    className="payOptionPop"
                >
                    <Modal.Body className='bg-white text-dark'>
                        <Row>
                            <Col md={12} className="text-center">
                                <span className='successIcon'><AiFillCheckCircle /></span>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className="text-center">
                                <h5>Your account has been verified successfully </h5>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <div className="text-center my-4">
                        <Button
                            type="button"
                            onClick={this.closeSuccesSubmitModal}
                        >
                            Return
                          </Button>
                    </div>
                </Modal>

                <Modal
                    show={this.state.showWarningModal}
                    onHide={this.closeWarningModal}
                    className="payOptionPop"
                >
                    <Modal.Body className="WarningModal bg-white">
                        <Row>
                            <Col md={12} className="text-center">
                                <h5 className='text-danger'> {this.state.errorMsg}</h5>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <div className="text-center mb-4">
                        <Button
                            className="but-gray"
                            type="button"
                            onClick={this.closeWarningModal}
                        >
                            Return
                          </Button>
                    </div>

                </Modal>

            </div>
        );
    }
}

VerifyAccount.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string
        })
    }),
    location: PropTypes.object,
    history: PropTypes.any,
};

export default VerifyAccount;
