import React, { Component } from 'react';
import {
	FaPlusCircle,
	FaMapPin,
} from 'react-icons/fa';
import './Address.scss';
//import { MdEdit } from 'react-icons/md';
import { RiArrowRightSLine } from 'react-icons/ri';
import { MdClose } from 'react-icons/md';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from './../../shared/eaxios';
import AppLoader from './../AppLoader/AppLoader';
import { Formik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { AiFillCheckCircle } from 'react-icons/ai';
import { Button, Col, Row, Modal } from 'react-bootstrap';
import LoadingSpinner from '../../Components/LoadingSpinner/LoadingSpinner';
// import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import Geocode from 'react-geocode';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { SERVERFOLDER, GOOGLEAPIKEY } from '../../Constants';

const {
	withScriptjs,
	withGoogleMap,
	GoogleMap,
	Marker,
	//InfoWindow,
} = require('react-google-maps');
const { compose, withProps, withState, withHandlers } = require('recompose');

const initialValues = {
	type: 1,
	tag: 'home',
	addr: '',
	addr2: '',
	country: '',
	state: '',
	city: '',
	zip: '',
	lat: '',
	lng: '',
	is_default: true
};

const schema = Yup.object().shape({
	addr: Yup.string()
		.strict()
		.required('Please type address'),
	addr2: Yup.string()
		.strict(),
	country: Yup.string()
		.strict()
		.required('Please type country'),
	state: Yup.string()
		.strict()
		.required('Please type state'),
	city: Yup.string()
		.strict()
		.required('Please type city'),
	zip: Yup.string()
		.strict()
		.required('Please type zip')
});

const getErrorStyles = (errors, touched, fieldName) => {
	if (getIn(errors, fieldName) && getIn(touched, fieldName)) {
		return {
			border: '1px solid red'
		};
	}
};

const MapWithControlledZoom = compose(
	withProps({
		googleMapURL: 'https://maps.googleapis.com/maps/api/js?key='+GOOGLEAPIKEY+'&libraries=geometry,drawing,places',
		loadingElement: <div style={{ height: '100%' }} />,
		containerElement: <div style={{ height: '100%' }} />,
		mapElement: <div style={{ height: '100%' }} />,
	}),
	withState('zoom', 'onZoomChange', 16),
	withHandlers(() => {
		const refs = { map: undefined };
		return {
			onMapMounted: () => ref => {
				refs.map = ref;
			},
			onZoomChanged: ({ onZoomChange }) => () => {
				onZoomChange(refs.map.getZoom());
			}
		};
	}),
	withScriptjs,
	withGoogleMap
)(props =>
	<GoogleMap
		center={{ lat: props.lat, lng: props.lng }}
		zoom={props.zoom}
		ref={props.onMapMounted}
		onZoomChanged={props.onZoomChanged}
	>
		<Marker
			position={{ lat: props.lat, lng: props.lng }}
			onClick={props.onMarkerClick}
			draggable={true}
			onDragEnd={props.onMarkerMove}
		/>
	</GoogleMap>
);

class Address extends Component {
	constructor() {
		super();
		this.state = {
			cardShow: false,
			addAddrsShow: false,
			finalConfirmation: false,
			searchString: '',
			loader: false,
			deleteLoader: false,
			latitude: null,
			longitude: null,
			addressLoader: false,
			addressList: [],
			errorMsg: null,
			addressErrorMessge: null,
			isEdit: false,
			editData: null,
			deleteData: null,
			isDelete: false,
			showDeleteModal: false,
			deleteErrorMessge: null,
			showMapModal: false,
			selectedAddressList: '',
			addressAutocomplete: '',
			formatted_address: '',
			clean_address: '',
			loading: false,
			loadingmap: false,

			actionVerify: false,
			actionVerifyType: null,
			actionVerifyText: null,
		};
	}

	displayError = (e) => {
		let errorMessge = '';
		try {
			errorMessge = e.data.message ? e.data.message : e.data.error_description;
		} catch (e) {
			errorMessge = 'Unknown error!';
		}
		return errorMessge;
	}

	componentDidMount() {
		this.setState({
			latitude: this.props.geolocation.latitude,
			longitude: this.props.geolocation.longitude
		});
		this.position(this.props.geolocation.latitude, this.props.geolocation.longitude);
		this.fetchAddressList();
	}

	handleSubmit = (values) => {
		values.lat = this.state.latitude;
		values.lng = this.state.longitude;
		values.user_id = this.props.user.id;
		this.setState({ loader: true }, () => {
			axios.post(this.state.isEdit === true ? `addressUpdate/${values.id}` : 'addressAddv2', values)
				.then(() => {
					this.setState({
						loader: false,
					}, () => {
						this.showSuccesSubmitModal();

						if (this.props.cartItem && this.props.cartItem.length > 0) {
							this.openCheckout();
						} else {
							this.position(this.props.geolocation.latitude, this.props.geolocation.longitude);
						}

					});
				})
				.catch(err => {
					let errorMsg = this.displayError(err);
					this.setState({
						loader: false,
						errorMsg: errorMsg,
					});
					setTimeout(() => {
						this.setState({ errorMsg: null });
					}, 2500);
				});
		});
	}

	handleDefault = () => {
		let values = {};
		values.user_id = this.props.user.id;

		//this.setState({ deleteLoader: true }, () => {
			axios.post(`addressDefaultv2/${this.state.deleteData}`, values)
				.then(() => {
					this.setState({
						statusConfirMmsg: true,
						deleteData: null,
						showDeleteModal: false,
						actionVerify: false,
					}, () => {
						this.showSuccesSubmitModal();
					});
				})
				.catch(e => {
					let errorMsg = this.displayError(e);
					this.setState({
						deleteErrorMessge: errorMsg,
						deleteLoader: false
					});
					setTimeout(() => {
						this.setState({ deleteErrorMessge: null });
					}, 5000);
				});
		//});
	}

	handleActionVerify = (id, actionVerifyType, active_status) => {
		this.setState({
			actionVerify: true,
			deleteData: id,
			actionVerifyType
		});

		if (actionVerifyType === 'change') {
			this.setState({
				active_status,
				actionVerifyText: 'Do you want to make this default address?'
			});
		} else if (actionVerifyType === 'delete') {
			this.setState({
				active_status,
				actionVerifyText: 'Do you want to delete this address?'
			});
		}
	}

	handleActionVerifyClose = () => {
		this.setState({
			statusConfirMmsg: false,
			successMessage: null,
			actionVerify: false,
			viewEditModal: false,

		});
	}

	handleHide = () => {
		this.setState({
			actionVerify: false,
			statuserrorMsg: null,
		});
	}

	openModalEditAddrs = (data) => {
		this.setState({
			addAddrsShow: true,
			isEdit: true,
			editData: data
		});
	}

	openModalDeleteAddrs = (id) => {
		this.setState({
			showDeleteModal: true,
			deleteData: id,
			isDelete: true
		});
	}

	showSuccesSubmitModal = () => {
		this.setState({ succesSubmitModal: true, addAddrsShow: false });
	};

	closeSuccesSubmitModal = () => {
		this.setState({ succesSubmitModal: false, isEdit: false, isDelete: false });
		this.fetchAddressList();
	};

	openModalAddAddrs = () => {
		this.setState({
			showMapModal: true
		});
	};

	closeMapModal = () => {
		this.setState({
			showMapModal: false,
			isEdit: false,
			isDelete: false,
			addressAutocomplete: '',
		});
	}

	closeModal = () => {
		this.setState({
			addAddrsShow: false,
			isEdit: false,
			isDelete: false,
		});
		if (this.state.isEdit === true) {
			this.setState({ showMapModal: false });
		} else {
			this.setState({ showMapModal: true });
		}
		this.position(this.props.geolocation.latitude, this.props.geolocation.longitude);
	};

	closeDeleteModal = () => {
		this.setState({
			showDeleteModal: false,
		});
	}

	fetchAddressList = () => {
		this.setState({ addressLoader: true }, () => {
			axios.get(`addressListv2?user_id=${this.props.user.id}`)
				.then(response => {
					this.setState({
						addressLoader: false,
						addressList: response.data.data.data
					});
				})
				.catch(e => {
					let errorMsg = this.displayError(e);
					this.setState({
						addressErrorMessge: errorMsg,
						addressLoader: false
					});
					setTimeout(() => {
						this.setState({ addressErrorMessge: null });
					}, 5000);
				});
		});
	}

	deleteAddress = () => {
		this.setState({ deleteLoader: true }, () => {
			axios.delete(`addressDeletev2/${this.state.deleteData}`)
				.then(() => {
					this.setState({
						deleteLoader: false,
						deleteData: null,
						showDeleteModal: false,
						actionVerify: false,
					}, () => {
						this.showSuccesSubmitModal();
					});
				})
				.catch(e => {
					let errorMsg = this.displayError(e);
					this.setState({
						deleteErrorMessge: errorMsg,
						deleteLoader: false
					});
					setTimeout(() => {
						this.setState({ deleteErrorMessge: null });
					}, 5000);
				});
		});
	}

	onMarkerClick = (e) => {
		this.position(e.latLng.lat(), e.latLng.lng());
	}

	position = async (lat, lng) => {
		this.setState({ loadingmap: true });
		Geocode.setApiKey(GOOGLEAPIKEY);
		//Geocode.setLanguage('en');
		//Geocode.setRegion('es');
		//Geocode.setLocationType('ROOFTOP');
		Geocode.fromLatLng(lat, lng).then(
			response => {
				console.log('Geocode Response=>', response.results[0].address_components);

				const formatted_address = response.results[0].formatted_address;
				const address_components = response.results[0].address_components;

				let result = {};

				for (let i = 0; i < address_components.length; i++) {

					for (let j = 0; j < address_components[i].types.length; j++) {

						switch (address_components[i].types[j]) {
							case 'establishment':
								result.establishment = address_components[i].long_name;
								break;
							case 'street_number':
								result.street_number = address_components[i].long_name;
								break;
							case 'street_address':
								result.street_address = address_components[i].long_name;
								break;
							case 'premise':
								result.premise = address_components[i].long_name;
								break;
							case 'route':
								result.route = address_components[i].long_name;
								break;
							case 'neighborhood':
								result.neighborhood = address_components[i].long_name;
								break;
							case 'landmark':
								result.landmark = address_components[i].long_name;
								break;
							case 'sublocality_level_3':
								result.sublocality_level_3 = address_components[i].long_name;
								break;
							case 'sublocality_level_2':
								result.sublocality_level_2 = address_components[i].long_name;
								break;
							case 'sublocality_level_1':
								result.sublocality_level_1 = address_components[i].long_name;
								break;
							case 'locality':
								result.city = address_components[i].long_name;
								break;
							case 'administrative_area_level_2':
								result.administrative_area_level_2 = address_components[i].long_name;
								break;
							case 'administrative_area_level_1':
								result.state = address_components[i].long_name;
								break;
							case 'country':
								result.country = address_components[i].long_name;
								result.country_code = address_components[i].short_name;
								break;
							case 'postal_code':
								result.zip = address_components[i].long_name;
								break;
						}

					}

				}

				let sparseArray = [
					result.establishment,
					result.street_number,
					result.street_address,
					result.premise,
					result.route,
					result.neighborhood,
					result.landmark,
					result.sublocality_level_3,
					result.sublocality_level_2,
					result.sublocality_level_1,
					result.administrative_area_level_2
				];
				//console.log('sparseArray=>',sparseArray);

				let cleanArray = sparseArray.filter(function (el) { return el != undefined; });

				//console.log('cleanArray=>',cleanArray);

				result.addr = cleanArray.join(', ').trim();
				//result.addr2 = formatted_address;
				result.lat = lat;
				result.lng = lng;

				//console.log('formatted_address=>', formatted_address);
				//console.log('result=>',result);

				//const selectedAddressList = address.split(',');
				this.setState({
					loadingmap: false,
					formatted_address,
					clean_address: result.addr,
					selectedAddressList: result,
					latitude: lat,
					longitude: lng
				}, () => { });
			});
	}

	handleLngLon = (description) => {
		this.setState({ loading: true });
		geocodeByAddress(description)
			.then(results => getLatLng(results[0]))
			.then(({ lat, lng }) =>
				this.setState({
					loading: false,
					addressAutocomplete: description
				}, () => {
					this.position(lat, lng);
				})
			);
	}

	handleAddress = () => {
		this.setState({
			addAddrsShow: true,
			showMapModal: false
		});
		const addressOnMap = this.state.selectedAddressList;

		//console.log('FINAL=',this.state.selectedAddressList);

		/*initialValues.country = addressOnMap[addressOnMap.length - 1];
		initialValues.state = addressOnMap[addressOnMap.length - 2].trim().replace(/[0-9]/g, '');
		initialValues.zip = addressOnMap[addressOnMap.length - 2].trim().replace(/^\D+/g, '');
		
		if (addressOnMap.length === 3) {
			initialValues.addr = addressOnMap[addressOnMap.length - 3];
		} else {
			initialValues.city = addressOnMap[addressOnMap.length - 3];
			initialValues.addr = addressOnMap[addressOnMap.length - 4];
		}*/

		initialValues.addr = addressOnMap.addr;
		initialValues.addr2 = addressOnMap.addr2;
		initialValues.city = addressOnMap.city;
		initialValues.state = addressOnMap.state;
		initialValues.country = addressOnMap.country;
		initialValues.country_code = addressOnMap.country_code;
		initialValues.zip = addressOnMap.zip;
	}

	openCheckout = () => {

		this.props.history.push(`${SERVERFOLDER + '/checkout'}`);
	};

	render() {
		const {
			addressLoader,
			addressErrorMessge,
			addressList,
			isEdit,
			isDelete,
			deleteLoader,
			deleteErrorMessge,
			formatted_address,
			actionVerifyType
		} = this.state;

		return (
			<div>
				{addressLoader ?
					<div className="" style={{ height: '72vh' }}><AppLoader /></div> :
					<div className="bodyPan">
						<div className="container cartBg">
							<div className="titlePan">
								<h2>Address Book</h2>
								<div className="row">
									<div className="col-7">
										{this.props.cartItem && this.props.cartItem.length > 0 &&
											<div className="cartInfo mb-4">
												<a className="addMore" onClick={() => this.openCheckout()}><FaPlusCircle /> Checkout Now</a>
											</div>
										}
										<div className="cartInfo mb-4">
											<a className="addMore" onClick={() => this.openModalAddAddrs()}><FaPlusCircle /> Add New Address</a>
										</div>
									</div>
								</div>
							</div>

							{addressErrorMessge ?
								<p className="alert alert-danger text-center" role="alert"> {addressErrorMessge} </p>
								:
								addressList && addressList.length > 0 && addressList.map((data, i) => {
									return <div key={data.id} className={`card nearCard addCard ${data.is_default == 1 ? 'defaultClass' : null}`}>
										<div className="card-body">
											<h4>Address {i + 1} <span>{data.tag}</span></h4>
											<p>{data.addr}<br />{data.addr2}<br />{data.city} {data.state} {data.country} {data.zip}</p>
										</div>
										<div className="card-footer">
											{/* <label >
                                                <a className="editLink" style={{ cursor: 'pointer' }} onClick={() => this.openModalEditAddrs(data)}> <MdEdit /> Edit</a>
                                            </label> */}
										
											{ data.is_default == 0 &&
											<>
												<label>
													<a onClick={() => this.handleActionVerify(data.id, 'change', '1')} className="editLink" style={{ cursor: 'pointer' }}>  Make Default</a>
												</label>
												<a onClick={() => this.handleActionVerify(data.id, 'delete')} className="editLink text-danger" style={{ cursor: 'pointer' }}><strong>DELETE ADDRESS</strong></a>
											</>
											}

											{/* <a className="editLink text-danger" style={{ cursor: 'pointer' }} onClick={() => this.openModalDeleteAddrs(data.id)}><strong>DELETE ADDRESS===</strong></a> */}
										</div>
									</div>;
								})
							}

						</div>

						<Modal size={'xl'} show={this.state.showMapModal} onHide={this.closeMapModal}>
							<Modal.Header>
								<Modal.Title>
									{this.state.isEdit === true ? 'Edit' : 'Add'} Address
								</Modal.Title>
								<span className="modalClose" onClick={() => this.closeMapModal()}><MdClose /></span>
							</Modal.Header>
							<Modal.Body>
								<div className="mb-3">
									<GooglePlacesAutocomplete
										onSelect={({ description }) => (this.handleLngLon(description))}
										placeholder='Search...'
										inputClassName={'form-control'}
										loader={<LoadingSpinner />}
									/>
								</div>


								<div style={{ height: '75vh' }}>
									{this.state.loadingmap ?
										<span className='text-center'><LoadingSpinner /></span> :
										<MapWithControlledZoom
											lat={this.state.latitude}
											lng={this.state.longitude}
											onMarkerClick={this.onMarkerClick}
											onMarkerMove={this.onMarkerClick}
										/>
									}
								</div>
								<div className="card shadow-lg gAddressCard">
									<div className="card-body">
										<h5>Address</h5>
										<p>{formatted_address}</p>
										<div className="text-center">
											<Button variant="primary mr-3" type="submit" className="btnStylePrimary mt-3" onClick={this.closeMapModal}>
												<span className="font-weight-normal">Return</span>
											</Button>
											<Button variant="primary mr-3" type="submit" className="btnStylePrimary mt-3" onClick={this.handleAddress}>
												<span className="font-weight-normal">Ok</span>
											</Button>
										</div>
										<p className="text-danger mt-3 mb-n3 text-center"><FaMapPin /> Move the drop pin to your location</p>

									</div>
								</div>
							</Modal.Body>
						</Modal>

						{/* Add Address Modal  */}
						<Modal show={this.state.addAddrsShow}
							onHide={this.closeModal}>
							<Modal.Header>
								<Modal.Title>
									{this.state.isEdit === true ? 'Edit' : 'Add'} Address</Modal.Title>
								<span className="modalClose" onClick={() => this.closeModal()}><MdClose /></span>
							</Modal.Header>
							<Modal.Body>
								<div>
									{this.state.loader ?
										<span className='loaderStyle'><LoadingSpinner /></span>
										: null
									}
									{this.state.errorMsg ?
										<p className="alert alert-danger text-center" role="alert"> {this.state.errorMsg} </p>
										: null
									}
									<Formik
										initialValues={this.state.isEdit === true ? this.state.editData : initialValues}
										validationSchema={schema}
										onSubmit={this.handleSubmit}
									>
										{({
											values,
											errors,
											touched,
										}) => {
											return (<Form className='text-dark'>
												<div className="position-relative">
													<div className="form-group">
														<Field
															style={getErrorStyles(errors, touched, 'addr')}
															type="text"
															name="addr"
															value={values.addr || ''}
															placeholder="Address Line 1"
															className="form-control"
														/>
														{errors.addr && touched.addr ?
															<span className="errorMsg text-danger">{errors.addr}</span>
															: null}
													</div>

													<div className="form-group">
														<Field
															style={getErrorStyles(errors, touched, 'addr2')}
															type="text"
															name="addr2"
															value={values.addr2 || ''}
															placeholder="Address Line 2"
															className="form-control"
														/>
														{errors.addr2 && touched.addr2 ?
															<span className="errorMsg text-danger">{errors.addr2}</span>
															: null}
													</div>
													<div className="form-group">
														<Field
															style={getErrorStyles(errors, touched, 'city')}
															type="text"
															name="city"
															value={values.city || ''}
															placeholder="City"
															className="form-control"
														/>
														{errors.city && touched.city ?
															<span className="errorMsg text-danger">{errors.city}</span>
															: null}
													</div>
													<div className="form-group">
														<Field
															style={getErrorStyles(errors, touched, 'state')}
															type="text"
															name="state"
															value={values.state || ''}
															placeholder="State"
															className="form-control"
														/>
														{errors.state && touched.state ?
															<span className="errorMsg text-danger">{errors.state}</span>
															: null}
													</div>
													<div className="form-group">
														<Field
															style={getErrorStyles(errors, touched, 'country')}
															type="text"
															name="country"
															value={values.country || ''}
															placeholder="Country"
															className="form-control"
														/>
														{errors.country && touched.country ?
															<span className="errorMsg text-danger">{errors.country}</span>
															: null}
													</div>
													<div className="form-group">
														<Field
															style={getErrorStyles(errors, touched, 'zip')}
															type="text"
															name="zip"
															value={values.zip || ''}
															placeholder="ZIP"
															className="form-control"
														/>
														{errors.zip && touched.zip ?
															<span className="errorMsg text-danger">{errors.zip}</span>
															: null}
													</div>
													<div className="form-group">
														<label>
															<Field
																style={getErrorStyles(errors, touched, 'tag')}
																type="radio"
																name="tag"
																value={'home'}
																checked={values.tag == 'home'}
																className="ml-3"
															/>
															&nbsp;Home
														</label>
														<label>
															<Field
																style={getErrorStyles(errors, touched, 'tag')}
																type="radio"
																name="tag"
																value={'work'}
																checked={values.tag == 'work'}
																className="ml-3"
															/>
															&nbsp;Work
														</label>
														<label>
															<Field
																style={getErrorStyles(errors, touched, 'tag')}
																type="radio"
																name="tag"
																value={'hotel'}
																checked={values.tag == 'hotel'}
																className="ml-3"
															/>
															&nbsp;Hotel
														</label>
														<label>
															<Field
																style={getErrorStyles(errors, touched, 'tag')}
																type="radio"
																name="tag"
																value={'other'}
																checked={values.tag == 'other'}
																className="ml-3"
															/>
															&nbsp;Other
														</label>
														<br />
													</div>
													<div className="form-group">
														<Field
															style={getErrorStyles(
																errors, touched,
																'is_default'
															)}
															type="checkbox"
															name="is_default"
															className="tc_check"
															defaultChecked="true"
														/> Make Default
														<br />
														{errors.is_default ? <span className="errorMsg text-danger">{errors.is_default}</span> : null}
													</div>

													<div className='row mt-2 text-center'>
														<div className='col-sm-12'>
															<Button variant="primary mr-3" type="submit" className="addCartBtn mt-3">
																{isEdit ? 'Update' : 'Save'} Address<RiArrowRightSLine />
															</Button>
														</div>
													</div>
												</div>
											</Form>);
										}}
									</Formik>
								</div>
							</Modal.Body>
						</Modal>
						{/* Add Address  Modal*/}

						<Modal
							show={this.state.succesSubmitModal}
							onHide={this.closeSuccesSubmitModal}
							className="payOptionPop"
						>
							<Modal.Body className='bg-white text-dark'>
								<Row>
									<Col md={12} className="text-center">
										<span className='successIcon'><AiFillCheckCircle /></span>
									</Col>
								</Row>
								<Row>
									<Col md={12} className="text-center">
										{isEdit ? <h5>Address has been Updated Successfully</h5> :
											isDelete ? <h5>Address has been deleted Successfully</h5> :
												<h5>Address has been Successfully Saved</h5>}
									</Col>
								</Row>
							</Modal.Body>
							<div className="text-center my-4">
								<Button
									type="button"
									onClick={this.closeSuccesSubmitModal}
								>
									Return
								</Button>
							</div>
						</Modal>

						<Modal
							show={this.state.actionVerify}
							onHide={this.handleHide}
							dialogClassName="modal-90w"
							aria-labelledby="example-custom-modal-styling-title"
						>
							<Modal.Body className="bg-white text-dark">
								{deleteLoader ?
									<span className='loaderStyle'><LoadingSpinner /></span>
									: null
								}
								{deleteErrorMessge ?
									<p className="alert alert-danger text-center" role="alert"> {deleteErrorMessge} </p>
									: null
								}
								<div className="m-auto text-center">
									<h6 className="mb-3 text-dark">{this.state.actionVerifyText}</h6>
								</div>
								{this.state.statuserrorMsg ? <div className="alert alert-danger my-3 text-center col-12" role="alert">{this.state.statuserrorMsg}</div> : null}
								<div className="m-auto text-center">

									<button className="btn btn-danger mr-2" onClick={() => this.handleHide()}>Return</button>

									{ actionVerifyType === 'change' && (this.state.statuserrorMsg == null ? <button className="btn btn-success" onClick={() => this.handleDefault()}>Confirm</button> : null)}

									{actionVerifyType === 'delete' && (deleteErrorMessge == null ? <button className="btn btn-success" onClick={() => this.deleteAddress()}>Confirm</button> : null)}

								</div>

							</Modal.Body>
						</Modal>

						<Modal
							show={this.state.showDeleteModal}
							onHide={this.closeDeleteModal}
							className="payOptionPop"
						>
							<Modal.Body className='bg-white text-dark'>
								{deleteLoader ?
									<span className='loaderStyle'><LoadingSpinner /></span>
									: null
								}
								{deleteErrorMessge ?
									<p className="alert alert-danger text-center" role="alert"> {deleteErrorMessge} </p>
									: null
								}
								<Row>
									<Col md={12} className="text-center">
										<h5>Do you want to delete this address?</h5>
									</Col>
								</Row>
							</Modal.Body>
							<div className="text-center my-4">
								<Button
									type="button"
									onClick={this.closeDeleteModal}
									variant="primary"
								>
									Return
								</Button>
								&nbsp;&nbsp;&nbsp;
								<Button
									type="button"
									onClick={this.deleteAddress}
									variant="primary mr-3"
									className="btnStylePrimary">
									Confirm
								</Button>

							</div>
						</Modal>
					</div>
				}
			</div>
		);
	}
}

Address.propTypes = {
	token: PropTypes.any,
	user: PropTypes.object,
	google: PropTypes.object,
	geolocation: PropTypes.any,
	cartItem: PropTypes.any,
	history: PropTypes.object,
};

const mapStateToProps = (state) => {
	return {
		geolocation: state.geolocation,
		cartItem: state.cart.cartItem,
		user: state.user,
	};
};

export default connect(mapStateToProps)(Address);
