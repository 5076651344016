import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// Import reducer files
import Auth from './reducers/auth';
import business from './reducers/business';
import user from './reducers/user';
import cart from './reducers/cart';
import servicecategory from './reducers/servicecategory';
import geolocation from './reducers/geolocation';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth', 'user', 'cart', 'servicecategory', 'geolocation']
};

const rootReducer = combineReducers({
    auth: Auth,
    business,
    user,
    cart,
    servicecategory,
    geolocation
});

export default persistReducer(persistConfig, rootReducer);
