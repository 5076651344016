/* eslint-disable no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unescaped-entities */
/* eslint-disable no-irregular-whitespace */

import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import LoadingSpinner from '../../../Components/LoadingSpinner/LoadingSpinner';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { handleCart } from '../../../redux/actions/cart';
import { SERVERFOLDER } from '../../../Constants';
import PropTypes from 'prop-types';

class DriverPolicy extends Component {
	state = {
		errorMsg: '',
		loader: false,
		user_type_id: null,
		succesSubmitModal: false,
	}

	componentDidMount() {
		window.scrollTo(0, 0);

		let appParam = this.props.match.params.page;
		if (appParam === 'app') {
			document.getElementById('header').classList.add('app-page');
		}

	}

	render() {
		return (
			<div className="position-relative">
				{this.state.loader ?
					<span className='loaderStyle'><LoadingSpinner /></span>
					: null
				}

				<Container className='py-4'>
					<h3>Our Driver Policy</h3>
					<div className="privacyContent">

						<p>Future Logistics Solutions Ltd (&quot;we&quot;, &quot;our&quot;, &quot;us&quot; &quot;TDG&quot; or &quot;The Delivery Guys&quot;) are committed to protecting the privacy of drivers who apply to deliver with us, including all users of the driver mobile device application (the Driver App) and related services.</p>
						<p>This Privacy Statement applies to the use of the TDG Drivers App. In order for Thedeliveryguys.ie to provide the Driver App to you and to make sure it functions properly, we use some of your personal data. TDG takes your privacy seriously and would like to inform you via this Privacy Statement regarding the personal data processed within the Courier App, the way we process this personal data and the purposes of the processing.</p>
						<p>The Driver App is provided to restaurants as part of the TDG service. The Driver App is intended to improve the order delivery process for restaurant partners and to improve the customer experience. The Driver App is no requirement of the TDG services. You as a delivery driver are not obligated to use the application. If you decide to use this application TDG will process your personal data. The processing of your personal data starts the moment you login to the app. From this moment the following data is processed by the Drivers App:</p>

						<ul className='list'>
							<li>Usage data (e.g. Browsing time, screen view)</li>
							<li>IP Address</li>
							<li>GPS Location</li>
							<li>Name</li>
							<li>Email address</li>
							<li>Vehicle type</li>
							<li>Age (over 18 checkbox)</li>
						</ul>

						<h5>Registration and Login</h5>

						<p>To limit the access and to improve the security of the app we ask you to login to the app with your name and email address. During your login you will be asked to select your vehicle type so we can select the best routes for you. The legal ground for the processing of this data is the legitimate interest of TDG.
						</p>

						<h5>Order Tracker</h5>

						<p>Your GPS location is relayed to the client and the restaurant for which you are delivering an order at that moment. We call this the Order Tracker. This Order Tracker enables restaurants and consumers to easily check the status of the order. The legal ground for the processing of this data is the legitimate interest of TDG.
						</p>

						<h5>GPS Location</h5>

						<p>The App shares your GPS location with us during your shift for the purposes described above. You should be aware of the following:
						</p>

						<ul className='list'>
							<li>Your GPS location together with your first name is only used to offer a Food Tracker to consumers and restaurants.</li>
							<li>Your GPS location is relayed to TDG in multiple intervals per minute. Motion data, such as speed, kilometres travelled and so on, are registered in the App by default but will not be used by TDG.</li>
							<li>Your GPS location is only shared during the times that you make yourself available for deliveries by “toggling on” as available,  and will not be tracked after you have “toggled off” and are unavailable for deliveries. </li>
							<li>You are able to turn off the location sharing by changing your device settings. Please note that the TDG Drivers App will not function properly if the location sharing settings in your device are turned off.</li>
						</ul>

						<h5>App Analytics</h5>

						<p>Personal data that is processed within the app is also used by TDG for analytical purposes and to improve the app and delivery process of TDG. TDG may uses cookies and other technologies for these analytical purposes. For more information on the use of these technologies, we would like to refer you to our cookie statement.</p>

						<h5>Additional purposes</h5>
						<p>We will only use the data obtained via the Technologies for the purposes described above. If we want to use the data for a different purpose, we will take appropriate measures to inform you, consistent with the significance of the changes we make. Where required by applicable data protection law, we will obtain your consent.</p>

						<h5>Sharing with the restaurant and consumer</h5>
						<p>This app is designed to optimize the delivery process of food orders and provide information about the delivery to both the restaurant and consumer. For this purpose your GPS location is shared with the restaurant and consumer during the delivery of the order via TDG’s Order Tracker.</p>

						<h5>Sharing with others</h5>
						<p>We will not sell your personal data to third parties, and we will only disclose them to third parties if necessary for the providence or performance of the application to you, analytical purposes, or to comply with legal obligations. Categories of third parties that may be used by TDG to process your personal data are:</p>
						<ul className='list'>
							<li>TDG’s affiliates, subsidiaries, franchisee and/or group companies of TDG</li>
							<li>Software providers</li>
							<li>Implementation partners</li>
							<li>Law enforcement, regulatory bodies and/or government agencies</li>
							<li>Any other party provided that you have given your consent to that disclosure</li>
						</ul>

						<p>Whenever we use third-parties to process personal data, we take appropriate measures pursuant to the applicable data protection law to guarantee the same level of protection and confidentiality of your personal data.</p>

						<h5>Automated decision-making and profiling</h5>

						<p>For the purposes described above TDG does not use automated decision-making and/or profiling. If you feel that your personal data is subject to automated decision-making and/or profiling please contact TDG directly <a href="mailto:info@thedeliveryguys.ie">info@thedeliveryguys.ie</a></p>

						<h5>How long we store personal data for</h5>
						<p>TDG will not store your personal data for longer than is strictly necessary for the purposes for which your personal data were collected. We will only store your personal data longer if we are required by law to do so.</p>
						
						<h5>Use of consumers’ data</h5>
						<p>When delivering orders, you also receive data of the consumers. We would like to point out to you that the data of consumers, such as name, address and telephone number, may only be used for the delivery of the order. The use of this data for other purposes than the delivery of the order is prohibited by law.</p>

						<h5>Rights, questions or complaints</h5>
						<p>You have the right to access all personal data we collect about you, as well as to request that we rectify or erase your personal data. If you feel that we are processing your personal data without a valid legal basis or that we are processing personal data that are not relevant for our selection process, please contact us. TDG will respond to your request as soon as possible, and in any case no later than four weeks after receiving your request. </p>

						<h5>Security</h5>
						<p>TDG takes personal data protection very seriously and we therefore take appropriate measures to protect your personal data against misuse, loss, unauthorised access, unwanted disclosure, and unauthorised alteration. If you feel that your personal data is not adequately protected or there are indications of misuse, please contact us <a href="mailto:info@thedeliveryguys.ie">info@thedeliveryguys.ie</a> </p>


						<h5>Data Protection Authority</h5>
						<p>Besides the option of lodging a complaint with us, you have the right to lodge a complaint with the relevant supervisory authority for the protection of personal data. To do so, contact the supervisory authority directly.</p>

						

					</div>
				</Container>


			</div>
		);
	}
}


DriverPolicy.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			page: PropTypes.string
		})
	})
};


const mapStateToProps = (state) => {
	return {
		cartItem: state.cart.cartItem,
		coupon: state.cart.coupon,
		deliveryInstructions: state.cart.deliveryInstructions,
		driverTip: state.cart.driverTip,
		grandTotal: state.cart.grandTotal,
		token: state.auth.token,
		user: state.user,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		handleCart: (data) => dispatch(handleCart(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DriverPolicy);
