// auth
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_LOGOUT_MSG = 'AUTH_LOGOUT_MSG';
export const AUTH_ALL_DATA_UPDATE = 'AUTH_ALL_DATA_UPDATE';

//access token
export const ACCESS_TOKEN = 'ACCESS_TOKEN';

//business
export const BUSINESS_DETAILS = 'BUSINESS_DETAILS';

//User name 
export const USER = 'USER';

//Cart item
export const CART = 'CART';

// Service Category
export const SERVICE_CATEGORY = 'SERVICE_CATEGORY';

// Location
export const GEOLOCATION = 'GEOLOCATION';
