import { SERVICE_CATEGORY } from '../actions';

const initialState = {
    categoryId: 1,
};

const handleServiceCategory = (state, {categoryId}) => {
    return {
        ...state,
        categoryId,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case SERVICE_CATEGORY:
        return handleServiceCategory(state, action);
    default:
        return state;
    }
};

export default reducer;
