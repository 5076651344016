/* eslint-disable no-unused-vars, no-mixed-spaces-and-tabs */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import defaultBanner from '../../../assets/images/banner.jpg';
import defaultRestLogo from '../../../assets/images/no-img.svg';
import { ImStarFull, ImLocation } from 'react-icons/im';
import axios from './../../../shared/eaxios';
import { IMAGEURL, SERVERFOLDER } from './../../../Constants';
import AppLoader from '../../../Components/AppLoader/AppLoader';
import { connect } from 'react-redux';
import { handleCart } from './../../../redux/actions/cart';
import { Map, GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react';
import { NavLink } from 'react-router-dom';
import { FaAngleLeft, FaPlusCircle } from 'react-icons/fa';


class RestaurantInfo extends Component {
	state = {
		restaurantInfo: null,
		getDataerrorMessge: null,
		getDataLoader: false,
		searchString: '',
		allowGoogleLocation: false,
		googleLocationMessage: null,
		showLocationDeniedModal: false,
		distance: '',
		showingInfoWindow: false,
		activeMarker: {},
		selectedPlace: {},
	}

	_isMounted = false;

	displayError = (e) => {
		let errorMessge = '';
		try {
			errorMessge = e.data.message ? e.data.message : e.data.error_description;
		} catch (e) {
			errorMessge = 'Unknown error!';
		}
		return errorMessge;
	}

	restaurant_info_fetch = (id) => {
		const { searchString } = this.state;
		this.setState({
			getDataLoader: true
		}, () => {
			axios.get(`restaurantDetails/${id}&searchString=${searchString}`)
				.then(res => {
					const distance = this.distance(res.data.data.restaurant_lat, res.data.data.restaurant_lng, sessionStorage.getItem('latitude'), sessionStorage.getItem('longitude'));
					this.setState({
						getDataLoader: false,
						restaurantInfo: res.data.data,
						distance
					});
				})
				.catch(e => {
					let errorMsg = this.displayError(e);
					this.setState({
						getDataerrorMessge: errorMsg,
						getDataLoader: false
					}, () => { });

					setTimeout(() => {
						this.setState({ getDataerrorMessge: null });
					}, 5000);
				});
		});
	};

	distance(lat1, lon1, lat2, lon2) {
		if ((lat1 == lat2) && (lon1 == lon2)) {
			return 0;
		} else {
			const radlat1 = Math.PI * lat1 / 180;
			const radlat2 = Math.PI * lat2 / 180;
			const theta = lon1 - lon2;
			const radtheta = Math.PI * theta / 180;
			let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
			if (dist > 1) {
				dist = 1;
			}
			dist = Math.acos(dist);
			dist = dist * 180 / Math.PI;
			dist = dist * 60 * 1.1515;
			dist = dist * 1.609344;
			return dist;
		}
	}

	getLocation() {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(this.getLocationData, this.handelLocationError);
		} else {
			console.log('Geolocation is not supported by this browser.');
		}
	}

	getLocationData = (position) => {
		if (position) {
			// this.setState({ allowGoogleLocation: true });
			sessionStorage.setItem('latitude', position.coords.latitude);
			sessionStorage.setItem('longitude', position.coords.longitude);
		}
	}

	handelLocationError = (error) => {
		this.setState({
			allowGoogleLocation: false,
			// showLocationDeniedModal: true
		});
		switch (error.code) {
			case error.PERMISSION_DENIED:
				console.log('User denied the request for Geolocation.');
				this.setState({ googleLocationMessage: 'User denied the request for Geolocation.' });
				break;
			case error.POSITION_UNAVAILABLE:
				console.log('Location information is unavailable.');
				this.setState({ googleLocationMessage: 'Location information is unavailable.' });
				break;
			case error.TIMEOUT:
				console.log('The request to get user location timed out.');
				this.setState({ googleLocationMessage: 'The request to get user location timed out.' });
				break;
			case error.UNKNOWN_ERROR:
				console.log('An unknown error occurred.');
				this.setState({ googleLocationMessage: 'An unknown error occurred.' });
				break;
		}
	}

	closeLocationDeniedModal = () => {
		this.setState({ showLocationDeniedModal: false });
		window.location.reload();
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		this.getLocation();
		this._isMounted = true;
		sessionStorage.getItem('restaurantId') && this.restaurant_info_fetch(sessionStorage.getItem('restaurantId'));
	}

	onMarkerClick = (props, marker, e) =>
		this.setState({
			selectedPlace: props,
			activeMarker: marker,
			showingInfoWindow: true
		});

	onMapClicked = (props) => {
		if (this.state.showingInfoWindow) {
			this.setState({
				showingInfoWindow: false,
				activeMarker: null
			});
		}
	};

	goBack = () => {
		this.props.history.goBack();
	}

	render() {
		const {
			getDataLoader,
			restaurantInfo,
			distance
		} = this.state;

		return (
			<div>
				{getDataLoader ?
					<div className=""><AppLoader /></div> :
					<div className="main" style={{ marginBottom: '500px' }}>

						{/* Resturant info */}
						{restaurantInfo &&
							<div className="innerBannerPan" style={{ backgroundImage: restaurantInfo.restaurant_banner ? `url(${IMAGEURL}restaurant/banner/${restaurantInfo.restaurant_banner})` : `url(${defaultBanner})` }}>
								{/* <button onClick={this.goBack} className="backArrow text-white"><FaAngleLeft /> Back to Restaurant</button> */}
								<div className="container bannerContainer">
									<div className="restroInfo">
										<div className="restroLogo">
											<img src={restaurantInfo.restaurant_logo ? `${IMAGEURL}restaurant/${restaurantInfo.restaurant_logo}` : `${defaultRestLogo}`} alt='Restro' />
										</div>
										<div className="restroDetails">
											<h2>{restaurantInfo.restaurant_company_name}</h2>
											{restaurantInfo.restaurant_addr ?
												<p className="location">
													{restaurantInfo.restaurant_addr + ' ' +
														(restaurantInfo.restaurant_addr2 !== null ? restaurantInfo.restaurant_addr2 + ' ' : ' ') +
														restaurantInfo.restaurant_city + ' ' +
														restaurantInfo.restaurant_state + ' ' +
														restaurantInfo.restaurant_country + ' ' +
														restaurantInfo.restaurant_zip + ' ' +
														(restaurantInfo.restaurant_email !== null ? restaurantInfo.restaurant_email + ' ' : ' ') +
														(restaurantInfo.restaurant_phone !== null ? restaurantInfo.restaurant_phone + ' ' : ' ')}</p>
												: null
											}
											<p className="ratingPan">
												{restaurantInfo.avg_rating && restaurantInfo.avg_rating.length > 0 ?
													<span className="rating"><ImStarFull />
														{restaurantInfo.avg_rating && restaurantInfo.avg_rating.length > 0 && restaurantInfo.avg_rating[0].aggregate} (
														{restaurantInfo.rating_count && restaurantInfo.rating_count.length > 0 && restaurantInfo.rating_count[0].totalCount + ' ratings)'}
													</span>
													:
													<span className="rating">No rating</span>
												}
												<span className="distance"><ImLocation /> {Number(distance).toFixed(2)} kms</span>
											</p>
										</div>
									</div>
								</div>
							</div>
						}
						{restaurantInfo &&
							<div className="nearPan">
								<div className="">
									<Map
										style={{ height: '50%', position: 'relative' }}
										google={this.props.google}
										zoom={14}
										initialCenter={{
											lat: restaurantInfo.restaurant_lat,
											lng: restaurantInfo.restaurant_lng
										}}
										onClick={this.onMapClicked}
										disableDoubleClickZoom={false}
										scrollwheel={false}
									>
										<Marker
											onClick={this.onMarkerClick}
											name={restaurantInfo.restaurant_company_name}
											position={{ lat: restaurantInfo.restaurant_lat, lng: restaurantInfo.restaurant_lng }}
										// icon={{
										//     url: '/path/to/custom_icon.png',
										//     anchor: new window.google.maps.Point(32, 32),
										//     scaledSize: new window.google.maps.Size(64, 64)
										// }} 
										/>
										<InfoWindow
											marker={this.state.activeMarker}
											visible={this.state.showingInfoWindow}>
											<div>
												<h1>{this.state.selectedPlace.name}</h1>
											</div>
										</InfoWindow>

									</Map>
								</div>
							</div>
						}
					</div>
				}
			</div>
		);
	}
}

RestaurantInfo.propTypes = {
	handleCart: PropTypes.func,
	cartItem: PropTypes.any,
	history: PropTypes.any,
	coupon: PropTypes.any,
	deliveryInstructions: PropTypes.any,
	driverTip: PropTypes.any,
	grandTotal: PropTypes.any,
	cart: PropTypes.any,
	google: PropTypes.any,
};

const mapStateToProps = (state) => {
	return {
		cartItem: state.cart.cartItem,
		coupon: state.cart.coupon,
		deliveryInstructions: state.cart.deliveryInstructions,
		driverTip: state.cart.driverTip,
		grandTotal: state.cart.grandTotal,
		cart: state.cart
	};
};

const mapDispatchToProps = dispatch => {
	return {
		handleCart: (data) => dispatch(handleCart(data))
	};
};

export default GoogleApiWrapper({
	apiKey: 'AIzaSyD_BBiV2jnDTjEMxjvFncobeQ3RY0wUP-Y'
})(connect(mapStateToProps, mapDispatchToProps)(RestaurantInfo));
