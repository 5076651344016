import React, { Component } from 'react';
import { Table, Tabs, Tab } from 'react-bootstrap';
import { Modal, Button } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';
import { RiArrowRightSLine } from 'react-icons/ri';
import { MdClose } from 'react-icons/md';
import driverImg from '../../../assets/images/driver-img.gif';
import foodImg from '../../../assets/images/icon-breakfast.png';
import PropTypes from 'prop-types';
import orderAxios from './../../../shared/orderEaxios';
import LoadingSpinner from '../../../Components/LoadingSpinner/LoadingSpinner';
import { connect } from 'react-redux';
import { handleCart } from './../../../redux/actions/cart';
import { handleServiceCategory } from './../../../redux/actions/servicecategory';
import { IMAGEURL } from './../../../Constants';
import { SERVERFOLDER } from './../../../Constants';
import moment from 'moment';
import ItemPerPage from '../../../Components/ItemsPerPage/ItemsPerPage';
import ReactStars from 'react-rating-stars-component';
import Pagination from 'react-js-pagination';
import { Col, Row } from 'react-bootstrap';
import axios from './../../../shared/eaxios';
import * as Utils from '../../../Utils';
import Track1 from '../../../assets/images/track@3x.png';
import Track2 from '../../../assets/images/track2@3x.png';
import Track3 from '../../../assets/images/track3@3x.png';
import Track4 from '../../../assets/images/track4@3x.png';

import delivered from '../../../assets/images/delivered.png';
import collected from '../../../assets/images/collected.png';
import cancelled from '../../../assets/images/cancelled.png';

// const orderConfirmedStyle = (status) => ({
// 	fontSize: 17,
// 	color: (status == 'approved' || status == 'pickup_ready' || status == 'delivery_pickedup' || status == 'delivered') ?
// 		'#ED592B' : '#B4B4B4',
// 	position: 'absolute',
// 	left: 360,
// 	top: 246,
// });
// const orderPreparingStyle = (status) => ({
// 	fontSize: 17,
// 	color: (status == 'pickup_ready' || status == 'delivered') ?
// 		'#ED592B' : '#B4B4B4',
// 	position: 'absolute',
// 	left: 562,
// 	top: 360,
// });
// const orderPickedUpStyle = (status) => ({
// 	fontSize: 17,
// 	color: (status == 'pickup_ready' || status == 'delivery_pickedup' || status == 'delivered') ?
// 		'#ED592B' : '#B4B4B4',
// 	position: 'absolute',
// 	left: 368,
// 	top: 506,
// });
// const orderDeliveredStyle = (status) => ({
// 	fontSize: 17,
// 	color: (status == 'delivered') ?
// 		'#ED592B' : '#B4B4B4',
// 	position: 'absolute',
// 	left: 630,
// 	top: 668,
// });

class OrderHistory extends Component {
	constructor() {
		super();
		this.state = {
			reviewShow: false,
			currentOrderErrorMessge: null,
			loader: false,
			activePage: 1,
			totalCount: 0,
			itemsPerPage: 10,
			currentOrderList: [],
			pastOrderErrorMessge: null,
			pastLoader: false,
			activePagePO: 1,
			totalCountPO: 0,
			itemsPerPagePO: 10,
			pastOrderList: [],
			showTrackModal: false,
			name: null,
			profile_pic: null,
			orderNumber: null,
			status: null,
			driverRating: 5,
			foodRating: 5,
			collectRating: 5,
            groceryRating: 5,
			searchString: '',
			shopInfo: null,
			reviewData: null,
			driverReview: null,
			foodReview: null,

			driver: [
				{ id: 1, value: '1', name: 'Professionalism Of Your Driver', isChecked: false },
				{ id: 2, value: '2', name: 'Delivery Time As Scheduled', isChecked: false },
				{ id: 3, value: '3', name: 'Drivers Overall Politeness', isChecked: false },
				{ id: 4, value: '4', name: 'Drivers Overall Performance', isChecked: false },
				{ id: 5, value: '5', name: 'Special Instructions Followed', isChecked: false },
			],
			driverNeg: [
				{ id: 6, value: '6', name: 'Meal In Poor Condition', isChecked: false },
				{ id: 7, value: '7', name: 'Unprofessional', isChecked: false },
				{ id: 8, value: '8', name: 'Delivery Later Than ETA', isChecked: false },
				{ id: 9, value: '9', name: 'Did Not Receive My Order', isChecked: false },
				{ id: 10, value: '10', name: 'Special Instructions Not Followed', isChecked: false },
			],
			food: [
				{ id: 1, value: '1', name: 'Meal in Good Condition', isChecked: false },
				{ id: 2, value: '2', name: 'Meal Remained Hot', isChecked: false },
				{ id: 3, value: '3', name: 'Fresh', isChecked: false },
				{ id: 4, value: '4', name: 'Value for Money', isChecked: false },
				{ id: 5, value: '5', name: 'Enjoyable Meal', isChecked: false },
				{ id: 6, value: '6', name: 'Special Instructions Followed', isChecked: false },
			],
			foodNeg: [
				{ id: 7, value: '7', name: 'Meal/Item Is Not Warm/Fresh', isChecked: false },
				{ id: 8, value: '8', name: 'Preparation Time Longer Than Estimated', isChecked: false },
				{ id: 9, value: '9', name: 'Incorrect order', isChecked: false },
				{ id: 10, value: '10', name: 'Special Instructions Not Followed', isChecked: false },
				{ id: 11, value: '11', name: 'Over Priced', isChecked: false },
				{ id: 12, value: '12', name: 'Limited Menu/Options', isChecked: false },
				{ id: 13, value: '13', name: 'Presentation Poor', isChecked: false },
			],
			collect: [
				{ id: 1, value: '1', name: 'Meal Was Hot And Fresh', isChecked: false },
				{ id: 2, value: '2', name: 'Good Value For Money', isChecked: false },
				{ id: 3, value: '3', name: 'Meal Was Ready As Scheduled', isChecked: false },
				{ id: 4, value: '4', name: 'Special Instructions Followed', isChecked: false },
				{ id: 5, value: '5', name: 'Enjoyable Meal', isChecked: false },
			],
			collectNeg: [
				{ id: 6, value: '6', name: 'Meal Was Not Hot And Fresh', isChecked: false },
				{ id: 7, value: '7', name: 'Not Value For Money', isChecked: false },
				{ id: 8, value: '8', name: 'Meal Was Not Ready As Scheduled', isChecked: false },
				{ id: 9, value: '9', name: 'Special Instructions Not Followed', isChecked: false },
				{ id: 10, value: '10', name: 'I Did Not Enjoy The Meal', isChecked: false },
			],
            grocery: [
                { id: 1, value: '1', name: 'Professionalism Of Your Shopper', isChecked: false },
                { id: 2, value: '2', name: 'Delivery Time As Scheduled ', isChecked: false },
                { id: 3, value: '3', name: 'Shoppers Overall Politeness ', isChecked: false },
                { id: 4, value: '4', name: 'Shoppers Overall Performance', isChecked: false },
                { id: 5, value: '5', name: 'Shopping List Correct', isChecked: false },
                { id: 6, value: '6', name: 'Special Instructions Followed', isChecked: false },
            ],
            groceryNeg: [
                { id: 7, value: '7', name: 'Shopping Packed Badly', isChecked: false },
                { id: 8, value: '8', name: 'Unprofessional/Rude', isChecked: false },
                { id: 9, value: '9', name: 'Delivery Later Than ETA', isChecked: false },
                { id: 10, value: '10', name: 'Did Not Receive My Order', isChecked: false },
                { id: 11, value: '11', name: 'Special Instructions Not Followed', isChecked: false },
                { id: 12, value: '12', name: 'Items Missing', isChecked: false },
            ],
			reviewSuccessModal: false,
			reviewErrorMessge: null,
			trackOrderData: null,
			fetchOrderLoader: false,
			order: null,
			showWarningModal: false,
		};
	}

	fetchOrderInfo = (order) => {

		this.setState({ pastLoader: true  }, () => {

			orderAxios.get(`repeatOrder/${order.id}`)
				.then(res => {
					this.setState({ pastLoader: false});
					if (res.data.success == true) {

						let data = {};
						let cartItem = this.props.cartItem;

						let shopInfo = res.data.data.shop;
						let products = [...res.data.data.product];

						let url;
						if (shopInfo.shopType == 'restaurant') {
							url = `restaurantDetails/${shopInfo.shopId}?lat=${this.props.geolocation.latitude}&lng=${this.props.geolocation.longitude}`;
						} else {
							url = `groceryDetails/${shopInfo.shopId}?lat=${this.props.geolocation.latitude}&lng=${this.props.geolocation.longitude}`;
						}

						axios.get(url)
							.then(res => {
							
							//console.log('shopdetail=>', res.data.data);

							//let isOpen = Utils.checkPlaceOpen(shopInfo.shopTime);
							let isOpen = res.data.data.isOpen;
							let isDelivery = res.data.data.isDelivery;

							if( isOpen == 'open' ) {
								
								let ServiceCategory = shopInfo.shopType == 'restaurant' ? 1 : 2;
								this.props.handleServiceCategory(ServiceCategory);

								data.isOpen = isOpen;
								data.isDelivery = isDelivery;
								data.shopType = shopInfo.shopType;
								data.shopId = shopInfo.shopId;
								data.only_collection = shopInfo.only_collection;
								data.currency_position = shopInfo.shopFranchisee.currency_position;
								data.currency_symbol = shopInfo.shopFranchisee.currency_symbol.currency_symbol;
								data.driverTip = order.driver_tip_amount;

								if (this.props.cart.shopId == shopInfo.shopId ){
									products.map((e) => {
										cartItem.push(e);
									});
									data.cartItem = cartItem;
								} else {
									let repeatData = products.map((e) => {
										return e;
									});
									data.cartItem = repeatData;
								}
								
								let totalPrice = 0;
								data.cartItem.map(data => {
									totalPrice = totalPrice + (data.selectedQuantity * data.totalSizePrice);
								});
								data.grandTotal = totalPrice;


								this.props.handleCart(data);
								this.props.history.push(`${SERVERFOLDER + '/cart'}`);

							} else {
								this.setState({
									showWarningModal: true,
									errorMessage: shopInfo.shopType == 'restaurant' ? 'Restaurant is closed now.' : 'Grocery is closed now.',
									pastLoader: false
								});
								setTimeout(() => {
									this.setState({ errorMessage: null });
								}, 5000);
							}
						});
							
					} 
				})
				.catch(e => {
					console.log('repeat err=>', e);
					let errorMsg = this.displayError(e);
					this.setState({
						showWarningModal: true,
						errorMessage: errorMsg,
						pastLoader: false
					});
					setTimeout(() => {
						this.setState({ errorMessage: null, showWarningModal: false, });
					}, 5000);
				});

		});
	};

	displayError = (e) => {
		let errorMessge = '';
		try {
			errorMessge = e.data.message ? e.data.message : e.data.error_description;
		} catch (e) {
			errorMessge = 'Unknown error!';
		}
		return errorMessge;
	}

	fetchCurrentOrder = () => {
		const { activePage, itemsPerPage } = this.state;
		const { id } = this.props.user;
		this.setState({ loader: true }, () => {
			orderAxios.get(`orderList?page=${activePage}&pageSize=${itemsPerPage}&restaurant_id=&customer_id=${id}&order_status_filter=current_order`)
				.then(response => {
					this.setState({
						loader: false,
						currentOrderList: response.data.data.data,
						totalCount: response.data.data.total
					});
				})
				.catch(e => {
					let errorMsg = this.displayError(e);
					this.setState({
						currentOrderErrorMessge: errorMsg,
						loader: false
					});
					setTimeout(() => {
						this.setState({ currentOrderErrorMessge: null });
					}, 5000);
				});
		});
	}

	fetchOrder = (id) => {
		this.setState({ fetchOrderLoader: true }, () => {
			orderAxios.get(`orderDetails/${id}`)
				.then(response => {
					this.setState({
						fetchOrderLoader: false,
						order: response.data.data
					});
				})
				.catch(e => {
					let errorMsg = this.displayError(e);
					this.setState({
						orderErrorMessge: errorMsg,
						fetchOrderLoader: false
					});
					setTimeout(() => {
						this.setState({ orderErrorMessge: null });
					}, 5000);
				});
		});
	}

	fetchPastOrder = () => {
		const { activePagePO, itemsPerPagePO } = this.state;
		const { id } = this.props.user;
		this.setState({ pastLoader: true }, () => {
			orderAxios.get(`orderList?order_status_filter=all_past_order&page=${activePagePO}&pageSize=${itemsPerPagePO}&customer_id=${id}&display_count=true`)
				.then(response => {
					this.setState({
						pastLoader: false,
						pastOrderList: response.data.data.data,
						totalCountPO: response.data.data.total
					});
				})
				.catch(e => {
					let errorMsg = this.displayError(e);
					this.setState({
						pastOrderErrorMessge: errorMsg,
						pastLoader: false
					});
					setTimeout(() => {
						this.setState({ pastOrderErrorMessge: null });
					}, 5000);
				});
		});
	}

	componentDidMount() {
		this.fetchCurrentOrder();
	}

	componentDidUpdate(prevProps) {
		if (this.props.categoryId !== prevProps.categoryId) {

			let data = this.props.cart;
			let cartItem = this.props.cartItem;

			cartItem = [];
			data.cartItem = cartItem;
			data.driverTip = 0;
			data.grandTotal = 0;
			this.props.handleCart(data);

			this.setState({
				cartItem
			});

			this.props.history.push(`${SERVERFOLDER + '/'}`);
		}
	}

	fetchOrderReviewDetails = () => {
		const { id } = this.props.user;
		this.setState({ orderReviewLoader: true }, () => {
			orderAxios.get(`orderReviewDetails/${id}`)
				.then(response => {
					this.setState({
						orderReviewLoader: false,
						orderReview: response.data,
					});
				})
				.catch(e => {
					let errorMsg = this.displayError(e);
					this.setState({
						orderReviewErrorMessge: errorMsg,
						orderReviewLoader: false
					});
					setTimeout(() => {
						this.setState({ orderReviewErrorMessge: null });
					}, 5000);
				});
		});
	}

	openModalReview = (data) => {
		this.setState({
			reviewShow: true,
			reviewData: data
		});
	};

	closeModal = () => {
		this.setState({
			reviewShow: false
		});
	};

	handleChangeItemPerPage = e => {
		this.setState({ itemsPerPage: e.target.value }, () => {
			this.fetchCurrentOrder();
		});
	};

	handleChangeItemPerPagePO = e => {
		this.setState({ itemsPerPagePO: e.target.value }, () => {
			this.fetchPastOrder();
		});
	};

	handlePageChange = pageNumber => {
		this.setState({ activePage: pageNumber }, () => {
			this.fetchCurrentOrder();
		});
	};

	handlePageChangePO = pageNumberPO => {
		this.setState({ activePagePO: pageNumberPO }, () => {
			this.fetchPastOrder();
		});
	};

	trackOrderStatus = (data) => {
		this.fetchOrder(data.id);
		this.setState({
			showTrackModal: true
		});
	}

	closeTrackModal = () => {
		this.setState({
			showTrackModal: false
		});
		this.fetchCurrentOrder();
	}

	handleRatingChange = (rating, type) => {
		//console.log('rating', rating);
		if (type === 'driver') {
			this.setState({ driverRating: rating });
		}

        if (type === 'grocery') {
            this.setState({ groceryRating: rating });
        } 

        if (type === 'food') {
			this.setState({ foodRating: rating });
		}

		if (type === 'collect') {
			this.setState({ collectRating: rating });
		}
	}
	
	handleReview = (event, type) => {

		//console.log('value=>', event.target.value);
		
		let foodRating = this.state.foodRating;
		let collectRating = this.state.collectRating;
		let driverRating = this.state.driverRating;
        let groceryRating = this.state.groceryRating;

		let food = this.state.food;
		let foodNeg = this.state.foodNeg;

		let driver = this.state.driver;
		let driverNeg = this.state.driverNeg;

		let collect = this.state.collect;
		let collectNeg = this.state.collectNeg;

        let grocery = this.state.grocery;
        let groceryNeg = this.state.groceryNeg;

		if (type === 'driver') {
			if (driverRating > 2) {
				driver.forEach(data => {
					if (data.value === event.target.value)
						data.isChecked = event.target.checked;
				});
				this.setState({ driver });
			} else {
				driverNeg.forEach(data => {
					if (data.value === event.target.value)
						data.isChecked = event.target.checked;
				});
				this.setState({ driverNeg });
			}

		}

        if (type === 'grocery') {
            if (groceryRating > 2) {
                grocery.forEach(data => {
                    if (data.value === event.target.value)
                        data.isChecked = event.target.checked;
                });
                this.setState({ grocery });
            } else {
                groceryNeg.forEach(data => {
                    if (data.value === event.target.value)
                        data.isChecked = event.target.checked;
                });
                this.setState({ groceryNeg });
            }

        } 

        if (type === 'food') {
			if (foodRating > 2) {
				food.forEach(data => {
					if (data.value === event.target.value)
						data.isChecked = event.target.checked;
				});
				this.setState({ food });
			} else {
				foodNeg.forEach(data => {
					if (data.value === event.target.value)
						data.isChecked = event.target.checked;
				});
				this.setState({ foodNeg });
			}
		}

		if (type === 'collect') {
			if (collectRating > 2) {
				collect.forEach(data => {
					if (data.value === event.target.value)
						data.isChecked = event.target.checked;
				});
				this.setState({ collect });
			} else {
				collectNeg.forEach(data => {
					if (data.value === event.target.value)
						data.isChecked = event.target.checked;
				});
				this.setState({ collectNeg });
			}
		}
	}

	handleReviewSubmit = (reviewData) => {
		this.setState({
			loaderReview: true
		}, () => {

			let foodRating = this.state.foodRating;
			let collectRating = this.state.collectRating;
			let driverRating = this.state.driverRating;
			let groceryRating = this.state.groceryRating;


			let driverFeedback = driverRating > 2 ? this.state.driver : this.state.driverNeg;
			let driver = [];
			driverFeedback.map(data => {
				data.isChecked === true && driver.push(data.value);
			});

			let foodFeedback = foodRating > 2 ? this.state.food : this.state.foodNeg;
			let food = [];
			foodFeedback.map(data => {
				data.isChecked === true && food.push(data.value);
			});

			let collectFeedback = collectRating > 2 ? this.state.collect : this.state.collectNeg;
			let collect = [];
			collectFeedback.map(data => {
				data.isChecked === true && collect.push(data.value);
			});

			let groceryFeedback = groceryRating > 2 ? this.state.grocery : this.state.groceryNeg;
			let grocery = [];
			groceryFeedback.map(data => {
				data.isChecked === true && grocery.push(data.value);
			});

			const formData = new window.FormData();

			formData.append('driver_rating', this.state.driverRating);
			formData.append('driver_feedback', driver.toString());


			if(reviewData && reviewData.restaurant_id && reviewData.collection_only == 0){
				formData.append('order_rating', this.state.foodRating);
				formData.append('order_feedback', food.toString());
			}

			if(reviewData && reviewData.restaurant_id && reviewData.collection_only == 1){
				formData.append('order_rating', this.state.collectRating);
				formData.append('order_feedback', collect.toString());
			}

			if(reviewData && reviewData.grocery_id){
				formData.append('order_rating', this.state.groceryRating);
				formData.append('order_feedback', grocery.toString());
			}


			
			orderAxios.post(`orderSubmitReview/${reviewData.id}`, formData)
				.then(() => {
					driverFeedback.map(data => {
						data.isChecked = false;
					});
					foodFeedback.map(data => {
						data.isChecked = false;
					});
					this.setState({
						loaderReview: false,
						reviewShow: false,
						reviewSuccessModal: true,
						driver: driverFeedback,
						food: foodFeedback
					});

				})
				.catch(e => {
					let errorMsg = this.displayError(e);
					this.setState({
						reviewErrorMessge: errorMsg,
						loaderReview: false
					});
					setTimeout(() => {
						this.setState({ reviewErrorMessge: null });
					}, 5000);
				});
		});
	}

	closeReviewModal = () => {
		this.setState({
			reviewShow: false,
			reviewSuccessModal: false
		});
		this.fetchPastOrder();
	}

	showWarningModal = message => {
        this.setState({
            showWarningModal: true,
            errorMessage: message
        });
    };

    closeWarningModal = () => {
        this.setState({
            showWarningModal: false
        });
    };

	render() {
		const {
			pastOrderList,
			currentOrderList,
			pastLoader,
			loader,
			totalCountPO,
			totalCount,
			itemsPerPage,
			activePagePO,
			itemsPerPagePO,
			activePage,
			reviewData,
			driver,
			driverNeg,
			driverRating,
			food,
			foodNeg,
			foodRating,
			collect,
			collectNeg,
			collectRating,
            grocery,
            groceryNeg,
            groceryRating,
			reviewSuccessModal,
			reviewErrorMessge,
			loaderReview,
			order,
			fetchOrderLoader
		} = this.state;

		let name = '';

		if (order && order.user_details.first_name !== null && order && order.user_details.last_name !== null) {
			name = order.user_details.first_name + ' ' + order.user_details.last_name;
		} else if (order && order.user_details.first_name === null && order && order.user_details.last_name !== null) {
			name = order.user_details.last_name;
		} else {
			name = order && order.user_details.first_name;
		}
		return (
			<div>

				<div className="bodyPan">
					<div className="container">
						<div className='pt-4'>
							<Tabs
								defaultActiveKey="current_order"
								onSelect={key => {
									key === 'current_order' ? this.fetchCurrentOrder() : this.fetchPastOrder();
								}}
								id="uncontrolled-tab-example">
								<Tab eventKey="current_order" title="Current Orders">
									<div className="tabContent">
										{loader ?
											<span className='loaderStyle'><LoadingSpinner /></span>
											: currentOrderList && currentOrderList.length > 0 ?
												currentOrderList.map(data => {
													return <div
														style={{ border: (data.order_status === 'approved' || data.order_status === 'pickup_ready' || data.order_status === 'delivery_pickedup' || data.order_status === 'delivered') ? '2px solid #A2FFC2' : '2px solid #FED1BD' }}
														className="card nearCard mb-4 trackCard" key={data.id}>
														<div className="card-body">
															<h5 className="card-title">{data.restaurant_id && data.restaurant_details && data.restaurant_details.restaurant_name} <strong className='text-success'>{data.grocery_id && data.grocery_details && data.grocery_details.grocery_name}</strong><span>{Utils.setCurrency(data, data.grand_total)}</span>
															</h5>
															<p className="location">Order Number: {data.order_number}</p>


															{/*{data.order_details && data.order_details.length > 0 && data.order_details.map(odata => {
																return <p className="location" key={odata.id}>{odata.product_name + ' (' + odata.size_name +' )' + Utils.setCurrency(data, odata.size_price) + 'X' + odata.quantity }</p>;
															})}*/}

															<Table responsive hover className='mt-3 orderHistoryTable'>
																<tbody>
																	{data.order_details.length > 0 ? (
																		data.order_details.map(order => (
																			<tr key={order.id} >
																				<td>{order.product_name + ' (' + order.size_name + ' ) ' + Utils.setCurrency(data, order.size_price) + 'X' + order.quantity}
																					{order.addon_item != ''
																						&&
																						<div className="addonTd"><small>
																							<b>Addon Items :</b>
																							{
																								JSON.parse(order.addon_item).length > 0 && JSON.parse(order.addon_item).map((item,keyA) => (
																									<div key={keyA}>
																										{item.itemdetail && <div className="addonItem">{item.itemdetail.name} <span>(Price: {data.franchisee.currency_position == 'AFTER' ? (item.itemdetail.price + ' ' + data.franchisee.currency_symbol.currency_symbol) : (data.franchisee.currency_symbol.currency_symbol + ' ' + item.itemdetail.price)})</span></div>}
																									</div>
																								))}
																						</small></div>
																					}
																					{order.product_option != ''
																						&&
																						<div className="addonTd"><small>
																							<b>Combos Selected :</b>
																							{JSON.parse(order.product_option).length > 0 && JSON.parse(order.product_option).map((item,keyC) => (
																								<div key={keyC}>
																									<div className="addonItem">
																										{item.product && item.product.product_name && item.product.product_name}
																										<span>(Price: {data.franchisee.currency_position == 'AFTER' ? (item.price + ' ' + data.franchisee.currency_symbol.currency_symbol) : (data.franchisee.currency_symbol.currency_symbol + ' ' + item.price)})</span></div>
																								</div>
																							))}
																						</small></div>
																					}
																				</td>

																			</tr>
																		))
																	) : ''
																	}
																</tbody>
															</Table>


															<p className="size">Ordered on {moment(data.created_at).format('LL')}</p>
															<a style={{ cursor: 'pointer' }} className="trackBtn" onClick={() => this.trackOrderStatus(data)}>Track</a>
														</div>
													</div>;
												})
												:
												<h6>Seems you have not ordered yet. Start ordering now.</h6>
										}
										{!loader &&
											<div className="container paginationPan">
												{totalCount ? (
													<Row>
														<Col md={6}>
															<ItemPerPage
																handleChangeItemsPerPage={this.handleChangeItemPerPage}
																itemsPerPage={itemsPerPage}
															/>
														</Col>
														<Col md={6}>
															<div className="paginationOuter text-right">
																<Pagination
																	activePage={activePage}
																	itemsCountPerPage={itemsPerPage}
																	totalItemsCount={totalCount}
																	onChange={this.handlePageChange}
																/>
															</div>
														</Col>
													</Row>
												) : null}
											</div>
										}
									</div>
								</Tab>

								<Tab eventKey="Past_order" title="Past Orders">
									<div className="tabContent">
										{pastLoader ?
											<span className='loaderStyle'><LoadingSpinner /></span>
											: pastOrderList && pastOrderList.length > 0 ?
												pastOrderList.map(data => {
													return <div className="card nearCard mb-4" key={data.id}>
														<div className="card-body">
															<h5 className="card-title">{data.restaurant_details && data.restaurant_details.restaurant_name}<strong className='text-success'>{data.grocery_id && data.grocery_details && data.grocery_details.grocery_name}</strong><span>{Utils.setCurrency(data, data.grand_total)}</span>
															</h5>
															<p className="location my-1">Order ID:{data.order_number}</p>


															{/*{data.order_details && data.order_details.length > 0 && data.order_details.map(odata => {
                                                                return <p className="location" key={odata.id}>{odata.product_name + ' (' + odata.size_name +' )' + Utils.setCurrency(data, odata.size_price) + 'X' + odata.quantity }</p>;
                                                            })}*/}

															<Table responsive hover className='orderHistoryTable'>
																<tbody>
																	{data.order_details.length > 0 ? (
																		data.order_details.map(order => (
																			<tr key={order.id} >
																				<td>{order.product_name + ' (' + order.size_name + ' ) ' + Utils.setCurrency(data, order.size_price) + 'X' + order.quantity}
																					{order.addon_item != ''
																						&&
																						<div className="addonTd"><small>
																							<b>Addon Items :</b>
																							{
																								JSON.parse(order.addon_item).length > 0 && JSON.parse(order.addon_item).map((item, k) => (
																									<div key={k}>
																										{item.itemdetail && <div className="addonItem">{item.itemdetail.name} <span>(Price: {data.franchisee.currency_position == 'AFTER' ? (item.itemdetail.price + ' ' + data.franchisee.currency_symbol.currency_symbol) : (data.franchisee.currency_symbol.currency_symbol + ' ' + item.itemdetail.price)})</span></div>}
																									</div>
																								))}
																						</small></div>
																					}
																					{order.product_option != ''
																						&&
																						<div className="addonTd"><small>
																							<b>Combos Selected :</b>
																							{JSON.parse(order.product_option).length > 0 && JSON.parse(order.product_option).map((item, j) => (
																								<div key={j}>
																									<div className="addonItem">
																										{item.product && item.product.product_name && item.product.product_name}
																										<span>(Price: {data.franchisee.currency_position == 'AFTER' ? (item.price + ' ' + data.franchisee.currency_symbol.currency_symbol) : (data.franchisee.currency_symbol.currency_symbol + ' ' + item.price)})</span></div>
																								</div>
																							))}
																						</small></div>
																					}
																				</td>

																			</tr>
																		))
																	) : ''
																	}
																</tbody>
															</Table>

															<div className='orderHistoryBottomPan'>
																<p className="size">Ordered on {moment(data.created_at).format('LL')}
																	<br />
																	{data.order_status_logs[0].order_status == 'delivered' ? <>
																		Delivered on {moment(data.order_status_logs[0].updated_at).format('LL')}
																		<span className="delivered text-capitalize"><FaCheckCircle /> {'Delivered'}</span></>
																		: null}
																</p>

																{data.order_status == 'rejected' || data.order_status == 'canceled' ?
																	<img className="" width='auto' height={60} src={cancelled} alt="" /> :
																	data.collection_only == 1
																		? <img className="" width='auto' height={60} src={collected} alt="" />
																		: <img className="" width='auto' height={60} src={delivered} alt="" />
																}
															</div>

															{data.order_status == 'completed' ? 

                                                                data.order_review && data.order_review.order_rating ?
																<div className="reviewPan">Already Reviewed
																	<div className="ratingPan">
																		<ReactStars
																			count={5}
																			size={24}
																			value={data.order_review.order_rating}
																			activeColor='#ffd700'
																			edit={false}
																		/>
																		{/* <GoStar /><GoStar /><GoStar /><GoStar /><GoStar /> */}
																	</div>
																</div> :
																<div className="reviewPan">
																	<a className="link" onClick={() => this.openModalReview(data)}><strong>Review Now</strong></a>
																</div>
                                                                : null
															}
															<div className="card-footer px-0 mx-0 w-100">
																<span>Previous Orders ({data.restaurant_id ? data.restaurant_previous_count : data.grocery_previous_count})</span>
																<a onClick={() => this.fetchOrderInfo(data)} style={{ cursor: 'pointer' }}>Repeat Order</a>
															</div>
														</div>
													</div>;
												})
												:
												<h6>Seems you have not ordered yet. Start ordering now.</h6>
										}
										{/* <div className="card nearCard mb-4">
        										<div className="card-body">
        											<h5 className="card-title">The American Resto <span>€10</span></h5>
        											<p className="location">Order ID: 723546</p>
        											<p className="size">Ordered on Oct,07 2020<br />
        											Delivered on Oct,09  2020
        											<span className="delivered"><FaCheckCircle /> Delivered</span></p>
        											<div className="reviewPan"><a className="link" onClick={() => this.openModalReview()}><strong>Review Now</strong></a></div>
        											<div className="card-footer">
        												<span>Previous Orders (14)</span>
        												<a href="#">Repeat Order</a>
        											</div>
        										</div>
        									</div> */}
										{!pastLoader &&
											<div className="container paginationPan">
												{totalCountPO ? (
													<Row className="px-3">
														<Col md={6}>
															<ItemPerPage
																handleChangeItemsPerPage={this.handleChangeItemPerPagePO}
																itemsPerPage={itemsPerPagePO}
															/>
														</Col>
														<Col md={6}>
															<div className="paginationOuter text-right">
																<Pagination
																	activePage={activePagePO}
																	itemsCountPerPage={itemsPerPagePO}
																	totalItemsCount={totalCountPO}
																	onChange={this.handlePageChangePO}
																/>
															</div>
														</Col>
													</Row>
												) : null}
											</div>
										}
									</div>
								</Tab>

							</Tabs>
						</div>
					</div>
				</div>

				{/* Review  */}
				<Modal show={this.state.reviewShow} size="md" onHide={this.closeModal}>
					<Modal.Header>
						<Modal.Title>Review</Modal.Title>
						<span className="modalClose" onClick={() => this.closeModal()}><MdClose /></span>
					</Modal.Header>
					<Modal.Body>
						{loaderReview ?
							<span className='loaderStyle'><LoadingSpinner /></span> : null
						}
						{reviewErrorMessge ?
							<p className="alert alert-danger text-center" role="alert"> {reviewErrorMessge} </p>
							: null
						}
						{reviewData && (reviewData.collection_only == 1 ||  reviewData.restaurant_grocery_delivery ==1) ? null :
							<div className="reviewBox">
								<h5 className="text-center"><strong>Review</strong> Your Delivery</h5>
								<div className="reviewImgPan">
									{reviewData && reviewData.driver_details && reviewData.driver_details.user_detail && reviewData.driver_details.user_detail.profile_pic ?
										<img className="card-img img-fluid img-thumbnail h-100-px" width={100} height={100} src={`${IMAGEURL}profile/${reviewData.driver_details.user_detail.profile_pic}`} alt="" /> :
										<img className="card-img" src={driverImg} />
									}
								</div>
								<div className="reviewTxtPan">
									<h4>
										{reviewData && reviewData.driver_details && reviewData.driver_details.user_detail && reviewData.driver_details.user_detail.first_name}
										{' '}
										{reviewData && reviewData.driver_details && reviewData.driver_details.user_detail && reviewData.driver_details.user_detail.last_name}
									</h4>
									<p>
										Vehicle type:{' '}
										{reviewData && reviewData.driver_details && reviewData.driver_details.driver_transport_details && reviewData.driver_details.driver_transport_details.vehicle_make}
										{' '}
										{reviewData && reviewData.driver_details && reviewData.driver_details.driver_transport_details && reviewData.driver_details.driver_transport_details.vehicle_model}
										<br />
										Number:{' '}
										{reviewData && reviewData.driver_details && reviewData.driver_details.driver_transport_details && reviewData.driver_details.driver_transport_details.vehicle_registration_number}
									</p>
									<div className="reviewStar">
										<ReactStars
											count={5}
											size={24}
											onChange={(rating) => this.handleRatingChange(rating, 'driver')}
											activeColor='#ffd700'
										/>
										{/* <span>(Satisfied)</span> */}
									</div>
									{driverRating > 2 ?
										<div className="reviewSelect">
											{driver.map(data => {
												return <label className="customChk radioBox" key={data.id}>
													<input type="checkbox" checked={data.isChecked} value={data.value} className="form-check" onChange={(e) => this.handleReview(e, 'driver')} />
													<span>{data.name}</span>
												</label>;
											})}
										</div>
										:
										<div className="reviewSelect">
											{driverNeg.map(data => {
												return <label className="customChk radioBox" key={data.id}>
													<input type="checkbox" checked={data.isChecked} value={data.value} className="form-check" onChange={(e) => this.handleReview(e, 'driver')} />
													<span className="Neg">{data.name}</span>
												</label>;
											})}
										</div>
									}
								</div>
							</div>
						}
                        
                        {reviewData && reviewData.restaurant_id && 
						<div className="reviewBox">
                            
                            <h5 className="text-center"><strong>Review</strong> Your Food</h5>
                            
							<div className="reviewImgPan">
								<img className="card-img" src={foodImg} />
							</div>
							<div className="reviewTxtPan">
								
								<div className="reviewStar">
									<ReactStars
										count={5}
										size={24}
										onChange={(rating) => this.handleRatingChange(rating, reviewData && reviewData.restaurant_id && reviewData.collection_only == 0 ? 'food' : 'collect')}
										activeColor='#ffd700'
									/>
								</div>

								{reviewData && reviewData.restaurant_id && reviewData.collection_only == 0 ? 
									foodRating > 2 ?
										<div className="reviewSelect">
											{food.map(data => {
												return <label className="customChk radioBox" key={data.id}>
													<input type="checkbox" checked={data.isChecked} value={data.value} className="form-check" onChange={(e) => this.handleReview(e, 'food')} />
													<span>{data.name}</span>
												</label>;
											})}
										</div>
										:
										<div className="reviewSelect">
											{foodNeg.map(data => {
												return <label className="customChk radioBox" key={data.id}>
													<input type="checkbox" checked={data.isChecked} value={data.value} className="form-check" onChange={(e) => this.handleReview(e, 'food')} />
													<span className="Neg">{data.name}</span>
												</label>;
											})}
										</div>
									: null
								}

								{reviewData && reviewData.restaurant_id && reviewData.collection_only == 1 ?
									collectRating > 2 ?
										<div className="reviewSelect">
											{collect.map(data => {
												return <label className="customChk radioBox" key={data.id}>
													<input type="checkbox" checked={data.isChecked} value={data.value} className="form-check" onChange={(e) => this.handleReview(e, 'collect')} />
													<span>{data.name}</span>
												</label>;
											})}
										</div>
										:
										<div className="reviewSelect">
											{collectNeg.map(data => {
												return <label className="customChk radioBox" key={data.id}>
													<input type="checkbox" checked={data.isChecked} value={data.value} className="form-check" onChange={(e) => this.handleReview(e, 'collect')} />
													<span className="Neg">{data.name}</span>
												</label>;
											})}
										</div>
									: null
								}

							</div>
						</div>
                        }

                        {reviewData && reviewData.grocery_id && 
                            <div className="reviewBox">
                                <h5 className="text-center"><strong>Review</strong> Your Grocery</h5>:
                                <div className="reviewImgPan">
                                    <img className="card-img" src={foodImg} />
                                </div>

                                <div className="reviewTxtPan">
                                    <div className="reviewStar">
                                        <ReactStars
                                            count={5}
                                            size={24}
                                            onChange={(rating) => this.handleRatingChange(rating, 'grocery')}
                                            activeColor='#ffd700'
                                        />
                                    </div>
                                    {groceryRating > 2 ?
                                        <div className="reviewSelect">
                                            {grocery.map(data => {
                                                return <label className="customChk radioBox" key={data.id}>
                                                    <input type="checkbox" checked={data.isChecked} value={data.value} className="form-check" onChange={(e) => this.handleReview(e, 'grocery')} />
                                                    <span>{data.name}</span>
                                                </label>;
                                            })}
                                        </div>
                                        :
                                        <div className="reviewSelect">
                                            {groceryNeg.map(data => {
                                                return <label className="customChk radioBox" key={data.id}>
                                                    <input type="checkbox" checked={data.isChecked} value={data.value} className="form-check" onChange={(e) => this.handleReview(e, 'grocery')} />
                                                    <span className="Neg">{data.name}</span>
                                                </label>;
                                            })}
                                        </div>
                                    }
                                </div>
                            </div>
                        }

					</Modal.Body>
					<Modal.Footer>
						<Button className="addCartBtn center" variant="primary" onClick={() => this.handleReviewSubmit(reviewData)}>Submit<RiArrowRightSLine /></Button>
					</Modal.Footer>
				</Modal>

				{/* Review  */}

				<Modal
					size={'xl'}
					show={this.state.showTrackModal}
					onHide={this.closeTrackModal}
					className="payOptionPop"
				>
					<Modal.Body className='bg-white text-dark'>
						<Row>
							{fetchOrderLoader ? <LoadingSpinner /> :
								<Col md={12} className="text-center">
									{order && order.user_details.profile_pic && <img className="img-fluid img-thumbnail h-100-px mb-2" width={100} height={100} src={`${IMAGEURL}profile/${order.user_details.profile_pic}`} alt="" />}
									<h5 className="font-weight-bold">{name}</h5>

									<div className='orderHistoryDetails'>
										<p>Order Number: {order && order.order_number}</p>
										{order && order.driver_details && order.driver_details.user_detail && order.driver_details.user_detail.first_name ?
											<p>Delivery Person: {order.driver_details.user_detail.first_name}</p> :
											<></>
										}

										{order && order.order_status == 'pending' ? null : order && order.collection_only == 1 && order.order_status == 'pickup_ready' ? (<p>Order Ready for Collection</p>) :
											<p>{Utils.getEstimatedTime(order)}</p>

										}

										{order && order.collection_only == 1 &&
											<p>To collect your order you need to bring your device from which the order is placed and scan a QR code</p>
										}
										{/*{order && order.collection_only == 0 && '(Estimated Delivery in ' + (order.restaurant_delivery_duration + order.order_delivery_duration / 60).toFixed(0) + ' minutes)'}*/}
										<p className={order && order.order_status === 'pending' ? 'text-danger mt-2' : 'text-success mt-2'}><strong>
											{order && order.order_status === 'delivery_onhold' ? 'Your order is on hold for delivery' : order && order.order_status === 'pending' ? 'Your order is pending' : null}
										</strong></p>
									</div>

									<div className="orderHistoryImgTxt">
										<div className="">
											{order ? order.order_status === 'approved' ?
												<img className="" width={500} height={700} src={Track1} alt="" /> :
												order && order.order_status === 'delivery_pickedup' ?
													<img className="" width={500} height={700} src={Track2} alt="" /> :
													order && order.order_status === 'pickup_ready' ?
														<img className="" width={500} height={700}
															src={Track3}
															alt="" /> :
														order.order_status === 'delivered' ?
															<img className="" width={500} height={700} src={Track4} alt="" /> :
															null :
												null
											}
										</div>
										<div>
											{order ? order.order_status === 'approved' || order.order_status === 'delivery_pickedup' ||
												order.order_status === 'pickup_ready' || order.order_status === 'delivered' ?
												<>
													<h4 className='orderConfirmedStyle'>Order Confirmed</h4>
													<h4 className='orderPreparingStyle'>Order ready for pick up</h4>
													<h4 className='orderPickedUpStyle'>Order Picked Up</h4>
													<h4 className='orderDeliveredStyle'>Order Delivered</h4>
												</> : null : null
											}
										</div>
									</div>
								</Col>
							}
						</Row>
					</Modal.Body>
					<div className="text-center my-2 mb-4">
						<Button
							type="button"
							onClick={this.closeTrackModal}
						>
							Return
						</Button>
					</div>
				</Modal>

				<Modal
					show={reviewSuccessModal}
					onHide={this.closeReviewModal}
					className="payOptionPop"
				>
					<Modal.Body className='bg-white text-dark'>
						<Row>
							<Col md={12} className="text-center">
								<h5 className="">{'Your ratings and reviews has been recorded successfully.'}</h5>
							</Col>
						</Row>
					</Modal.Body>
					<div className="text-center my-2">
						<Button
							type="button"
							onClick={this.closeReviewModal}
						>
							Return
						</Button>
					</div>
				</Modal>

				<Modal
                    show={this.state.showWarningModal}
                    onHide={this.closeWarningModal}
                    className="payOptionPop"
                >
                    <Modal.Body className="WarningModal bg-white">
                        <Row>
                            <Col md={12} className="text-center">
                                <h5 className='text-danger'> {this.state.errorMessage}</h5>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <div className="text-center mb-4">
                        <Button
                            className="but-gray"
                            type="button"
                            onClick={this.closeWarningModal}
                        >
                            Return
                          </Button>
                    </div>
                </Modal>


			</div>
		);
	}
}

OrderHistory.propTypes = {
	categoryId: PropTypes.any,
	handleCart: PropTypes.func,
	handleServiceCategory: PropTypes.func,
	cartItem: PropTypes.any,
	coupon: PropTypes.any,
	deliveryInstructions: PropTypes.any,
	driverTip: PropTypes.any,
	grandTotal: PropTypes.any,
	token: PropTypes.any,
	user: PropTypes.object,
	history: PropTypes.any,
	cart: PropTypes.any,
	geolocation: PropTypes.any,
};

const mapStateToProps = (state) => {
	return {
		categoryId: state.servicecategory.categoryId,
		cartItem: state.cart.cartItem,
		coupon: state.cart.coupon,
		deliveryInstructions: state.cart.deliveryInstructions,
		driverTip: state.cart.driverTip,
		grandTotal: state.cart.grandTotal,
		token: state.auth.token,
		user: state.user,
		cart: state.cart,
		geolocation: state.geolocation
	};
};

const mapDispatchToProps = dispatch => {
	return {
		handleCart: (data) => dispatch(handleCart(data)),
		handleServiceCategory: (data) => dispatch(handleServiceCategory(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistory);
