import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Col, Container, Row } from 'react-bootstrap';
import FooterWelcome from '../FooterWelcome';
//import HeaderLogoWelcome from '../LogoWelcomeHeader';
import { Formik, Field, Form, getIn } from 'formik';
import { connect } from 'react-redux';
import {
	accessToken,
	logoutMsg
} from './../../../redux/actions/auth';
import { handleCart } from './../../../redux/actions/cart';
import { handelUser } from './../../../redux/actions/user';
import { handleGeoLocation } from './../../../redux/actions/geolocation';
import * as Yup from 'yup';
import axios from './../../../shared/eaxios';
import LoadingSpinner from './../../../Components/LoadingSpinner/LoadingSpinner';
import '../PreAuth.scss';
import { SERVERFOLDER } from './../../../Constants';
//import { values } from 'lodash';
//import InputMask from 'react-input-mask';

const initialValues = {
	otp: '',
	user_type_id: 2
};

let randomToken = require('random-token').create('abcdefghijklmnopqrstuvwxzyABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789');
let device_token = randomToken(20);


const OTPSchema = Yup.object().shape({
	otp:
		Yup
			.number()
			.strict()
			.required('Please enter OTP'),

});

const getErrorStyles = (errors, touched, fieldName) => {
	if (getIn(errors, fieldName) && getIn(touched, fieldName)) {
		return {
			border: '1px solid red'
		};
	}
};

class OTP extends Component {
	state = {
		pathName: this.props.match.params.path,
		errorMsg: '',
		loader: false,
		user_type_id: null,
		seconds: 60,
		otp: this.props.location.state.otp
	}

	componentDidUpdate(prevProps) {
        if (this.props.categoryId !== prevProps.categoryId) {

            let data = this.props.cart;
            let cartItem = this.props.cartItem;

            cartItem = [];
            data.cartItem = cartItem;
            data.driverTip = 0;
            data.grandTotal = 0;
            this.props.handleCart(data);

            this.setState({
                cartItem
            });

            this.props.history.push(`${SERVERFOLDER + '/'}`);
        }
    }

	handleTimer = () => {
		if (this.state.seconds > 0) {
			this.interval = setInterval(() => this.setState({ seconds: this.state.seconds > 0 ? this.state.seconds - 1 : '' }), 1000);
		} else {
			this.setState({ seconds: null });
		}
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	handleResendOtp = () => {
		const formData = new window.FormData();
		formData.append('phone', this.props.location.state.phone);
		formData.append('user_type_id', 2);
		this.setState({ loader: true }, () => {
			axios.post('sendOtp', formData)
				.then( () => {
					this.setState({
						loader: false,
						//otp: response.data.message,
						seconds: 60
					}, () => {
						//this.showSuccesSubmitModal();
					});
				})
				.catch(err => {
					console.log('err', err);
					let errorMessage = '';
					try {
						errorMessage = err.data.message ? err.data.message : err.data.error_description;
					} catch (err) {
						errorMessage = 'No records found.';
					}
					this.setState({
						loader: false,
						errorMsg: errorMessage,

					},
						//this.showWarningModal(this.state.errorMsg)
					);
					setTimeout(() => {
						this.setState({ errorMsg: null });
					}, 5000);
				});
		});
	}

	handleSubmit = (values) => {
		let url;
		values.phone = this.props.location.state.phone;
		values.device_token = device_token;
		values.pathName = this.state.pathName;

		this.setState({ loader: true }, () => {

			if (this.state.pathName == 'login') {
				url = `otpLogin?phone=${this.props.location.state.phone}&otp=${values.otp}`;
			} else {
				url = `otpRegister?phone=${this.props.location.state.phone}&otp=${values.otp}`;
			}

			axios.post( url)
				.then(res => {
					if (res.data.success == false) {
						let errorMessage = res.data.message;
						this.setState({
							loader: false,
							errorMsg: errorMessage
						});
					} else {
						let timeStamp = new Date().getTime();
						let user = {};
						user.order_token = res.data.order_token;
						user.first_name = res.data.user.user_detail.first_name;
						user.last_name = res.data.user.user_detail.last_name;
						user.timeStamp = timeStamp;
						user.id = res.data.user.id;
						user.user_type_name = this.state.user_type_name;
						user.profile_pic = res.data.user.user_detail.profile_pic;
						user.gender = res.data.user.user_detail.gender;
						user.dob = res.data.user.user_detail.dob;
						user.stripe_customer_id = res.data.user.stripe_customer_id;
						user.email = res.data.user.email;
						user.about = res.data.user.user_detail.about;
						user.phone = res.data.user.phone;
						user.register_with = res.data.user.register_with;
						this.props.handelUser(user);

						// Set default Address
						if ( res.data.user.default_address ) {

							let geodata = this.props.geolocation;
							let addObj = res.data.user.default_address;
							let fulladdress = addObj.addr + ', ' + addObj.addr2 + ', ' + addObj.city + ', ' + addObj.state  + ', ' + addObj.country + ', ' + addObj.zip;
	
							geodata.latitude = addObj.lat;
							geodata.longitude = addObj.lng;
							geodata.address = fulladdress;
							geodata.timezone = addObj.timezone;
							geodata.refresh = true;
	
							this.props.handleGeoLocation(geodata);
						}

						this.props.sessionTokenUpdate(res.data.token, null);
						
						this.props.history.push(`${SERVERFOLDER + '/'}`);
						
						this.props.cartItem && this.props.cartItem.length > 0 ?
							this.props.history.push(`${SERVERFOLDER + '/checkout'}`) :
							this.props.history.push(`${SERVERFOLDER + '/'}`);
					}
				})
				.catch(err => {

					console.log('err value:', err);
					let errorMessage = '';
					try {
						errorMessage = err.data.message ? err.data.message : err.data.error_description;
					} catch (err) {
						errorMessage = 'Unknown error!';
					}
					this.setState({
						loader: false,
						errorMsg: errorMessage
					});
					setTimeout(() => {
						this.setState({ errorMsg: null });
					}, 5000);
				});
		});


	}

	componentDidMount() {
		this.handleTimer();
		setTimeout(() => {
			this.props.resetLogoutMsg(null);
		}, 5000);

		this.setState({
			user_type_id: 2,
			user_type_name: 'Customer'

		});

	}

	user_type_idHandler = (e) => {
		let user_type_name;


		this.setState({
			user_type_id: e.target.value,
			user_type_name

		});
	}

	render() {
		const { token } = this.props;
		initialValues.otp = this.state.otp;
		if (token) {
			//return (<Redirect to={`/${userType}/dashboard`} />);
			return (<Redirect to={SERVERFOLDER + '/'} />);
		} else {

			return (
				<div className="position-relative">
					{this.state.loader ?
						<span className='loaderStyle'><LoadingSpinner /></span>
						: null
					}
					<div className="welcomeOuter">
						{/*<HeaderLogoWelcome />*/}
						<div className="regWhiteOuter">
							<Container>
								<div className="welcomePage">
									<div className="row">
										<div className="col-sm-12">
											{
												this.props.authlogoutMsg && this.props.authlogoutMsg !== null ?
													<div className="alert alert-warning alert-dismissible fade show" role="alert">
														{this.props.authlogoutMsg}
													</div>
													:
													null
											}
										</div>
									</div>
									<Row className="show-grid">
										<Col md={12}>
											<div className="welcomeIntro mt-4">
												<div className="login position-relative">
													<h4 className='text-center text-orange-primary mb-3'>Check Your Messages!</h4>
													<p className='text-center mt-n3'>We sent you a one time password.</p>
													<h6 className='mb-3'> Enter OTP</h6>
													{this.state.errorMsg ?
														<p className="alert alert-danger text-center" role="alert"> {this.state.errorMsg} </p>
														: null
													}
													<Formik
														initialValues={initialValues}
														validationSchema={OTPSchema}
														onSubmit={this.handleSubmit}

													>
														{({ values,
															errors,
															touched,
														}) => {
															return (<Form className='text-dark'>
																<div className="position-relative">
																	<div className="form-group">

																		<Field
																			style={getErrorStyles(
																				errors,
																				touched,
																				'otp'
																			)}
																			type="number"
																			name="otp"
																			value={values.otp || ''}
																			placeholder="Enter OTP"
																			className="form-control"
																		/>
																		{errors.otp && touched.otp ? <span className="errorMsg text-danger">{errors.otp}</span> : null}
																	</div>

																	<div className="row">
																		<div className="col text-left">
																			{this.state.seconds > 0 ? '00.' + this.state.seconds : null}
																		</div>
																		<div className="col text-right">
																			<a className="backArrow" onClick={this.handleResendOtp}>RESEND</a>
																		</div>
																	</div>

																	<div className='row mt-2 text-center'>
																		<div className='col-sm-12'>
																			<Button variant="primary mr-3" type="submit" className="btnStylePrimary mt-3">
																				<span className="font-weight-normal">Verify</span>
																			</Button>
																		</div>
																	</div>

																</div>
															</Form>);
														}}
													</Formik>

												</div>{/* end: login */}
											</div>
										</Col>
									</Row>
								</div>{/* end: welcomePage*/}
							</Container>
						</div>{/* end: regWhiteOuter*/}
						<FooterWelcome />
					</div>
				</div>
			);
		}
	}
}

OTP.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			path: PropTypes.string
		})
	}),
	location: PropTypes.object,
	history: PropTypes.object,
	sessionTokenUpdate: PropTypes.func,
	handelUser: PropTypes.func,
	token: PropTypes.any,
	authlogoutMsg: PropTypes.string,
	resetLogoutMsg: PropTypes.func,
	userType: PropTypes.string,
	cartItem: PropTypes.any,
	categoryId: PropTypes.any,
	handleCart: PropTypes.func,
	cart: PropTypes.any,
	geolocation: PropTypes.any,
	handleGeoLocation: PropTypes.func,
};

const mapStateToProps = (state) => {
	return {
		token: state.auth.token,
		authlogoutMsg: state.auth.logoutMsg,
		userType: state.user.user_type_name,
		cart: state.cart,
		cartItem: state.cart.cartItem,
		categoryId: state.servicecategory.categoryId,
		geolocation: state.geolocation
	};
};

const mapDispatchToProps = dispatch => {
	return {
		sessionTokenUpdate: (token) => dispatch(accessToken(token)),
		handelUser: (user) => dispatch(handelUser(user)),
		resetLogoutMsg: data => dispatch(logoutMsg(data)),
		handleCart: (data) => dispatch(handleCart(data)),
		handleGeoLocation: (data) => dispatch(handleGeoLocation(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(OTP);
