/* eslint-disable no-unused-vars */
/* eslint-disable no-unescaped-entities */
/* eslint-disable no-irregular-whitespace */
/* eslint-disable no-mixed-spaces-and-tabs */


//import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Container } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { handleCart } from '../../../redux/actions/cart';
import { SERVERFOLDER } from '../../../Constants';
import PropTypes from 'prop-types';



class DriverTermsCondtions extends Component {
	state = {
		errorMsg: '',
		loader: false,
		user_type_id: null,
		succesSubmitModal: false,
	}

	componentDidMount() {
		window.scrollTo(0, 0);

		let appParam = this.props.match.params.page;
		if (appParam === 'app') {
			document.getElementById('header').classList.add('app-page');
		}

	}


	render() {
		return (
			<div className='position-relative'>
				<Container className='py-4'>
					<h3 className='mb-2'>Supplier Agreement</h3>
					<p>Future Logistic Solutions Ltd t/a The Delivery Guys</p>
					<div className='privacyContent'>

						<p>It is agreed that THIS SUPPLIER AGREEMENT is entered into and accepted by you, on the date that you (virtually or otherwise) read, understand and accept our T&C’s by using our software or signing this contract in person.</p>

						<h5>Between</h5>

						<ol className='list'>
							<li>(&quot;You the &quot; Supplier&quot;)<br /><br />
								& </li>

							<li>(We) Future Logistics Solutions Ltd t/a The Delivery Guys and or any of its franchised affiliates (from here forward referred to as TDG)</li>
						</ol>

						<h5>Background</h5>

						<ol className='list'>
							<li>The Delivery Guys is a business built on collecting food, drink, shopping and parcels from restaurants and other outlets with which it has business partnerships and agreements, and delivering same to the locations of customers as requested. This requires a large pool of delivery suppliers to perform the delivery services for our delivery partners by bicycle, motorcycle, car and van.</li>

							<li>You are a supplier in business on your own account who wishes to join the TDG supplier pool, and your commissions are made from multiple sources and various business, that has been channeled through the TDG software platform, and subject to the terms and conditions set out here, and you are able to meet the service standards of TDG, more fully set out in this Schedule of Agreement.</li>
						</ol>

						<h5>It is agreed as follows:</h5>

						<h6>1.	Commencement and duration</h6>

						<ol type='a' className='list'>
							<li>This Agreement commences on the date that you accept and agree to TDG T&C’s by ticking the “Agreed” box and will continue until it is terminated by either party in accordance with clause 9 below.</li>

							<li>TDG sub-contracts you to perform delivery services for our delivery partners on the terms set out in this agreement</li>

							<li>Throughout this Agreement, &quot;Services&quot; means the collection by you of hot/cold food and/or drinks (&quot;Order Items&quot;) parcels or shopping from such restaurants and or other partners (&quot;Partners&quot;) as notified to you through the TDG driver app, and the delivery of such order Items to TDG customers at such locations as you are notified to you through the driver application, in accordance with the Service Delivery Standards contained in the Schedule.</li>
						</ol>

						<h6>2.	Commencement and duration</h6>

						<ol type='a' className='list'>
							<li><strong>You are a Self-Employed Supplier</strong> and therefore understand and acknowledge that you are neither an employee of TDG, nor a worker within the conventional meaning of any employment rights or legislation. For the avoidance of doubt, throughout the term of this agreement you are free to work for such third parties as you choose, provided it is not in conflict with TDG’s core business and in accordance with the Service Delivery Standards and the non-compete clause within this agreement</li>

							<li>You further warrant that neither you nor anyone acting on your behalf will present any claim in the Workplace Relations Commission or any civil court in which it is contended that you are either an employee or a worker of any kind associated with TDG.</li>

							<li>If, despite clause 2.b, either you or anyone acting on your behalf (or your substitute or anyone acting on your substitute’s behalf) presents any claim in the Workplace Relations Commission or any civil court which would not be able to proceed unless it was successfully contended that you (or your substitute) are an employee or a worker within the meaning of any employment rights legislation, you undertake to indemnify and keep indemnified TDG against costs (including legal costs) and expenses that it incurs in connection with those proceedings, and you agree that TDG may set off any sum owed to you against any damages, compensation, costs or other sum that may be awarded to you in those proceedings.</li>

							<li>Nothing in this Agreement shall make you an agent of TDG or any of its contracted franchisee’s and you shall not have any right or power to enter into contracts on behalf of TDG with third parties, unless specifically authorized to do so, nor you shall not have any authority to incur any expenditure in the name of TDG or any of its franchisee’s.</li>

							<li>On the signing or digital acceptance of this contract, it is understood that you also agree to the &quot;Non-Compete Arrangement&quot; and therefore shall not service any restaurants with a delivery service that TDG have a working relationship with, for a minimum period of 18 months after leaving TDG. Breach of this arrangement will result in TDG filing for loss of income against you.</li>
						</ol>

						<h6>3.	Performing the services – practicalities</h6>

						<ol type='a' className='list'>
							<li>TDG is not obliged to make available any minimum level or amount of hours to you, nor are you obliged to perform any minimum level or amount of hours or deliveries.</li>

							<li>Without prejudice to clause 3.a, when applying to join the TDG supplier pool and at regular intervals thereafter, you will provide an indication of the time periods of your availability, in which you typically expect to be available to work. TDG places reliance on such indications provided by suppliers in planning to meet customer demand. We accordingly expect you to inform a member of the Operations Team if this changes materially, and reserve the right to terminate this Agreement if you are no longer able to work at time periods which meet the needs of TDG</li>

							<li>During your onboarding process, you will have discussed with a member of the Operations Team of the level of demand for suppliers within your town/area and consequently the level of availability to perform services which TDG requires you to provide after you have given your commitment to do so. You are expected to be as flexible as you can be to meet those needs.</li>

							<li>When you have confirmed your availability to perform services during a particular time period, it is your responsibility to log in to and continually monitor, the driver app during this period and to undertake any orders in your zone which you are acceptable to you.</li>

							<li>You will ensure that you perform the Services in accordance with the Service Delivery Standards set out in this schedule, including the standards of dress contained there. However, and for the avoidance of doubt, you will be subject to, direction and the expectations of TDG in performing such services.</li>

							<li>You must immediately notify a member of the Operations Team if you become unable to work during a time period that you have previously agreed to work in accordance with clause 3.d, and explain the reasons for this. For the avoidance of doubt, no retainers shall be payable in respect of such period.</li>
						</ol>

						<h6>4.	Equipment</h6>

						<ol type='a' className='list'>
							<li>You will supply a mode of transport and smartphone ensuring that it is sufficiently charged and subject to a current mobile phone subscription which enables you to access to voice and data services at all times while this Agreement is in force. During the times that you are suppling services to TDG your location settings <em><u>must be switched on at all times</u></em>.</li>

							<li>You will download and install, or permit TDG to download and install, such applications as are required for you to be able to receive and perform services, and will also apply any and all new versions, revisions and fixes to such software as may be required by TDG from time to time.</li>

							<li>You will supply your own car/motorbike/van (at your own cost) for the purposes of providing the services. You will ensure that at all times your vehicle is clean, in a good state of repair, and roadworthy, covered by a current MOT certificate, and has up-to-date insurance and road tax. <em><u>You agree to periodic inspection regarding cleanliness</u></em>.</li>

							<li>You will not, at any time, drive the car/motorbike/van while under the influence of drugs or alcohol. You acknowledge that you are responsible for all and any charges, penalties and fines relating to your usage of the car/motorbike/van, including but not limited to parking charges, congestion charges and traffic offences</li>

							<li>You will provide (either personally or by way of purchase of the equipment pack referred to herein</li>

							<li>And at all times when providing your services wear, TDG branded clothing that will keep you safe, dry and comfortable. For the avoidance of doubt the wearing of branded clothing shall not make you a worker within the meaning of any employment rights or legislation.</li>

							<li>You will immediately inform a member of the Operations Team if you are involved in any form of accident when using your car/motorbike/van while performing services for TDG.</li>

							<li>Upon entering into this Agreement, you will purchase or hire an equipment pack. This is a local decision that will be instructed to you by your local Operations Team. On termination of the Agreement, provided the equipment (not including clothing) is in a good state of repair (to be determined by TDG in its sole discretion), your deposit will be returned. You will be required to supply a suitable box for the transportation of orders if using a motorbike.</li>

							<li>You agree that TDG equipment, and in particular the TDG thermal bags, must never be used for any purpose other than performing the services of TDG.</li>

							<li>It is the responsibility of the supplier to ensure the product(s) are delivered in perfect order. Damaged, dropped, or spilled products are the sole responsibility of the supplier to replace at their own cost immediately after the incident occurs to ensure customer and restaurant satisfaction.</li>

							<li>In restaurants where TDG operate a cash return model, the supplier will be required to pay for the order in cash at the restaurant till and directly recoup the fee from the customer at the door or deposit all cash collected during your shift at a preset location. Suppliers will therefore be required to ensure that they have a sufficient float of cash with them to facilitate this as advised by the operations manager on a location and seasonal basis.</li>
						</ol>

						<h6>5.	Fees and invoicing</h6>

						<ol type='a' className='list'>
							<li>TDG and/or its franchisee’s will pay you an agreed set fee for each complete delivery/order OR an agreed combination of set hourly retainer, (or part thereof) and a “drop fee” for each completed successful delivery scheduled in accordance with clause 3.d These fees may be varied depending on the day, time and location and any changes will be notified to you in advance by Driver Operations. A delivery, for the avoidance of doubt, being the collection of Ordered Items from a partner restaurant/supermarket and delivery to a customer of TDG. For the avoidance of doubt, you MUST be eligible to work legally in The Republic of Ireland and it is your duty to adhere to the taxation laws set out by the revenue commissioners of Ireland. </li>

							<li>TDG reserves the right, upon notice to you, to discontinue the arrangements set out in 5.a above and move to a set delivery fee model. In such event, you will no longer have any entitlement to an hourly retainer but will instead be entitled to a set delivery fee in an amount to be notified to you for each delivery you complete (a delivery, for these purposes, being the collection of Order Items from a partner and delivery to a customer of TDG). This arraignment can be specific to individual partners or across and entire town/area. </li>

							<li>You will invoice TDG weekly in respect of services provided in the previous week stating your tax number and any other relevant information. Each invoice must be accompanied by a description of the services provided (retainer hours, number of deliveries, online tips etc) and such other information as TDG may reasonably require from time to time. TDG will make available to you an electronic facility to enable you to prepare and submit your invoices to TDG in a timely manner.  Subject to satisfactory and timely submission of invoices, TDG will pay your fees/invoice and 100% of any online gratuities (tips) by electronic transfer to your nominated Irish bank account.</li>

							<li>All tips or gratuities, ether paid to you directly or electronically by any of our restaurant partner’s customers in respect of services provided by you under this agreement, are 100% yours, and online tips are transferred to you weekly with your service payments. Any revenue liabilities in relation to <em><u>this matter is entirely your responsibility</u></em>.  </li>

							<li>You understand and indemnify TDG and continue to keep TDG indemnified at all time against any claim or demand made against you in respect of any income tax, value added tax or any other tax or national insurance or social security contributions due on fees and tips payable under this Agreement and against any interest or penalties imposed in connection with any such tax or contributions. You will inform TDG of your tax reference number on request. TDG may at its option satisfy such indemnity in whole or in part by way of deductions from any payments owed to you.</li>
						</ol>

						<h5>Non-Compete Agreement</h5>

						<ul className='list'>
							<li>This is an Agreement between (&quot;You&quot;) and Future Logistics Solutions Ltd T/A The Delivery Guys and or any of its franchised affiliates. From here forward referred to as TDG. </li>

							<li>In consideration of the service contract opportunity provided by (You), intending to be legally bound, agree to the following:</li>

							<li>Term of Agreement.  This Agreement commences on the date that you tick the “I Accept Box” that you understand, accept and agree to all of TDG’s T&C’s by ticking the “Agreed” box and will continue until it is terminated by either party</li>

							<li>Limitations of this Agreement. This Agreement is not a contract of employment. Neither You nor the Company are obligated to any specific term of employment.  This Agreement is limited to the subject matter of covenants not to compete or solicit as described in this Agreement.</li>

							<li>Covenant Not to Compete. You agree that at no time during the term of your service contract with the Company (whether you are a driver, dispatcher, manager or franchisee) will you engage in any business activity which is competitive with the Company nor work for any company which competes with the Company.  </li>

							<li>For a period of one (1) calendar year immediately following the termination of your service contract, you will not, for yourself or on behalf of any other person or business enterprise, engage in any business activity which competes with the Company in which you were contracted without explicit written consent of the company.</li>

							<li>Non-solicitation. During the term of your service contract and for a period of one (1) calendar year immediately thereafter, You agree not to solicit any employee or independent contractor of the Company on behalf of any other business or enterprise, nor shall you induce any employee or independent contractor associated with the Company to terminate or breach an employment, contractual or other relationship with the Company. </li>

							<li>Soliciting Customers after Termination of Agreement. For a period of one (1) year following the termination of your service contract and your relationship with the Company, You shall not, directly or indirectly, disclose to any person, firm or corporation the names or addresses of any of the customers or clients of the Company or any other information pertaining to them. Neither shall you call on, solicit, take away, or attempt to call on, solicit, or take away any customers or clients of the Company on whom you have called or with whom you became acquainted during the term of your service contract, as the direct or indirect result of your employment with the Company. I understand that a breach of this agreement will result in TDG taking legal action against you for loss of earnings for up to 1 year’s loss of revenue.</li>

							<li>Injunctive Relief.  You hereby acknowledge (1) that the Company will suffer irreparable harm if you breach your obligations under this Agreement; and (2) that monetary damages will be inadequate to compensate the Company for such a breach. Therefore, if you breach any of such provisions, then the Company shall be entitled to injunctive relief, in addition to any other remedies at law or equity, to enforce such provisions.</li>

							<li>Severable Provisions. The provisions of this Agreement are severable, and if any one or more provisions may be determined to be illegal or otherwise unenforceable, in whole or in part, the remaining provisions and any partially unenforceable provisions to the extent enforceable shall nevertheless be binding and enforceable.</li>

							<li>Modifications. This Agreement may be modified only by a writing executed by both you and the Company.</li>

							<li>Prior Understandings. This Agreement contains the entire agreement between the parties with respect to the subject matter of this Agreement. The Agreement supersedes all prior understanding, agreements, or representations.  </li>

							<li>Waiver. Any waiver of a default under this Agreement must be made in writing and shall not be a waiver of any other default concerning the same or any other provision of this Agreement. No delay or omission in the exercise of any right or remedy shall impair such right or remedy or be constructed as a waiver. A consent to or approval of any act shall not be deemed to waive or render unnecessary consent to or approval of any other or subsequent act.</li>

							<li>Jurisdiction and Venue.  This Agreement is to be construed pursuant to the laws of the nation of Ireland. You agree to submit to the jurisdiction and venue of any court of competent jurisdiction in Ireland without regard to conflict of law’s provisions, for any claim arising out of this Agreement.</li>
						</ul>

						<h6>6.	Warranties  </h6>

						<p><strong>As strict conditions of this Agreement you warrant that:</strong></p>

						<ol type='a' className='list'>
							<li>You are a self-employed independent contractor in business on your own account;</li>

							<li>You have the right to reside and work in the Republic of Ireland and have all necessary visas, licenses and permits allowing you to do so;</li>

							<li>You hold, and will continue to hold, a driver’s license which permits you to drive the car/van/motorbike in the Republic of Ireland, and will notify TDG immediately should this cease to be the case;</li>

							<li>You hold, and will continue to hold, valid motor vehicle insurance in respect of the car/vehicle and will notify TDG immediately should this cease to be the case;</li>

							<li>You will account to the Revenue Commissioners and any other appropriate authorities for any income tax or PRSI or USC contributions due in respect of sums payable in connection with this Agreement;</li>

							<li>You are not registered for VAT and at no time during the life of this Agreement shall your Irish turnover of taxable goods and services in any tax year exceed (or be likely to exceed) the applicable VAT threshold and will notify TDG immediately should this warranty cease to be true or be at material risk of becoming untrue;</li>

							<li>You have never been convicted of any criminal offence; </li>

							<li>You will comply with the Service Delivery Standards.</li>
						</ol>

						<h6>7.	Insurance</h6>

						<p>Using your own vehicle, you will obtain at your own cost appropriate insurance which shall remain in force in respect of the provision of the Services by you throughout the life of this Agreement. You shall, on request, supply copies of such policies together with evidence that the relevant premiums or top up insurance</p>

						<h6>8. Liability and indemnity</h6>

						<ol type='a' className='list'>
							<li>You acknowledge and agree that you are personally responsible for the performance of the Services, regardless of whether actually performed by you, and accordingly accept liability for any costs, claims, proceedings, damages, losses, expenses or other liabilities (including legal costs) (together, “Losses”) threatened, suffered or incurred by TDG as a result of your negligent provision of the Services or failure to ensure that the Services are provided with a sufficient standard of care. You agree to indemnify and keep indemnified TDG against all such Losses.</li>
						</ol>

						<h6>9.	Right to appoint substitute</h6>

						<p>While as a general rule you are expected to perform the Services personally you do have the right, to arrange with another registered TDG driver/cyclist for them to perform a particular delivery or deliveries on your behalf. </p>

						<p>In such event you acknowledge that this will be a private arrangement between you and that individual and you will continue to bear full responsibility for meeting the Service Delivery Standards in relation to such delivery and all other obligations under this Agreement.  You will continue to invoice TDG in accordance with this Agreement, and shall be wholly responsible for the remuneration of your substitute.</p>

						<h6>10.	 Confidentiality and detrimental remarks</h6>

						<ol type='a' className='list'>
							<li>Both during the term of this Agreement and following its termination you must not (unless required to do so by law, protected in doing so by a legal right of protected disclosure or doing so in properly providing the Services):</li>

							<li>Disclose any of TDG trade process or confidential information to any person;</li>

							<li>Use any of TDG trade process or confidential information for any purposes other than TDG</li>

							<li>The words &quot;confidential information&quot; include but are not limited to:</li>

							<li>Personal data identifying or relating to any of TDG customers (including but not limited to names, addresses and other personal information such as dietary requirements and details of other household members), suppliers or employees;</li>

							<li>Training materials, and other confidential material provided to you during the course of TDG onboarding process;</li>

							<li>Details of relationships or arrangements with TDG other suppliers and business partners, including but not limited to restaurants;</li>

							<li>Details of TDG business methods, finances, pricing strategy, marketing or development plans or strategies; and</li>

							<li>Any other information you know to have been divulged to you by TDG, or by a third party in the course of performing the Services, in confidence.</li>

							<li>You will not make, directly or indirectly, any detrimental or derogatory comments about QSRS Ltd, its officers, employees, business partners (including restaurants) or suppliers either verbally or in writing, including in particular but not limited to on any social media platform or forum. A failure to comply with these obligations shall be considered a breach of this agreement.</li>
						</ol>

						<h6>11.	Termination</h6>

						<ol type='a' className='list'>
							<li>TDG may terminate this Agreement at any time and for any reason, and you may terminate this agreement at any time and for any reason on giving TDG not less than one week’s notice in writing.</li>

							<li>Without prejudice to 11.a above, TDG also reserves the right to terminate this agreement with immediate effect in the event of any serious or material breach by you of any obligation owed to TDG or our partners</li>

							<li>Upon termination of this Agreement, you shall be entitled to invoice TDG in respect of Services performed by you up to the date termination takes effect but you acknowledge you have no other right to any further or final payments in respect of termination or severance or otherwise from us.</li>

							<li>Upon termination of this Agreement for whatever reason, you shall deliver to TDG all property belonging to TDG which is in your possession or control, and permit TDG to disable and / or remove from your smartphone any TDG application. You shall also permit TDG to re-purchase from you any equipment you purchased from TDG.</li>

							<li>Following the termination of this Agreement you will no longer represent yourself as being connected in any way with the business of TDG, and in particular shall no longer wear any TDG branded apparel.</li>
						</ol>

						<h6>12.	Data protection & monitoring</h6>

						<ol type='a' className='list'>
							<li>The supplier consents to the Company and any Associated Company holding and processing personal data relating to him/her for legal, personnel, administrative and management purposes and in particular to the processing of &quot;sensitive personal data&quot; (as defined in the Data Protection Acts 1988 to 2003) relating to the Consultant. The supplier consents to the Company making such information available to any Associated Company, those who provide products or services to the Company (such as advisers), regulatory authorities, governmental or quasi-governmental organizations and potential purchasers of the Company or any part of its business.</li>

							<li>The supplier consents to the transfer of such information to the Company’s and any Associated Company’s business contacts outside the European Economic Area in order to further the Company’s business interests.</li>

							<li>The supplier agrees to treat any personal data relating to other employees of the Company, Associated Companies, the Company’s customers or third parties to which the supplier has access in the course of performing the supplier obligations pursuant to this Agreement strictly in accordance with the Company’s instructions and all legal requirements. In particular, the supplier will not use any such data other than in connection with, and to the extent necessary, for the performing the supplier obligations pursuant to this Agreement.</li>

							<li>The supplier shall take appropriate security measures (including appropriate technical security measures and organizational measures) against unauthorized access to, or unauthorized alteration, disclosure or destruction of, the personal data received from the Company or another party pursuant to this agreement, in particular where the processing involves the transmission of data over a network, and against all other unlawful forms of processing. The supplier agrees that in determining appropriate security measures, where the processing involves the transmission of data over a network, it shall ensure that the measures provide a level of security appropriate to the harm that might result from unauthorized or unlawful processing, accidental or unlawful destruction or accidental loss of, or damage to, the data concerned, and appropriate to the nature of the data concerned.</li>

							<li>The supplier will process the personal data only on and subject to the Company’s instructions given to the supplier from time to time and agrees that the Company can take all reasonable steps to ensure compliance with this obligation and the obligation in this Clause 12.</li>

							<li>Any breach of this clause 12 by the supplier will be considered serious misconduct in the performance of the Services, and Clause 11 will be invoked. </li>
						</ol>

						<h6>13.	Miscellaneous</h6>

						<ol type='a' className='list'>
							<li>TDG reserves the right in its sole discretion to make changes to the terms of this Agreement at any time upon written notice to you.</li>

							<li>No person other than you and TDG may enforce any term of this Agreement.</li>

							<li>This Agreement contains the whole agreement between you and TDG in connection with your engagement by TDG and you confirm that you are not entering into the Agreement in reliance upon any oral or written representations made to you by or on behalf of TDG</li>

							<li>This Agreement is personal to you and may not be assigned to a third party without TDG express written agreement.</li>

							<li>This Agreement will be governed by the laws of the Republic of Ireland and the Courts of the Republic of Ireland will have non-exclusive jurisdiction to adjudicate any disputes arising under it.</li>
						</ol>

						<h5>Service delivery standards set by restaurant partners </h5>

						<p><u>To fulfill this contract you will be expected to meet the following minimum Service Delivery Standards</u></p>

						<ul className='list'>
							<li>During a time period in which you have registered to perform Services, you will log into the driver App and will promptly accept any orders in your zone which you are available to perform.</li>

							<li>You will promptly answer calls from members of the Operations team or, if you are unable to answer them for any reason (for example, because it would not be safe to do so), you will return them as soon as reasonably practicable.</li>

							<li>Upon collecting an order from a partner restaurant, you will click on the driver App to confirm that you have done so. You will then click again when you have successfully delivered the meal to the customer.</li>

							<li>You will be expected to meet certain minimum delivery times from Partner to customer. These shall be as notified to you by a member of the Operations team. Persistent failure to meet these requirements shall be considered a serious breach of the Agreement.</li>

							<li>You will be courteous in all of your dealings with TDG staff, fellow drivers and cyclists, restaurant personnel, customers and any other third parties with whom you interact while performing Services for TDG.</li>

							<li>You will perform the Services with all due care, skill and ability and, in particular, will comply with all applicable laws regarding road safety and usage.  You will ride and park your motorbike safely and considerately and in compliance in all respects with the law, the Highway Code, any applicable parking restrictions and with all health and safety policies and procedures imposed by TDG or by any Partner restaurant with which you may have dealings.</li>

							<li>You will comply with the requirements of TDG driver’s manual for riders as provided and explained to you during onboarding.</li>

							<li>Dress code: When performing the Services you must dress presentably in a clean collared shirt, blouse or company branded clothing, and full length trousers, or in accordance with such other TDG dress code as may be notified to you from time to time. You must keep your clothing clean and in a good state of repair, and for the avoidance of doubt, at all times when performing the Services you must wear Company branded clothing. You must <u>never</u> wear clothing bearing any offensive language/image or logo or mark of, or otherwise representative of, any competitor organization while performing the services.</li>
						</ul>
					</div>
				</Container>


			</div>
		);
	}
}

DriverTermsCondtions.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			page: PropTypes.string
		})
	})
};

const mapStateToProps = (state) => {
	return {
		cartItem: state.cart.cartItem,
		coupon: state.cart.coupon,
		deliveryInstructions: state.cart.deliveryInstructions,
		driverTip: state.cart.driverTip,
		grandTotal: state.cart.grandTotal,
		token: state.auth.token,
		user: state.user,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		handleCart: (data) => dispatch(handleCart(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DriverTermsCondtions);
