import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';
import { SERVERFOLDER } from './../../../Constants';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { handleCart } from './../../../redux/actions/cart';
import { accessToken } from './../../../redux/actions/auth';
import { handelUser } from './../../../redux/actions/user';
import { handleServiceCategory } from './../../../redux/actions/servicecategory';

import * as Utils from '../../../Utils';
import CookieConsent from 'react-cookie-consent';

class Footer extends Component {
    render() {
        //console.log(window.location.pathname);
        return ( <> {
                window.location.pathname != '/checkout' && this.props.cartItem && this.props.cartItem.length > 0 &&
                <div className="cartNotificationPan">
						<div className="container">
							<div className="row">
								<div className="col-7">
									<strong>{this.props.cartItem.length} Items</strong>
									<span className='ml-2'>
										{Utils.setCurrency2(this.props.cart.currency_symbol, this.props.cart.currency_position, parseFloat(this.props.cart.grandTotal).toFixed(2))}
									</span>
								</div>
								<div className="col-5 text-right">
									<Link to={SERVERFOLDER + '/cart'}>
										<strong>Go to cart</strong>
									</Link>
								</div>
							</div>
						</div>
					</div>
            } {
                /*{process.env.REACT_APP_MODE == 'production' && window.location.pathname == '/' &&
                					<div className="cartNotificationPan">
                						<div className="container">
                							<div className="row">
                								<div className="col-12 text-center">
                									<strong> This site is under maintenance mode.</strong>
                									<span className='ml-2'>
                									</span>
                								</div>
                							</div>
                						</div>
                					</div>
                				}*/
            }
            <div className="footer">
					<div className="container">
						<div className="row">
							<div className="col-md-6">
								© {(new Date().getFullYear())} Delivery Guys. All Rights Reserved. Registered company #601081.
							</div>
							<div className="col-md-6 followCol">
								Follow Us On 
								<a target="_blank" href="https://www.facebook.com/TDG.ie" rel="noopener noreferrer"><FaFacebookF/></a> 
								<a target="_blank" href="https://twitter.com/TDGie" rel="noopener noreferrer"><FaTwitter/></a>
								<a target="_blank" href="https://www.instagram.com/thedeliveryguysireland" rel="noopener noreferrer"><FaInstagram/></a>
								<a href="https://tdg.ie/cookie-policy.html" className="ml-2" target="_blank" rel="noopener noreferrer">Cookie policy</a>
								<a href="https://tdg.ie/app-privacy-policy.html" className="ml-2" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
								<a href="https://tdg.ie/terms-conditions.html" className="ml-2" target="_blank" rel="noopener noreferrer">Terms of Service</a>
								{/*<Link className='ml-2' to={SERVERFOLDER + '/privacy-policy'}>Privacy Policy</Link>
								<Link className='ml-2' to={SERVERFOLDER + '/termsofservice'}>Terms of Service</Link>*/}
								<Link className='ml-2' to={SERVERFOLDER + '/contact-us'}>Contact Us</Link>
							</div>
						</div>
					</div>
				</div> 
			<CookieConsent 
			cookieName = "tdgCookie"
            buttonClasses = "btn btn-primary"
            containerClasses = "CookieConsent col-lg-12"
            contentClasses = "cookieDiv"
            buttonText = "Okay" >
            <h4 className='cookieh4'>Cookie Notice</h4> 
            <p className = 'cookiePara'> Our website uses analytic cookies to improve website usability and advertisement / tracking cookies in order to show relevant ads to our users.As soon as you make use of this website, you automatically authorize the usage of these cookies and scripts. <a target="_blank" rel="noopener noreferrer" href="https://tdg.ie/cookie-policy.html">Cookie policy</a></p>
            </CookieConsent> 
            </>
        );
    }
}

Footer.propTypes = {
    handleCart: PropTypes.func,
    cartItem: PropTypes.any,
    user: PropTypes.object,
    sessionTokenUpdate: PropTypes.func,
    handelUser: PropTypes.func,
    categoryId: PropTypes.any,
    handleServiceCategory: PropTypes.func,
    history: PropTypes.object,
    cart: PropTypes.any,
};

const mapStateToProps = (state) => {
    return {
        cartItem: state.cart.cartItem,
        user: state.user,
        categoryId: state.servicecategory.categoryId,
        cart: state.cart
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handleCart: (data) => dispatch(handleCart(data)),
        sessionTokenUpdate: (token) => dispatch(accessToken(token)),
        handelUser: (user) => dispatch(handelUser(user)),
        handleServiceCategory: (data) => dispatch(handleServiceCategory(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
