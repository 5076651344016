import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { FaAngleLeft } from 'react-icons/fa';
import { Formik, Field, Form, getIn } from 'formik';
import { connect } from 'react-redux';
import { accessToken, logoutMsg } from './../../../redux/actions/auth';
import { Link } from 'react-router-dom';
import { MdPlayArrow } from 'react-icons/md';
import { handelUser } from './../../../redux/actions/user';
import { handleCart } from './../../../redux/actions/cart';
import { handleGeoLocation } from './../../../redux/actions/geolocation';
import { FaFacebookF } from 'react-icons/fa';
import * as Yup from 'yup';
import axios from './../../../shared/eaxios';
import '../PreAuth.scss';
import { SERVERFOLDER } from './../../../Constants';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';

//let CryptoJS = require('crypto-js');


let initialValues1 = {
	email: '',
	user_type_id: 2
};

let initialValues2 = {
	email: '',
	password: '',
	user_type_id: 2
};

const LoginSchema1 = Yup.object().shape({
	email:
		Yup
			.string()
			.trim('Please remove whitespace')
			.strict()
			.test('email', 'Must be a valid phone number',
				function (value) {
					let isValid = false;
					
					//const phoneno = (/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);
					//const phoneno = (/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/);
					//const email = /\S+@\S+\.\S+/;
					
					if (value) {
						//if (value.match(phoneno) || value.match(email)) {
						if ( !isNaN(+value)) {
							isValid = true;
							return isValid;
						}
					}
				}
			)
			.required('Please enter your email or phone number.')
});

const LoginSchema2 = Yup.object().shape({
	email:
		Yup
			.string()
			.trim('Please remove whitespace')
			.strict()
			.email('Must be a valid email')
			.required('Please enter email address'),
	password:
		Yup
			.string()
			.trim('Please remove whitespace')
			.strict()
			.required('Please enter password..'),
});

const getErrorStyles = (errors, touched, fieldName) => {
	if (getIn(errors, fieldName) && getIn(touched, fieldName)) {
		return {
			border: '1px solid red'
		};
	}
};

class Login extends Component {
	state = {
		errorMsg: '',
		loader: false,
		user_type_id: null,
		isEmail: false,
		isPhone: false
	}

	isValidEmailAddress = (e) => {
		const inputValue = e.target.value;
		//console.log('inputValue=',inputValue);
		
		//const email = /\S+@\S+\.\S+/;

		//console.log('Number=',!isNaN(+inputValue)); // true if its a number, false if not

		if (!isNaN(+inputValue)) {
			this.setState({ isPhone: true, isEmail: false });
			console.log('phone', this.state.isPhone);
		}

		/*if (inputValue.match(email)) {
			this.setState({ isEmail: true, isPhone: false });
			console.log('mail', this.state.isEmail);
		}*/

	}

	handleSubmit1 = (values) => {
		if (this.state.isPhone === true) {
			this.handlePhone(values);
		} else {
			//this.handleEmail();
		}
	}

	handleEmail = () => {
		this.setState({ isEmail: true });
	}

	handlePhone = (values) => {
		const formData = new window.FormData();
		formData.append('phone', values.email);
		formData.append('user_type_id', 2);
		this.setState({ loader: true }, () => {
			axios.post('sendOtp', formData)
				.then(() => {
					this.setState({
						loader: false,
					});
					this.props.history.push({
						pathname: `${SERVERFOLDER + '/otp/login'}`,
						state: { 
							//otp: response.data.message, 
							phone: values.email
						}
					});
				})
				.catch(err => {
					//console.log('err', err);
					let errorMessage = '';
					try {
						errorMessage = err.data.message ? err.data.message : err.data.error_description;
					} catch (err) {
						errorMessage = 'No records found.';
					}
					this.setState({
						loader: false,
						errorMsg: errorMessage,
					});
					setTimeout(() => {
						this.setState({ errorMsg: null });
					}, 5000);
				});
		});
	}

	handleSubmit2 = (values) => {

		let params = `user_type_id=${values.user_type_id}&email=${values.email}&password=${values.password}`;


		//params = CryptoJS.AES.encrypt(JSON.stringify(params), '123').toString();

		params = 'login2?' + params;

		this.setState({ loader: true },
			() => {
				axios.post(params) ////user_type_id=2
					.then(res => {
						let timeStamp = new Date().getTime();
						let user = {};
						user.order_token = res.data.order_token;
						user.first_name = res.data.user.user_detail.first_name;
						user.last_name = res.data.user.user_detail.last_name;
						user.timeStamp = timeStamp;
						user.id = res.data.user.id;
						user.user_type_name = this.state.user_type_name;
						user.profile_pic = res.data.user.user_detail.profile_pic;
						user.gender = res.data.user.user_detail.gender;
						user.dob = res.data.user.user_detail.dob;
						user.stripe_customer_id = res.data.user.stripe_customer_id;
						user.email = res.data.user.email;
						user.about = res.data.user.user_detail.about;
						user.phone = res.data.user.phone;
						user.register_with = res.data.user.register_with;

						this.props.handelUser(user);
						this.props.sessionTokenUpdate(res.data.token, null);
						this.props.cartItem && this.props.cartItem.length > 0 ?
							this.props.history.push(`${SERVERFOLDER + '/checkout'}`) :
							this.props.history.push(`${SERVERFOLDER + '/'}`);
					})
					.catch(err => {
						console.log('err=>>', err);
						let errorMessage = '';
						try {
							errorMessage = err.data.message ? err.data.message : err.data.error_description;
						} catch (err) {
							errorMessage = 'No records found.';
						}
						this.setState({
							loader: false,
							errorMsg: errorMessage
						});
						setTimeout(() => {
							this.setState({ errorMsg: null });
						}, 5000);
					});
			}
		);
	}

	componentDidMount() {
		setTimeout(() => {
			this.props.resetLogoutMsg(null);
		}, 5000);
		this.setState({
			user_type_id: 2,
			user_type_name: 'Customer'
		});
	}

	componentDidUpdate(prevProps) {
		if (this.props.categoryId !== prevProps.categoryId) {

			let data = this.props.cart;
			let cartItem = this.props.cartItem;

			cartItem = [];
			data.cartItem = cartItem;
			data.driverTip = 0;
			data.grandTotal = 0;
			this.props.handleCart(data);

			this.setState({
				cartItem
			});

			this.props.history.push(`${SERVERFOLDER + '/'}`);
		}
	}

	user_type_idHandler = (e) => {
		let user_type_name;
		this.setState({
			user_type_id: e.target.value,
			user_type_name
		});
	}

	handleBack = () => {
		this.setState({
			isEmail: false,
			isPhone: false
		});
	}

	responseFacebook = (response) => {
		console.log('facebook-response', response);
		if (!response.error) {
			const formData = new window.FormData();
			formData.append('email', response.email);
			formData.append('first_name', response.first_name);
			formData.append('last_name', response.last_name);
			formData.append('provider_user_id', response.id);
			formData.append('provider', 'Facebook');
			//formData.append('device_token', response.accessToken);
			formData.append('token', '');
			//console.log(formData);
			axios.post('socialMediaLogin', formData)
				.then((res) => {
					if (res.data.success === true) {
						let timeStamp = new Date().getTime();
						let user = {};

						user.order_token = res.data.order_token;
						user.first_name = res.data.user.user_detail.first_name;
						user.last_name = res.data.user.user_detail.last_name;
						user.timeStamp = timeStamp;
						user.id = res.data.user.id;
						user.user_type_name = this.state.user_type_name;
						user.profile_pic = res.data.user.user_detail.profile_pic;
						user.gender = res.data.user.user_detail.gender;
						user.dob = res.data.user.user_detail.dob;
						user.stripe_customer_id = res.data.user.stripe_customer_id;
						user.email = res.data.user.email;
						user.about = res.data.user.user_detail.about;
						user.phone = res.data.user.phone;
						user.register_with = res.data.user.register_with;

						this.props.handelUser(user);

						// Set default Address
						if ( res.data.user.default_address ) {

							let geodata = this.props.geolocation;
							let addObj = res.data.user.default_address;
							let fulladdress = addObj.addr + ', ' + addObj.addr2 + ', ' + addObj.city + ', ' + addObj.state  + ', ' + addObj.country + ', ' + addObj.zip;
	
							geodata.latitude = addObj.lat;
							geodata.longitude = addObj.lng;
							geodata.address = fulladdress;
							geodata.timezone = addObj.timezone;
							geodata.refresh = true;
	
							this.props.handleGeoLocation(geodata);
						}

						this.props.sessionTokenUpdate(res.data.token, null);
						this.props.cartItem && this.props.cartItem.length > 0 ?
							this.props.history.push(`${SERVERFOLDER + '/checkout'}`) :
							this.props.history.push(`${SERVERFOLDER + '/'}`);
					} else {
						let errorMessage = '';
						try {
							errorMessage = res.data.message ? res.data.message : res.data.error_description;
						} catch (err) {
							errorMessage = 'No records found.';
						}
						this.setState({
							loader: false,
							errorMsg: errorMessage,
						});
						setTimeout(() => {
							this.setState({ errorMsg: null });
						}, 5000);
					}
				})
				.catch(err => {
					//console.log('err', err);
					let errorMessage = '';
					try {
						errorMessage = err.data.message ? err.data.message : err.data.error_description;
					} catch (err) {
						errorMessage = 'No records found.';
					}
					this.setState({
						loader: false,
						errorMsg: errorMessage,
					});
					setTimeout(() => {
						this.setState({ errorMsg: null });
					}, 2500);
				});

		}
	}

	responseGoogle = (response) => {
		console.log('google-response from login', response);
		if (!response.error) {

			const formData = new window.FormData();
			formData.append('email', response.profileObj.email);
			formData.append('first_name', response.profileObj.givenName);
			formData.append('last_name', response.profileObj.familyName);
			formData.append('provider_user_id', response.profileObj.googleId);
			formData.append('provider', 'Google');
			//formData.append('device_token', response.accessToken);
			formData.append('token', '');

			axios.post('socialMediaLogin', formData)
				.then((res) => {
					if (res.data.success === true) {
						let timeStamp = new Date().getTime();
						let user = {};

						user.order_token = res.data.order_token;
						user.first_name = res.data.user.user_detail.first_name;
						user.last_name = res.data.user.user_detail.last_name;
						user.timeStamp = timeStamp;
						user.id = res.data.user.id;
						user.user_type_name = this.state.user_type_name;
						user.profile_pic = res.data.user.user_detail.profile_pic;
						user.gender = res.data.user.user_detail.gender;
						user.dob = res.data.user.user_detail.dob;
						user.stripe_customer_id = res.data.user.stripe_customer_id;
						user.email = res.data.user.email;
						user.about = res.data.user.user_detail.about;
						user.phone = res.data.user.phone;
						user.register_with = res.data.user.register_with;

						this.props.handelUser(user);

						if ( res.data.user.default_address ) {

							let geodata = this.props.geolocation;
							let addObj = res.data.user.default_address;
							let fulladdress = addObj.addr + ', ' + addObj.addr2 + ', ' + addObj.city + ', ' + addObj.state  + ', ' + addObj.country + ', ' + addObj.zip;
	
							geodata.latitude = addObj.lat;
							geodata.longitude = addObj.lng;
							geodata.address = fulladdress;
							geodata.timezone = addObj.timezone;
							geodata.refresh = true;
	
							this.props.handleGeoLocation(geodata);
						}

						this.props.sessionTokenUpdate(res.data.token, null);
						this.props.cartItem && this.props.cartItem.length > 0 ?
							this.props.history.push(`${SERVERFOLDER + '/checkout'}`) :
							this.props.history.push(`${SERVERFOLDER + '/'}`);
					} else {
						let errorMessage = '';
						try {
							errorMessage = res.data.message ? res.data.message : res.data.error_description;
						} catch (err) {
							errorMessage = 'No records found.';
						}
						this.setState({
							loader: false,
							errorMsg: errorMessage,
						});
						setTimeout(() => {
							this.setState({ errorMsg: null });
						}, 5000);
					}
				})
				.catch(err => {
					//console.log('err', err);
					let errorMessage = '';
					try {
						errorMessage = err.data.message ? err.data.message : err.data.error_description;
					} catch (err) {
						errorMessage = 'No records found.';
					}
					this.setState({
						loader: false,
						errorMsg: errorMessage,
					});
					setTimeout(() => {
						this.setState({ errorMsg: null });
					}, 2500);
				});
		}
	}

	displayError = (e) => {
		let errorMessge = '';
		try {
			errorMessge = e.data.message ? e.data.message : e.data.error_description;
		} catch (e) {
			errorMessge = 'Unknown error!';
		}
		return errorMessge;
	}

	render() {
		const { token } = this.props;
		initialValues2.email = initialValues1.email;
		if (token) {
			// return (<Redirect to={`/${userType}/dashboard`} />);
			return (<Redirect to={`${SERVERFOLDER + '/'}`} />);
		} else {
			return (
				<div className="position-relative">
					<div className="welcomeOuter">
						<div className="regWhiteOuter">
							<Container>
								<div className="welcomePage">
									<div className="row">
										<div className="col-sm-12">
											{
												this.props.authlogoutMsg && this.props.authlogoutMsg !== null ?
													<div className="alert alert-warning alert-dismissible fade show" role="alert">
														{this.props.authlogoutMsg}
													</div>
													: null
											}
										</div>
									</div>
									<Row className="show-grid">
										<Col md={12}>
											<div className="welcomeIntro mt-4">
												{this.state.isEmail === true && <a className="" style={{ cursor: 'pointer' }} onClick={this.handleBack}><FaAngleLeft /> Back</a>}
												<div className="login position-relative">
													<h4 className='text-center'>Sign in</h4>
													<h6 style={{ color: 'GrayText' }} className='text-sm-center'>Fill Your Account Details</h6>
													<h4 style={{ color: 'white' }} className='text-center'>####</h4>
													{this.state.errorMsg ?
														<p className="alert alert-danger text-center" role="alert"> {this.state.errorMsg} </p>
														: null
													}



													<Formik
														initialValues={this.state.isEmail === false ? initialValues1 : initialValues2}
														validationSchema={this.state.isEmail === false ? LoginSchema1 : LoginSchema2}
														onSubmit={this.state.isEmail === true ? this.handleSubmit2 : this.handleSubmit1}
													>
														{({ values,
															errors,
															touched,
															setFieldValue

														}) => {
															return (<Form className='text-dark'>
																<div className="position-relative">
																	<div className="form-group">
																		{/*<label className="form-label font-weight-bold">
																			{this.state.isEmail === true ? 'Enter Email Address' :
																				'Enter Email Address or Phone Number'}
																			<span className="text-danger">*</span></label>*/}
																		<Field
																			style={getErrorStyles(
																				errors,
																				touched,
																				'email'
																			)}
																			type="text"
																			name="email"
																			value={values.email || ''}
																			placeholder="Phone Number"
																			onChange={(e) => {
																				setFieldValue('email', e.target.value);
																				this.isValidEmailAddress(e);
																			}}
																			className="form-control"
																		/>
																		{errors.email && touched.email ? <span className="errorMsg text-danger">{errors.email}</span> : null}
																	</div>
																	{this.state.isEmail === true &&
																		<div className="form-group">
																			<label className="form-label font-weight-bold">Password <span className="text-danger">*</span></label>
																			<Field
																				style={getErrorStyles(
																					errors,
																					touched,
																					'password'
																				)}
																				value={values.password || ''}
																				type="password"
																				name="password"
																				placeholder="Password"
																				className="form-control"
																			/>
																			{errors.password && touched.password ? <span className="errorMsg text-danger">{errors.password}</span> : null}
																		</div>
																	}

																	<div className='row mt-2 text-center'>
																		<div className='col-sm-12'>
																			<Button variant="primary mr-3" type="submit" className="btnStylePrimary mt-3">
																				<span className="font-weight-normal">{this.state.isEmail === true ? 'Submit' : 'Next'}</span>
																			</Button>
																		</div>
																	</div>

																	<div className='row text-center'>
																		{this.state.isEmail === true ?
																			<div className='col-sm-12 mt-3'>
																				<span className="text-muted center">Click here for <Link to={SERVERFOLDER + '/forgotPassword'} className='text-orange-primary mb-3'>Forgot Password</Link></span>
																			</div> :
																			null
																		}
																		<div className='col-sm-12 mt-3'>
																			<span className="text-muted center">New to Delivery Guys? <Link to={SERVERFOLDER + '/alllogin'} className='text-orange-primary mb-3'>Sign Up</Link></span>
																		</div>
																	</div>
																</div>
															</Form>);
														}}
													</Formik>

													<div className='text-center text-secondary mt-3 mb-3'><strong>OR</strong></div>


													<div className="col-md-12">
														<div className="card googleCard" >
															<div className="card-body">
																<GoogleLogin
																	clientId="309165283057-6co9ojqu3cj0r81tlq1ujlc4519c0m81.apps.googleusercontent.com"
																	onSuccess={this.responseGoogle}
																	idpId={false}
																	icon={<MdPlayArrow />}
																	buttonText={'Sign in with Google'}
																	className={'playBtn'}
																	onFailure={this.responseGoogle}
																	cookiePolicy={'single_host_origin'}

																/>
															</div>
														</div>
													</div><br></br>

													<div className="col-md-12">
														<div className="card facebookCard">
															<div className="card-body">
																<FacebookLogin
																	appId="1131659344036923"
																	fields="first_name,last_name,email,picture"
																	onClick={this.compountClicked}
																	callback={this.responseFacebook}
																	cssClass={'playBtn'}
																	icon={<FaFacebookF />}
																	isMobile={false}
																	disableMobileRedirect={false}
																/>
															</div>
														</div>
													</div>


												</div>{/* end: login */}
											</div>
										</Col>
									</Row>
								</div>{/* end: welcomePage*/}
							</Container>
						</div>{/* end: regWhiteOuter*/}
						{/*<FooterWelcome />*/}
					</div>
				</div>
			);
		}
	}
}

Login.propTypes = {
	location: PropTypes.object,
	handleCart: PropTypes.func,
	history: PropTypes.any,
	sessionTokenUpdate: PropTypes.func,
	handelUser: PropTypes.func,
	token: PropTypes.any,
	authlogoutMsg: PropTypes.string,
	resetLogoutMsg: PropTypes.func,
	userType: PropTypes.string,
	cartItem: PropTypes.any,
	categoryId: PropTypes.any,
	cart: PropTypes.any,
	geolocation: PropTypes.any,
	handleGeoLocation: PropTypes.func,
};

const mapStateToProps = (state) => {
	return {
		token: state.auth.token,
		authlogoutMsg: state.auth.logoutMsg,
		userType: state.user.user_type_name,
		cartItem: state.cart.cartItem,
		cart: state.cart,
		categoryId: state.servicecategory.categoryId,
		geolocation: state.geolocation
	};
};

const mapDispatchToProps = dispatch => {
	return {
		sessionTokenUpdate: (token) => dispatch(accessToken(token)),
		handelUser: (user) => dispatch(handelUser(user)),
		resetLogoutMsg: data => dispatch(logoutMsg(data)),
		handleCart: (data) => dispatch(handleCart(data)),
		handleGeoLocation: (data) => dispatch(handleGeoLocation(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);




