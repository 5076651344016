

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Col, Container, Row, Modal } from 'react-bootstrap';

import HeaderLogoWelcome from '../LogoWelcomeHeader';
import { Formik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import axios from '../../../shared/eaxios';
import LoadingSpinner from '../../../Components/LoadingSpinner/LoadingSpinner';
import '../PreAuth.scss';
import { AiFillCheckCircle } from 'react-icons/ai';
import { SERVERFOLDER } from './../../../Constants';
const initialValues = {
	email: '',
};

const ForgotPasswordSchema = Yup.object().shape({
	email:
		Yup
			.string()
			.trim('Please remove whitespace')
			.email('Email must be valid')
			.strict()
			.required('Please enter email address'),

});

const getErrorStyles = (errors, touched, fieldName) => {
	if (getIn(errors, fieldName) && getIn(touched, fieldName)) {
		return {
			border: '1px solid red'
		};
	}
};

class ForgotPassword extends Component {
	state = {
		errorMsg: '',
		loader: false,
		user_type_id: null,
		succesSubmitModal: false,
		//showWarningModal:false,
	}

	handleSubmit = (values) => {
		//this.props.history.push('/otp');
		this.setState({ loader: true }, () => {
			axios.post('forgotPassword', values)
				.then(() => {
					this.setState({
						loader: false,
					}, () => {
						this.showSuccesSubmitModal();
					});
					//this.props.history.push('/otp');
				})
				.catch(err => {
					console.log('err', err);
					let errorMessage = '';
					try {
						errorMessage = err.data.message ? err.data.message : err.data.error_description;
					} catch (err) {
						errorMessage = 'No records found.';
					}
					this.setState({
						loader: false,
						errorMsg: errorMessage,

					},
						//this.showWarningModal(this.state.errorMsg)
					);
					setTimeout(() => {
						this.setState({ errorMsg: null });
					}, 2500);
				});
		});
	}

	componentDidMount() {
	}

	showSuccesSubmitModal = () => {
		this.setState({ succesSubmitModal: true });
	};

	closeSuccesSubmitModal = () => {
		this.setState(
			{ succesSubmitModal: false },
			() => {
				this.props.history.push(`${SERVERFOLDER + '/login'}`);
			}
		);
	};

	// showWarningModal = message => {
	//     this.setState({ 
	//         showWarningModal: true, 
	//         errorMessage: message 
	//     });
	// };

	// closeWarningModal = () => {
	//     this.setState({ 
	//         showWarningModal: false 
	//     });
	// };

	render() {

		return (
			<div className="position-relative">
				{this.state.loader ?
					<span className='loaderStyle'><LoadingSpinner /></span>
					: null
				}
				<div className="welcomeOuter">
					<HeaderLogoWelcome />
					<div className="regWhiteOuter">
						<Container>
							<div className="welcomePage">
								<Row className="show-grid">
									<Col md={12}>
										<div className="welcomeIntro">
											<div className="login position-relative">
												<h4 className='text-center'>Forgot Your Password?</h4>
												<p>To recover your password, you need to enter your registered email address. We will send the recovery code to your email address.</p>
												{this.state.errorMsg ?
													<p className="alert alert-danger text-center" role="alert"> {this.state.errorMsg} </p>
													: null
												}
												<Formik
													initialValues={initialValues}
													validationSchema={ForgotPasswordSchema}
													onSubmit={this.handleSubmit}

												>
													{({
														errors,
														touched,

													}) => {
														return (<Form className='text-dark'>
															<div className="position-relative">
																<div className="form-group">
																	<label className="form-label font-weight-bold">Email Address <span className="text-danger">*</span></label>
																	<Field
																		style={getErrorStyles(
																			errors,
																			touched,
																			'email'
																		)}
																		type="email"
																		name="email"
																		placeholder="Enter email"
																		className="form-control"
																	/>
																	{errors.email && touched.email ? <span className="errorMsg text-danger">{errors.email}</span> : null}
																</div><div className='row mt-2 text-center'>
																	<div className='col-sm-12'>
																		<Button variant="primary mr-3" type="submit" className="btnStylePrimary mt-3">
																			<span className="font-weight-normal">Submit</span>
																		</Button>
																	</div>
																</div>
															</div>
														</Form>);
													}}
												</Formik>

											</div>{/* end: login */}
										</div>
									</Col>
								</Row>
							</div>{/* end: welcomePage*/}
						</Container>
					</div>{/* end: regWhiteOuter*/}

				</div>

				<Modal
					show={this.state.succesSubmitModal}
					onHide={this.closeSuccesSubmitModal}
					className="payOptionPop"
				>
					<Modal.Body className='bg-white text-dark'>
						<Row>
							<Col md={12} className="text-center">
								<span className='successIcon'><AiFillCheckCircle /></span>
							</Col>
						</Row>
						<Row>
							<Col md={12} className="text-center">
								<h5>Reset password mail sent successfully</h5>
							</Col>
						</Row>
					</Modal.Body>
					<div className="text-center my-4">
						<Button
							type="button"
							//disabled={loader}
							onClick={this.closeSuccesSubmitModal}
						>
							Return
                          </Button>
					</div>
				</Modal>


				{/* <Modal
                      show={this.state.showWarningModal}
                      onHide={this.closeWarningModal}
                      className="payOptionPop"
                  >
                      <Modal.Body className="WarningModal bg-white">
                          <Row>
                              <Col md={12} className="text-center">
                                  <h5 className='text-danger'> {this.state.errorMsg}</h5>
                              </Col>
                          </Row>
                      </Modal.Body>
                      <div className="text-center mb-4">
                          <Button
                              className="but-gray"
                              type="button"
                              //disabled={loader}
                              onClick={this.closeWarningModal}
                          >
                            Return
                          </Button>
                      </div>
                      
                  </Modal> */}
			</div>
		);
	}
}
ForgotPassword.propTypes = {
	location: PropTypes.object,
	history: PropTypes.object,
};

export default ForgotPassword;
