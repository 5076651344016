/* eslint-disable no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unescaped-entities */
//import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Container } from 'react-bootstrap';


import LoadingSpinner from '../../../Components/LoadingSpinner/LoadingSpinner';

import { connect } from 'react-redux';
import { handleCart } from '../../../redux/actions/cart';
//import { SERVERFOLDER } from '../../../Constants';




class AccountDeletion extends Component {
	state = {
		errorMsg: '',
		loader: false,
		user_type_id: null,
		succesSubmitModal: false,
	}



	render() {
		return (
			<div className="position-relative">
				{this.state.loader ?
					<span className='loaderStyle'><LoadingSpinner /></span>
					: null
				}

				<Container className='py-4'>
					<h3>Account Deletion Instructions</h3>
					<div className="privacyContent">

						<p>You know us as The Delivery Guys, but our legal name is Future Logistics Solutions Limited (we, our, us, TDG or The Delivery Guys) we are committed to protecting the privacy of all the users of our website(s) The Delivery Guys.ie/co.uk, or mobile applications (together, the Site). Please read the following privacy policy that explains how we use and protect your information. We are the controller of the information we process, unless otherwise stated.
						</p>

						<ol className='list'>
							<li>Users will be able to delete their accounts from the app & from the web panel. There is an option in the profile page to delete the account. Chose delete my account option and hit the confirmation dialog.</li>
							<li>Once you delete the account the users are notified via an email confirming their claim.</li>
							<li>You will have 30 days to cancel your account deletion, after this you won’t be able to access your account or retrieve any information from it. To cancel you just login with your credentials again.</li>
							<li>The system will store the order information for the accounting purpose even after deletion of the account.</li>
						</ol>



					</div>
				</Container>


			</div>
		);
	}
}



const mapStateToProps = (state) => {
	return {
		cartItem: state.cart.cartItem,
		coupon: state.cart.coupon,
		deliveryInstructions: state.cart.deliveryInstructions,
		driverTip: state.cart.driverTip,
		grandTotal: state.cart.grandTotal,
		token: state.auth.token,
		user: state.user,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		handleCart: (data) => dispatch(handleCart(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountDeletion);
