import React from 'react';
import './AppLoader.scss';



const AppLoader = () => (

    <div className="loaderapp">Loading...</div>

);

export default AppLoader;
