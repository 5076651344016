/* eslint-disable no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable no-irregular-whitespace */
/* eslint-disable no-mixed-spaces-and-tabs */

import React, { Component } from 'react';
import { Container } from 'react-bootstrap';

import LoadingSpinner from '../../../Components/LoadingSpinner/LoadingSpinner';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { handleCart } from '../../../redux/actions/cart';
import { SERVERFOLDER } from '../../../Constants';
import PropTypes from 'prop-types';




class PrivacyPolicy extends Component {
    state = {
        errorMsg: '',
        loader: false,
        user_type_id: null,
        succesSubmitModal: false,
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        let appParam = this.props.match.params.page;
        if (appParam === 'app') {
            document.getElementById('header').classList.add('app-page');
        }

    }

    render() {
        return (
            <div className="position-relative">
				{this.state.loader ?
					<span className='loaderStyle'><LoadingSpinner /></span>
					: null
				}

				<Container className='py-4'>
					<h3>Privacy Policy</h3>
					<div className="privacyContent">

						

					<h4>Our Privacy Policy</h4>
	<p>You know us as The Delivery Guys, but our legal name is Future Logistics Solutions Limited (&quot;we&quot;,
		&quot;our&quot;, &quot;us&quot;
		&quot;TDG&quot; or &quot;The Delivery Guys&quot;). we are committed to protecting the privacy of all the
		visitors of our website(s)
		tdg.ie, thedeliveryguys.ie/co.uk, or mobile applications (together, the &quot;Site&quot;). Please read the
		following
		privacy policy that explains how we use and protect your personal data.Unless otherwise stated, the data
		controller for processing your personal data is The Delivery Guys.</p>
	<p><strong>1) Contact Details</strong><br />
		If you have any queries or requests concerning this privacy policy or how we handle your data more generally,
		you can get in touch with us using the following details.
	</p>
	<ul>
		<li>By contacting our general customer services team at: support@thedeliveryguys.ie</li>
	</ul>
	<p><strong>2) How We Collect Your Personal Data</strong><br />
		Your personal data is collected when you interact with us via the mobile app, or through one of the restaurants
		that we represent that use our distribution services, or when you use our site to place an order. Your personal
		data is collected through the following ways:
	</p>
	<ol>
		<li>When you create an account with us or you change your account settings;</li>
		<li>When you place an order with us and during the order process, including payment and order delivery details.
		</li>
		<li>When you give us or one of our delivery partners your consent to contact you via email, phone, post,
			message, push notification via our application, to send you marketing campaigns, tell you about The Delivery
			Guys initiatives, or to invite you to participate in surveys about our services, or our restaurant
			partner&apos;s
			services, or to help us find you with your order.</li>
		<li>When you contact us directly via email, phone, post, message or through social media.</li>
		<li>When you browse and use our site (before and after you create an account with us).</li>
	</ol>
	<p>We also collect personal data from third party site such as advertising and social media platforms, and our fraud
		detection provider. If you link your social media or your third-party accounts to us, we will keep a record of
		your social media handle, and the other information that is made available to us as per your Social Media
		account settings. If your employer signs up for The Delivery Guys for business, we may receive your contact
		details from your employer to enable us to provide our services to you.</p>

	<p>Where we need to collect information by law, or under the terms of a contract we have with you, and you fail to
		provide that information, we may not be able to perform the contract we have or are trying to enter into with
		you (for example, to provide you with our services). In this case, we may have to cancel our service to you but
		we will notify you if this is the case at the time.</p>

	<p><strong>3) What Personal Data We Collect and Why</strong><br />
		As part of our commitment to the privacy of our customers and visitors to our site, we want to be clear about
		the sorts of personal data we will collect from you when you interact with our services. We will only process
		the data we collect about you if there is a reason for doing so, and if that reason is permitted under current
		data protection laws we&apos;ll upload the collected data to TDG backend data server. We may process your
		personal
		data for the following reasons:
	</p>

	<p><strong>3.1) Supply of Service</strong><br />
		When you have created an account or placed an order on our site, we will use the personal data you provide to
		supply the
		services you have requested. The legal basis for this processing of personal data is your consent, you may
		withdraw your
		consent by reaching out to contact details provided above. we collect the following data:</p>

	<ul>
		<li>First name</li>
		<li>Last Name</li>
		<li>Profile Picture (optional)</li>
		<li>date of birth</li>
		<li>phone number</li>
		<li>email address</li>
		<li>gender</li>
		<li>delivery address</li>
		<li>order details</li>
		<li>credit or debit card information</li>
		<li>We may process health information about you only where you volunteer and consent to this, for example if you
			report any specific food allergies after placing an order.</li>
	</ul>

	<p><strong>3.2) Cookies</strong><br />
		TDG uses cookies to gather data for functional, analytical, and marketing purposes. Data collected for cookies
		depend on the preferences which you can set. You can decide to refuse all or some browser cookies, or to alert
		you when website set or access cookies. If you disable or refuse cookies, please note that some parts of the
		Site may become inaccessible or not function properly.</p>

	<p><strong>3.3) Analysis</strong><br />
		TDG uses your personal data to be able to improve our website and our range of products and services, for
		example personalization of our service. This may include processing data to make it easier and faster for you to
		place orders. We collect your personal data for these reasons below:</p>

	<ul>
		<li>To improve the effectiveness and quality of service that our customers can expect from us in the future;
		</li>
		<li>To tailor content that we or our partners display to you, for example so that we can show you partners which
			are in your area or make sure you see the advertising which is most relevant to you, based on
			characteristics determined by us;</li>
		<li>To enable our customer support team to help you with any enquiries or complaints in the most efficient way
			possible and to provide a positive customer experience;</li>
		<li>To contact you for your views and feedback on our services or our partners&apos; services and/or products
			and to
			notify you if there are any important changes or developments to the Site or our services, including letting
			you know that our services are operating in a new area, where you have asked us to do so;</li>
		<li> To send you information by post about our products, services, promotions and The Delivery Guys initiatives
			(if you do not want to receive these, you can let us know by getting in touch (see Contact Details)</li>
		<li>To analyze your activity on the Site so that we can administer, support, improve and develop our business
			and for statistical and analytical purposes and to help us to prevent fraud and;</li>
		<li>To detect, investigate, report and seek to prevent fraud or crime.</li>
	</ul>

	<p>We also process your data to enforce our contractual terms with you and any other agreement, and for the exercise
		or defense of legal claims and to protect the rights of The Delivery Guys, our partners, delivery persons, or
		others (including to prevent fraud).</p>

	<p>We collect information from your mobile device or computer, such as its operating system, the device and
		connection type and the IP address from which you are accessing our Sites. We also collect technical information
		about your use of our services through a mobile device, for example, carrier, location data and performance data
		such as mobile payment methods, interaction with other retail technology such as use of NFC Tags, QR Codes
		and/or use of mobile vouchers. Unless you have elected to remain anonymous through your device and/or platform
		settings, this information may be collected and used by us automatically if you use the service through your
		mobile device(s) via TDG mobile application, through your mobile&apos;s browser or otherwise.</p>

	<p>If you submit comments and feedback regarding the site and the services, we may use such comments and feedback on
		the Site and in any marketing or advertising materials. We will only identify you for this purpose by your first
		name and the city in which you live such comments and feedback may be shared with our partners to assess and
		improve their services.</p>

	<p>We will also analyze data about your use of our services from your location data to create profiles relating to
		you and for you. This means that we may make certain assumptions about what you may be interested in and use
		this, for example, to send you more tailored marketing communications, to present you with partners that we
		think you will prefer, or to let you know about special offers or products which we think you may be interested
		in (including The Delivery Guys for Business). This activity is referred to as profiling. You have certain
		rights in relation to this type of processing. Please see the Your Rights section below for more information.
	</p>

	<p><strong>4) Age</strong><br />
		Our App is neither intended for persons aged under 18, and nor do we intend to collect personal data of website
		visitors who are aged under 18. However, we are unable to verify visitors&apos; age. We therefore advise parents
		to
		monitor their children&apos;s online activities, to prevent their personal data being collected without parental
		consent.
	</p>

	<p><strong>5) How long we store personal data for</strong><br />
		We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for,
		including for the purposes of satisfying any legal, tax, accounting, or reporting requirements. To determine the
		appropriate retention period for personal data, we take into account the following factors:
	</p>

	<ul>
		<li>our contractual obligations and rights in relation to the information involved;</li>
		<li>legal obligation(s) under applicable law to retain information for a certain period of time;</li>
		<li>statute of limitations under applicable law(s);</li>
		<li>our legitimate interests for retaining the information (please see section 3. What personal data we collect
			and why);</li>
		<li>whether there is an actual or potential dispute; and</li>
		<li>guidelines issued by relevant data protection authorities.</li>
	</ul>

	<p>In some circumstances we may anonymize your personal data so that it can no longer be associated with you, in
		which case we may use such information without further notice to you.</p>

	<p>If you have any questions on the way we retain your personal data you can contact us via our contact details
		above</p>

	<p><strong>6) Information We Collect Through Automatic Data Collection Technologies</strong><br />
		TDG is collecting information and uploading the data to its internal server for the purpose of internal service
		and reporting. The personal data that is collected from the users includes:
	</p>

	<ul>
		<li>Usage information: Details of your use of our Services, including traffic data, location data, logs and
			other communication data and the resources that you access and use on or through our Services.</li>
		<li>Computer and device information: Information about your computer, Internet connection and mobile device,
			including your IP address, operating systems, platforms, browser type, other browsing information
			(connection, speed, connection type etc.), device type, device&apos;s unique device identifier, mobile
			network
			information and the device&apos;s telephone number.</li>
		<li>Stored information and files: Our applications also may access metadata and other information associated
			with other files stored on your mobile device. This may include, for example, photographs, audio and video
			clips, personal contacts and address book information.</li>
		<li>Mobile device IDs: Unique mobile device identifier (e.g. IDFA or other device IDs on Apple devices like the
			iPhone and iPad), if you&apos;re using our Services on a mobile device, we may use mobile device IDs (the
			unique
			identifier assigned to a device by the manufacturer), instead of cookies, to recognize you. We may do this
			to store your preferences and track your use of our applications. Unlike cookies, mobile device IDs cannot
			be deleted. Advertising companies may use device IDs to track your use of our applications track the number
			of advertisements displayed, measure advertising performance and display advertisements that are more
			relevant to you Analytics companies may use mobile device IDs to track your usage of our applications.</li>
		<li>Mobile Device ID would be collected for the purpose of managing the Crash related data to understand why the
			crash appeared and for the purpose of further development to ensure these type of event does not occur
			again. This is a formal bug report managed with Google Firebase analytics &amp; Crashlytics.</li>
	</ul>

	<p><strong>7) Sharing your information</strong><br />
		The information we collect about you will be transferred to and stored on our servers located within the EU. We
		are very careful and transparent about who else your information is shared with globally.
	</p>

	<p>We share your information within the TDG group companies only where necessary for the purposes set out in the Use
		of Your Information section above. We share your information with our partners (such as restaurants, grocers,
		and other retail partners on The Delivery Guys), who have access to limited information to enable them to fulfil
		an order. Where relevant and you give your consent, we may share the health information you volunteer to us with
		partners to enable them to investigate and respond to complaints.</p>

	<p>We share your information with third party service providers that provide services on our behalf. The types of
		third party service providers whom we share your information with include for example:</p>

	<ul>
		<li>TDG Affiliates, subsidiaries, and/or group companies of TDG</li>
		<li>Payment providers (including online payment providers and fraud detection providers);</li>
		<li>IT service providers (including cloud providers);</li>
		<li>Riders, who have access to limited information to enable them to fulfil an order;</li>
		<li>Customer support providers (including, but not limited to, companies that assist us to provide customer or
			technical support); and</li>
		<li>Marketing and advertising partners.</li>
		<li>if we are under a duty to disclose or share your information in order to comply with Law enforcement,
			regulatory bodies and/or government agencies</li>
		<li>Prospective buyers</li>
		<li>Any other third party provided that you have given your consent to the disclosure</li>
	</ul>

	<p>We share your information when we promote a program or offer a service or product in conjunction with a
		third-party business partner. We will share your information with that partner to assist in marketing or to
		provide the associated product or service. In most of those cases, the program or offer will include the name of
		the third-party business partner, either alone or with ours. An example of such a business partner relationship
		would be a partner that we partner with for providing delivery services.</p>

	<p>If you submit comments and feedback regarding the Sites, services, and our partners we may share such comments
		and feedback with our partners. In addition, and if you consent to it, we may share health information about you
		with our partners, for example if you report any specific food allergies after placing an order.</p>

	<p>TDG will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with
		this privacy policy and applicable laws when it is transferred to third parties.</p>

	<p>If our business enters into a joint venture with, purchases or is sold to or merged with another business entity,
		your information may be disclosed or transferred to the target company, our new business partners or owners or
		their advisors.</p>

	<p>In some cases, the information we collect from you might be processed outside the European Economic Area or the
		United Kingdom, such as the United States and the countries in which TDG operates. These countries may not have
		the same protections for your information as the EEA or the UK has. To the extent these countries have not been
		lawfully recognized as providing an adequate level of data protection, we will ensure that the information that
		is processed by us and our suppliers outside of the EEA or the UK is protected in the same way as it would be if
		it was processed within the EEA or the UK. We ensure to use an appropriate data transfer mechanism, such as
		reliance on the protections set out in approved standard contractual clauses.</p>

	<p>We ensure a similar degree of protection is afforded to it by ensuring at least one of the following safeguards
		is implemented:</p>

	<ul>
		<li>Your personal data is transferred to countries that have been deemed to provide an adequate level of
			protection for personal data by the Secretary of State; and</li>
		<li>We use any Secretary of State approved data transfer mechanisms,</li>
	</ul>

	<p>Please contact us using the contact details above for further information on the specific mechanism used by us
		when transferring your information.</p>

	<p><strong>8) Marketing</strong><br />
		Where you have given your consent or where we have a legitimate interest for doing so (and are permitted to do
		so by law) we will use your information to let you know about our other products and services, or The Delivery
		Guys initiatives that may be of interest to you and we may contact you to do so by email, post, phone, or push
		notification.</p>

	<p>We use online advertising to keep you aware of what we&apos;re up to and to help you see and find our products.
		You
		may see The Delivery Guys banners and ads when you are on other website and apps, such as social media. We
		manage this through a variety of digital marketing networks. We also use a range of advertising technologies.
		The banners and ads you see are based on information we hold about you, or your previous use of The Delivery
		Guys (for example, you&apos;re The Delivery Guys order history) or on The Delivery Guys banners or ads you have
		previously clicked on. We use your customer&apos;s profile to send you communications that are the most relevant
		to
		you. You have certain rights in relation to this type of processing. Please see the Your Rights section below
		for more information.</p>

	<p>Where you have chosen at a device level to begin or continue receiving push notifications from us, we may send
		you push notifications relating to the services that you have requested from us and information about our
		services, offers and The Delivery Guys initiatives. You can choose to stop receiving push notifications from us
		at any time by changing your preferences on your mobile device.</p>

	<p>We may still contact you through email where you have opted out of direct marketing with service communications,
		including, but not limited to, correspondence providing information about service interruption and delivery
		safety.</p>

	<p><strong>9) Automated Decision Making.</strong><br />
		We undertake fraud checks on all customers because this is necessary for us to perform our contracted services
		to customers, by ensuring that the services we (and all our partners) provide are duly paid for, and also so
		that individuals themselves are protected from fraudulent transactions on their cards.</p>

	<p>Given the volumes of customers and orders we deal with, we use automated systems including a third party fraud
		detection provider from Stripe (our card services provider), which analyses your order data in order to make
		automated decisions as to whether or not we will accept an order. We find this is a fairer, more accurate and
		more efficient way of conducting fraud checks since human checks would simply not be possible in the timeframes
		and given the volumes of customers that we deal with.</p>

	<p>The checks and decisions that are made look at various components including known industry indicators of fraud
		which our expert fraud detection provider makes available to us, as well as fraud patterns we have detected on
		our Site. When combined, these generate an automated score indicating the likelihood of a fraudulent
		transaction. If our systems indicate a high score for you, then we may decline an order or even block you from
		our services. The specific fraud indicators are dynamic so will change depending on what types of fraud are
		being detected in the wider world, country and our site at any particular time.</p>

	<p>You have certain rights in respect of this activity - please see the Your Rights section below for more
		information. Our fraud detection is in place to protect all of our customers as well as The Delivery Guys. You
		have the right to contest any fraud decision made about you and to be given more information about why any such
		decision was made by contacting us using the Contact Details above.</p>

	<p><strong>10) Security</strong><br />
		TDG takes personal data protection very seriously and we therefore take appropriate measures to protect your
		personal data against misuse, loss, unauthorized access, unwanted disclosure, and unauthorized alteration.</p>

	<p>We adopt robust technologies and policies to protect your information from being accidentally lost, used or
		accessed in an unauthorized way, altered or disclosed.</p>

	<p>We have implemented procedures to deal with any data breach and will notify you and any applicable regulator of a
		breach where we are legally required to do so.</p>

	<p>Unfortunately, the transmission of information via the internet is not completely secure. Although we will take
		steps to protect your information, we cannot guarantee the security of your information transmitted to the
		Sites; any transmission is at your own risk. Once we have received your information, we will use strict
		procedures and security features to try to prevent unauthorized access.</p>

	<p>When you open an account you may create a password, or other secure login method and also provide payment card
		details. You must use a unique password and keep any password you create, or other secure login method, secret
		in order to help prevent others from accessing your account.</p>

	<p><strong>11) Your Rights</strong><br />
		Subject to applicable law, you may have a number of rights concerning the data we hold about you. If you wish to
		exercise any of these rights, please contact our Data Protection Officer using the Contact Details set out
		above.</p>

	<p><strong>The right to be informed.</strong> You have the right to be provided with clear, transparent and easily
		understandable information about how we use your information and your rights. This is why we’re providing you
		with the information in this policy.</p>

	<p><strong>The right of access.</strong> You have the right to obtain access to your information (if we’re
		processing it). This will enable you, for example, to check that we&apos;re using your information in accordance
		with
		data protection law. If you wish to access the information we hold about you in this way, please get in touch.
	</p>

	<p><strong>The right to rectification.</strong> You are entitled to have your information corrected if it is
		inaccurate or incomplete. You can request that we rectify any errors in information that we hold by contacting
		us.</p>

	<p><strong>The right to erasure.</strong> This is also known as &apos;the right to be forgotten &apos; and, in
		simple terms,
		enables you to request the deletion or removal of certain of the information that we hold about you by
		contacting us. This right is not absolute and only applies in certain circumstances.</p>

	<p><strong>The right to restrict processing.</strong> You have rights to &apos;block&apos; or &apos;suppress&apos;
		further use of your
		information in certaincircumstances. When processing is restricted, we can still store your information, but
		will not use it further.
	</p>

	<p><strong>The right to data portability.</strong> You have the right to obtain your personal information in an
		accessible and transferrable format so that you can re-use it for your own purposes across different service
		providers. This is not an absolute right however and there are exceptions. To learn more please get in touch.
	</p>

	<p><strong>The right to lodge a complaint.</strong> You have the right to lodge a complaint about the way we handle
		or process your information with a competent data protection authority.</p>

	<p><strong>The right to withdraw consent.</strong> If you have given your consent to anything we do with your
		information (i.e. if we rely on consent as a legal basis for processing certain information), you have the right
		to withdraw that consent at any time. You can do this by contacting us. Withdrawing consent will not, however,
		make unlawful our use of your information before you withdraw consent.</p>

	<p><strong>The right to object to processing.</strong> You have the right to object to certain types of processing,
		including processing for direct marketing and profiling. You can object by changing your marketing preferences,
		disabling cookies as set out in the Cookies and Marketing sections above or by getting in touch. You also have
		the right not to be subject to a decision based solely on automated processing, such as in connection with our
		fraud checks. You can exercise this right by contesting the fraud decision made against you (see Automated
		Decision Making), or by getting in touch.</p>

	<p><strong>12) Your location</strong><br />
		When you have provided permission at a device level, we may process location data obtained from your use of the
		Apps and via GPS, to clearly show restaurants and grocery outlets available to you to choose from, and to also
		calculate an accurate delivery cost to your location. This information also enables our delivery drivers to find
		your location without disturbing or contacting you further.</p>

	<p><strong>13) Information Used</strong></p>

	<ol>
		<li>Registration information when you create a TDG account so we can:
			<ul type="disc">
				<li>create your account so you can place Orders</li>
				<li>identify you when you sign-in to your account;</li>
				<li>contact you for your views on our Services; and</li>
				<li>notify you of changes or updates to our Services.</li>
			</ul>
		</li>
		<li>
			Transaction Information when you place an Order with a Restaurant in order to:
			<ul type="disc">
				<li>process your Order and to bill you;</li>
				<li>communicate your Order to the Restaurant;</li>
				<li>send you status updates on your Order;</li>
				<li>reply to your Order queries and questions and to resolve problems;</li>
				<li>carry out analysis and research to develop and improve our Services; and</li>
				<li>protect you and the Services by seeking to detect and prevent fraud or other acts in breach of our
					Terms or policies relevant to the Services.</li>
			</ul>
		</li>
		<li>Feedback on your views of our Services in order to:
			<ul type="disc">
				<li>reply to your questions or queries;</li>
				<li>publish reviews you submit to us about our Restaurants and services; and</li>
				<li>conduct analysis and research to improve and develop our Services.</li>
			</ul>
		</li>
		<li>Information that we collect automatically
			<ul type="disc">
				<li>provide you with an improved experience;</li>
				<li>give you access to your Order history and preferences; and</li>
				<li>provide other services at your request.</li>
			</ul>
		</li>
		<li>
			Cookies and similar technologies so we can:
			<ul type="disc">
				<li>measure and analyze the use and effectiveness of our Services;</li>
				<li>customize and optimize the targeting of advertising for our Services across other websites and
					platforms; and</li>
				<li>provide location services if you choose to share your geo-location.</li>
			</ul>
		</li>
	</ol>

	<p style="padding: 0; margin:0"><strong>14) Account Deletion Instructions</strong></p>
	<ul>
		<li>Users will be able to delete their accounts from the app &amp; from the web panel. There is an option in the
			profile page to delete the account. Chose delete my account option and hit the confirmation dialog.</li>
		<li>Once you delete the account the users are notified via an email confirming their claim.</li>
		<li>You will have 30 days to cancel your account deletion, after this you won&apos;t be able to access your
			account
			or retrieve any information from it. To cancel you just login with your credentials again.</li>
		<li>The system will store the order information for the accounting purpose even after deletion of the account.
		</li>
	</ul>

	<p><strong>15) Changes to Our Privacy Policy</strong><br />
		We update this Privacy Policy from time to time, so remember to check back in, in case anything has changed and,
		where appropriate, we may notify you of the changes for example by email or push notification.
	</p>









						
					</div>
				</Container>


			</div>
        );
    }
}


PrivacyPolicy.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            page: PropTypes.string
        })
    })
};


const mapStateToProps = (state) => {
    return {
        cartItem: state.cart.cartItem,
        coupon: state.cart.coupon,
        deliveryInstructions: state.cart.deliveryInstructions,
        driverTip: state.cart.driverTip,
        grandTotal: state.cart.grandTotal,
        token: state.auth.token,
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handleCart: (data) => dispatch(handleCart(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);
