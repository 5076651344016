import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const FooterWelcome = () => {
    return (
        <div className="regCopyOuter d-flex justify-content-center align-items-center">
            <Container>
                <Row>
                    <Col md={12}>
                        <p className="copy">
                            &copy; TDG {(new Date().getFullYear())} All Rights Reserved
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default FooterWelcome;
