import React, { Component } from 'react';

import { GoSearch, GoStar } from 'react-icons/go';

class Search extends Component {

	constructor() {
		super();
		this.state = {
			show: false,
		};
	}



	render() {
		return (
			<div>
				<div className="bannerPan innerBanner">
					<div className="container">
						<h3>Your favourite food delivered to your door!</h3>
						<div className="searchPan">
							<div className="searchDrop">
								<select>
									<option>Select</option>
								</select>
							</div>
							<div className="searchTxtbox">
								<input type="text" placeholder="Search for Restaurant, Cuisine or Dish" className="form-control" />
								<button type="button" className="searchBtn"><GoSearch /></button>
							</div>
						</div>
					</div>
				</div>

				<div className="bodyPan">
					<div className="container">
						<div className="searchPan">
							<h3>Dishes</h3>
							<ul className="searchList">
								<li>
									<div className="nearCard card searchCard">
										<div className="searchTitle">
											Cheese Pizza
											<em>simply dummy text of the printing and typesetting</em>
										</div>
										<span className="price">€10</span>
									</div>
								</li>
								<li>
									<div className="nearCard card searchCard">
										<div className="searchTitle">
											Chicken- Cheese Pizza
											<em>simply dummy text of the printing and typesetting</em>
										</div>
										<span className="price">€12</span>
									</div>
								</li>
								<li>
									<div className="nearCard card searchCard">
										<div className="searchTitle">
											Cheese Sandwich
											<em>simply dummy text of the printing and typesetting</em>
										</div>
										<span className="price">€12</span>
									</div>
								</li>
							</ul>
						</div>

						<div className="searchPan">
							<h3>Restaurants</h3>
							<ul className="searchList">
								<li>
									<div className="nearCard card searchCard">
										<div className="searchTitle">
											Fast Pizza Café
											<em>Westwood Street</em>
										</div>
										<span className="price"><GoStar className="star" /> 5</span>
									</div>
								</li>
								<li>
									<div className="nearCard card searchCard">
										<div className="searchTitle">
											Smith Pizza House
											<em>Westwood Street</em>
										</div>
										<span className="price"><GoStar className="star" /> 4</span>
									</div>
								</li>
								<li>
									<div className="nearCard card searchCard">
										<div className="searchTitle">
											Pizzas Corner
											<em>Westwood Street</em>
										</div>
										<span className="price"><GoStar className="star" /> 3</span>
									</div>
								</li>
							</ul>
						</div>

						<div className="searchPan">
							<h3>Grocery</h3>
							<ul className="searchList">
								<li>
									<div className="nearCard card searchCard">
										<div className="searchTitle">
											Tesco Super Store
											<em>Westwood Street</em>
										</div>
										<span className="price"><GoStar className="star" /> 5</span>
									</div>
								</li>
							</ul>
						</div>

					</div>
				</div>



			</div>
		);
	}
}

export default Search;