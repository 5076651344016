import React from 'react';
import PropTypes from 'prop-types';
import { SERVERFOLDER } from '../Constants';
import { Route, Redirect } from 'react-router-dom';

// eslint-disable-next-line react/prop-types
export const PrivateRoute = ({ component: Component, token: authToken, ...rest }) => (
    <Route {...rest} render={props => (
        authToken
            ? <Component {...props} />
            : <Redirect to={{ pathname: SERVERFOLDER + '/login', state: { from: props.location } }} />
    )} />
);


PrivateRoute.propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
};
