/* eslint-disable no-unused-vars */
/* eslint-disable no-unescaped-entities */
/* eslint-disable no-irregular-whitespace */
/* eslint-disable no-mixed-spaces-and-tabs */


//import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Container } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { handleCart } from '../../../redux/actions/cart';
import { SERVERFOLDER } from '../../../Constants';
import PropTypes from 'prop-types';



class TermsOfService extends Component {
	state = {
		errorMsg: '',
		loader: false,
		user_type_id: null,
		succesSubmitModal: false,
	}

	componentDidMount() {
		window.scrollTo(0, 0);

		let appParam = this.props.match.params.page;
		//console.log('appParam',appParam);
		if (appParam === 'app') {
			document.getElementById('header').classList.add('app-page');
		}

	}


	render() {
		return (
			<div className='position-relative'>
				<Container className='py-4'>
					<h3>The Delivery Guys - Terms of Service</h3>
					<div className='privacyContent'>

						<p>Welcome to The Delivery Guys. This page (together with the documents referred to in it) tells you the terms (the &quot;Terms&quot;) which apply when you order any menu items (the &quot;Items&quot;) from our thedeliveryguys.ie website or mobile applications and related services (each referred to as an &quot;Application&quot;).</p>

						<p>Please read these Terms carefully before ordering any Items from our Application. If you have any questions relating to these Terms please contact <a href="mailto:info@thedeliveryguys.ie">info@thedeliveryguys.ie</a> before you place an order.  If you are a consumer, you have certain legal rights when you order Items using our Application. You can find more information about these rights at: <a href="https://www.citizensinformation.ie/en/consumer_affairs/consumer_protection/consumer_rights/">https://www.citizensinformation.ie/en/consumer_affairs/consumer_protection/consumer_rights/</a> and from the Competition and Consumer Protection Commission (CCPC), <a href="https://www.ccpc.ie">https://www.ccpc.ie.</a> Your legal rights are not affected by these Terms, which apply in addition to them and do not replace them.  By setting up your The Delivery Guys account, you confirm that you accept these Terms and the use of your phone number and location to ensure that we get your delivery to you ASAP.</p>

						<h5>1. Information About Us</h5>
						<p>Future Logistics Solutions Limited t/a The Delivery Guys is a company incorporated and registered in Ireland, with a registered office at Ennis, Co Clare.  Our Company registration number is 684931. Our VAT number is IE 3726697VH. You may contact us at <a href="mailto:info@thedeliveryguys.ie">info@thedeliveryguys.ie</a> or by using any of our social media channels. </p>

						<h5>2. Purpose</h5>
						<p>Our objective is to link you to the restaurants we partner with (<strong>&quot;Partner Restaurants&quot;</strong>) and allow you to order Items for delivery (our <strong>&quot;Service&quot;</strong>). Where you order from a Partner Restaurant, The Delivery Guys act as an agent on behalf of that Partner Restaurant to conclude your order from our Application and to manage your experience throughout the order process. Once you have placed an order, delivery of your Items will be arranged by The Delivery Guys (<strong>&quot;The Delivery Guys Delivery&quot;</strong>) or our Partner Restaurant (<strong>&quot;Partner Delivery&quot;</strong>) (each a <strong>&quot;Delivery&quot;</strong>) depending on the Partner Restaurant you have selected. In some cases, the Partner Restaurant may also employ their own drivers, and in that case we have no association or control of any order or delivery through that channel. </p>

						<h5>3. Your Account</h5>
						<p>Before you can place orders for Items using our Application, you need to open a The Delivery Guys account. When you open an account you must create a password, or other secure login method, and may also have to provide credit card details. You must keep any password you create, or other secure login method, secret, and prevent others from accessing your email account or mobile phone. If another person uses these methods to access your account, you will be responsible to pay for any Items they order, and we are not responsible for any other losses you suffer, unless the person using your password obtained it because we did not keep it secure.</p>

						<p>You may close your account at any time by requesting to do so in your account section of our website or contacting us using the contact details above. We may suspend your access to your account, or close it permanently, if we believe that your account has been used by someone else. We may also close your account if in our opinion you are abusing our Service (for example, by applying for refunds or credit to which we do not consider you are entitled, making repeated unreasonable complaints, mistreating our staff or riders, or any other good reason). If we close your account permanently we will refund any remaining account credit you have validly obtained from our customer service team or Application following any issue with an order, by applying a credit to your registered credit card, or if that is not possible for any reason, by way of a bank transfer using bank details (provided you have supplied them to us).</p>

						<h5>4. Service Availability</h5>
						<p>Each Partner Restaurant has a prescribed delivery area. This delivery area may change at any time due to factors such as weather, or demand on our service. This is to ensure that Items reach your door at their best. Our Partner Restaurants each decide their own operating hours and preparation time. That means that the availability of our Service, and the range of Partner Restaurants from which you can order, depends on the Partner Restaurants in your area. If you try to order a delivery to a location outside the delivery area or operating hours of a Partner Restaurant, or the Application is otherwise unavailable for any reason, we will notify you that ordering will not be possible.</p>

						<h5>5. Orders</h5>
						<p>When you place an order through our Application, it needs to be accepted by us or the Partner Restaurant before it is confirmed. We will send you a notification if your order has been accepted (the <strong>&quot;Confirmation Notice&quot;</strong>). The contract for the supply of any Item you have ordered comes into existence when we send the Confirmation Notice. You are responsible for paying for all Items ordered using your account, and for related delivery charges, and for complying with these Terms, even if you have ordered the Item for someone else. Some Partner Restaurants operate a minimum order value policy. This will be displayed on our Application. All Items are subject to availability.  Partner Restaurants may use nuts or other allergens in the preparation of certain Items. Please contact our Partner Restaurant prior to ordering if you have an allergy. The Delivery Guys cannot guarantee that any of the Items sold by our Partner Restaurants are free of allergens.</p>

						<h5>6. Delivery</h5>
						<p>When you place an order you will have the choice to place it as an ASAP Delivery or a Scheduled Delivery. For an ASAP Delivery, we will tell you an estimated delivery time for your Item, <strong>but we will attempt delivery as soon as possible</strong>; you must therefore be available to accept delivery from the time you place the order. For a Scheduled Delivery, we will tell you the time when the Item is expected to be delivered; you must be available to accept delivery for fifteen minutes before and thirty minutes after that time.</p>

						<p>Unfortunately, despite our, and our Partner Restaurant’s best efforts, things do not always go to plan and factors such as traffic and weather conditions may prevent us from delivering your Item on time. If your order is more than 15 minutes late, and we haven’t notified you giving you the option to cancel your order, we will work with you to make things right unless you have caused the delay (e.g. because you gave us the wrong address or did not come to the door).</p>

						<p>We will attempt delivery at the address you provide to us when you place your order. If you need to change the delivery location after you have placed your order, we may be able to change to the address to an alternative one that is registered with your account if you let us or the restaurant know before the rider has been dispatched, and the new address is within the same zone as the address you originally ordered your Item to. If we cannot change the delivery address, you have the option to cancel the order, but if food preparation has started you will be charged the full price for the Item, and if the rider has been despatched you will also be charged for delivery.</p>

						<p>You will still be charged for the Item and for delivery in the event of a failed delivery if you have caused such failure for any reason. Reasons you might cause a delivery to fail include (but are not limited to):  </p>

						<ul className='list'>
							<li>You do not come to the door, did not pick up the phone when the rider contacted you using the contact information you have provided us and/or you picked up the phone but then failed to provide access within a reasonable amount of time, and the rider is unable to find a safe location to leave the food.</li>
							<li>The rider refuses to deliver the Item to you in accordance with section 8 (Age Restricted Products).</li>
							<li>The rider is not permitted into an apartment block, hotel or place of business, meaning that you must meet them at the main entrance, reception or whatever safe place the rider deems appropriate. </li>
						</ul>

						<h5>7. Your Rights if Something is Wrong with Your Items</h5>

						<p>You have a legal right to receive goods which comply with their description, which are of satisfactory in quality and which comply with any specific requirements you tell us about (and we agree to) before you place your order. If you believe that the Items you have been delivered do not comply with these legal rights, please let us know. We may request a photograph showing the problem if it is something that can be seen by inspecting the Items and the return of the order if requested by the restaurant. We will provide a refund or account credit in respect of the affected part of the Item, and also in respect of delivery if the whole order was affected, unless we have reasonable cause to believe that the problem was caused after delivery.</p>

						<p>Prior to processing your refund or account credit, we may take into account relevant factors including the details of the order, including your account history, what happened on delivery and information from the Partner Restaurant.</p>

						<h5>8. Age Restricted Products</h5>
						<p>Age restricted products (including, without limitation, alcohol, tobacco and cigarettes) can only be sold and delivered to persons aged 18 or over. By placing an order for an age restricted product, you confirm that you are at least 18 years old. The Delivery Guys operates an age verification policy whereby customers ordering age restricted products will be asked by the rider to provide proof that they are aged 18 or over and that ID must match the card that paid for the goods.. The rider may refuse to deliver any age restricted product to any person unless they can provide valid photo ID proving that they are aged 18 or over. The Partner Restaurant and the rider may refuse to deliver alcohol to any person who is, or appears to be under the influence of either alcohol or drugs. If delivery of any age restricted product is refused, you will still be charged for the relevant Item and for delivery.</p>

						<h5>9. Cancellation</h5>
						<p>You may cancel an order without charge at any time before the Restaurant Partner has started preparing the food (a <strong>&quot;Started Order&quot;</strong>). If you wish to cancel an order before it becomes a Started Order, please contact us immediately, via our social media channels or directly to the partner restaurant. If the Partner Restaurant confirms the order was not a Started Order, we will refund your payment (excluding any discount, or Voucher that was applied to the order - see Voucher and Account Credit Terms for more detail). If you cancel any order after it becomes a Started Order, you will be charged the full price for the Items, and if the rider has been despatched you will also be charged for delivery.  </p>

						<p>The Delivery Guys and the Partner Restaurant may notify you that an order has been cancelled at any time. You will not be charged for any orders cancelled by us or the Partner Restaurant, and we will reimburse you for any payment already made using the same method you used to pay for your order. We may also apply credit to your account to reflect the inconvenience caused.</p>

						<h5>10. Prices, Payments and Offers</h5>
						<p>Prices include VAT. You confirm that you are using our Service for personal, non-commercial use unless you request a VAT invoice. The Delivery Guys may operate dynamic pricing some of the time, which means that prices of Items and delivery may change while you are browsing. Prices can also change at any time at the discretion of the Partner Restaurants. We reserve the right to charge a Service Fee, which may be subject to change, for the provision of our Services. You will be notified of any applicable Service Fee and taxes prior to purchase on the checkout page on our Application. No changes will affect existing confirmed orders, unless there is an obvious pricing mistake. Nor will changes to prices affect any orders in process and appearing within your basket, provided you complete the order within 2 hours of creating the basket. If you do not conclude the order before the 2 hour cut-off the items will be removed from your basket automatically and the price change will apply. If there is an obvious pricing mistake we will notify you as soon as we can and you will have the choice of confirming the order at the original price or cancelling the order without charge and with a full refund of any money already paid.  Where The Delivery Guys or any Partner Restaurants make a delivery, we or the Partner Restaurant may also charge you a delivery fee. This will be notified to you during the order process before you complete your order.</p>

						<p>The total price of your order will be set out on the checkout page on our Application, including the prices of Items and Delivery and applicable Service Fees and taxes.</p>

						<p>Payment for all Items and deliveries can be made on our Application by credit or debit card, or other payment method made available by The Delivery Guys. Once your order has been confirmed your credit or debit card will be authorised and the total amount marked for payment. Payment is made directly to The Delivery Guys acting as agent on behalf of the Partner Restaurant only. Payment may also be made by using vouchers or account credit. Use of these is subject to The Delivery Guys Voucher and Account Credit Terms.</p>

						<p>We are authorised by our Partner Restaurants to accept payment on their behalf and payment of the price of any Items or delivery charges to us will fulfil your obligation to pay the price to the Partner Restaurant. In some cases, you can alternatively make your payment in cash directly to the Partner Restaurant by paying the rider at the time of delivery.  Where cash payment is possible, this will be made clear on our Application before you place your order.</p>

						<p>Partner Restaurants sometimes make special offers available through our Application. These are visible when you look at a Partner Restaurant menu. These offers are at the discretion of the Partner Restaurants. Unless the offer terms state a fixed or minimum period for which an offer will be available, it can be withdrawn at any time, unless you have already placed an order based on the offer and we have sent the Confirmation Notice.</p>

						<h5>11. Tips</h5>
						<p><strong>When Placing Your Order:</strong></p>

						<p>When you place an order, you will have the option to pay a tip to your rider or the restaurant,  in addition to the purchase price of the Items in your order. Any payment will be collected by The Delivery Guys using the payment method used for the original order and your rider or the restaurant will receive 100% of any payment you choose to make.</p>

						<p><strong>After You Have Received Your Order:</strong></p>
						<p>Once you’ve received your order, you may receive a notification giving you the chance to pay a tip to your rider. The Delivery Guys will collect payment on behalf of the rider, as their limited payment collection agent, and payment of the tips shall be considered a direct payment from you to the rider. We’ll share your first name with your rider when we notify them of the tip. Your rider will receive 100% of any payment you choose to make. As this payment is made after you receive your order, this payment is non-refundable and does not form part of your order. Depending on the payment method used for your original order, your tip may show up on your bank/credit card statement as a separate payment.</p>

						<h5>12. Our Responsibility for Loss and Damage That You Suffer</h5>
						<p>We are responsible to you for any loss or damage that you suffer that is a foreseeable result of our breaking these Terms or of failing to use reasonable care and skill in relation to your use of our Service. We are not responsible for any loss or damage that is not foreseeable. Loss or damage is “foreseeable” if it is either obvious that it will happen, or if you told us that it might happen, for example if you tell us about particular circumstances that might increase the loss or damage arising from our breach of these Terms before you place an order.</p>

						<p>We do not exclude or limit our responsibility to you for loss or damage where it would be unlawful to do so. This includes any responsibility for death or personal injury caused by our failure, or our employees’, agents’ or subcontractors’ failure, to use reasonable care and skill; for fraud or fraudulent misrepresentation; for breach of your legal rights in relation to the Items, as summarised at part 7 above; or for defective Items under the Consumer Protection Act 2007. Subject to the previous paragraph, we are not responsible for any loss or damage that you suffer as a result of your own breach of these Terms, or as a result of any IT hardware or software failure other than a failure in our Applications.  </p>

						<h5>13. Data Protection</h5>
						<p>We process your personal data with great care in accordance with our <a href="/privacy-policy">Privacy Policy</a>.</p>

						<h5>14. Other Terms </h5>
						<p>If either we or you have any right to enforce these Terms against the other, that right will not be lost even if the person who has the right delays enforcing it, or waives their right to enforce it in any instance.  If a court or other authority decides that any part of these Terms is illegal or ineffective, the rest of the terms will be unaffected and will remain in force.</p>

						<p>We may change these Terms from time to time. If we make any changes which affect your rights in relation to our Service, we will notify you. Changes to the Terms will not affect any orders you have placed where we have sent the Confirmation Notice. These Terms are governed by the law of Ireland and you can bring legal proceedings in relation to our Service in the Irish courts. We are required by EU law to provide <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=EN">this link</a> to the EU’s online dispute resolution portal, however we do not participate in dispute resolution under this process.</p>

						<h5>The Delivery Guys Terms of Use For Website And Applications</h5>
						<p>This page (together with the documents referred to on it) tells you the terms of use on which you may make use of our website The Delivery Guys.ie (and all pages attaching to and including this domain name) (our <strong>&quot;Site&quot;</strong>) or any application we make available via an app store or otherwise (our <strong>&quot;Service&quot;</strong>), whether as a guest or a registered user. Please read these terms of use carefully before you start to use or Site or our Service. By accessing our Site or by using our Service, you indicate that you accept these terms of use and that you agree to abide by them. If you do not agree to these terms of use, do not use access our Site or use our Service.</p>

						<h5>1. Information About Us</h5>
						<p>The Delivery Guys.ie, and all pages attaching to and including this domain name, is a website operated by The Delivery Guys Limited (&quot;we&quot;, &quot;us&quot; or &quot;The Delivery Guys&quot;), incorporated and registered in the Ireland, whose registered office is at Cappamore, Barefield, Ennis, Co Clare, Ireland. Our Company registration number is 601081. Our VAT number is IE 3463184DH.  The Delivery Guys is a business where the food is prepared by independent restaurants (our <strong>&quot;Partner Restaurants&quot;</strong>) and delivered by us to you on behalf of our Partner Restaurants.</p>

						<h5>2. Accessing Our Service Or Our Services</h5>
						<p>Access to our Site and to our Service is permitted on a temporary basis, and we reserve the right to withdraw or amend access to our Site or our Service without notice (see below). We will not be liable if, for any reason, our Site or our Service is unavailable at any time or for any period. From time to time, we may restrict access to some parts our Site or our Service, or our entire Site or Service, including to users who have registered with us. You are responsible for maintaining the confidentially of your login details and any activities that occur under your account. If you have any concerns about your login details or think they have been misused, you should contact us straight away to let us know. We can deactivate your account at any time.</p>

						<h5>3. Acceptable Use</h5>
						<p>You may use our Service only for lawful purposes. You may not use our Site or our Service in any way that breaches any applicable local, national or international law or regulation or to send, knowingly receive, upload, download, use or re-use any material which does not comply with our content standards in clause 5 below. You also agree not to access without authority, interfere with, damage or disrupt any part of our Site or our Service or any network or equipment used in the provision of our Service.</p>

						<h5>4. Interactive Features Of Our Site</h5>
						<p>We may from time to time provide certain features which allow you to interact through our Site or our Service such as chat rooms. Generally, we do not moderate any interactive service we provide although we may remove content in contravention of these Terms of Use as set out in section 6. If we do decide to moderate an interactive service, we will make this clear before you use the service and normally provide you with a means of contacting the moderator, should a concern or difficulty arise.</p>

						<h5>5. Content Standards</h5>
						<p>These content standards apply to any and all material which you contribute to our Service (the <strong>&quot;Contributions&quot;</strong>), and to any interactive services associated with it. You must comply with the spirit of the following standards as well as the letter. The standards apply to each part of any Contributions as well as to its whole. Contributions must be accurate (where they state facts), be genuinely held (where they state opinions) and comply with applicable law in the Ireland and in any country from which they are posted. Contributions must not:</p>

						<ul className='list'>
							<li>contain any material which is defamatory of any person, obscene, offensive, hateful or inflammatory, promote sexually explicit material or promote violence or promote discrimination based on race, sex, religion, nationality, disability, sexual orientation or age;</li>
							<li>infringe any copyright, database right or trademark of any other person;</li>
							<li>be likely to deceive any person or be made in breach of any legal duty owed to a third party, such as a contractual duty or a duty of confidence or promote any illegal activity;</li>
							<li>be threatening, abuse or invade another’s privacy, or cause annoyance, inconvenience or needless anxiety or be likely to harass, upset, embarrass, alarm or annoy any other person;</li>
							<li>be used to impersonate any person, or to misrepresent your identity or affiliation with any person or give the impression that they emanate from us, if this is not the case; or</li>
							<li>advocate, promote or assist any unlawful act such as (by way of example only) copyright infringement or computer misuse.</li>
						</ul>

						<h5>6. Suspension and Termination</h5>
						<p>Failure to comply with section 3 (Acceptable Use) and/or 5 (Content Standards) in these Terms of Use constitutes a material breach of the Terms of Use, and may result in our taking all or any of the following actions:</p>

						<ul className='list'>
							<li>immediate, temporary or permanent withdrawal of your right to use our Service;</li>
							<li>immediate, temporary or permanent removal of any posting or material uploaded by you to our Service;</li>
							<li>issuing of a warning to you;</li>
							<li>legal action against you including proceedings for reimbursement of all costs on an (including, but not limited to, reasonable administrative and legal costs) resulting from the breach;</li>
							<li>disclosure of such information to law enforcement authorities as we reasonably feel is necessary.</li>
						</ul>

						<p>The responses described in this clause are not limited, and we may take any other action we reasonably deem appropriate.</p>

						<h5>7. Intellectual Property Rights</h5>
						<p>We are the owner of or the licensee of all intellectual property rights in our Site and our Service, and in the material published on it (excluding your Contributions). Those works are protected by copyright laws and treaties around the world. All such rights are reserved. You may not copy, reproduce, republish, download, post, broadcast, transmit, make available to the public, or otherwise use any content on our site in any way except for your own personal, non-commercial use.</p>

						<h5>8. Reliance On Information Posted</h5>
						<p>Commentary and other materials posted on our Service are not intended to amount to advice on which reliance should be placed. We therefore disclaim all liability and responsibility arising from any reliance placed on such materials by any visitor to our Service, or by anyone who may be informed of any of its contents.</p>

						<h5>9. Our Site And Our Service Change Regularly</h5>
						<p>We aim to update our Site and our Service regularly, and may change the content at any time. If the need arises, we may suspend access to our Site and our Service, or close them indefinitely. Any of the material on our Site or our Service may be out of date at any given time, and we are under no obligation to update such material.</p>

						<h5>10. Our Liability</h5>
						<p>We have taken every care in the preparation of our Site and our Service. However, we will not be responsible for any errors or omissions in relation to such content or for any technical problems you may experience with our Site or our Service. If we are informed of any inaccuracies on our Site or in our Service we will attempt to correct this as soon as we reasonably can. To the extent permitted by law, we exclude all liability (whether arising in contract, in negligence or otherwise) for loss or damage which you or any third party may incur in connection with our Site, our Service, and any website linked to our Site and any materials posted on it. This does not affect our liability for death or personal injury arising from our negligence, or our liability for fraudulent misrepresentation or misrepresentation as to a fundamental matter, or any other liability which cannot be excluded or limited under applicable law.</p>

						<h5>11. Information About You And Your Visits To Our Site And Use Of Our Service</h5>
						<p>We collect certain data about you as a result of you using our Service. This is described in more detail in our privacy policy.</p>

						<h5>12. Uploading Material To Our Site And Our Service</h5>
						<p>Any material you upload to our Service or data that we collect as set out above (section 11) will be considered non-confidential and non-proprietary, and you acknowledge and agree that we have the right to use, copy, distribute, sell and disclose to third parties any such material or data for any purpose related to our business. To the extent that such material is protected by intellectual property rights, you grant us a perpetual, worldwide, royalty-free licence to use, copy, modify, distribute, sell and disclose to third parties any such material or data for any purpose related to our business.</p>

						<h5>13. Links From Our Site</h5>
						<p>Where our Site contains links to other sites and resources provided by third parties, these links are provided for your information only. We have no control over the contents of those sites or resources, and accept no responsibility for them or for any loss or damage that may arise from your use of them.</p>

						<h5>14. Jurisdiction And Applicable Law</h5>
						<p>The Irish courts will have jurisdiction over any claim arising from, or related to, a visit to our Site or use of our Services. These terms of use and any dispute or claim arising out of or in connection with them or their subject matter or formation (including non-contractual disputes or claims) shall be governed by and construed in accordance with the law of Ireland.</p>

						<h5>15. Variations</h5>
						<p>We may revise these terms of use at any time by amending this page. You are expected to check this page from time to time to take notice of any changes we make, as they are binding on you.</p>

						<h5>16. Your Concerns</h5>
						<p>If you have any concerns about material which appears on our Service, please contact <a href="mailto:info@thedeliveryguys.ie">info@thedeliveryguys.ie</a></p>

						<h5>The Delivery Guys Voucher and Account Credit Terms</h5>
						<h5>1. Introduction</h5>

						<p>The Delivery Guys makes available from time to time: (i) <strong>Vouchers</strong> (which are promotional credits that may be offered by us or by Partner Restaurants on our Site) and (ii) <strong>Account Credit</strong> (which is credit that may be applied to a customer’s account at The Delivery Guy’s discretion). Vouchers and Account Credits both operate as credits applying to a customer’s The Delivery Guys account, redeemable (subject to conditions, as set out below) against the cost of Items. Vouchers and Account Credits are referred to collectively in these terms (the <strong>Credit Terms</strong>) as <strong>Credits</strong>.</p>

						<p>These Credit Terms apply to your use of any Credits to pay for Items using the The Delivery Guys Service. These Credit Terms apply together with the The Delivery Guys Terms and Conditions of Service, which continue to apply unless they conflict with these Credit Terms, in which case these Credit Terms have priority. Capitalised words used in these Credit Terms have the same meanings as in our Terms of Service unless they are defined in these Credit Terms.</p>

						<p>You can &quot;apply&quot; a voucher to your account by (i) entering the Voucher code (if any) at the point you place an order, or (ii) by adding any reward that we make available to you in the “Your offers and rewards” section of your account page. Unless otherwise stated, a voucher that has been applied to your account will remain there until it is &quot;redeemed&quot; against an eligible order, or expires (whichever is sooner).  Account Credits are Credits that we apply to your account ourselves. In either case, once any Credit has been applied to your account, the amount of the Credit remains on your account until redeemed as payment for Items, unless it is lost, or, in the case of Vouchers, it expires in accordance with the terms of that Voucher. The reasons why Credits may be lost or Vouchers may expire are set out in these Credit Terms.</p>

						<h5>2. Terms that apply to Vouchers only</h5>
						<p>Vouchers offered in a particular country and currency may be subject to geographical restrictions that mean they cannot be used in a different country or currency. Vouchers are usually offered subject to conditions, such as time limits, geographic limits, limits to particular categories of customer (eg new customers), or use only at a particular Partner Restaurant or for a particular menu item. If conditions apply they will be stated when we offer the Voucher.</p>

						<p>Some Vouchers are only available to new The Delivery Guys customers (&quot;New Customer Vouchers&quot;). That means that the Voucher is applied (subject to any other applicable conditions) automatically when the Customer registers a new The Delivery Guys account and will be redeemed when the Customer places their first eligible order. The Delivery Guys reserves the right to refuse to apply and/or allow you to redeem a new customer Voucher if you are registering a new account using credit card details or a delivery name and address already on our system.</p>

						<p>Use of a Voucher in payment or part-payment may also be subject to specific conditions applicable to that Voucher only, such as a specific minimum order value or that the Voucher may only be redeemed at specific Partner Restaurants or on specific Items. If the order placed does not comply with the applicable conditions, the Credit may not be redeemed against that order. The payment page on our Site where you confirm your order for Items will show the total order value and the applicable Voucher, if any. If there is a Voucher credit on your account which does not apply to your order, it will not appear on the payment page. At that point you will have the choice of paying in another way or cancelling the order.</p>

						<p>Unless we tell you otherwise, all Vouchers must be redeemed within one calendar month of the date they are applied.</p>

						<p>Only one Voucher can be redeemed per order. Each Voucher can only be used once per customer. If you have more than one eligible Voucher applied to your account when you place an order, the Vouchers are redeemed in order of expiry date, with the Voucher expiring the earliest redeemed first. </p>

						<p>Vouchers cannot be redeemed in conjunction with any other The Delivery Guys offer.</p>

						<p>If you cancel an order made using a Voucher, the Voucher credit will not be restored to the account and the Voucher cannot be re-used. If the Voucher was a New Customer Voucher, you will no longer be a new customer and will not be eligible for any subsequent new customer Vouchers.</p>

						<h5>3. Terms that apply to Vouchers and Account Credit</h5>

						<p>Item orders are generally subject to minimum order values, which continue to apply regardless of whether payment or part-payment is by way of a Credit.</p>

						<p>Credits cannot be exchanged for cash or any other alternatives and have no monetary value.</p>

						<p>Any attempt to manipulate our Site and our offers of Credits by use of bulk entry via third parties or syndicates, macros, &apos;script&apos;, &apos;brute force&apos;, masking identity by manipulating IP addresses, using identities other than their own or any other automated means (including systems which can be programmed to enter), will render the order and the relevant Credit invalid. The Delivery Guys is entitled to take all reasonable steps to protect itself against fraudulent or invalid Credit redemptions, including requiring additional reasonable verification close the accounts of any customers it reasonably believes have carried out any such acts.</p>

						<h5>The Delivery Guys Corporate E-Gift Card Terms and Conditions (Where Applicable)</h5>

						<p><strong>Last updated: 10 April 2021</strong></p>

						<p>This page sets out the terms that apply to E-Gift Cards supplied by Future Logistics Solutions Ireland Limited (&quot;The Delivery Guys&quot;), a company registered in Ireland with registered number 601081.  Our registered address is Ennis, Co Clare.  </p>

						<p>Please read these terms carefully before purchasing or using any E-Gift Card on our Service. By purchasing or using an E-Gift Card, you confirm your acceptance of these terms. We may change these terms and from time to time, so we recommend that you check back regularly. </p>

						<p>These E-Gift Card terms and any dispute or claim arising out of them are governed by the laws of Ireland and subject to the exclusive jurisdiction of the Irish courts.</p>

						<p>These terms have two parts: </p>

						<ol className='list'>
							<li><strong>Terms about purchasing E-Gift Cards</strong></li>
							<li><strong>Terms about using E-Gift Cards</strong></li>
						</ol>

						<h5>Terms about purchasing E-Gift Cards</h5>

						<h5>1. Who can purchase E-Gift Cards?</h5>
						<p>If you are a business registered in Ireland, you are eligible to place an order for the purchase of The Delivery Guys E-Gift Cards (<strong>&quot;Order&quot;</strong>).  Orders may be subject to a minimum Order value.  The Delivery Guys may refuse an Order in its discretion, including if The Delivery Guys believes an Order is not a genuine purchase.</p>

						<h5>2. Payments</h5>
						<p>Payment for your Order must be made via credit card, charge card or debit card or, if available, via bank transfer at the time that the Order is placed. Service fees may apply to your purchase of E-Gift Cards.</p>

						<p>Exceptionally, we may agree to accept payment after the time of Order - this is always subject to our written approval and may be subject to credit checks. If we do give this approval, payment must be made within 14 days of the placement of the Order. </p>

						<h5>3. Delivery of E-Gift Cards</h5>

						<p>The Delivery Guys is under no obligation to issue any E-Gift Cards until full payment for your Order has been received. Title to an E-Gift Card passes to you on full payment. </p>

						<p>All E-Gift Cards will be sent to you by email. Risk in an E-Gift Card passes to you upon delivery. If there are any issues with your Order, you must notify The Delivery Guys within 72 hours of delivery. </p>

						<h5>4. Refunds</h5>
						<p>Purchases of E-Gift Cards by businesses are non-refundable. Purchases of E-Gift Cards by consumers (but not businesses) have a 14 day refund period from date of receipt, after which they are non-refundable. Customers must contact our Customer Support Team <a href="mailto:info@thedeliveryguys.ie">info@thedeliveryguys.ie</a> to obtain their refund. On refund, E-Gift Cards will be cancelled and the E-Gift Card will become void. </p>

						<p>The Delivery Guys reserves the right not to fulfil an E-Gift Card that has not been redeemed, in which case the purchaser will be refunded. </p>

						<h5>5. Cancellation</h5>

						<p>In the event that: </p>

						<ul className='list'>
							<li>you make any voluntary arrangement with your creditors or become the subject of any administration, liquidation or winding up order;</li>
							<li>a receiver or administrative receiver is appointed over your assets;</li>
							<li>you suspend or threaten to suspend any payments hereunder or threaten to cease to carry on business; or</li>
							<li>The Delivery Guys reasonably considers that you are about to suffer any of the events above, </li>
						</ul>

						<p>The Delivery Guys may cancel its contracts with you, suspend any further Orders from being placed or fulfilled and deactivate any E-Gift Cards which have not been paid for, without liability to you. </p>

						<h5>6. Limitation of Liability</h5>

						<p>The Delivery Guys will not be liable, in contract, tort (including, without limitation, negligence) or otherwise pursuant to or in connection with these terms for:</p>

						<ul className='list'>
							<li>any economic losses (including without limitation loss of revenues, profits, contracts, business or anticipated savings);</li>
							<li>any loss of goodwill or reputation; or</li>
							<li>any special, indirect, inconsequential, exemplary, incidental or punitive losses,</li>
						</ul>

						<p>suffered or incurred by you out of or in connection with the provision of any E-Gift Cards or under these terms. In the event that an E-Gift Card does not work, your sole remedy and our sole liability will be the replacement of the E-Gift Card.</p>

						<p>Nothing in these terms excludes or limits our liability for death or personal injury caused by our negligence or fraud.</p>

						<h5>7. Use of The Delivery Guys Brand </h5>
						<p>You may not use any trade mark, logo, service mark, designation, product image or any other trading name or get-up of The Delivery Guys (<strong>&quot;The Delivery Guys Brand&quot;</strong>) without our prior written consent. We may withdraw such consent at any time by notifying you. </p>

						<h5>8. <a href="https://tdg.ie/privacy-policy">Privacy policy</a> </h5>
						<p>The Delivery Guys <a href="https://tdg.ie/privacy-policy">privacy policy</a> (available on <a href="https://tdg.ie/privacy-policy">https://tdg.ie/privacy-policy</a>) explains how we collect or handle personal information.</p>

						<h5>9. General</h5>
						<p>In addition to these terms, the general The Delivery Guys terms and conditions found on <a href="https://tdg.ie/termsofservice">https://tdg.ie/termsofservice</a> will apply to your purchase of E-Gift Cards. </p>

						<p>A person who is not a party to these terms and conditions cannot enforce or take the benefit of any of these terms.</p>

						<h5>10. Contact</h5>
						<p>Contact <a href="mailto:info@thedeliveryguys.ie">info@thedeliveryguys.ie</a> for further details or if you have any questions.</p>

						<h5>Terms about using E-Gift Cards</h5>
						<h5>1. What can E-Gift Cards be used for?</h5>

						<p>The Delivery Guys E-Gift Cards may only be redeemed for purchases of menu items from <a href="https://The Delivery Guys.ie/">www.The Delivery Guys.ie</a> or the The Delivery Guys Irish app.</p>

						<p>E-Gift Cards cannot be:</p>
						<ul className='list'>
							<li>topped up or reloaded;</li>
							<li>redeemed alongside promotional codes or promotional vouchers;</li>
							<li>used to pay for subscriptions (e.g. for The Delivery Guys Plus) or to purchase The Delivery Guys E-Gift Cards;</li>
							<li>redeemed for cash;</li>
							<li>resold or transferred for value; or</li>
							<li>Transferred to another The Delivery Guys account, once redeemed.</li>
						</ul>

						<h5>2. When does my E-Gift Card expire?</h5>
						<p>Your E-Gift Card expires on the date stated on the email in which the E-Gift Card was delivered by us. If there is no expiry date specified (or the date stated on the email in which the E-Gift Card was delivered is less than 1 year after the date your E-Gift Card was purchased) your E-Gift Card will expire 1 year after the date the E-Gift Card was purchased.</p>

						<p>Once you redeem your E-Gift Card, you must use that credit before the expiry date of the E-Gift Card. Any credit that has not been spent by the expiry date will be cancelled and will no longer be available in your The Delivery Guys account.</p>

						<h5>3. How do I redeem my E-Gift Card?</h5>
						<p>To redeem your E-Gift Card, enter the unique code on the E-Gift Card at the checkout when you place your next order and your The Delivery Guys account will be credited with the amount loaded on your E-Gift Card. Once credited to a The Delivery Guys account E-Gift Cards are non-refundable and non-transferable.</p>

						<h5>4. What happens if my order is for more or less than my E-Gift Card balance?</h5>
						<p>If your purchase exceeds your total credit, the rest of your order must be paid with another payment method accepted under our general terms of use</p>

						<p>If your purchase is less than your total credit, the balance of your credit will remain available on your account for future purchases. To view your credit balance, visit your The Delivery Guys account.</p>

						<h5>5. What happens to my credit if my order is cancelled?</h5>
						<p>If you use credit (in full or in part) to pay for your The Delivery Guys order and your order is cancelled or you are entitled to a refund, your refund will be issued to you in the same form as the original payment was made (ie as a credit). There will be no monetary exchange for returned or cancelled orders made with credit.</p>

						<h5>6. What happens if my E-Gift Card is lost, stolen or destroyed?</h5>
						<p>Please look after your E-Gift Card. The Delivery Guys is not responsible if any E-Gift Card is lost, stolen, or used without your permission and if this happens your E-Gift Card will not be replaced.</p>

						<h5>7. Fraud and abuse</h5>
						<p>The Delivery Guys is not responsible for E-Gift Cards sold or distributed by an unauthorised third party.</p>

						<p>If an E-Gift Card is abused, or an E-Gift Card which we know or suspect was obtained fraudulently is redeemed and/or used to make purchases on our service, The Delivery Guys may:</p>

						<ul className='list'>
							<li>suspend or close the affected customer account;</li>
							<li>cancel any E-Gift Cards; and/or</li>
							<li>take legal action.</li>
						</ul>

						<h5>8. The Delivery Guys General Terms  </h5>
						<p>When you use your E-Gift Card on our website or our app, the general The Delivery Guys terms and conditions will also apply.</p>

						<h5>9. <a target="_blank" rel="noopener noreferrer" href="https://tdg.ie/app-privacy-policy.html">Privacy policy</a> </h5>
						<p>The Delivery Guys privacy policy (available on <a href="https://tdg.ie/app-privacy-policy.html">https://tdg.ie/app-privacy-policy.html</a>) explains how we use your personal information.</p>

						<h5>10. How do I get help?</h5>
						<p>If you have any issues with your E-Gift Card, contact our Customer Support Team at <a href="mailto:info@thedeliveryguys.ie">info@thedeliveryguys.ie</a> </p>
						<p>Last updated: 03/11/2022</p>

					</div>
				</Container>


			</div>
		);
	}
}

TermsOfService.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			page: PropTypes.string
		})
	})
};

const mapStateToProps = (state) => {
	return {
		cartItem: state.cart.cartItem,
		coupon: state.cart.coupon,
		deliveryInstructions: state.cart.deliveryInstructions,
		driverTip: state.cart.driverTip,
		grandTotal: state.cart.grandTotal,
		token: state.auth.token,
		user: state.user,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		handleCart: (data) => dispatch(handleCart(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsOfService);
