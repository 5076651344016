import React from 'react';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { store, persistor } from './redux/store';

import { PersistGate } from 'redux-persist/integration/react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

import 'bootstrap/scss/bootstrap.scss';
import './index.scss';

import App from './App';

import axiosInstance, { setupAxiosClient } from './shared/eaxios';
setupAxiosClient(store, axiosInstance);

import axiosOrderInstance, { setupOrderAxiosClient } from './shared/orderEaxios';
setupOrderAxiosClient(store, axiosOrderInstance);


if (process.env.REACT_APP_MODE == 'production') {
    console.log = () => {
    };
}

//console.log('NODE_ENV=>', process.env);

const app = (
<Provider store={store}>
        <BrowserRouter>
            <PersistGate persistor={persistor}>
                <Elements stripe={stripePromise}>
                    <App />
                </Elements>
            </PersistGate>
        </BrowserRouter>
    </Provider>

);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
