import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Col, Container, Row, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Formik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import axios from '../../../shared/eaxios';
import LoadingSpinner from '../../../Components/LoadingSpinner/LoadingSpinner';
import { handleCart } from './../../../redux/actions/cart';
import { AiFillCheckCircle } from 'react-icons/ai';
import { SERVERFOLDER } from './../../../Constants';
import ReCAPTCHA from 'react-google-recaptcha';
const CAPTCHA_KEY = process.env.REACT_APP_CAPTCHA_KEY;

const initialValues = {
	first_name: '',
	email: '',
	phone: '',
	password: '',
	password_confirmation: '',
	chk_confirm: '',
	captcha_value: ''
};

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const RegisterSchema = Yup.object().shape({
	first_name:
		Yup
			.string()
			.trim('Please remove whitespace')
			.strict()
			.required('The name field is required.'),
	email:
		Yup
			.string()
			.trim('Please remove whitespace')
			.email('Email must be valid')
			.strict()
			.required('The email field is required.'),
	phone:
		Yup
			.string()
			.trim('Please remove whitespace')
			.strict()
			.matches(phoneRegExp, 'Phone number is not valid')
			.required('Mobile number field is required.'),
	password:
		Yup
			.string()
			.trim('Please remove whitespace')
			.strict()
			.required('The password field is required.'),
	password_confirmation:
		Yup
			.string()
			.trim('Please remove whitespace')
			.strict()
			.required('Confirm password field is required.')
			.oneOf([Yup.ref('password')], 'Passwords does not match'),
	chk_confirm:
		Yup
			.boolean()
			.required('Please accept Terms & Conditions'),
	captcha_value: 
		Yup
			.string()
			.required('Are you Robot?'),

});

const getErrorStyles = (errors, touched, fieldName) => {
	if (getIn(errors, fieldName) && getIn(touched, fieldName)) {
		return {
			border: '1px solid red'
		};
	}
};

class Register extends Component {
	state = {
		errorMsg: '',
		loader: false,
		user_type_id: null,
		succesSubmitModal: false,
	}

	handleCaptcha = (value) => {
		console.log('Captcha value:', value);
	}

	componentDidUpdate(prevProps) {
		if (this.props.categoryId !== prevProps.categoryId) {

			let data = this.props.cart;
			let cartItem = this.props.cartItem;

			cartItem = [];
			data.cartItem = cartItem;
			//data.coupon = [];
			data.driverTip = 0;
			data.grandTotal = 0;
			this.props.handleCart(data);

			this.setState({
				cartItem
			});

			this.props.history.push(`${SERVERFOLDER + '/'}`);
		}
	}

	handleSubmit = (values) => {
		console.log('Inside handel submit');
		console.log(values.email);
		values.user_type_id = '2';
		this.setState({ loader: true }, () => {
			axios.post(`register?user_type_id=2&email=${values.email}&phone=${values.phone}&password=${values.password}&password_confirmation=${values.password_confirmation}&first_name=${values.first_name}`)
				.then(() => {
					this.setState({
						loader: false,
					}, () => {
						this.showSuccesSubmitModal();
					});
				})
				.catch(err => {
					console.log('err', err);
					let errorMessage = '';
					try {
						errorMessage = err.data.message ? err.data.message : err.data.error_description;
					} catch (err) {
						errorMessage = 'No records found.';
					}
					this.setState({
						loader: false,
						errorMsg: errorMessage,
					});
					setTimeout(() => {
						this.setState({ errorMsg: null });
					}, 2500);
				});
		});
	}

	showSuccesSubmitModal = () => {
		this.setState({ succesSubmitModal: true });
	};

	closeSuccesSubmitModal = () => {
		this.setState({ succesSubmitModal: false },
			() => {
				this.props.history.push(`${SERVERFOLDER + '/login'}`);
			}
		);
	};

	render() {
		return (
			<div className="position-relative">
				{this.state.loader ?
					<span className='loaderStyle'><LoadingSpinner /></span>
					: null
				}
				<div className="welcomeOuter">
					<div className="regWhiteOuter">
						<Container>
							<div className="welcomePage">
								<Row className="show-grid">
									<Col md={12}>
										<div className="welcomeIntro mt-4">
											<div className="login position-relative">
												<h4 className='text-center'>Sign Up</h4>
												<h6 className='text-sm-center text-secondary mb-4'>Create Your Personal Account</h6>
												{this.state.errorMsg ?
													<p className="alert alert-danger text-center" role="alert"> {this.state.errorMsg} </p>
													: null
												}
												<Formik
													initialValues={initialValues}
													validationSchema={RegisterSchema}
													onSubmit={this.handleSubmit}
												>
													{({
														errors,
														touched,
														setFieldValue
													}) => {
														return (<Form className='text-dark'>
															<div className="position-relative">
																<div className="form-group">
																	<label className="form-label font-weight-bold">Name <span className="text-danger">*</span></label>
																	<Field
																		style={getErrorStyles(
																			errors,
																			touched,
																			'first_name'
																		)}
																		type="input"
																		name="first_name"
																		placeholder="Name"
																		className="form-control"
																	/>
																	{errors.first_name && touched.first_name ? <span className="errorMsg text-danger">{errors.first_name}</span> : null}
																</div>
																<div className="form-group">
																	<label className="form-label font-weight-bold">Email ID <span className="text-danger">*</span></label>
																	<Field
																		style={getErrorStyles(
																			errors,
																			touched,
																			'email'
																		)}
																		type="email"
																		name="email"
																		placeholder="Email ID"
																		className="form-control"
																	/>
																	{errors.email && touched.email ? <span className="errorMsg text-danger">{errors.email}</span> : null}
																</div>
																<div className="form-group">
																	<label className="form-label font-weight-bold">Mobile Number <span className="text-danger">*</span></label>
																	<Field
																		style={getErrorStyles(
																			errors,
																			touched,
																			'phone'
																		)}
																		type="input"
																		name="phone"
																		placeholder="Mobile Number"
																		className="form-control"
																	/>
																	{errors.phone && touched.phone ? <span className="errorMsg text-danger">{errors.phone}</span> : null}
																</div>
																<div className="form-group">
																	<label className="form-label font-weight-bold">Password <span className="text-danger">*</span></label>
																	<Field
																		style={getErrorStyles(
																			errors,
																			touched,
																			'password'
																		)}
																		type="password"
																		name="password"
																		placeholder="Password"
																		className="form-control"
																	/>
																	{errors.password && touched.password ? <span className="errorMsg text-danger">{errors.password}</span> : null}
																</div>
																<div className="form-group">
																	<label className="form-label font-weight-bold">Confirm Password <span className="text-danger">*</span></label>
																	<Field
																		style={getErrorStyles(
																			errors,
																			touched,
																			'password_confirmation'
																		)}
																		type="password"
																		name="password_confirmation"
																		placeholder="Confirm Password"
																		className="form-control"
																	/>
																	{errors.password_confirmation && touched.password_confirmation ? <span className="errorMsg text-danger">{errors.password_confirmation}</span> : null}
																</div>

																<div className="form-group">
																	<Field
																		style={getErrorStyles(
																			errors, touched,
																			'chk_confirm'
																		)}
																		type="checkbox"
																		name="chk_confirm"
																		className="tc_check"
																	/> I agree to all <Link target="_blank" to={SERVERFOLDER + '/termsofservice'} className='text-orange-primary mb-3'>Terms &amp; Conditions</Link>
																	<br />
																	{errors.chk_confirm && touched.chk_confirm ? <span className="errorMsg text-danger">{errors.chk_confirm}</span> : null}
																</div>


																{/*<div className="App">
																	<ReCAPTCHA sitekey={CAPTCHA_KEY} onChange={this.handleCaptcha} />
																</div>*/}

																<div className="form-group">
																	<label></label>
																	<ReCAPTCHA
																		sitekey={CAPTCHA_KEY}
																		theme='light'
																		size='normal'
																		onChange={(response) => { setFieldValue('captcha_value', response); }}
																	/>
																	{errors.captcha_value && touched.captcha_value ? <span className="errorMsg text-danger">{errors.captcha_value}</span> : null}
																
																</div>

																<div className='row mt-2 text-center'>
																	<div className='col-sm-12'>
																		<Button variant="primary mr-3" type="submit" className="btnStylePrimary mt-3">
																			<span className="font-weight-normal">Submit</span>
																		</Button>
																		<div>
																			<span className="text-muted center">Already on Delivery Guys? <Link to={SERVERFOLDER + '/login'} className='text-orange-primary mb-3'>Sign in</Link></span>
																		</div>

																	</div>
																</div>
															</div>
														</Form>);
													}}
												</Formik>

											</div>
										</div>
									</Col>
								</Row>
							</div>
						</Container>
					</div>

				</div>

				<Modal
					show={this.state.succesSubmitModal}
					onHide={this.closeSuccesSubmitModal}
					className="payOptionPop"
				>
					<Modal.Body className='bg-white text-dark'>
						<Row>
							<Col md={12} className="text-center">
								<span className='successIcon'><AiFillCheckCircle /></span>
							</Col>
						</Row>
						<Row>
							<Col md={12} className="text-center">
								<h5>You Have Successfully Registered </h5>
							</Col>
						</Row>
					</Modal.Body>
					<div className="text-center my-4">
						<Button

							type="button"
							onClick={this.closeSuccesSubmitModal}
						>
							Return
						</Button>
					</div>
				</Modal>

			</div>
		);
	}
}
Register.propTypes = {
	location: PropTypes.object,
	history: PropTypes.object,
	handleCart: PropTypes.func,
	cartItem: PropTypes.any,
	categoryId: PropTypes.any,
	cart: PropTypes.any,
};


const mapStateToProps = (state) => {
	return {
		cartItem: state.cart.cartItem,
		cart: state.cart,
		categoryId: state.servicecategory.categoryId,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		handleCart: (data) => dispatch(handleCart(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
