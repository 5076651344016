import React, { Component } from 'react';
import { Fragment } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-dropdown/style.css';
import { NavLink } from 'react-router-dom';
import { ImStarFull } from 'react-icons/im';
import { MdClose } from 'react-icons/md';
import { GoSearch } from 'react-icons/go';
import { GiChiliPepper } from 'react-icons/gi';
import { MdPlayArrow } from 'react-icons/md';
import { FaApple, FaGooglePlay } from 'react-icons/fa';
import { Button, Col, Row, Modal } from 'react-bootstrap';
import noImage from '../../../assets/images/no-img.svg';
import serviceImg1 from '../../../assets/images/service-img1.png';
import serviceImg2 from '../../../assets/images/service-img2.png';
//import serviceImg3 from '../../../assets/images/service-img3.png';
import allrestaurant from '../../../assets/images/allrestaurant.png';
import allgrocery from '../../../assets/images/groceryall.png';
import axios from './../../../shared/eaxios';
import { IMAGEURL, IMAGEURL_CAT, SERVERFOLDER } from './../../../Constants';
import * as Utils from '../../../Utils';
import LoadingSpinner from '../../../Components/LoadingSpinner/LoadingSpinner';
import ItemPerPage from '../../../Components/ItemsPerPage/ItemsPerPage';
import Dropdown from 'react-dropdown';
import Pagination from 'react-js-pagination';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { handleServiceCategory } from './../../../redux/actions/servicecategory';
import { handleCart } from './../../../redux/actions/cart';
import { handleGeoLocation } from './../../../redux/actions/geolocation';
import Geocode from 'react-geocode';
import { scroller } from 'react-scroll';
import batchOffer from '../../../assets/images/batch-offer.png';
import batchGenuine from '../../../assets/images/batch-genuine.png';


const GOOGLEAPIKEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;


class Home extends Component {
	state = {
		latitude: null,
		longitude: null,
		showDropDown: false,
		sort: 'open',
		selectedCategry: '',
		identityList: [],
		categoryList: [],

		activePage: 1,
		totalCount: 0,
		itemsPerPage: 12,

		activePageSearch: 1,
		totalCountSearch: 0,
		itemsPerPageSearch: 12,

		getDataerrorMessge: null,
		getCategoryLoader: false,
		getDataLoader: false,
		searchLoader: false,
		searchString: '',
		searchText: '',
		searchBy: this.props.categoryId == 1 ? 'dish' : 'product',
		searchPlaceholder: this.props.categoryId == 1 ? 'Cuisine/Dish Search' : 'Prouct/Item Search',
		seacrhActive: false,
		searchList: [],
		next_page_url_search: '',
		Loading: false,
		count: 0,
		searchActive: false,
		sIdentity: '',
		searchListData: [],
		allowGoogleLocation: false,
		googleLocationMessage: null,
		showLocationDeniedModal: false
	}

	_isMounted = false;

	displayError = (e) => {
		let errorMessge = '';
		try {
			errorMessge = e.data.message ? e.data.message : e.data.error_description;
		} catch (e) {
			errorMessge = 'Unknown error!';
		}
		return errorMessge;
	}

	getCategory = () => {
		const {
			searchString,
		} = this.state;

		this.setState({
			getCategoryLoader: true
		}, () => {
			axios.get(`categoryListByService/${this.props.categoryId}?searchString=${searchString}`)
				.then(res => {
					if (this._isMounted) {
						this.setState({
							getCategoryLoader: false,
							categoryList: res.data,
						});
					}
				})
				.catch(e => {
					let errorMsg = this.displayError(e);
					this.setState({
						getDataerrorMessge: errorMsg,
						getCategoryLoader: false
					}, () => {

					});
					setTimeout(() => {
						this.setState({ getDataerrorMessge: null });
					}, 5000);
				});
		});
	};

	getLocation = () => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(this.handelLocation, this.handelLocationError);
		} else {
			console.log('Geolocation is not supported by this browser.');
		}
	}

	handelLocation = (position) => {
		let geodata = this.props.geolocation;

		geodata.latitude = position.coords.latitude;
		geodata.longitude = position.coords.longitude;

		this.props.handleGeoLocation(geodata);
		this.position(position.coords.latitude, position.coords.longitude);

		console.log('breakpoint=', 'handelLocation');
		this.handelSwich();
		if (this.state.searchText.length > 0) {
			this.serachApicall();
		}
	}

	position = async (lat, lng) => {
		this.setState({ loadingmap: true });
		Geocode.setApiKey(GOOGLEAPIKEY);
		Geocode.fromLatLng(lat, lng).then(
			response => {
				const formatted_address = response.results[0].formatted_address;

				/*const address_components = response.results[0].address_components;
				let result = {};
				for (let i = 0; i < address_components.length; i++) {
					for (let j = 0; j < address_components[i].types.length; j++) {
						switch (address_components[i].types[j]) {
							case 'street_number':
								result.street_number = address_components[i].long_name;
								break;
							case 'street_address':
								result.street_address = address_components[i].long_name;
								break;
							case 'premise':
								result.premise = address_components[i].long_name;
								break;
							case 'route':
								result.route = address_components[i].long_name;
								break;
							case 'neighborhood':
								result.neighborhood = address_components[i].long_name;
								break;
							case 'sublocality_level_2':
								result.sublocality_level_2 = address_components[i].long_name;
								break;
							case 'sublocality_level_1':
								result.sublocality_level_1 = address_components[i].long_name;
								break;
							case 'locality':
								result.city = address_components[i].long_name;
								break;
							case 'administrative_area_level_1':
								result.state = address_components[i].long_name;
								break;
							case 'country':
								result.country = address_components[i].long_name;
								result.country_code = address_components[i].short_name;
								break;
							case 'postal_code':
								result.zip = address_components[i].long_name;
								break;
						}
					}
				}
				let sparseArray = [result.street_number,
				result.street_address,
				result.route,
				result.neighborhood,
				result.sublocality_level_2,
				result.sublocality_level_1
				];
				let cleanArray = sparseArray.filter(function (el) { return el != undefined; });
				result.addr2 = cleanArray.join(', ').trim();
				result.addr = formatted_address;
				result.lat = lat;
				result.lng = lng;*/

				let geodata = this.props.geolocation;
				geodata.address = formatted_address;

				this.props.handleGeoLocation(geodata);
				console.log('result for ' + lat +'/'+ lng, formatted_address);
			});
	}

	handelSwich = () => {
		let url;
		const {
			selectedCategry,
			itemsPerPage,
			activePage,
			searchString,
			sort,
		} = this.state;

		if(this.props.geolocation.latitude){

			this.setState({
				getDataLoader: true
			}, () => {

				if (this.props.categoryId == 1) {
					url = `customerRestaurantList?category=${selectedCategry}&sortBy=${sort}&pageSize=${itemsPerPage}&page=${activePage}&lat=${this.props.geolocation.latitude}&lng=${this.props.geolocation.longitude}&searchString=${searchString}`;
				} else {
					url = `customerGroceryList?category=${selectedCategry}&sortBy=${sort}&pageSize=${itemsPerPage}&page=${activePage}&lat=${this.props.geolocation.latitude}&lng=${this.props.geolocation.longitude}&searchString=${searchString}`;
				}

				axios.get(url)
					.then(res => {
						if (this._isMounted) {

							/*this.handleShopOpenClose(
								res.data.data.data,
								res.data.identity,
								res.data.data.next_page_url,
								0,
							);*/

							this.setState({
								getDataLoader: false,
								identityList: res.data.data.data,
								totalCount: res.data.data.total,
							});
						}
					})
					.catch(e => {
						let errorMsg = this.displayError(e);
						this.setState({
							getDataerrorMessge: errorMsg,
							getDataLoader: false
						}, () => {

						});
						setTimeout(() => {
							this.setState({ getDataerrorMessge: null });
						}, 5000);
					});
			});
		}
	};

	componentDidMount() {
		this._isMounted = true;
		if (this.props.geolocation.address == null) {
			//this.getLocation();
			//console.log('breakpoint=', 'componentDidMount:getLocation');
		}
		this.getCategory();
		this.handelSwich();
		window.scrollTo(0, 0); // after social media login
	}

	componentDidUpdate(prevProps) {
		
		if (this.props.categoryId !== prevProps.categoryId) {
			//console.log('breakpoint=', 'componentDidUpdate');
			let data = this.props.cart;
			let cartItem = this.props.cartItem;

			cartItem = [];
			data.cartItem = cartItem;
			data.driverTip = 0;
			data.grandTotal = 0;
			this.props.handleCart(data);

			this.setState({
				searchListData: [],
				selectedCategry: '',
				searchText: '',
				searchBy: this.props.categoryId == 1 ? 'dish' : 'product',
				searchPlaceholder: this.props.categoryId == 1 ? 'Cuisine/Dish Search' : 'Prouct/Item Search',
			}, () => {
				this.getCategory();
				this.handelSwich();
				if (this.state.searchText.length > 0) {
					this.serachApicall();
				}
			});
		}

		if (this.props.shopId !== prevProps.shopId) {
			let data = this.props.cart;
			let cartItem = this.props.cartItem;

			cartItem = [];
			data.cartItem = cartItem;
			data.driverTip = 0;
			data.grandTotal = 0;
			this.props.handleCart(data);
		}
		
		// after refresh call apis
		if (this.props.geolocation.refresh === true) {
			let geodata = this.props.geolocation;
			geodata.refresh = false;
			this.props.handleGeoLocation(geodata);

			//console.log('breakpoint=', 'componentDidUpdate geolocation refresh');
			this.getCategory();
			this.handelSwich();
			if (this.state.searchText.length > 0) {
				this.serachApicall();
			}
		}
	}

	storeId = (type, shop, search = false) => {

		//console.log('shop=>',shop);


		let dataPrev = this.props.cart;
		let cartItem = this.props.cartItem;

		cartItem = [];
		dataPrev.cartItem = cartItem;
		dataPrev.driverTip = 0;
		dataPrev.grandTotal = 0;
		this.props.handleCart(dataPrev);

		const otherType = type == 'restaurant' ? 'grocery' : 'restaurant';

		if (sessionStorage.getItem(otherType + 'Id')) {
			sessionStorage.removeItem(otherType + 'Id');
		}
		sessionStorage.setItem(type + 'Id', shop.id);

		let data = this.props.cart;

		data.isOpen = shop.isOpen;
		data.isDelivery = shop.isDelivery;
		data.shopType = type;
		data.shopId = search ? type == 'restaurant' ? shop.restaurant_id : shop.grocery_id : shop.id;
		data.only_collection = search ? type == 'restaurant' ? shop.restaurant_details.only_collection : shop.grocery_details.only_collection : shop.only_collection;
		data.currency_symbol = search ? type == 'restaurant' ? shop.restaurant_details.franchisee.currency_symbol.currency_symbol : shop.grocery_details.franchisee.currency_symbol.currency_symbol : shop.franchisee.currency_symbol.currency_symbol;
		data.currency_position = search ? type == 'restaurant' ? shop.restaurant_details.franchisee.currency_position : shop.grocery_details.franchisee.currency_position : shop.franchisee.currency_position;

		this.props.handleCart(data);
	}

	handleSearchBy = (e) => {
		let v = e.target.value;
		let txt = '';

		if (v == 'restaurant') {
			txt = 'Search For Restaurants Near You';
		}
		if (v == 'dish') {
			txt = 'Cuisine/Dish Search';
		}
		if (v == 'grocery') {
			txt = 'Search For Groceries Near You';
		}
		if (v == 'product') {
			txt = 'Prouct/Item Search';
		}

		this.setState({ searchBy: v, searchPlaceholder: txt }, () =>
			this.serachApicall()
		);
	};

	handleKeyPress = (e) => {
		this.setState({
			selectedCategry: '',
			searchText: e.target.value
		}, () => {
			this.serachApicall();
		});
		this.scrollToSearch();
	}

	handelSerachFunc = () => {
		this.serachApicall();
	};

	cancelSearch = () => {
		this.setState({ searchText: '', searchActive: false, searchListData: [] });
	};

	serachApicall = () => {
		let url;
		const {
			searchText,
			searchBy,
			itemsPerPageSearch,
			activePageSearch,
		} = this.state;

		if (this.state.searchText.length > 0) {
			this.setState({
				searchLoader: true,
				searchListData: []
			}, () => {

				if (this.props.categoryId == 1) {
					url = `filterRestaurantProductCustomer?searchString=${searchText}&searchBy=${searchBy}&lat=${this.props.geolocation.latitude}&lng=${this.props.geolocation.longitude}&pageSize=${itemsPerPageSearch}&page=${activePageSearch}`;
				} else {
					url = `filterGroceryProductCustomer?searchString=${searchText}&searchBy=${searchBy}&lat=${this.props.geolocation.latitude}&lng=${this.props.geolocation.longitude}&pageSize=${itemsPerPageSearch}&page=${activePageSearch}`;
				}
				axios.get(url)
					.then(res => {
						//console.log('serachApicall=====>', res.data.data.data);

						/*res.data.data.data.map((element, index) => {
							if (element.restaurant_time !== null) {
								let arr = res.data.data.data[index];
								let status = Utils.checkPlaceOpen(element.restaurant_time);
								arr.isOpen = status;
								res.data.data.data[index] = arr;
							}
						});*/

						if (searchBy == 'restaurant' || searchBy == 'dish') {
							res.data.data.data.map((element, index) => {
								let arr = res.data.data.data[index];
								let status = Utils.checkPlaceOpen(element.restaurant_time);
								arr.isOpenPrev = status;
								res.data.data.data[index] = arr;
							});
						}

						if (searchBy == 'grocery' || searchBy == 'product') {
							res.data.data.data.map((element, index) => {
								let arr = res.data.data.data[index];
								let status = Utils.checkPlaceOpen(element.grocery_time);
								arr.isOpenPrev = status;
								res.data.data.data[index] = arr;
							});
						}



						const DATA = [
							{ title: res.data.identity, list: [...res.data.data.data], searchTotal: res.data.data.total },
						];

						this.setState({
							searchListData: DATA,
							sIdentity: res.data.identity,
							searchLoader: false
						}, () => {
							//console.log('searchListData=====>', this.state.searchListData);
						});

					})
					.catch(e => {
						let errorMsg = this.displayError(e);
						this.setState({
							getDataerrorMessge: errorMsg,
							searchLoader: false
						}, () => {

						});
						setTimeout(() => {
							this.setState({ getDataerrorMessge: null });
						}, 5000);
					});
			});
		} else {
			this.setState({ searchListData: [] });
		}
	};

	setCategory = (val) => {

		this.setState({
			selectedCategry: val,
			searchText: '',
			searchBy: this.props.categoryId == 1 ? 'dish' : 'product',
		}, () => {
			this.handelSwich();
			if (this.state.searchText.length > 0) {
				this.serachApicall();
			}
			//console.log('breakpoint=', 'setCategory');
		});
		this.scrollToSlider();
	};

	scrollToSlider = () => {
		scroller.scrollTo('bannerSlider', {
			duration: 800,
			delay: 0,
			smooth: 'easeInOutQuart',
		});
	};

	scrollToSearch = () => {
		scroller.scrollTo('searchPan', {
			duration: 800,
			delay: 0,
			smooth: 'easeInOutQuart',
		});
	};


	handleShopOpenClose = (res_arr, identity, nextPage, loadmore) => {
		//console.log(identity);
		res_arr.map((element, index) => {
			//console.log('================Checking Start==========', element.restaurant_name);
			let status = 0; // 0 -close 1- open

			let alreadyExcecute = 0;
			let time_field = '';

			if (identity == 'Restaurant') {
				time_field = 'restaurant_time';
			} else {
				time_field = 'grocery_time';
			}

			element[time_field].map((item) => {
				// let today = new Date().toLocaleString('en-us', {weekday: 'long'});
				// today = today.toLowerCase();
				let date = new Date();
				// date.getDay()
				// console.log(date.getDay());
				//console.log(item[today]);

				let today = Utils.getdays();

				if (item[today] == 1 && alreadyExcecute == 0) {
					//console.log(element.restaurant_name);
					alreadyExcecute = 1;

					let open_time = Date.parse('01/01/2021 ' + item.open);
					let close_time = Date.parse('01/01/2021 ' + item.close);

					let hours = date.getHours();
					let min = date.getMinutes();
					let seconds = date.getSeconds();

					let time_current = hours + ':' + min + ':' + seconds;

					//console.log(time_current);

					let curent_time = Date.parse('01/01/2021 ' + time_current);

					//console.log('=====OPEN', open_time);
					//console.log('=====CLOSE', close_time);
					//console.log('=====CURRENT', curent_time);

					if (open_time <= close_time) {
						// start and stop times are in the same day
						if (curent_time >= open_time && curent_time <= close_time) {
							// current time is between start and stop

							//console.log('OPEN', index);
							status = 1;
						}
					} else {
						//console.log(curent_time >= open_time);
						//console.log(curent_time <= close_time);

						// start and stop times are in different days
						if (curent_time >= open_time || curent_time <= close_time) {
							// current time is between start and stop

							//console.log('OPEN', index);
							status = 1;
						}
					}
				}

				let arrElement = res_arr[index];
				arrElement.isOpen = status;

				res_arr[index] = arrElement;
			});

			if (element[time_field].length == 0) {
				let arrElement = res_arr[index];
				arrElement.isOpen = 0;
				res_arr[index] = arrElement;
			}
			//console.log('================Checking End==========', status);
		});
		//console.log('handleShopOpenClose <=', res_arr);
		if (loadmore == 1) {
			this.setState({
				identityList: [...this.state.identityList, ...res_arr],
				next_page_url: nextPage,
			});
		} else {
			this.setState({
				identityList: res_arr,
				next_page_url: nextPage,
			});
		}
	};

	handelLocationError = (error) => {
		this.setState({
			allowGoogleLocation: false,
			//showLocationDeniedModal: true
		});
		switch (error.code) {
			case error.PERMISSION_DENIED:
				console.log('User denied the request for Geolocation.');
				// this.setState({ googleLocationMessage: 'User denied the request for Geolocation.' });
				break;
			case error.POSITION_UNAVAILABLE:
				console.log('Location information is unavailable.');
				// this.setState({ googleLocationMessage: 'Location information is unavailable.' });
				break;
			case error.TIMEOUT:
				console.log('The request to get user location timed out.');
				// this.setState({ googleLocationMessage: 'The request to get user location timed out.' });
				break;
			case error.UNKNOWN_ERROR:
				console.log('An unknown error occurred.');
				// this.setState({ googleLocationMessage: 'An unknown error occurred.' });
				break;
		}
	}

	closeLocationDeniedModal = () => {
		this.setState({ showLocationDeniedModal: false });
		window.location.reload();
	}

	selectSoringVal = (e) => {
		//console.log('val', e);
		this.setState({
			sort: e.value
		}, () =>
			this.handelSwich(),
		);
	};

	handleChangeItemPerPage = e => {
		this.setState({ itemsPerPage: e.target.value }, () => {
			this.handelSwich();
		});
	};

	handlePageChange = (pageNumber) => {
		this.setState({ activePage: pageNumber }, () => {
			this.handelSwich();
		});
	};

	handleChangeItemPerPageSearch = e => {
		this.setState({ itemsPerPageSearch: e.target.value, activePageSearch: 1 }, () => {
			this.serachApicall();
		});
	};

	handlePageChangeSearch = (pageNumber) => {
		this.setState({ activePageSearch: pageNumber }, () => {
			this.serachApicall();
		});
	};




	render() {
		const {
			sort,
			searchText,
			selectedCategry,
			identityList,
			categoryList,
			searchListData,
			getCategoryLoader,
			getDataLoader,
			searchLoader,
			//allowGoogleLocation,
			googleLocationMessage,
			searchPlaceholder
		} = this.state;

		let settings = {
			dots: false,
			infinite: true,
			speed: 500,
			slidesToShow: categoryList && categoryList.length > 0 ? categoryList.length > 6 ? 6 : categoryList.length : 0,
			slidesToScroll: 1,
			responsive: [{
				breakpoint: 1199,
				settings: {
					slidesToShow: 5,
					slidesToScroll: 1,
				}
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1,
				}
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 2,
				}
			},
			{
				breakpoint: 540,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
			]
		};

		let testimonialSetting = {
			dots: true,
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,

		};

		const options = [
			{ value: 'open', label: 'Open Now' },
			{ value: 'rating', label: 'Rating' },
			{ value: 'cost', label: 'Cost' },
		];

		return (
			<div>
				{/* allowGoogleLocation ? null : <span className='loaderStyle'><LoadingSpinner /></span>*/}
				<div className="bannerPan">
					<div className="container">
						<h3>A New version is available ! Please update your mobile app before you place any new order!</h3>
						<h3>Your favourite food delivered to your door!</h3>
						<div className="searchPan">
							<div className="searchDrop">
								<select onChange={this.handleSearchBy}>
									{this.props.categoryId == 1 &&
										<Fragment>
											<option value='dish'>Cuisine or Dish</option>
											<option value='restaurant'>Restaurant</option>
										</Fragment>
									}
									{this.props.categoryId == 2 &&
										<Fragment>
											<option value='product'>Product or Item</option>
											<option value='grocery'>Grocery</option>
										</Fragment>
									}
								</select>
							</div>
							<div className="searchTxtbox">
								<input type="text" placeholder={searchPlaceholder} className="form-control" value={this.state.searchText} onChange={this.handleKeyPress} />
								{searchText ? <button type="button" className="searchBtn" onClick={() => this.cancelSearch()}><MdClose /></button> : <button type="button" className="searchBtn" onClick={() => this.handelSerachFunc()}><GoSearch /></button>}
							</div>
						</div>

						<div className="bannerSlider">
							<h2><strong>Popular</strong> Choices </h2>
							{getCategoryLoader ?
								null :
								<Slider {...settings}>
									{this.props.categoryId == 1 &&
										<div className="slideItem" onClick={() => this.setCategory('')}>
											<div className={`slideBox ${selectedCategry == '' ? 'selectClass' : null}`}>
												<div className="slideImg"><img src={allrestaurant} /></div>
												<span>All Restaurants</span>
											</div>
										</div>
									}
									{this.props.categoryId == 2 &&
										<div className="slideItem" onClick={() => this.setCategory('')}>
											<div className={`slideBox ${selectedCategry == '' ? 'selectClass' : null}`}>
												<div className="slideImg"><img src={allgrocery} /></div>
												<span>All Groceries</span>
											</div>
										</div>
									}
									{categoryList && categoryList.length > 0 &&
										categoryList.map(category => (
											<div className="slideItem" key={category.id} >
												<div className={`slideBox ${selectedCategry == category.id ? 'selectClass' : null}`} onClick={() => this.setCategory(category.id)}>
													<div className="slideImg">
														{category.image ?
															<img src={`${IMAGEURL_CAT}category/${category.image}`} /> : <img src={noImage}></img>
														}
													</div>
													<span>{category.name}</span>
												</div>
											</div>
										))
									}
								</Slider>
							}
						</div>

					</div>
				</div>

				{searchLoader ?
					<span className='loaderStyle'><LoadingSpinner /></span> :
					searchListData && searchListData.length > 0 && searchListData.map((item, index) => (
						<div className="nearPan" key={index}>
							<div className="container">
								<h2><strong>{item.list && item.list.length > 0 && item.searchTotal + ' ' + item.title + ' found'}</strong></h2>
								<div className="row">
									<Fragment>
										{item.list && item.list.length > 0 ?
											item.list.map((each, i) =>

												item.title == 'Dishes' || item.title == 'Products' ?
													(
														<div className="col-md-6" key={i} >
															<div className={`card nearCard foodCard ${each.isOpen == 'open' ? null : 'closeClass'}`}>
																<div className="card-body withImgPan">
																	<h5 className="card-title">{each.product_name}
																		{this.props.categoryId == 1 ?
																			<span>{Utils.setCurrency(each.restaurant_details, each.product_size[0].amount)}</span> :
																			<span>{Utils.setCurrency(each.grocery_details, each.product_size[0].amount)}</span>
																		}
																	</h5>
																	<h5 className="card-title black">
																		{this.props.categoryId == 1 ?
																			<>{each && each.restaurant_id && each.restaurant_details.restaurant_name}</> :
																			<>{each && each.grocery_id && each.grocery_details.grocery_name}</>
																		}
																	</h5>
																	<div className="cardImg">
																		{this.props.categoryId == 1 ?
																			<img src={each.product_image ? `${IMAGEURL}product/${each.product_image}` : each.product_category[0] ? `${IMAGEURL}product_category/${each.product_category[0].image}` : `${noImage}`} alt='Restro' /> :
																			<img src={each.product_image ? `${IMAGEURL}grocery_product/${each.product_image}` : each.product_category[0] ? `${IMAGEURL}grocery_product_category/${each.product_category[0].image}` : `${noImage}`} alt='Restro' />
																		}
																	</div>
																	<div className="cardDetails">
																		{each.is_veg == 1 && <span className='veg'>Veg</span>}

																		{each.is_spicy == 1 && each.spice_level && [...Array(each.spice_level)].map((el, index) => <span className='chili' key={index}><GiChiliPepper /></span>)}

																		<p className="location desc">{each.product_description && each.product_description.substring(0, 75)}</p>
																		<p className="location">
																			{each && each.product_ingredient && each.product_ingredient.map((data, indexI) => {
																				return <span key={indexI}>{data.ingredient_name}, </span>;
																			})}
																		</p>
																		<p className="size">
																			{each && each.product_size && each.product_size.map((data, indexS) => {
																				return <span
																					key={indexS}
																				>
																					<a className={data.amount === each.selectedSizePrice ? 'font-weight-bold' : ''} style={{ cursor: 'pointer' }}>{data.size_name}</a>
																				</span>;
																			})}
																		</p>
																	</div>

																	{each.exclusive_offer == 1 &&
																		<div className="batchPan"><img src={batchOffer} title='Exclusive offer' alt='Exclusive offer' className='batchOffer' /></div>
																	}

																	{this.props.categoryId == 1 &&
																		<NavLink
																			className="playBtn"
																			onClick={() => this.storeId('restaurant', each, true)}
																			to={SERVERFOLDER + `/restaurant/${each.restaurant_id}/${each.id}`}>
																			<MdPlayArrow />
																		</NavLink>
																	}

																	{this.props.categoryId == 2 &&
																		<NavLink
																			className="playBtn"
																			onClick={() => this.storeId('grocery', each, true)}
																			to={SERVERFOLDER + `/grocery/${each.grocery_id}/${each.id}`}>
																			<MdPlayArrow />
																		</NavLink>
																	}


																</div>
															</div>
														</div>
													) :

													(
														<div className='col-md-4' key={i} >
															<div className={`card nearCard foodCard ${each.isOpen == 'open' ? null : 'closeClass'}`} >
																{each.restaurant_name &&
																	<img className="card-img" src={each.restaurant_logo ? `${IMAGEURL}restaurant/${each.restaurant_logo}` : each.restaurant_category[0] ? `${IMAGEURL}category/${each.restaurant_category[0].image}` : `${noImage}`}></img>
																}
																{each.grocery_name &&
																	<img className="card-img" src={each.grocery_logo ? `${IMAGEURL}grocery/${each.grocery_logo}` : each.grocery_category[0] ? `${IMAGEURL}category/${each.grocery_category[0].image}` : `${noImage}`}></img>
																}


																<div className="card-body">
																	{/*{each.isOpen == 'open' ? null : (
															<span className="closeSpan">Closed</span>
														)
														}*/}
																	<h5 className="card-title">
																		{this.props.categoryId == 1 ?
																			each.restaurant_name :
																			each.grocery_name
																		}
																	</h5>

																	{this.props.categoryId == 1 ?
																		(each.restaurant_name && <p className="location">{each.restaurant_addr}</p>) :
																		(each.grocery_name && <p className="location">{each.grocery_addr}</p>)
																	}

																	{this.props.categoryId == 1 && each.restaurant_name && each.avg_rating && each.avg_rating.length > 0 &&
																		<p className="rating">
																			{each.avg_rating[0].aggregate}
																			{each.rating_count && each.rating_count.length > 0 &&
																				' (' + each.rating_count[0].totalCount + ' ratings)'}
																		</p>
																	}

																	{this.props.categoryId == 2 && each.grocery_name && each.avg_rating && each.avg_rating.length > 0 &&
																		<p className="rating">
																			{each.avg_rating[0].aggregate}
																			{each.rating_count && each.rating_count.length > 0 &&
																				' (' + each.rating_count[0].totalCount + ' ratings)'}
																		</p>
																	}
																	{each.genuine_menu == 1 &&
																		<div className="batchPan"><img src={batchGenuine} title='Genuine Menu Price' alt='Genuine Menu Price' className='batchGenuine' /></div>
																	}

																	{this.props.categoryId == 1 &&
																		<NavLink
																			className="foodSearch playBtn"
																			onClick={() => this.storeId('restaurant', each)}
																			to={SERVERFOLDER + `/restaurant/${each.id}`}>

																		</NavLink>
																	}

																	{this.props.categoryId == 2 &&
																		<NavLink
																			className="grocerySearch playBtn"
																			onClick={() => this.storeId('grocery', each)}
																			to={SERVERFOLDER + `/grocery/${each.id}`}>

																		</NavLink>
																	}

																</div>
															</div>
														</div>

													)
											) :
											<div className='col-12'>
												<p color='#9C9C9C'>Oops!</p>
												<p color='#9C9C9C' >We Could Not Understand What You Mean, Try Rephrasing The Query.</p>
											</div>

										}
									</Fragment>
								</div>

								{/* Pagination */}
								<div className="container paginationPan">
									{item.searchTotal ? (
										<Row>
											<Col md={6}>
												<ItemPerPage
													handleChangeItemsPerPage={this.handleChangeItemPerPageSearch}
													itemsPerPage={this.state.itemsPerPageSearch}
												/>
											</Col>
											<Col md={6} className="paginationCount">
												<div className="paginationOuter text-right">
													<Pagination
														activePage={this.state.activePageSearch}
														itemsCountPerPage={this.state.itemsPerPageSearch}
														totalItemsCount={item.searchTotal}
														onChange={this.handlePageChangeSearch}
													/>
												</div>
											</Col>
										</Row>
									) : null}
								</div>

							</div>
						</div>
					))
				}


				{getDataLoader ?
					<span className='loaderStyle'><LoadingSpinner /></span> :
					<div className="nearPan">
						<div className="container">
							<h2><strong>Near</strong> You
								<Dropdown options={options} value={sort} onChange={this.selectSoringVal} />
							</h2>
							<div className="row">
								{identityList.length > 0 ?
									identityList.map(each => (
										<div className="col-md-4" key={each.id} >
											<div className={`card nearCard foodCard ${each.isOpen == 'open' ? null : 'closeClass'}`}>

												{each.restaurant_name &&
													
														<img className="card-img" src={each.restaurant_logo ? `${IMAGEURL}restaurant/${each.restaurant_logo}` : each.restaurant_category[0] ? `${IMAGEURL}category/${each.restaurant_category[0].image}` : `${noImage}`}></img>
												}
												{each.grocery_name &&
													
														<img className="card-img" src={each.grocery_logo ? `${IMAGEURL}grocery/${each.grocery_logo}` : each.grocery_category[0] ? `${IMAGEURL}category/${each.grocery_category[0].image}` : `${noImage}`}></img>
												}


												<div className="card-body">

													{this.props.categoryId == 1 &&
														
															<h5 className="card-title">{each.restaurant_name ? each.restaurant_name : each.grocery_name} </h5>
														
													}

													{this.props.categoryId == 2 &&
														
															<h5 className="card-title">{each.restaurant_name ? each.restaurant_name : each.grocery_name} </h5>
														
													}


													<p className="location">{each.restaurant_name ? each.restaurant_addr : each.grocery_addr}</p>
													<p className="location">
														{each.franchisee.currency_position == 'BEFORE' ? 'Minimum order ' + each.franchisee.currency_symbol.currency_symbol + each.min_order : 'Minimum order ' + each.min_order + each.franchisee.currency_symbol.currency_symbol}</p>

													{each.avg_rating && each.avg_rating.length > 0 &&
														<p className="rating"><ImStarFull />
															{each.avg_rating[0].aggregate}
															{each.rating_count && each.rating_count.length > 0 &&
																' (' + each.rating_count[0].totalCount + ' ratings)'}
														</p>
													}

													{each.genuine_menu == 1 &&
														<div className="batchPan"><img src={batchGenuine} alt='Genuine Menu Price' className='batchGenuine' /></div>
													}


													{this.props.categoryId == 1 &&
														<NavLink
															className="foodSearch playBtn"
															onClick={() => this.storeId('restaurant', each)}
															to={SERVERFOLDER + `/restaurant/${each.id}`}>

														</NavLink>
													}
													{this.props.categoryId == 2 &&
														<NavLink
															className="grocerySearch playBtn"
															onClick={() => this.storeId('grocery', each)}
															to={SERVERFOLDER + `/grocery/${each.id}`}>

														</NavLink>
													}

												</div>

											</div>
										</div>
									))
									:
									<div className='col-12'><h5 className="alert alert-warning text-center">I’m Sorry Nothing Found Near You.</h5></div>
								}
							</div>


							{/* Pagination */}
							<div className="container paginationPan">
								{
									this.state.totalCount ? (
										<Row>
											<Col md={6}>
												<ItemPerPage
													handleChangeItemsPerPage={this.handleChangeItemPerPage}
													itemsPerPage={this.state.itemsPerPage}
												/>
											</Col>
											<Col md={6} className="paginationCount">
												<div className="paginationOuter text-right">
													<Pagination
														activePage={this.state.activePage}
														itemsCountPerPage={this.state.itemsPerPage}
														totalItemsCount={this.state.totalCount}
														onChange={this.handlePageChange}
													/>
												</div>
											</Col>
										</Row>
									) : null
								}
							</div>



						</div>
					</div>
				}

				<div className="servicePan">
					<div className="container">
						<h2><strong>Delivering</strong> An Excellent Experience</h2>
						<ul className="serviceList">
							<li>
								<div className="serviceImg"><img src={serviceImg1} /></div>
								<h4>Delivery <strong>Food</strong></h4>
							</li>
							<li>
								<div className="serviceImg"><img src={serviceImg2} /></div>
								<h4>Delivery <strong>Grocery</strong></h4>
							</li>
							{/*<li>
								<div className="serviceImg"><img src={serviceImg3} /></div>
								<h4>Delivery <strong>Percel</strong></h4>
							</li>*/}
						</ul>
					</div>
				</div>

				<div className="getAppPan">
					<div className="container">
						<div className="appImg"></div>
						<p><strong>Get</strong> the mobile app for</p>
						<a href="https://apps.apple.com/us/app/the-delivery-guys/id1606717988" className="btn"><FaApple className="apple" /> iPhone</a>
						<a href="https://play.google.com/store/apps/details?id=com.tdg.customer" className="btn"><FaGooglePlay /> Android</a>
					</div>
				</div>

				{/* <div className="citiesPan">
					<div className="container">
						<h2><strong>Cities</strong> We Deliver To</h2>
						<ul className="itiesList">
							<li>Dublin</li>
							<li>Limerick</li>
							<li>Galway</li>
							<li>Waterford</li>
							<li>Drogheda</li>
							<li>Kilkenny</li>
							<li>Wexford</li>
							<li>Sligo</li>
							<li>Clonmel</li>
							<li>Dundalk</li>
							<li>Bray</li>
							<li>Navan</li>
							<li>Ennis</li>
							<li>Tralee</li>
							<li>Carlow</li>
							<li>Naas</li>
							<li>Athlone</li>
							<li>Letterkenny</li>
							<li>Tullamore</li>
							<li>Killarney</li>
							<li>Arklow</li>
							<li>Cobh</li>
							<li>Castlebar</li>
							<li>Midleton</li>
							<li>Mallow</li>
							<li>Ballina</li>
							<li>Enniscorthy</li>
							<li>Wicklow</li>
							<li>Cavan</li>
							<li>Athy</li>
							<li>Longford</li>
							<li>Dungarvan</li>
							<li>Nenagh</li>
						</ul>
					</div>
				</div> */}

				<div className="testimonialSlider">
					<div className="container">
						<h2>Testimonials</h2>
						<Slider {...testimonialSetting}>
							<div className="slideItem">
								<div>
									<p>”Excellent Service At A Fair Price!”</p>
									<span>(Alfonso) from Enzo’s Traditional Fish & Chips</span>
								</div>
							</div>
							<div className="slideItem">
								<div>
									<p>”All My Favourite Restaurants & Shops In One App!”</p>
									<span>Lily (Customer)</span>
								</div>
							</div>
							<div className="slideItem">
								<div>
									<p>”Great Service & Brilliant Software”</p>
									<span>(Shirley) Franchise Owner with Supermacs  </span>
								</div>
							</div>
							<div className="slideItem">
								<div>
									<p>”I May Never Leave The House Again! Love It”</p>
									<span>Linda (Customer)</span>
								</div>
							</div>
							<div className="slideItem">
								<div>
									<p>”Opened Up A New Revenue Stream For Our Restaurant”</p>
									<span>(Mark) From Dan O’Connell’s</span>
								</div>
							</div>
							<div className="slideItem">
								<div>
									<p>”So Convenient And The Drivers Are Lovely”</p>
									<span>Mary (Customer)</span>
								</div>
							</div>
							<div className="slideItem">
								<div>
									<p>”Great Service & Easy To Use”</p>
									<span>(Vittorina) from Al Numero Uno </span>
								</div>
							</div>
							<div className="slideItem">
								<div>
									<p>”Easy To Use, Quick And Efficient”</p>
									<span>Dermot (Customer)</span>
								</div>
							</div>
							<div className="slideItem">
								<div>
									<p>”Highly Recommended”</p>
									<span>(Peter) from Rob’s Ranch House  </span>
								</div>
							</div>
							<div className="slideItem">
								<div>
									<p>”Great App And So Easy To Use”</p>
									<span>Laura (Customer)</span>
								</div>
							</div>
							<div className="slideItem">
								<div>
									<p>”Love The New App!”</p>
									<span>(Ash & Regina) Franchise Owners Four Star Pizza & Bun Bro’s </span>
								</div>
							</div>
						</Slider>
					</div>
				</div>

				<Modal
					show={this.state.showLocationDeniedModal}
					onHide={this.closeLocationDeniedModal}
					className="payOptionPop"
				>
					<Modal.Body className='bg-white text-dark'>
						<Row>
							<Col md={12} className="text-center">
								<h5>To continue, turn on device location, which uses Google&apos;s location service.</h5>
								<p>For details, go to the location settings.</p>
								<p className="text-danger">{googleLocationMessage && googleLocationMessage}</p>
							</Col>
						</Row>
					</Modal.Body>
					<div className="text-center my-2">
						<Button
							type="button"
							onClick={this.closeLocationDeniedModal}
						>
							Return
						</Button>
					</div>
				</Modal>

			</div >
		);
	}
}

Home.propTypes = {
	handleServiceCategory: PropTypes.func,
	handleCart: PropTypes.func,
	handleGeoLocation: PropTypes.func,
	cart: PropTypes.any,
	cartItem: PropTypes.any,
	categoryId: PropTypes.any,
	shopId: PropTypes.any,
	geolocation: PropTypes.any,
	refresh: PropTypes.any,
	searchText: PropTypes.any,
};

const mapStateToProps = (state) => {
	return {
		cartItem: state.cart.cartItem,
		categoryId: state.servicecategory.categoryId,
		shopId: state.cart.shopId,
		cart: state.cart,
		geolocation: state.geolocation
	};
};

const mapDispatchToProps = dispatch => {
	return {
		handleServiceCategory: (data) => dispatch(handleServiceCategory(data)),
		handleCart: (data) => dispatch(handleCart(data)),
		handleGeoLocation: (data) => dispatch(handleGeoLocation(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
