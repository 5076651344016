import React, { Component } from 'react';
// import { NavLink } from 'react-router-dom';
import { FaPlusCircle } from 'react-icons/fa';
import { RiArrowRightSLine } from 'react-icons/ri';
import { MdClose, MdEdit } from 'react-icons/md';
import { connect } from 'react-redux';
import { handleCart } from './../../redux/actions/cart';
import PropTypes from 'prop-types';
import AppLoader from './../AppLoader/AppLoader';
import { Formik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { AiFillCheckCircle } from 'react-icons/ai';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import LoadingSpinner from '../../Components/LoadingSpinner/LoadingSpinner';
import orderAxios from './../../shared/orderEaxios';
import valid from 'card-validator';
import InputMask from 'react-input-mask';

const initialValues = {
    nameOnCard: '',
    cardNumber: '',
    expdate: '',
    cvc: '',
};

const createCardSchema = Yup.object().shape({
    nameOnCard: Yup.string()
        .required('Please type card holder name'),
    cardNumber: Yup.string()
        .test('test-cardNumber', 'Credit/Debit Card number is invalid', value => valid.number(value).isValid)
        .required('Please type card number'),
    expdate: Yup.string()
        .test('test-expdate', 'Expiration date is invalid', value => valid.expirationDate(value).isValid)
        .required('Expiration date is required'),
    cvc: Yup.string()
        .test('test-cvc', 'CVC is invalid', value => valid.cvv(value).isValid)
        .required('Please type your cvc number'),
});

const editCardSchema = Yup.object().shape({
    expdate: Yup.string()
        .test('test-expiry_date', 'Expiration date is invalid', value => valid.expirationDate(value).isValid)
        .required('Expiration date is required'),
    nameOnCard: Yup.string().required('Please type the name on card').nullable(),
});

const getErrorStyles = (errors, touched, fieldName) => {
    if (getIn(errors, fieldName) && getIn(touched, fieldName)) {
        return {
            border: '1px solid red'
        };
    }
};

class PaymentMethod extends Component {
    constructor() {
        super();
        this.state = {
            addrShow: false,
            cardShow: false,
            finalConfirmation: false,
            searchString: '',
            getcardloader: false,
            loader: false,
            latitude: null,
            longitude: null,
            cod: false,
            cardLoader: false,
            cardList: [],
            isEdit: false,
            editData: null,
            deleteData: null,
            isDelete: false,
            deleteErrorMessge: null,
            deleteLoader: false,
            cardErrorMessge: null,
            errorMsg: null
        };
    }

    openModalDeleteCard = (id) => {
        this.setState({
            showDeleteModal: true,
            deleteData: id,
            isDelete: true
        });
    }

    closeDeleteModal = () => {
        this.setState({
            showDeleteModal: false,
        });
    }

    fetchCurrentlocation = () => {
        const location = window.navigator && window.navigator.geolocation;
        if (location) {
            location.getCurrentPosition((position) => {
                this.setState({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                });
            }, () => {
                this.setState({ latitude: 'err-latitude', longitude: 'err-longitude' });
            });
        }
    }

    displayError = (e) => {
        let errorMessge = '';
        try {
            errorMessge = e.data.message ? e.data.message : e.data.error_description;
        } catch (e) {
            errorMessge = 'Unknown error!';
        }
        return errorMessge;
    }

    fetchCardList = () => {
        let values = {};
        values.user_id = this.props.user.id;
        this.setState({ cardLoader: true }, () => {
            orderAxios.get(`creditCardLists?user_id=${this.props.user.id}`)
                .then(response => {
                    this.setState({
                        cardLoader: false,
                        cardList: response.data.message.data
                    });
                })
                .catch(e => {
                    let errorMsg = this.displayError(e);
                    this.setState({
                        cardErrorMessge: errorMsg,
                        cardLoader: false
                    });
                    setTimeout(() => {
                        this.setState({ cardErrorMessge: null });
                    }, 5000);
                });
        });
    }

    componentDidMount() {
        this.fetchCurrentlocation();
        this.fetchCardList();
    }

    handleSubmitCreateStripe = (values) => {
        const formData = new window.FormData();
        formData.append('user_id', this.props.user.id);
        formData.append('email', this.props.user.email);
        formData.append('number', values.cardNumber);
        formData.append('exp_month', values.expdate.substring(0, 2));
        formData.append('exp_year', values.expdate.substring(3));
        formData.append('cvc', values.cvc);
        formData.append('name', values.nameOnCard);
        formData.append('addr1', '');
        formData.append('addr2', '');
        formData.append('city', '');
        formData.append('state', '');
        formData.append('country', '');
        formData.append('zip', '');
        this.setState({ loader: true }, () => {
            orderAxios.post('createStripeUser', formData)
                .then(() => {
                    this.setState({
                        loader: false,
                    }, () => {
                        this.showSuccesSubmitModal();
                    });
                })
                .catch(err => {
                    let errorMsg = this.displayError(err);
                    this.setState({
                        loader: false,
                        errorMsg: errorMsg,
                    });
                    setTimeout(() => {
                        this.setState({ errorMsg: null });
                    }, 2500);
                });
        });
    }

    handleSubmitCreateCard = (values) => {
        const formData = new window.FormData();
        if (this.state.isEdit === true) {
            formData.append('user_id', this.props.user.id);
            formData.append('card_id', values.id);
            formData.append('exp_month', values.expdate.substring(0, 2));
            formData.append('exp_year', values.expdate.substring(3));
            formData.append('name', values.nameOnCard);
        } else {
            formData.append('user_id', this.props.user.id);
            formData.append('number', values.cardNumber);
            formData.append('exp_month', values.expdate.substring(0, 2));
            formData.append('exp_year', values.expdate.substring(3));
            formData.append('cvc', values.cvc);
            formData.append('name', values.nameOnCard);
            formData.append('address_line1', '');
            formData.append('address_line2', '');
            formData.append('address_city', '');
            formData.append('address_state', '');
            formData.append('address_country', '');
            formData.append('address_zip', '');
        }
        this.setState({ loader: true }, () => {
            orderAxios.post(this.state.isEdit === true ? 'updateCard' : 'createCard', formData)
                .then(() => {
                    this.setState({
                        loader: false,
                    }, () => {
                        this.showSuccesSubmitModal();
                    });
                })
                .catch(err => {
                    let errorMsg = this.displayError(err);
                    this.setState({
                        loader: false,
                        errorMsg: errorMsg,
                    });
                    setTimeout(() => {
                        this.setState({ errorMsg: null });
                    }, 2500);
                });
        });
    }

    openModalEditCard = (data) => {
        let cardData = data;
        cardData.nameOnCard = data.name;
        cardData.expdate = data.exp_month.toString().length === 1 ?
            '0'.concat(data.exp_month.toString()) + '/' + data.exp_year.toString().substring(2) :
            data.exp_month.toString() + '/' + data.exp_year.toString().substring(2);
        this.setState({
            cardShow: true,
            editData: cardData,
            isEdit: true
        });
    }

    showSuccesSubmitModal = () => {
        this.setState({ succesSubmitModal: true, cardShow: false });
    };

    closeSuccesSubmitModal = () => {
        this.setState({ succesSubmitModal: false, isEdit: false, isDelete: false });
        this.fetchCardList();
    };

    openModalAddCard = () => {
        this.setState({
            cardShow: true
        });
    };

    closeModal = () => {
        this.setState({
            cardShow: false,
            isEdit: false,
            isDelete: false
        });
    };

    deleteCard = () => {
        this.setState({ deleteLoader: true }, () => {
            orderAxios.delete(`deleteCard/${this.state.deleteData}?user_id=${this.props.user.id}`)
                .then(() => {
                    this.setState({
                        deleteLoader: false,
                        deleteData: null,
                        showDeleteModal: false
                    }, () => {
                        this.showSuccesSubmitModal();
                    });
                })
                .catch(e => {
                    let errorMsg = this.displayError(e);
                    this.setState({
                        deleteErrorMessge: errorMsg,
                        deleteLoader: false
                    });
                    setTimeout(() => {
                        this.setState({ deleteErrorMessge: null });
                    }, 5000);
                });
        });
    }

    render() {
        const {
            cardLoader,
            loader,
            cardErrorMessge,
            deleteErrorMessge,
            deleteLoader,
            isEdit,
            isDelete,
            errorMsg,
            cardShow,
            editData,
            cardList
        } = this.state;
        return (
            <div>
				{cardLoader ?
					<div className="" style={{ height: '72vh' }}><AppLoader /></div> :
					<div className="bodyPan">
						<div className="container cartBg">
							<div className="titlePan">
								<h2> Saved Card Details</h2>
								<a className="addMore" onClick={() => this.openModalAddCard()}><FaPlusCircle /> Add New Card</a>
							</div>

							<div className="row">
								{cardErrorMessge ?
									<p className="alert alert-danger text-center" role="alert"> {cardErrorMessge} </p> :
									<div className="col-7">
										<div className="cartInfo mb-4">
											{cardList && cardList.length > 0 ? cardList.map(data => {
												return <div className="card nearCard visaCard" key={data.id}>
													<p className="visaTitle text-uppercase">{data.brand}</p>
													<span>**** **** **** {data.last4}</span>
													<div className="card-footer">
														<label >
															<a className="editLink" style={{ cursor: 'pointer' }} onClick={() => this.openModalEditCard(data)}> <MdEdit /> Edit</a>
														</label>
														<a className="editLink text-danger" style={{ cursor: 'pointer' }} onClick={() => this.openModalDeleteCard(data.id)}><strong>DELETE CARD</strong></a>
													</div>
												</div>;
											}) :
												'No saved card found.'
											}
										</div>
									</div>
								}
							</div>
						</div>

						{/* Add Card  */}
						<Modal show={cardShow} onHide={this.closeModal}>
							<Modal.Header>
								<Modal.Title><h2 className='text-orange-primary mb-3'>{isEdit === true ? 'Update Card' : 'Add New Card'}</h2></Modal.Title>
								<span className="modalClose" onClick={() => this.closeModal()}><MdClose /></span>
							</Modal.Header>
							<Modal.Body>
								<p className="mb-4">We accept Credit and Debit Cards from Visa, Revolut, Mastercard, American Express and Maestro.</p>
								<div>
									{loader ?
										<span className='loaderStyle'><LoadingSpinner /></span>
										: null
									}
									{errorMsg ?
										<p className="alert alert-danger text-center" role="alert"> {errorMsg} </p>
										: null
									}
									<Formik
										initialValues={isEdit === true ? editData : initialValues}
										validationSchema={isEdit === true ? editCardSchema : createCardSchema}
										onSubmit={isEdit === true ? this.handleSubmitCreateCard : this.props.user.stripe_customer_id === null ? this.handleSubmitCreateStripe : this.handleSubmitCreateCard}
									>
										{({
											values,
											errors,
											touched,
											handleChange,
											handleBlur
										}) => {
											return (<Form className='text-dark'>
												<div className="position-relative">
													<div className="form-group">
														<Field
															style={getErrorStyles(errors, touched, 'nameOnCard')}
															type="text"
															name="nameOnCard"
															value={values.nameOnCard || ''}
															placeholder="Name on card"
															className="form-control"
														/>
														{errors.nameOnCard && touched.nameOnCard ?
															<span className="errorMsg text-danger">{errors.nameOnCard}</span>
															: null}
													</div>

													{isEdit === true ? null :
														<div className="form-group">
															<InputMask
																style={getErrorStyles(errors, touched, 'cardNumber')}
																mask="9999 9999 9999 9999"
																maskChar={null}
																type="text"
																name="cardNumber"
																placeholder="Card Number"
																className={'form-control'}
																value={values.cardNumber || ''}
																onChange={handleChange}
																onBlur={handleBlur}
																disabled={isEdit === true ? true : false}
															/>
															{errors.cardNumber && touched.cardNumber ?
																<span className="errorMsg text-danger">{errors.cardNumber}</span>
																: null}
														</div>
													}

													<div className="form-group">
														<InputMask
															style={getErrorStyles(errors, touched, 'expdate')}
															mask="99/99"
															maskChar={null}
															type="text"
															name="expdate"
															placeholder="Expiry Date"
															className={'form-control'}
															value={values.expdate || ''}
															onChange={handleChange}
															onBlur={handleBlur}
														/>
														{errors.expdate && touched.expdate ?
															<span className="errorMsg text-danger">{errors.expdate}</span>
															: null
														}
													</div>

													{isEdit === true ? null :
														<div className="form-group">
															<InputMask
																style={getErrorStyles(errors, touched, 'cvc')}
																mask="999"
																maskChar={null}
																type="password"
																name="cvc"
																value={values.cvc || ''}
																placeholder="Cvv"
																className="form-control"
																onChange={handleChange}
																onBlur={handleBlur}
																disabled={isEdit === true ? true : false}
															/>
															{errors.cvc && touched.cvc ?
																<span className="errorMsg text-danger">{errors.cvc}</span>
																: null}
														</div>
													}
													<div className='row mt-2 text-center'>
														<div className='col-sm-12'>
															<Button variant="primary mr-3" type="submit" className="addCartBtn mt-3">
																{isEdit === true ? 'Update Card' : 'Save Card'}
																<RiArrowRightSLine />
															</Button>
														</div>
													</div>
												</div>
											</Form>);
										}}
									</Formik>
								</div>
							</Modal.Body>
						</Modal>
						{/* Add Card  */}
						<Modal
							show={this.state.showDeleteModal}
							onHide={this.closeDeleteModal}
							className="payOptionPop"
						>
							<Modal.Body className='bg-white text-dark'>
								{deleteLoader ?
									<span className='loaderStyle'><LoadingSpinner /></span>
									: null
								}
								{deleteErrorMessge ?
									<p className="alert alert-danger text-center" role="alert"> {deleteErrorMessge} </p>
									: null
								}
								<Row>
									<Col md={12} className="text-center">
										<h5>Do you want to delete this card?</h5>
									</Col>
								</Row>
							</Modal.Body>
							<div className="text-center my-4">
								<Button
									type="button"
									onClick={this.closeDeleteModal}
									variant="primary"
								>Return</Button>
									&nbsp;&nbsp;&nbsp;
									<Button
									type="button"
									onClick={this.deleteCard}
									variant="primary mr-3"
									className="btnStylePrimary"
								>Confirm</Button>
							</div>
						</Modal>

						<Modal
							show={this.state.succesSubmitModal}
							onHide={this.closeSuccesSubmitModal}
							className="payOptionPop"
						>
							<Modal.Body className='bg-white text-dark'>
								<Row>
									<Col md={12} className="text-center">
										<span className='successIcon'><AiFillCheckCircle /></span>
									</Col>
								</Row>
								<Row>
									<Col md={12} className="text-center">
										{isEdit ? <h5>Card details has been Updated Successfully</h5> :
											isDelete ? <h5>Card has been deleted Successfully</h5> :
												<h5>Card details has been Successfully Saved</h5>}
									</Col>
								</Row>
							</Modal.Body>
							<div className="text-center my-4">
								<Button
									type="button"
									onClick={this.closeSuccesSubmitModal}
								>Return</Button>
							</div>
						</Modal>

					</div>
				}
			</div>
        );
    }
}

PaymentMethod.propTypes = {
    token: PropTypes.any,
    user: PropTypes.object,
};

const mapStateToProps = (state) => {
    return {
        token: state.auth.token,
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handleCart: (data) => dispatch(handleCart(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethod);
