import { BUSINESS_DETAILS } from '../actions';

const initialState = {
    businessData: null
};

const handelBusinessIDName = (state, {businessData}) => {
    return {
        ...state,
        businessData
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case BUSINESS_DETAILS:
        return handelBusinessIDName(state, action);
    default:
        return state;
    }
};

export default reducer;
