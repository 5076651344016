import {
    USER
} from './actionTypes';

export const handelUser = (data) => {
    return {
        type: USER,
        ...data
    };
};
