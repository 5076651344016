import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Col, Container, Row, Modal } from 'react-bootstrap';
import { Field, Form, getIn, withFormik } from 'formik';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import Avater from './../../../assets/images/avatar.png';
import axios from './../../../shared/eaxios';
import LoadingSpinner from './../../../Components/LoadingSpinner/LoadingSpinner';
import Moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { AiFillCheckCircle } from 'react-icons/ai';
import { IMAGEURL } from './../../../Constants';
import { handelUser } from './../../../redux/actions/user';

const ProfileSchema = Yup.object().shape({
    first_name: Yup
        .string()
        .trim('Please remove whitespace')
        .strict()
        .required('First Name is required')
        .nullable(),
    last_name: Yup
        .string()
        .trim('Please remove whitespace')
        .strict()
        .required('Last Name is required')
        .nullable(),
    email: Yup
        .string()
        .trim('Please remove whitespace')
        .email('Email must be valid')
        .strict()
        .required('Please enter email address')
        .nullable(),
    phone: Yup
        .string()
        .trim('Please remove whitespace')
        .strict()
        .matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Must be a valid phone number')
        .required('Please enter phone number')
        .nullable(),
    /*about: Yup
        .string()
        .trim('Please remove whitespace')
        .strict()
        .nullable(),*/
    gender: Yup
        .string()
        .required('Gender is required')
        .nullable(),
    dob: Yup
        .string()
        .test(
            'dob',
            'Age should be more than 14 years.Please enter a valid Date of Birth',
            function(value) {
                let isValid = false;
                let optimizedBirthday = value.replace(/-/g, '/');
                let myBirthday = new Date(optimizedBirthday);
                let currentDate = new Date().toJSON().slice(0, 10) + ' 01:00:00';
                let myAge = ~~((Date.now(currentDate) - myBirthday) / (31557600000));
                if (myAge >= 14) {
                    isValid = true;
                    return isValid;
                }
            }
        )
        .required('Please enter  date of birth'),

});

const getErrorStyles = (errors, touched, fieldName) => {
    if (getIn(errors, fieldName) && getIn(touched, fieldName)) {
        return {
            border: '1px solid red'
        };
    }
};

class Profile extends Component {
    
    state = {
        successMessage:'',
        errorMsg: '',
        user_type_id: null,
        disabled: false,
        disabledEmail: (this.props.user.email === null || this.props.user.email === '') ? false : true,
        disabledPhone: (this.props.user.phone === null || this.props.user.phone === '') ? false : true,
        succesSubmitModal: false,
        showWarningModal: false,
    }

    enableEdit = () => {
        this.setState({
            disabled: false,
        });
    }

    componentDidMount() {
        const { setStatus } = this.props;
        setStatus({
            loader: false,
            user: {}
        });
    }

    componentDidUpdate(prevProps) {
        const {
            success: wasSuccess = false,
            error: wasError = false
        } = prevProps.status || {};

        const {
            success: isSuccess = false,
            error: isError = false,
            errorMessage: message = '',
            successMessage: message2 = '',
        } = this.props.status || {};

        if (isSuccess && !wasSuccess) {
            let user = this.props.user;

            user.first_name = this.props.status.user.user_detail.first_name;
            user.last_name = this.props.status.user.user_detail.last_name;
            user.profile_pic = this.props.status.user.user_detail.profile_pic;
            user.gender = this.props.status.user.user_detail.gender;
            user.dob = this.props.status.user.user_detail.dob;
            user.email = this.props.status.user.email;
            user.phone = this.props.status.user.phone;
            user.stripe_customer_id = this.props.status.user.stripe_customer_id;
            this.props.handelUser(user);
            this.showSuccesSubmitModal(message2);
        }
        
        if (isError && !wasError) {
            this.showWarningModal(message);
        }
    }

    showSuccesSubmitModal = message2 => {
        this.setState({ succesSubmitModal: true, successMessage: message2 });
    };

    closeSuccesSubmitModal = () => {
        const { closeActionModal, stepRegister, handelLogout } = this.props;
        this.setState(
            { succesSubmitModal: false },
            () => {
                closeActionModal();
            }
        );

        if (stepRegister) {
            handelLogout();
        }
    };

    showWarningModal = message => {
        this.setState({
            showWarningModal: true,
            errorMessage: message
        });
    };

    closeWarningModal = () => {
        this.setState({
            showWarningModal: false
        });
    };


    render() {

        const {
            values,
            errors,
            touched,
            formLoading,
            status,
            setValues,
            setFieldValue,
            isSubmitting,
            //stepRegister
        } = this.props;
        const { disabled , disabledEmail, disabledPhone} = this.state;
        console.log(this.state.disabledEmail, this.state.disabledPhone);
        return (
            <div className="position-relative mb-5">
                {status && status.loader && <LoadingSpinner />}
                {formLoading == true ? (
                    <LoadingSpinner />
                ) : (
                    <Container>
                        <Row className="show-grid">
                            <Col md={12}>
                                {this.state.errorMsg && (<p className="alert alert-danger text-center" role="alert"> {this.state.errorMsg} </p>)}
                                <Form className='text-dark'>
                                    <div className="position-relative">
                                        <div className="text-center mb-4 mt-4">
                                            {values.profile_pic ?
                                                <img className="img-fluid img-thumbnail h-100-px" width={100} height={100} src={`${IMAGEURL}profile/${values.profile_pic}`}></img>
                                                : <img src={Avater} className="img-fluid img-thumbnail" width={100} height={100} alt="..." />
                                            }
                                        </div>

                                        <div className='row'>
                                            <div className={'col-sm-6'}>
                                                <div className="form-group">
                                                    <label className="form-label font-weight-bold ">First Name <span className="text-danger">*</span></label>
                                                    <Field
                                                        style={getErrorStyles(errors, touched, 'first_name')}
                                                        type="input"
                                                        name="first_name"
                                                        value={values.first_name || ''}
                                                        placeholder="First Name"
                                                        className="form-control"
                                                        disabled={disabled}
                                                    />
                                                    {errors.first_name && touched.first_name ?
                                                        <span className="errorMsg text-danger">{errors.first_name}</span>
                                                        : null}
                                                </div>
                                            </div>

                                            <div className={'col-sm-6'}>
                                                <div className="form-group">
                                                    <label className="form-label font-weight-bold ">Last Name <span className="text-danger">*</span></label>
                                                    <Field
                                                        style={getErrorStyles(errors, touched, 'last_name')}
                                                        type="input"
                                                        name="last_name"
                                                        value={values.last_name || ''}
                                                        placeholder="Last Name"
                                                        className="form-control"
                                                        disabled={disabled}
                                                    />
                                                    {errors.last_name && touched.last_name ?
                                                        <span className="errorMsg text-danger">{errors.last_name}</span>
                                                        : null}
                                                </div>
                                            </div>

                                            <div className={'col-sm-6'}>
                                                <div className="form-group">
                                                    <label className="form-label font-weight-bold ">Email <span className="text-danger">*</span></label>
                                                    <Field
                                                        style={getErrorStyles(errors, touched, 'email')}
                                                        type="email"
                                                        name="email"
                                                        value={values.email || ''}
                                                        placeholder="Email"
                                                        className="form-control"
                                                        disabled={disabledEmail}
                                                    />
                                                    {errors.email && touched.email ?
                                                        <span className="errorMsg text-danger">{errors.email}</span>
                                                        : null}
                                                </div>
                                            </div>

                                            <div className={'col-sm-6'}>
                                                <div className="form-group">
                                                    <label className="form-label font-weight-bold ">Phone Number <span className="text-danger">*</span></label>
                                                    <Field
                                                        style={getErrorStyles(errors, touched, 'phone')}
                                                        type="text"
                                                        name="phone"
                                                        value={values.phone || ''}
                                                        placeholder="Phone Number"
                                                        className="form-control"
                                                        disabled={disabledPhone}
                                                    />
                                                    {errors.phone && touched.phone ?
                                                        <span className="errorMsg text-danger">{errors.phone}</span>
                                                        : null}
                                                </div>
                                            </div>


                                            <div className={'col-sm-6'}>
                                                <div className="form-group">
                                                    <label className="form-label font-weight-bold ">Date of Birth <span className="text-danger">*</span></label>
                                                    <DatePicker
                                                        selected={new Date(values.dob)}
                                                        className="form-control"
                                                        required
                                                        dateFormat="dd-MM-yyyy"
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        onChange={date => {
                                                            values.dob = date;
                                                            values.selectedDob = Moment(date).format('YYYY-MM-DD');
                                                            setFieldValue('dob', values.dob);
                                                        }}
                                                        disabled={disabled}
                                                    />
                                                    {errors.dob && touched.dob ?
                                                        <span className="errorMsg text-danger">{errors.dob}</span>
                                                        : null}
                                                </div>
                                            </div>

                                            <div className={'col-sm-6'}>
                                                <div className="form-group">
                                                    <label className="form-label font-weight-bold ">Gender <span className="text-danger">*</span></label>
                                                    <Field
                                                        style={getErrorStyles(errors, touched, 'gender')}
                                                        name="gender"
                                                        component="select"
                                                        className={'form-control'}
                                                        autoComplete="nope"
                                                        placeholder="select"
                                                        value={values.gender || ''}
                                                        disabled={disabled}
                                                    >
                                                        <option value="">Select Gender</option>
                                                        <option value="Male" key="1"> Male</option>
                                                        <option value="Female" key="0">Female</option>
                                                    </Field>
                                                    {errors.gender && touched.gender ?
                                                        <span className="errorMsg text-danger">{errors.gender}</span>
                                                        : null}
                                                </div>
                                            </div>

                                            <div className={'col-sm-6'}>
                                                <div className="form-group">
                                                    <label className="form-label font-weight-bold ">Profile Picture</label>
                                                    <input
                                                        style={getErrorStyles(
                                                            errors,
                                                            touched,
                                                            'profile_pic'
                                                        )}
                                                        accept="image/*"
                                                        type="file"
                                                        name="profile_pic"
                                                        className="form-control"
                                                        onChange={e => {
                                                            values.selectedFile = e.target.files[0];
                                                            setValues(values);
                                                            console.log(values);
                                                        }}
                                                        disabled={disabled}
                                                    />
                                                    {errors.profile_pic && touched.profile_pic ?
                                                        <span className="errorMsg text-danger">{errors.profile_pic}</span>
                                                        : null}
                                                </div>
                                            </div>


                                            {/*<div className={'col-sm-6'}>
                                                <div className="form-group">
                                                    <label className="form-label font-weight-bold ">About</label>
                                                    <textarea
                                                        style={getErrorStyles(errors, touched, 'about')}
                                                        name="about"
                                                        value={values.about || ''}
                                                        
                                                        className="form-control"
                                                        disabled={disabled}
                                                    />
                                                    {errors.about && touched.about ?
                                                        <span className="errorMsg text-danger">{errors.about}</span>
                                                        : null}
                                                </div>
                                            </div>*/}
                                        </div>
                                        <div className='row mt-2 text-center mb-4'>
                                            <div className='col-sm-12'>
                                                {disabled ?
                                                    <>
                                                        <Button variant="secondary mr-3" className="mt-3" onClick={this.props.closeActionModal}>
                                                            <span className="font-weight-normal">Cancel</span>
                                                        </Button>
                                                        <Button variant="primary mr-3" className="mt-3" onClick={this.enableEdit}>
                                                            <span className="font-weight-normal">Edit</span>
                                                        </Button>
                                                    </>
                                                    :
                                                    <Button variant="primary mr-3" type="submit" className="mt-3" disabled={isSubmitting}>
                                                        <span className="font-weight-normal">Submit</span>
                                                    </Button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                )}

                <Modal
                    show={this.state.succesSubmitModal}
                    onHide={this.closeSuccesSubmitModal}
                    className="payOptionPop"
                >
                    <Modal.Body className='bg-white text-dark'>
                        <Row>
                            <Col md={12} className="text-center">
                                <span className='successIcon'><AiFillCheckCircle /></span>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className="text-center">
                                <h5> {this.state.successMessage}</h5>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <div className="text-center my-4">
                        <Button
                            className="but-gray"
                            type="button"
                            onClick={this.closeSuccesSubmitModal}
                        >
                            Return
                        </Button>
                    </div>
                </Modal>

                <Modal
                    show={this.state.showWarningModal}
                    onHide={this.closeWarningModal}
                    className="payOptionPop"
                >
                    <Modal.Body className="WarningModal bg-white">
                        <Row>
                            <Col md={12} className="text-center">
                                <h5 className='text-danger'> {this.state.errorMessage}</h5>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <div className="text-center mb-4">
                        <Button
                            className="but-gray"
                            type="button"
                            onClick={this.closeWarningModal}
                        >
                            Return
                          </Button>
                    </div>
                </Modal>

            </div>
        );
    }
}


Profile.propTypes = {
    history: PropTypes.any,
    userType: PropTypes.string,
    user: PropTypes.object,
    values: PropTypes.object,
    errors: PropTypes.object,
    touched: PropTypes.object,
    formLoading: PropTypes.bool,
    setStatus: PropTypes.func,
    status: PropTypes.object,
    setValues: PropTypes.func,
    setFieldValue: PropTypes.func,
    handelUser: PropTypes.func,
    closeActionModal: PropTypes.func,
    isSubmitting: PropTypes.any,
    stepRegister: PropTypes.any,
    handelLogout: PropTypes.func,
};

const mapStateToProps = (state) => {
    
    console.log('state.user', state.user);
    let user = state.user;
    user.dob = (user.dob === null ? Moment(new Date()) : Moment(user.dob));
    return {
        userType: state.user.user_type_name,
        user,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handelUser: (user) => dispatch(handelUser(user)),
    };
};

const withFormikConfig = {
    validationSchema: ProfileSchema,
    mapPropsToValues: ({ user }) => {
        return {
            selectedFile: {},
            ...user
        };
    },

    handleSubmit(
        values, 
        { props, setStatus, setSubmitting }
    ) {
        setSubmitting(true);

        const { stepRegister, user } = props;

        const formData = new window.FormData();
        formData.append('user_id', user.id);
        formData.append('first_name', values.first_name);
        formData.append('last_name', values.last_name);
        formData.append('email', values.email);
        formData.append('phone', values.phone);
        formData.append('gender', values.gender);
        formData.append('profile_pic', values.selectedFile);
        formData.append('stepRegister', stepRegister ? 1 : 0);
        formData.append('dob', Moment(new Date(values.dob)).format('YYYY-MM-DD'));

        setStatus({ loader: true });

        for (let pair of formData.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }

        try {
            axios.post(`customerUpdatev2/${values.id}`, formData)
                .then(function (res) {
                    setSubmitting(false);
                    let user = res.data.user;
                    setStatus({
                        success: true,
                        loader: false,
                        successMessage : res.data.message,
                        user
                    });
                   
                })
                .catch((err) => {
                    setSubmitting(false);
                    let errorMessage = '';
                    try {
                        errorMessage = err.data.message
                            ? err.data.message
                            : err.data.error_description;
                    } catch (err) {
                        errorMessage = 'User is not updated 11';
                    }
                    setStatus({
                        error: true,
                        errorMessage: errorMessage,
                        loader: false
                    });
                });
        
        } catch (err) {
            let errorMessage = '';
            try {
                errorMessage = err.data.message
                    ? err.data.message
                    : err.data.error_description;
            } catch (err) {
                errorMessage = 'User is not updated.22';
            }
            setStatus({
                error: true,
                errorMessage: errorMessage,
            });
        }
    }

};

export default connect(mapStateToProps, mapDispatchToProps)(withFormik(withFormikConfig)(Profile)); 
