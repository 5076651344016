import React from 'react';
import AllLogin from './Containers/PreAuth/Login/AllLogin';
import Login from './Containers/PreAuth/Login/Login';
import SignUp from './Containers/PreAuth/SignUp/Signup';
import Address from './Components/Address/Address';
import ChangePassword from './Containers/PreAuth/ChangePassword/ChangePassword';
import Mobilesignup from './Containers/PreAuth/SignUp/MobileSignup';
import Otp from './Containers/PreAuth/SignUp/Otp';
import Forgotpassword from './Containers/PreAuth/ForgotPassword/ForgotPassword';
import ErrorPage from './Components/Error/error-page';
import { SERVERFOLDER } from './Constants';
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { PrivateRoute } from './shared/PrivateRoute';
import { handelUser } from './redux/actions/user';
import { handleCart } from './redux/actions/cart';
import { accessToken } from './redux/actions/auth';
import { handleServiceCategory } from './redux/actions/servicecategory';
import './App.scss';
import PrivacyPolicy from './Containers/PreAuth/PrivacyPolicy/PrivacyPolicy';
import DriverPolicy from './Containers/PreAuth/DriverPolicy/DriverPolicy';
import ContactUs from './Containers/PreAuth/ContactUs/ContactUs';
import TermsOfService from './Containers/PreAuth/TermsOfService/TermsOfService';
import AccountDeletion from './Containers/PreAuth/AccountDeletion/AccountDeletion';
import DriverTermsCondtions from './Containers/PreAuth/DriverTermsCondtions/DriverTermsCondtions';

import Home from './Containers/PreAuth/Home/Home';
import Header from './Containers/PreAuth/Header/Header';
import Footer from './Containers/PreAuth/Footer/Footer';
import Restaurant from './Containers/Common/Restaurant/Restaurant';
import Grocery from './Containers/Common/Grocery/Grocery';
import Cart from './Containers/PostAuth/Cart/Cart';
import Checkout from './Containers/PostAuth/Checkout/Checkout';
import Search from './Containers/PostAuth/Search/Search';
import OrderHistory from './Containers/PostAuth/OrderHistory/OrderHistory';
import PaymentMethod from './Components/PaymentMethod/PaymentMethod';
import RestaurantInfo from './Containers/Common/RestaurantInfo/RestaurantInfo';
import VerifyAccount from './Containers/PreAuth/VerifyAccount/VerifyAccount';
import ResetPassword from './Containers/PreAuth/ResetPassword/ResetPassword';

class App extends React.Component {

    render() {
        const {token} = this.props;
        return (
            <div className="App">
				<Router>
					{ /* <Header /> */ }
					{window.location.pathname !== '/privacy-policy-app' && <Header />}
					<Switch>
						<Route exact path={SERVERFOLDER + '/'} component={Home} />
						<PrivateRoute exact path={SERVERFOLDER + '/address'} component={Address} token={token} />
						<PrivateRoute exact path={SERVERFOLDER + '/paymentmethod'} component={PaymentMethod} token={token} />
						<Route exact path={SERVERFOLDER + '/verify-account/:id'} component={VerifyAccount} />
						<Route exact path={SERVERFOLDER + '/reset-password/:id'} component={ResetPassword} />
						<Route exact path={SERVERFOLDER + '/restaurant/:id'} component={Restaurant} />
						<Route exact path={SERVERFOLDER + '/restaurant/:id/:productid'} component={Restaurant} />
						<Route exact path={SERVERFOLDER + '/grocery/:id'} component={Grocery} />
						<Route exact path={SERVERFOLDER + '/grocery/:id/:productid'} component={Grocery} />
						<Route exact path={SERVERFOLDER + '/alllogin'} component={AllLogin} />
						<Route exact path={SERVERFOLDER + '/login'} component={Login} />
						<Route exact path={SERVERFOLDER + '/signup'} component={SignUp} />
						{ /* <PrivateRoute exact path={SERVERFOLDER + '/profile'} component={Profile} token={token} /> */ }
						<PrivateRoute exact path={SERVERFOLDER + '/changepassword'} component={ChangePassword} token={token} />
						<Route exact path={SERVERFOLDER + '/otp/:path'} component={Otp} />
						<Route exact path={SERVERFOLDER + '/mobilesignup'} component={Mobilesignup} />
						<Route exact path={SERVERFOLDER + '/forgotpassword'} component={Forgotpassword} />
						<Route exact path={SERVERFOLDER + '/cart'} component={Cart} />
						<Route exact path={SERVERFOLDER + '/cart/:id'} component={Cart} />
						<PrivateRoute exact path={SERVERFOLDER + '/checkout'} component={Checkout} token={token} />
						<Route exact path={SERVERFOLDER + '/search'} component={Search} />
						<PrivateRoute exact path={SERVERFOLDER + '/order-history'} component={OrderHistory} token={token} />
						<Route exact path={SERVERFOLDER + '/restaurant-info'} component={RestaurantInfo} />
						<Route exact path={SERVERFOLDER + '/privacy-policy'} component={PrivacyPolicy} />
						<Route exact path={SERVERFOLDER + '/termsofservice'} component={TermsOfService} />
						<Route exact path={SERVERFOLDER + '/accountdeletion'} component={AccountDeletion} />
						<Route exact path={SERVERFOLDER + '/privacy-policy-app'} component={PrivacyPolicy} />
						<Route exact path={SERVERFOLDER + '/driver-terms-condtions'} component={DriverTermsCondtions} />

						<Route exact path={SERVERFOLDER + '/privacy-policy-app/:page'} component={PrivacyPolicy} />
						<Route exact path={SERVERFOLDER + '/termsofservice-app/:page'} component={TermsOfService} />
						<Route exact path={SERVERFOLDER + '/driver-terms-condtions-app/:page'} component={DriverTermsCondtions} />

						<Route exact path={SERVERFOLDER + '/contact-us'} component={ContactUs} />
						<Route exact path={SERVERFOLDER + '/driver-policy'} component={DriverPolicy} />


						<Route exact path={SERVERFOLDER + '/error'} component={ErrorPage} />
						<Route component={ErrorPage} />
						{ /* <Redirect from='*' to='/error' /> */ }

					</Switch>
					{window.location.pathname !== '/privacy-policy-app' && <Footer />}
					{ /* <Footer /> */ }
				</Router>

			</div>
        );
    }
}

App.propTypes = {
    handleCart: PropTypes.func,
    cartItem: PropTypes.any,
    user: PropTypes.object,
    sessionTokenUpdate: PropTypes.func,
    handelUser: PropTypes.func,
    categoryId: PropTypes.any,
    handleServiceCategory: PropTypes.func,
    history: PropTypes.object,
    token: PropTypes.any,
};

const mapStateToProps = (state) => {
    return {
        token: state.auth.token,
        cartItem: state.cart.cartItem,
        user: state.user,
        categoryId: state.servicecategory.categoryId,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handleCart: (data) => dispatch(handleCart(data)),
        sessionTokenUpdate: (token) => dispatch(accessToken(token)),
        handelUser: (user) => dispatch(handelUser(user)),
        handleServiceCategory: (data) => dispatch(handleServiceCategory(data)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
