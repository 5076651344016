import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Col, Container, Row, Modal } from 'react-bootstrap';
import { Formik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import axios from '../../../shared/eaxios';
import LoadingSpinner from '../../../Components/LoadingSpinner/LoadingSpinner';
import { AiFillCheckCircle } from 'react-icons/ai';
import { connect } from 'react-redux';
import { handleCart } from '../../../redux/actions/cart';
import { SERVERFOLDER } from '../../../Constants';
import { Link } from 'react-router-dom';

const initialValues = {
	old_password: '',
	new_password: '',
	confirm_password: '',
	chk_confirm: ''
};

const schema = Yup.object().shape({
	old_password:
		Yup
			.string()
			.trim('Please remove whitespace')
			.strict()
			.required('Please enter old password'),
	new_password:
		Yup
			.string()
			.trim('Please remove whitespace')
			.strict()
			.required('Please enter new password'),
	confirm_password:
		Yup
			.string()
			.trim('Please remove whitespace')
			.strict()
			.required('Please enter confirm password')
			.oneOf([Yup.ref('new_password')], 'Passwords does not match'),
	chk_confirm:
		Yup
			.boolean()
			.required('Please accept Terms & Conditions')
});

const getErrorStyles = (errors, touched, fieldName) => {
	if (getIn(errors, fieldName) && getIn(touched, fieldName)) {
		return {
			border: '1px solid red'
		};
	}
};

class ChangePassword extends Component {
	state = {
		errorMsg: '',
		loader: false,
		user_type_id: null,
		succesSubmitModal: false,
	}

	handleSubmit = (values) => {
		this.setState({ loader: true }, () => {
			axios.post('changePassword', values)
				.then(() => {
					this.setState({
						loader: false,
					}, () => {
						this.showSuccesSubmitModal();
					});
				})
				.catch(err => {
					console.log('err', err);
					let errorMessage = '';
					try {
						errorMessage = err.data.message ? err.data.message : err.data.error_description;
					} catch (err) {
						errorMessage = 'No records found.';
					}
					this.setState({
						loader: false,
						errorMsg: errorMessage,
					});
					setTimeout(() => {
						this.setState({ errorMsg: null });
					}, 2500);
				});
		});
	}

	showSuccesSubmitModal = () => {
		this.setState({ succesSubmitModal: true });
	};

	closeSuccesSubmitModal = () => {
		this.setState({ succesSubmitModal: false });
		this.props.history.push(`${SERVERFOLDER + '/'}`);
	};

	render() {
		return (
			<div className="position-relative">
				{this.state.loader ?
					<span className='loaderStyle'><LoadingSpinner /></span>
					: null
				}
				<div className="welcomeOuter">
					<div className="regWhiteOuter">
						<Container>
							<div className="welcomePage">
								<Row className="show-grid">
									<Col md={12}>
										<div className="welcomeIntro mt-4">
											<div className="login position-relative">
												<h4 className='text-center'>Change Password</h4>
												{this.state.errorMsg ?
													<p className="alert alert-danger text-center" role="alert"> {this.state.errorMsg} </p>
													: null
												}
												<Formik
													initialValues={initialValues}
													validationSchema={schema}
													onSubmit={this.handleSubmit}
												>
													{({
														errors,
														touched,
													}) => {
														return (<Form className='text-dark'>
															<div className="position-relative">
																<div className="form-group">
																	<label className="form-label font-weight-bold">Old Password <span className="text-danger">*</span></label>
																	<Field
																		style={getErrorStyles(
																			errors,
																			touched,
																			'old_password'
																		)}
																		type="password"
																		name="old_password"
																		placeholder="Old Password"
																		className="form-control"
																	/>
																	{errors.old_password && touched.old_password ? <span className="errorMsg text-danger">{errors.old_password}</span> : null}
																</div>
																<div className="form-group">
																	<label className="form-label font-weight-bold">New Password <span className="text-danger">*</span></label>
																	<Field
																		style={getErrorStyles(
																			errors,
																			touched,
																			'new_password'
																		)}
																		type="password"
																		name="new_password"
																		placeholder="New Password"
																		className="form-control"
																	/>
																	{errors.new_password && touched.new_password ? <span className="errorMsg text-danger">{errors.new_password}</span> : null}
																</div>
																<div className="form-group">
																	<label className="form-label font-weight-bold">Confirm Password <span className="text-danger">*</span></label>
																	<Field
																		style={getErrorStyles(
																			errors,
																			touched,
																			'confirm_password'
																		)}
																		type="password"
																		name="confirm_password"
																		placeholder="Confirm Password"
																		className="form-control"
																	/>
																	{errors.confirm_password && touched.confirm_password ? <span className="errorMsg text-danger">{errors.confirm_password}</span> : null}
																</div>
																<div className="form-group">
																	<Field
																		style={getErrorStyles(
																			errors, touched,
																			'chk_confirm'
																		)}
																		type="checkbox"
																		name="chk_confirm"
																		className="tc_check"
																	/> I agree to all <Link to={SERVERFOLDER + '/termsofservice'} className='text-orange-primary mb-3'>Terms &amp; Conditions</Link>
																	<br />
																	{errors.chk_confirm ? <span className="errorMsg text-danger">{errors.chk_confirm}</span> : null}
																</div>

																<div className='row mt-2 text-center'>
																	<div className='col-sm-12'>
																		<Button variant="primary mr-3" type="submit" className="btnStylePrimary mt-3">
																			<span className="font-weight-normal">Submit</span>
																		</Button>

																	</div>
																</div>
															</div>
														</Form>);
													}}
												</Formik>

											</div>{/* end: login */}
										</div>
									</Col>
								</Row>
							</div>{/* end: welcomePage*/}
						</Container>
					</div>{/* end: regWhiteOuter*/}

				</div>

				<Modal
					show={this.state.succesSubmitModal}
					onHide={this.closeSuccesSubmitModal}
					className="payOptionPop"
				>
					<Modal.Body className='bg-white text-dark'>
						<Row>
							<Col md={12} className="text-center">
								<span className='successIcon'><AiFillCheckCircle /></span>
							</Col>
						</Row>
						<Row>
							<Col md={12} className="text-center">
								<h5>Your password have been changed successfully </h5>
							</Col>
						</Row>
					</Modal.Body>
					<div className="text-center my-4">
						<Button
							type="button"
							onClick={this.closeSuccesSubmitModal}
						>
							Return
						</Button>
					</div>
				</Modal>

			</div>
		);
	}
}

ChangePassword.propTypes = {
	location: PropTypes.object,
	handleCart: PropTypes.func,
	cartItem: PropTypes.any,
	coupon: PropTypes.any,
	deliveryInstructions: PropTypes.any,
	driverTip: PropTypes.any,
	grandTotal: PropTypes.any,
	token: PropTypes.any,
	user: PropTypes.object,
	history: PropTypes.any,
};

const mapStateToProps = (state) => {
	return {
		cartItem: state.cart.cartItem,
		coupon: state.cart.coupon,
		deliveryInstructions: state.cart.deliveryInstructions,
		driverTip: state.cart.driverTip,
		grandTotal: state.cart.grandTotal,
		token: state.auth.token,
		user: state.user,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		handleCart: (data) => dispatch(handleCart(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
