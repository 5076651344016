import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
//import restroLogo from '../../../assets/images/bastion-logo.jpg';
import { ImStarFull, ImLocation } from 'react-icons/im';
import { FaAngleLeft, FaPlusCircle } from 'react-icons/fa';
import { TiMinus, TiPlus, } from 'react-icons/ti';
import { MdEdit, MdDelete } from 'react-icons/md';
import { RiArrowRightSLine } from 'react-icons/ri';
import { IMAGEURL, SERVERFOLDER } from './../../../Constants';
import { connect } from 'react-redux';
import { handleCart } from './../../../redux/actions/cart';
import PropTypes from 'prop-types';
import axios from './../../../shared/eaxios';
import orderAxios from './../../../shared/orderEaxios';
import defaultRestLogo from '../../../assets/images/restro-img1.jpg';
import emptyCart from '../../../assets/images/emptyCart.png';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import { Formik, Field, Form, } from 'formik';
import { MdClose } from 'react-icons/md';
import AppLoader from '../../../Components/AppLoader/AppLoader';
//import LoadingSpinner from '../../../Components/LoadingSpinner/LoadingSpinner';
import Moment from 'moment';
import * as Utils from '../../../Utils';

class Cart extends Component {
	//constructor() {
	//super();
	state = {
		valid: this.props.match.params.id ? this.props.match.params.id : '',
		show: false,
		getDataerrorMessge: null,
		shopInfo: '',
		searchString: '',
		//latitude: '',
		//longitude: '',
		distance: '',
		cartItem: [],
		totalPrice: 0,
		showSpecialInstructionModal: false,
		showApplyCouponModal: false,
		cartItemForSpecialInstruction: null,
		coupon: null,
		couponId: null,
		grandTotal: 0,
		googleLocationMessage: null,
		showLocationDeniedModal: false,
		deliveryAvailable: null,
		minOrderModal: false,
		couponList: [],
		couponErrorMessge: null
	};
	//}

	showDelivery = () => {
		this.setState({
			show: !this.state.show
		});
	}

	distance(lat1, lon1) {

		let lat2 = this.props.geolocation.latitude;
		let lon2 = this.props.geolocation.longitude;

		if ((lat1 == lat2) && (lon1 == lon2)) {
			return 0;
		} else {
			const radlat1 = Math.PI * lat1 / 180;
			const radlat2 = Math.PI * lat2 / 180;
			const theta = lon1 - lon2;
			const radtheta = Math.PI * theta / 180;
			let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
			if (dist > 1) {
				dist = 1;
			}
			dist = Math.acos(dist);
			dist = dist * 180 / Math.PI;
			dist = dist * 60 * 1.1515;
			dist = dist * 1.609344;
			return dist;
		}
	}

	validatecartProduct = () => {
		console.log('D');

		let product = [...this.props.cartItem];

		console.log('===============ORGINAAL BODY', JSON.stringify(product));

		const newColumns = product.map((item) => {
			delete item.product_size;
			delete item.selectedSize;
			const { selectedSizeId: selectedSize, ...rest } = item;
			return { selectedSize, ...rest };
		});

		console.log('=======BEFORE PRODUCT VALIDATION', JSON.stringify(newColumns));

		const formData = new window.FormData();

		if (this.props.cart.shopType == 'restaurant') {
			formData.append('restaurant_id', this.props.cart.shopId);
		} else {
			formData.append('grocery_id', this.props.cart.shopId);
		}

		formData.append('customer_id', this.props.user.id);
		formData.append('product', JSON.stringify(newColumns));

		this.setState({ getDataLoader: true }, () => {
			orderAxios.post('checkoutValidation', formData)
				.then(res => {
					console.log('=======AFTER PRODUCT VALIDATION', JSON.stringify(res.data.data.product));

					/*let product = res.data.data.product;
					let cartItem = [];

					if(product && this.state.valid) {

						product.map(data => {
							cartItem.push(data);
						});

						console.log('=======cartItem', JSON.stringify(cartItem));

						let data = this.props.cart;

						data.cartItem = cartItem;
						let totalPrice = 0;
						data.cartItem.map(data => {
							totalPrice = totalPrice + (data.selectedQuantity * data.totalSizePrice);
						});
						data.grandTotal = totalPrice;
							


						//this.props.handleCart(data);
							
						


					}*/

				})
				.catch(e => {
					let errorMsg = this.displayError(e);
					this.setState({
						getDataerrorMessge: errorMsg,
						getDataLoader: false
					});
					setTimeout(() => {
						this.setState({ getDataerrorMessge: null });
					}, 5000);
				});
		});
	};

	componentDidMount() {
		if (this.props.cartItem && this.props.cartItem.length > 0) {
			sessionStorage.setItem('cartItemLength', this.props.cartItem.length);
		} else {
			sessionStorage.removeItem('cartItemLength');
		}

		if (this.props.cartItem && this.props.cartItem.length > 0) {
			let totalPrice = 0;
			this.props.cartItem.map(data => {
				totalPrice = totalPrice + (data.selectedQuantity * data.totalSizePrice);
			});
			const grandTotal = Number(totalPrice) + Number(this.props.driverTip);
			this.setState({
				cartItem: this.props.cartItem,
				totalPrice: Number(totalPrice).toFixed(2),
				coupon: this.props.coupon,
				grandTotal: Number(grandTotal).toFixed(2)
			});
			console.log('grandTotal=', grandTotal);
			this.shop_info_fetch();
			//this.fetchCouponList();
			//this.validatecartProduct();
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.categoryId !== prevProps.categoryId) {

			let data = this.props.cart;
			let cartItem = this.props.cartItem;

			cartItem = [];
			data.cartItem = cartItem;
			data.driverTip = 0;
			data.grandTotal = 0;
			this.props.handleCart(data);

			this.setState({
				cartItem
			});

			this.props.history.push(`${SERVERFOLDER + '/'}`);
		}
	}

	shop_info_fetch = () => {
		let url;
		const { searchString } = this.state;

		if (this.props.categoryId == 1) {
			url = `restaurantDetails/${this.props.cart.shopId}?lat=${this.props.geolocation.latitude}&lng=${this.props.geolocation.longitude}&searchString=${searchString}`;

		} else {
			url = `groceryDetails/${this.props.cart.shopId}?lat=${this.props.geolocation.latitude}&lng=${this.props.geolocation.longitude}&searchString=${searchString}`;
		}

		this.setState({
			getDataLoader: true
		}, () => {
			axios.get(url)
				.then(res => {
					this.setState({
						getDataLoader: false,
						shopInfo: res.data.data,
					}, () => {
						let data = this.props.cart;
						data.only_collection = this.state.shopInfo.only_collection;
						this.props.handleCart(data);

						this.state.shopInfo.delivery_area !== '' &&
							this.checkDeliveryStatus(this.state.shopInfo.delivery_area);

						let distance;
						if (this.props.cart.shopType == 'restaurant') {
							distance = this.distance(res.data.data.restaurant_lat, res.data.data.restaurant_lng);
						} else {
							distance = this.distance(res.data.data.grocery_lat, res.data.data.grocery_lng);
						}
						this.setState({
							latitude: this.props.geolocation.latitude,
							longitude: this.props.geolocation.latitude,
							distance
						});
						console.log('distance=', distance);
					});
				})
				.catch(e => {
					let errorMsg = this.displayError(e);
					this.setState({
						getDataerrorMessge: errorMsg,
						getDataLoader: false
					});
					setTimeout(() => {
						this.setState({ getDataerrorMessge: null });
					}, 5000);
				});
		});
	};

	displayError = (e) => {
		let errorMessge = '';
		try {
			errorMessge = e.data.message ? e.data.message : e.data.error_description;
		} catch (e) {
			errorMessge = 'Unknown error!';
		}
		return errorMessge;
	}

	changeQuantity(value, i) {
		if (value === 'add') {
			let cartItem = this.state.cartItem;
			let data = this.props.cart;

			console.log(i + 'add=', cartItem[i].product_name);

			console.log(i + 'selectedQuantity=', Number(cartItem[i].selectedQuantity));

			cartItem[i].selectedQuantity = Number(cartItem[i].selectedQuantity) + 1;

			console.log(i + 'after add=', cartItem[i].selectedQuantity);

			let totalPrice = 0;
			cartItem.map((data, j) => {
				totalPrice = totalPrice + Number(data.selectedQuantity * data.totalSizePrice);
				console.log(j + data.product_name + '=', data.selectedQuantity);
			});

			const grandTotal = Number(totalPrice) + Number(this.props.driverTip);
			data.cartItem = cartItem;
			data.grandTotal = Number(grandTotal).toFixed(2);
			this.setState({
				cartItem,
				totalPrice: Number(totalPrice).toFixed(2),
				grandTotal: Number(grandTotal).toFixed(2)
			});
			this.props.handleCart(data);
		}

		if (value === 'sub') {
			console.log('sub=', i);
			let cartItem = this.state.cartItem;
			let data = this.props.cart;
			cartItem[i].selectedQuantity = cartItem[i].selectedQuantity - 1;
			if (cartItem[i].selectedQuantity === 0) {
				cartItem.splice(i, 1);
			}
			let totalPrice = 0;
			cartItem.map(data => {
				totalPrice = totalPrice + Number(data.selectedQuantity * data.totalSizePrice);
			});
			const grandTotal = Number(totalPrice) + Number(this.props.driverTip);
			data.cartItem = cartItem;
			data.grandTotal = Number(grandTotal).toFixed(2);
			this.setState({
				cartItem,
				totalPrice: Number(totalPrice).toFixed(2),
				grandTotal: Number(grandTotal).toFixed(2)
			});
			this.props.handleCart(data);
		}

	}

	addSpecialInstructions = (i) => {
		const cartItem = this.state.cartItem;
		this.setState({
			showSpecialInstructionModal: true,
			cartItemForSpecialInstruction: cartItem[i],
		});
	}

	closeSpecialInstructionModal = () => {
		this.setState({
			showSpecialInstructionModal: false,
		});
	};

	closeApplyCouponModal = () => {
		this.setState({
			showApplyCouponModal: false,
		});
	};

	openApplyCouponModal = () => {
		this.setState({
			showApplyCouponModal: true
		});
	}

	handleSubmitSpecialInstructions = (values) => {
		console.log('values==', values);

		let cartItemForSpecialInstruction = this.state.cartItemForSpecialInstruction;

		cartItemForSpecialInstruction.specialInstructions = values.specialInstructions;
		cartItemForSpecialInstruction.item_instructions = values.specialInstructions;

		this.setState({ cartItemForSpecialInstruction, showSpecialInstructionModal: false });
	}

	handleSubmitCoupon = () => {
		let data = this.props.cart;
		data.cartItem = this.state.cartItem;
		data.coupon = this.state.coupon;
		this.props.handleCart(data);
		this.setState({ showApplyCouponModal: false });
	}

	storeDeliveryInstruction = (e) => {
		let data = this.props.cart;
		data.cartItem = this.state.cartItem;
		data.deliveryInstructions = e.target.value;
		this.props.handleCart(data);
	}

	storedriverTip = (e) => {
		//console.log('storedriverTip',e.target.value);

		let tip = e.target.value;

		if (tip.match(/^(?!0\d)\d*(\.\d+)?$/)) {
			//console.log('preg' ,tip);
			if (tip !== '') {
				//console.log('success' ,tip);

				let data = this.props.cart;
				data.cartItem = this.state.cartItem;
				data.driverTip = tip;
				const grandTotal = Number(this.state.totalPrice) + Number(tip);
				data.grandTotal = Number(grandTotal).toFixed(2);

				this.props.handleCart(data);
				this.setState({ grandTotal: Number(grandTotal).toFixed(2) });

			} else {
				//console.log('false',tip);

				tip = 0;
				let data = this.props.cart;
				data.cartItem = this.state.cartItem;
				data.driverTip = tip;
				const grandTotal = Number(this.state.totalPrice) + Number(tip);
				data.grandTotal = Number(grandTotal).toFixed(2);

				this.props.handleCart(data);
				this.setState({ grandTotal: Number(grandTotal).toFixed(2) });
			}
		}
	}

	applycoupon = (code) => {
		this.setState({ coupon: code });
	}

	checkDeliveryStatus = (area) => {
		const deliveryArea = JSON.parse(area);
		const polygon = new window.google.maps.Polygon({ paths: deliveryArea });
		const currentCoordinates = new window.google.maps.LatLng(this.props.geolocation.latitude, this.props.geolocation.longitude);
		const resultPath = window.google.maps.geometry.poly.containsLocation(currentCoordinates, polygon);
		this.setState({ deliveryAvailable: resultPath });
	}

	removeItemFromCart = (cartItem, i) => {
		cartItem.splice(i, 1);
		this.setState({
			cartItem
		});
		let data = this.props.cart;
		data.cartItem = cartItem;
		let totalPrice = 0;
		data.cartItem.map(data => {
			totalPrice = totalPrice + (data.selectedQuantity * data.totalSizePrice);
		});

		const grandTotal = Number(totalPrice) + Number(this.props.driverTip);
		data.grandTotal = grandTotal;
		this.setState({
			totalPrice: Number(totalPrice).toFixed(2),
			grandTotal: Number(grandTotal).toFixed(2)
		});
		this.props.handleCart(data);
	}

	handleCheckOut = () => {
		const minOrder = this.state.shopInfo && Math.round(this.state.shopInfo.min_order * 100);
		const grandTotal = Math.round(this.state.totalPrice * 100);

		//console.log('minOrder', minOrder);
		//console.log('grandTotal', grandTotal);

		if (minOrder > grandTotal) {
			this.setState({
				minOrderModal: true,
			});
		} else {
			if (this.props.cart.isOpen == 'open') {
				this.props.history.push(`${SERVERFOLDER + '/checkout'}`);
			}
		}
	}

	closeMinOrderModal = () => {
		this.setState({ minOrderModal: false });
	}

	fetchCouponList = () => {
		this.setState({ couponLoader: true }, () => {
			axios.get('customerCouponList')
				.then(response => {
					this.setState({
						couponLoader: false,
						couponList: response.data.data.data
					});
				})
				.catch(e => {
					let errorMsg = this.displayError(e);
					this.setState({
						couponErrorMessge: errorMsg,
						couponLoader: false
					});
					setTimeout(() => {
						this.setState({ couponErrorMessge: null });
					}, 5000);
				});
		});
	}

	render() {
		const {
			shopInfo,
			cartItem,
			totalPrice,
			cartItemForSpecialInstruction,
			coupon,
			grandTotal,
			getDataLoader,
			googleLocationMessage,
			deliveryAvailable,
			minOrderModal,
			couponList
		} = this.state;

		return (
			<div>
				{getDataLoader ?
					<div className="" style={{ height: '72vh' }}><AppLoader /></div> :

					<div className="bodyPan">

						<div className="container cartBg">
							<div className="titlePan">
								{this.props.cart.shopType ?
									<NavLink to={SERVERFOLDER + `/${this.props.cart.shopType}/${this.props.cart.shopId}`} className="backArrow"><FaAngleLeft /> Back to {this.props.cart.shopType == 'restaurant' ? 'Restaurant' : 'Grocery'}</NavLink> :
									<NavLink to={SERVERFOLDER + '/'} className="backArrow"><FaAngleLeft /> Back</NavLink>
								}
								<h2>Cart</h2>
							</div>
							{cartItem && cartItem.length === 0 ?
								<div className="" >
									<div className="text-center">
										<img src={emptyCart} alt='Empty cart' />
									</div>
									<h5 className="text-center" style={{ color: '#ED592B' }}>{this.props.categoryId == 1 ? 'Our Carefully Selected Restaurants Are Ready To Cook Something Special Just For You! So Let’s Make An Order!' : 'Groceries have new item everyday. But your cart looks empty. Let&apos;s order something'} </h5>
								</div> :
								<div className="restroInfo">
									<div className="restroLogo">
										{this.props.cart.shopType == 'restaurant' ?
											<img src={shopInfo && shopInfo.restaurant_logo ? `${IMAGEURL}restaurant/${shopInfo.restaurant_logo}` : `${defaultRestLogo}`} alt='Restro' /> :
											<img src={shopInfo && shopInfo.grocery_logo ? `${IMAGEURL}grocery/${shopInfo.grocery_logo}` : `${defaultRestLogo}`} alt='Restro' />
										}
									</div>
									<div className="restroDetails">
										<h2>{this.props.cart.shopType == 'restaurant' ? shopInfo && shopInfo.restaurant_name : shopInfo && shopInfo.grocery_name}</h2>
										{this.props.cart.shopType == 'restaurant' ?
											(shopInfo && shopInfo.restaurant_addr ?
												<p className="location">{shopInfo.restaurant_addr + ' ' + shopInfo.restaurant_city + ' ' + shopInfo.restaurant_state + ' ' + shopInfo.restaurant_country + ' ' + shopInfo.restaurant_zip}</p>
												: null) :
											(shopInfo && shopInfo.grocery_addr ?
												<p className="location">{shopInfo.grocery_addr + ' ' + shopInfo.grocery_city + ' ' + shopInfo.grocery_state + ' ' + shopInfo.grocery_country + ' ' + shopInfo.grocery_zip}</p>
												: null)
										}
										<p className="ratingPan">
											{shopInfo && shopInfo.avg_rating && shopInfo.avg_rating.length > 0 ?
												<span className="rating"><ImStarFull />
													{shopInfo.avg_rating && shopInfo.avg_rating.length > 0 && shopInfo.avg_rating[0].aggregate}
													{shopInfo.rating_count && shopInfo.rating_count.length > 0 && ' (' + shopInfo.rating_count[0].totalCount + ' ratings)'}
												</span>
												:
												<span className="rating">No rating</span>
											}
											<span className="distance"><ImLocation /> {Number(this.state.distance).toFixed(2)} kms</span>
										</p>
									</div>
								</div>
							}

							{cartItem && cartItem.length > 0 &&
								<div className="row">
									<div className="col-12 col-lg-8">
										<div className="cartInfo">
											<h3>Items({this.state.cartItem.length})</h3>
											<table className="table">
												<tbody>
													{cartItem.map((data, i) => {
														return <tr key={i}>
															<td className="itemName">
																{data.product_name}
																<span style={{ cursor: 'pointer' }} className="text-secondary ml-3" onClick={() => this.removeItemFromCart(cartItem, i)}>
																	<MdDelete />
																</span>
																<em>Size :  {data.selectedSizeName} {'(Price : ' + Utils.setCurrency(shopInfo, parseFloat(data.selectedSizePrice).toFixed(2)) + ')'}</em>
																<div className="addonTd"><small>
																{data.selctedAddon && data.selctedAddon.length > 0 &&
																	<b>Addon Items :</b>
																}
																{data.selctedAddon && data.selctedAddon.length > 0 && data.selctedAddon.map(el => {
																	return <em key={el.id}>{el.name} {'(Price : ' + Utils.setCurrency(shopInfo, parseFloat(el.price).toFixed(2)) + ')'}</em>;
																})}
																
																{data.combo && data.combo.length > 0 &&
																	<b>Combos Selected :</b>
																}	
																{data.combo && data.combo.length > 0 && data.combo.map(el => {
																	return <em key={el.id}>{el.product.product_name} {'(Price : ' + Utils.setCurrency(shopInfo, parseFloat(el.price).toFixed(2)) + ')'}</em>;
																})}
																</small></div>
																<div className="instructionPan" onClick={() => this.addSpecialInstructions(i)}>{data.specialInstructions ? data.specialInstructions : 'Add special instructions (optional) '}
																	<MdEdit /></div>

															</td>
															<td className="count">
																<span className="plus"><TiMinus onClick={() => this.changeQuantity('sub', i)} /></span>
																<span className="number">{data.selectedQuantity}</span>
																<span className="minus"><TiPlus onClick={() => this.changeQuantity('add', i)} /></span>
															</td>
															<td className="ammount">
																{Utils.setCurrency(shopInfo, Number(data.totalSizePrice * data.selectedQuantity).toFixed(2))}
															</td>
														</tr>;
													})}

													<tr>
														<td>
															<NavLink className="addMore" to={SERVERFOLDER + `/${this.props.cart.shopType}/${this.props.cart.shopId}`}><FaPlusCircle /> Add More</NavLink>
														</td>
														<td className="subTotal">Sub Total :</td>
														<td className="ammount">
															{Utils.setCurrency(shopInfo, parseFloat(totalPrice).toFixed(2))}
														</td>
													</tr>
												</tbody>
											</table>
										</div>
										<div className="deliveryInst">

											{deliveryAvailable &&
												<div>
													<label onClick={() => this.showDelivery()}>Delivery Instruction</label>
													<input
														type="text"
														maxLength="100"
														name="deliveryInstructions"
														value={this.props.deliveryInstructions || ''}
														className="form-control"
														onChange={this.storeDeliveryInstruction}
													/>
												</div>
											}

											{this.state.show &&
												<div>
													<textarea className="form-control"></textarea>
												</div>
											}
										</div>
									</div>
									<div className="col-12 col-lg-4">

										<div className="grandTotalPan">
											<table className="table">
												<tbody>
													<tr>
														<td className="subTotal">Sub Total :</td>
														<td className="ammount">{Utils.setCurrency(shopInfo, parseFloat(totalPrice).toFixed(2))}</td>
													</tr>
													<tr>
														<td className="subTotal">Driver Tip :</td>
														<td className="ammount">
															<div className='driverAmountPan'>
																{this.state.shopInfo && this.state.shopInfo.franchisee && this.state.shopInfo.franchisee.currency_position == 'BEFORE' &&
																	<>
																		{this.state.shopInfo && this.state.shopInfo.franchisee && this.state.shopInfo.franchisee.currency_symbol && this.state.shopInfo.franchisee.currency_symbol.currency_symbol}
																	</>
																}

																<input
																	type="number"
																	name="driverTip"
																	value={this.props.driverTip || ''}
																	className="form-control"
																	onChange={this.storedriverTip}
																/>

																{this.state.shopInfo && this.state.shopInfo.franchisee && this.state.shopInfo.franchisee.currency_position == 'AFTER' &&
																	<>
																		{this.state.shopInfo && this.state.shopInfo.franchisee && this.state.shopInfo.franchisee.currency_symbol && this.state.shopInfo.franchisee.currency_symbol.currency_symbol}
																	</>
																}
															</div>
														</td>
													</tr>
													<tr>
														<td className="subTotal2">Grand Total :
														</td>
														<td className="ammount2">
															{Utils.setCurrency(shopInfo, parseFloat(grandTotal).toFixed(2))}</td>
													</tr>
												</tbody>
											</table>
										</div>
										<button onClick={this.handleCheckOut} className="addCartBtn btn w-100">Check Out <RiArrowRightSLine /></button>
										{this.props.cart.isOpen == 'open' ? null :
											<p className="text-danger">{'Restaurant is closed.'}</p>
										}
									</div>
								</div>
							}

						</div>

						{/* Special Instruction  */}
						<Modal show={this.state.showSpecialInstructionModal} onHide={this.closeSpecialInstructionModal} className="WarningModal">
							<Modal.Header>
								<Modal.Title>Special Instructions?</Modal.Title>
								<span className="modalClose text-right" onClick={() => this.closeSpecialInstructionModal()}><MdClose /></span>
							</Modal.Header>
							<Modal.Body>
								<Formik
									initialValues={cartItemForSpecialInstruction}
									onSubmit={this.handleSubmitSpecialInstructions}
								>
									{({ values }) => {
										return (
											<Form className='text-dark'>
												<div className="position-relative">
													<div className="form-group">
														<Field
															value={values.specialInstructions || ''}
															type="text"
															maxLength="100"
															name="specialInstructions"
															className="form-control"
														/>
													</div>
													<div className='row mt-2 text-center'>
														<div className='col-sm-12'>
															<Button variant="primary mr-3" type="submit" className="btnStylePrimary mt-3">
																<span className="font-weight-normal">Save</span>
															</Button>
														</div>
													</div>
												</div>
											</Form>);
									}}
								</Formik>
							</Modal.Body>
						</Modal>
						{/* Special Instruction  */}

						<Modal show={this.state.showApplyCouponModal} onHide={this.closeApplyCouponModal} className="WarningModal">
							<Modal.Header>
								<Modal.Title>Coupons</Modal.Title>
								<span className="modalClose text-right" onClick={() => this.closeApplyCouponModal()}><MdClose /></span>
							</Modal.Header>
							<Modal.Body>
								<Formik
									onSubmit={this.handleSubmitCoupon}
								>
									{({ handleChange }) => {
										return (
											<Form className='text-dark'>
												<div className="position-relative">
													<div className="form-group">
														<Field
															value={coupon}
															type="text"
															name="coupon"
															onChange={e => {
																handleChange;
																this.setState({ coupon: e.target.value });
															}}
															readOnly
															className="form-control"
														/>
														{this.state.coupon &&
															<em className="text-danger"
																style={{ cursor: 'pointer' }}
																onClick={() => this.setState({ coupon: '', couponId: null })}><MdDelete /></em>
														}
													</div>

													{couponList && couponList.length > 0 ?
														couponList.map(data => {
															return <Button className="discountPan w-100" key={data.id}
																onClick={() => this.setState({ coupon: data.coupon, couponId: data })}>
																<div className="discountTxt">
																	<strong>{data.name}</strong>
																	Valid up to : {Moment(data.end_date).format('MM-DD-YYYY')}
																</div>
																<div className="discountCode">CODE: {data.coupon}</div>
															</Button>;
														}) :
														'No coupons available at the moment'}

													<div className='row mt-2 text-center'>
														<div className='col-sm-12'>
															<Button variant="primary mr-3" type="submit" className="btnStylePrimary mt-3">
																<span className="font-weight-normal">Apply</span>
															</Button>
														</div>
													</div>
												</div>
											</Form>);
									}}
								</Formik>
							</Modal.Body>
						</Modal>

					</div>
				}

				<Modal size="lg" show={minOrderModal} onHide={this.closeMinOrderModal} className="payOptionPop" >
					<Modal.Body className='bg-white text-dark'>
						<Row>
							<Col md={12} className="text-center">
								<h5>Sub Total should be greater than or equal to minimum order value ({this.state.shopInfo && this.state.shopInfo.franchisee && this.state.shopInfo.franchisee.currency_symbol && this.state.shopInfo.franchisee.currency_symbol.currency_symbol}{this.state.shopInfo && this.state.shopInfo.min_order}), Please add more item or increase quantity to continue.</h5>
							</Col>
						</Row>
					</Modal.Body>
					<div className="text-center my-2">
						<Button
							type="button"
							onClick={this.closeMinOrderModal}
						>
							Return
						</Button>
					</div>
				</Modal>

				<Modal show={this.state.showLocationDeniedModal} onHide={this.closeLocationDeniedModal} className="payOptionPop" >
					<Modal.Body className='bg-white text-dark'>
						<Row>
							<Col md={12} className="text-center">
								<h5>To continue, turn on device location, which uses Google&apos;s location service.</h5>
								<p>For details, go to the location settings.</p>
								<p className="text-danger">{googleLocationMessage && googleLocationMessage}</p>
							</Col>
						</Row>
					</Modal.Body>
					<div className="text-center my-2">
						<Button
							type="button"
							onClick={this.closeLocationDeniedModal}
						>
							Return
						</Button>
					</div>
				</Modal>

			</div>
		);
	}
}

Cart.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			id: PropTypes.string,
			productid: PropTypes.string
		})
	}),
	user: PropTypes.object,
	handleCart: PropTypes.func,
	cartItem: PropTypes.any,
	coupon: PropTypes.any,
	deliveryInstructions: PropTypes.any,
	driverTip: PropTypes.any,
	grandTotal: PropTypes.any,
	cart: PropTypes.any,
	history: PropTypes.any,
	categoryId: PropTypes.any,
	geolocation: PropTypes.any,
};

const mapStateToProps = (state) => {
	return {
		user: state.user,
		cartItem: state.cart.cartItem,
		coupon: state.cart.coupon,
		deliveryInstructions: state.cart.deliveryInstructions,
		driverTip: state.cart.driverTip,
		grandTotal: state.cart.grandTotal,
		cart: state.cart,
		categoryId: state.servicecategory.categoryId,
		geolocation: state.geolocation
	};
};

const mapDispatchToProps = dispatch => {
	return {
		handleCart: (data) => dispatch(handleCart(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
