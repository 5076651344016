/* eslint-disable no-unused-vars, no-var */

//import AsyncStorage from '@react-native-async-storage/async-storage';
//import * as geolib from 'geolib';

/*export const asyncSetData = (key, value) => {
  AsyncStorage.setItem(key, value);
};

export const asyncGetData = async (key) => {
  let res = await AsyncStorage.getItem(key);
  res = res !== undefined ? res : '';
  return res;
};*/

/*export const checkAreaInside = (userlat, userlng, deliveryARea) => {
  if (deliveryARea == '') {
    return true;
  } else {
    let area = JSON.parse(deliveryARea);

    return geolib.isPointInPolygon(
      {latitude: userlat, longitude: userlng},
      area,
    );
  }
};*/

export const getdays = () => {
    var day = '';
    switch (new Date().getDay()) {
    case 0:
        day = 'sunday';
        break;
    case 1:
        day = 'monday';
        break;
    case 2:
        day = 'tuesday';
        break;
    case 3:
        day = 'wednesday';
        break;
    case 4:
        day = 'thursday';
        break;
    case 5:
        day = 'friday';
        break;
    case 6:
        day = 'saturday';
    }

    return day;
};

export const checkPlaceOpen = (timeArray) => {
    var status = 0; // 0 -close 1- open

    var alreadyExcecute = 0;

    timeArray.map((item, i) => {
        var date = new Date();

        var today = getdays();

        if (item[today] == 1 && alreadyExcecute == 0) {
            alreadyExcecute = 1;

            var open_time = Date.parse('01/01/2021 ' + item.open);
            var close_time = Date.parse('01/01/2021 ' + item.close);

            var hours = date.getHours();
            var min = date.getMinutes();
            var seconds = date.getSeconds();

            let time_current = hours + ':' + min + ':' + seconds;

            var curent_time = Date.parse('01/01/2021 ' + time_current);

            if (open_time <= close_time) {
                // start and stop times are in the same day
                if (curent_time >= open_time && curent_time <= close_time) {
                    // current time is between start and stop

                    status = 1;
                }
            } else {
                // start and stop times are in different days
                if (curent_time >= open_time || curent_time <= close_time) {
                    // current time is between start and stop

                    status = 1;
                }
            }
        }
    });

    return status;
};

export const checkDeliveryOpen = (timeArray) => {
    var status = 0; // 0 -close 1- open

    var alreadyExcecute = 0;
    timeArray.map((item, i) => {
        var date = new Date();

        var today = getdays();

        if (item[today] == 1 && alreadyExcecute == 0) {
            alreadyExcecute = 1;

            var open_time = Date.parse('01/01/2021 ' + item.order_open);
            var close_time = Date.parse('01/01/2021 ' + item.order_close);

            var hours = date.getHours();
            var min = date.getMinutes();
            var seconds = date.getSeconds();

            let time_current = hours + ':' + min + ':' + seconds;

            var curent_time = Date.parse('01/01/2021 ' + time_current);

            if (open_time <= close_time) {
                // start and stop times are in the same day
                if (curent_time >= open_time && curent_time <= close_time) {
                    // current time is between start and stop

                    status = 1;
                }
            } else {
                // start and stop times are in different days
                if (curent_time >= open_time || curent_time <= close_time) {
                    // current time is between start and stop

                    status = 1;
                }
            }
        }
    });

    return status;
};

export const setCurrency = (shopInfo, price) => {
    //console.log('price', price);
    //console.log('shopInfo', shopInfo);

    let str = '';
    //let info = this.state.restaurantInfo;

    if (shopInfo && shopInfo.franchisee && shopInfo.franchisee.currency_symbol) {

        if (shopInfo.franchisee.currency_position == 'BEFORE') {
            str = shopInfo.franchisee.currency_symbol.currency_symbol + ' ' + parseFloat(price).toFixed(2);
        } else {
            str = parseFloat(price).toFixed(2) + ' ' + shopInfo.franchisee.currency_symbol.currency_symbol;
        }
    }


    //console.log('SET CURRENCT RETURN', str);
    return str;
};


export const setCurrency2 = (currency_symbol, currency_position, price) => {
    //console.log('price', price);

    let str = '';

    if (currency_position == 'BEFORE') {
        str = currency_symbol + ' ' + price;
    } else {
        str = price + ' ' + currency_symbol;
    }

    //console.log('SET CURRENCT RETURN', str);
    return str;
};

export const getEstimatedTime = (item) => {

    //console.log('item==', item);

    //console.log(item.order_status);
    //console.log(item.order_delivery_duration);
    if(item){
        
        var totalTime = 0;

        if (item.order_status == 'approved') {
            totalTime = item.restaurant_delivery_duration;
        } else if (item.order_status == 'delivery_onhold') {
            totalTime = item.order_onhold_duration;
        } else if (
                item.order_status == 'delivery_pickedup' ||
                item.order_status == 'pickup_ready' ||
                item.order_status == 'pending'
        ) {
            totalTime = item.order_delivery_duration;
        } else {
            totalTime = item.order_delivery_duration;
        }

        console.log(totalTime);
        totalTime = parseFloat(totalTime / 60);

        totalTime = totalTime.toFixed(0);
        totalTime = totalTime.toString();

        var str = item.order_status == 'approved'
            ? ' (Preparation Time)'
            : ' (Estimated Delivery)';

        totalTime = totalTime + 'min' + str;
        return totalTime;
    }

};
