import React, { Component } from 'react';
import { Col, Container, Row, Image } from 'react-bootstrap';
import logoImgLg from './../../assets/images/tdg-logo.png';

class LogoWelcomeHeader extends Component {
	render() {
		return (
			<div className="regToppanel">
				<Container>
					<Row className="show-grid">
						<Col md={12}>
							<div className="logoHeadFull" id="logoHeadFull">
								<Image src={logoImgLg} className='logoLg' />
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default LogoWelcomeHeader;
