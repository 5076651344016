import { GEOLOCATION } from '../actions';

const initialState = {
    latitude: null,
    longitude: null,
    address: null,
    refresh: null,
    timezone: null,
};

const handleGeoLocation = (state, action) => {
    return {
        ...state,
        latitude: action.latitude,
        longitude: action.longitude,
        address: action.address,
        refresh: action.refresh,
        timezone: action.timezone
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GEOLOCATION:
            return handleGeoLocation(state, action);
        default:
            return state;
    }
};

export default reducer;
