import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
//import FooterWelcome from '../FooterWelcome';
import HeaderLogoWelcome from '../LogoWelcomeHeader';
import { Formik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import axios from '../../../shared/eaxios';
import { connect } from 'react-redux';
import { accessToken, logoutMsg } from '../../../redux/actions/auth';
import { handelUser } from '../../../redux/actions/user';
import { handleCart } from './../../../redux/actions/cart';
import LoadingSpinner from '../../../Components/LoadingSpinner/LoadingSpinner';
import '../PreAuth.scss';
// import { AiFillCheckCircle } from 'react-icons/ai';
import { SERVERFOLDER } from './../../../Constants';
//import { NavLink } from 'react-router-dom';
import { MdPlayArrow } from 'react-icons/md';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';

import { FaFacebookF } from 'react-icons/fa';

const initialValues = {
	phone: '',
	chk_confirm: ''
};

const MobileRegisterSchema = Yup.object().shape({
	phone:
		Yup
			.string()
			.trim('Please remove whitespace')
			.strict()
			//.matches(/^[0-9]+$/, 'Must be only digits')
			.matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Must be a valid phone number')
			//.min(10, 'Must be exactly 10 digits')
			//.max(10, 'Must be exactly 10 digits')
			.required('Please enter phone number'),
	chk_confirm:
		Yup
			.boolean().oneOf([true],'Please accept Terms & Conditions')
			.required('Please accept Terms & Conditions')
});

const getErrorStyles = (errors, touched, fieldName) => {
	if (getIn(errors, fieldName) && getIn(touched, fieldName)) {
		return {
			border: '1px solid red'
		};
	}
};

class MobileRegister extends Component {
	state = {
		errorMsg: '',
		loader: false,
		user_type_id: null,
	}

	componentDidUpdate(prevProps) {
		if (this.props.categoryId !== prevProps.categoryId) {

			let data = this.props.cart;
			let cartItem = this.props.cartItem;

			cartItem = [];
			data.cartItem = cartItem;
			data.driverTip = 0;
			data.grandTotal = 0;
			this.props.handleCart(data);

			this.setState({
				cartItem
			});

			this.props.history.push(`${SERVERFOLDER + '/'}`);
		}
	}

	handleSubmit = (values) => {
		values.user_type_id = 2;
		this.setState({ loader: true }, () => {
			axios.post('sendOtpRegister', values)
				.then( () => {
					this.setState({
						loader: false
					});
					this.props.history.push({
						pathname: `${SERVERFOLDER + '/otp/signup'}`,
						state: { 
							//otp: response.data.message, 
							phone: values.phone 
						}
					});
				})
				.catch(err => {
					console.log('err', err);
					let errorMessage = '';
					try {
						errorMessage = err.data.message ? err.data.message : err.data.error_description;
					} catch (err) {
						errorMessage = 'No records found.';
					}
					this.setState({
						loader: false,
						errorMsg: errorMessage,
					});
					setTimeout(() => {
						this.setState({ errorMsg: null });
					}, 5000);
				});
		});
	}

	responseFacebook = (response) => {
		console.log('facebook-response', response);
		if (!response.error) {
			const formData = new window.FormData();
			formData.append('email', response.email);
			formData.append('first_name', response.first_name);
			formData.append('last_name', response.last_name);
			formData.append('provider_user_id', response.id);
			formData.append('provider', 'Facebook');
			//formData.append('device_token', response.accessToken);
			//formData.append('token', '');
			//console.log(formData);
			axios.post('socialMediaLogin', formData)
				.then(res => {
					console.log('res', res);
					if (res.data.success === true) {
						let timeStamp = new Date().getTime();
						let user = {};

						user.order_token = res.data.order_token;
						user.first_name = res.data.user.user_detail.first_name;
						user.last_name = res.data.user.user_detail.last_name;
						user.timeStamp = timeStamp;
						user.id = res.data.user.id;
						user.user_type_name = this.state.user_type_name;
						user.profile_pic = res.data.user.user_detail.profile_pic;
						user.gender = res.data.user.user_detail.gender;
						user.dob = res.data.user.user_detail.dob;
						user.stripe_customer_id = res.data.user.stripe_customer_id;
						user.email = res.data.user.email;
						user.about = res.data.user.user_detail.about;
						user.phone = res.data.user.phone;
						user.register_with = res.data.user.register_with;

						this.props.handelUser(user);
						this.props.sessionTokenUpdate(res.data.token, null);
						this.props.cartItem && this.props.cartItem.length > 0 ?
							this.props.history.push(`${SERVERFOLDER + '/checkout'}`) :
							this.props.history.push(`${SERVERFOLDER + '/'}`);
					} else {
						let errorMessage = '';
						try {
							errorMessage = res.data.message ? res.data.message : res.data.error_description;
						} catch (err) {
							errorMessage = 'No records found.';
						}
						this.setState({
							loader: false,
							errorMsg: errorMessage,
						});
						setTimeout(() => {
							this.setState({ errorMsg: null });
						}, 5000);
					}
				})
				.catch(err => {
					console.log('err', err);
					let errorMessage = '';
					try {
						errorMessage = err.data.message ? err.data.message : err.data.error_description;
					} catch (err) {
						errorMessage = 'No records found.';
					}
					this.setState({
						loader: false,
						errorMsg: errorMessage,
					});
					setTimeout(() => {
						this.setState({ errorMsg: null });
					}, 5000);
				});

		}
	}

	responseGoogle = (response) => {
		console.log('google-response from AllLogin', response);
		if (!response.error) {

			const formData = new window.FormData();
			formData.append('email', response.profileObj.email);
			formData.append('first_name', response.profileObj.givenName);
			formData.append('last_name', response.profileObj.familyName);
			formData.append('provider_user_id', response.profileObj.googleId);
			formData.append('provider', 'Google');
			//formData.append('device_token', response.accessToken);
			formData.append('token', '');

			axios.post('socialMediaLogin', formData)
				.then((res) => {
					if (res.data.success === true) {
						let timeStamp = new Date().getTime();
						let user = {};

						user.order_token = res.data.order_token;
						user.first_name = res.data.user.user_detail.first_name;
						user.last_name = res.data.user.user_detail.last_name;
						user.timeStamp = timeStamp;
						user.id = res.data.user.id;
						user.user_type_name = this.state.user_type_name;
						user.profile_pic = res.data.user.user_detail.profile_pic;
						user.gender = res.data.user.user_detail.gender;
						user.dob = res.data.user.user_detail.dob;
						user.stripe_customer_id = res.data.user.stripe_customer_id;
						user.email = res.data.user.email;
						user.about = res.data.user.user_detail.about;
						user.phone = res.data.user.phone;
						user.register_with = res.data.user.register_with;

						this.props.handelUser(user);
						this.props.sessionTokenUpdate(res.data.token, null);
						this.props.cartItem && this.props.cartItem.length > 0 ?
							this.props.history.push(`${SERVERFOLDER + '/checkout'}`) :
							this.props.history.push(`${SERVERFOLDER + '/'}`);
					} else {
						let errorMessage = '';
						try {
							errorMessage = res.data.message ? res.data.message : res.data.error_description;
						} catch (err) {
							errorMessage = 'No records found.';
						}
						this.setState({
							loader: false,
							errorMsg: errorMessage,
						});
						setTimeout(() => {
							this.setState({ errorMsg: null });
						}, 5000);
					}
				})
				.catch(err => {
					console.log('err', err);
					let errorMessage = '';
					try {
						errorMessage = err.data.message ? err.data.message : err.data.error_description;
					} catch (err) {
						errorMessage = 'No records found.';
					}
					this.setState({
						loader: false,
						errorMsg: errorMessage,
					});
					setTimeout(() => {
						this.setState({ errorMsg: null });
					}, 5000);
				});
		}
	}


	render() {
		return (
			<div className="position-relative">
				{this.state.loader ?
					<span className='loaderStyle'><LoadingSpinner /></span>
					: null
				}
				<div className="welcomeOuter">
					<HeaderLogoWelcome />
					<div className="regWhiteOuter">
						<Container>
							<div className="welcomePage">
								<Row className="show-grid">
									<Col md={12}>
										<div className="welcomeIntro">
											<div className="login position-relative">
												<h4 className='text-center'>Welcome</h4>
												<h6 className='text-sm-center text-secondary'>Please Create an account to proceed</h6>
												{this.state.errorMsg ?
													<p className="alert alert-danger text-center" role="alert"> {this.state.errorMsg} </p>
													: null
												}
												<Formik
													initialValues={initialValues}
													validationSchema={MobileRegisterSchema}
													onSubmit={this.handleSubmit}
												>
													{({
														errors,
														touched,

													}) => {
														return (<Form className='text-dark'>
															<div className="position-relative">
																<div className="form-group">
																	<Field
																		style={getErrorStyles(
																			errors,
																			touched,
																			'phone'
																		)}
																		type="input"
																		name="phone"
																		placeholder="Phone Number"
																		className="form-control"
																	/>
																	{errors.phone && touched.phone ? <span className="errorMsg text-danger">{errors.phone}</span> : null}
																</div>

																<div className="form-group">
																	<label>
																	<Field
																		style={getErrorStyles(
																			errors, touched,
																			'chk_confirm'
																		)}
																		type="checkbox"
																		name="chk_confirm"
																		className="tc_check"
																	/> I agree to all <Link to={SERVERFOLDER + '/termsofservice'} className='text-orange-primary mb-3'>Terms of Service</Link>
																	</label>
																	<br />
																	{errors.chk_confirm ? <span className="errorMsg text-danger">{errors.chk_confirm}</span> : null}
																</div>

																<div className='row mt-2 text-center'>
																	<div className='col-sm-12'>
																		<Button variant="primary mr-3" type="submit" className="btnStylePrimary mt-3">
																			<span className="font-weight-normal">Get OTP</span>
																		</Button>
																	</div>
																</div>
															</div>
														</Form>);
													}}
												</Formik>

												<div className='text-center text-secondary mt-3 mb-3'><strong>OR</strong></div>

												<div className="col-md-12">
													<div className="card googleCard" >
														<div className="card-body">
															<GoogleLogin
																clientId="309165283057-6co9ojqu3cj0r81tlq1ujlc4519c0m81.apps.googleusercontent.com"
																onSuccess={this.responseGoogle}
																idpId={false}
																icon={<MdPlayArrow />}
																buttonText={'Sign in with Google'}
																className={'playBtn'}
																onFailure={this.responseGoogle}
																cookiePolicy={'single_host_origin'}

															/>
														</div>
													</div>
												</div><br></br>

												<div className="col-md-12">
													<div className="card facebookCard">
														<div className="card-body">
															<FacebookLogin
																appId="1131659344036923"
																fields="first_name,last_name,email,picture"
																onClick={this.compountClicked}
																callback={this.responseFacebook}
																cssClass={'playBtn'}
																icon={<FaFacebookF />}
																isMobile={false}
																disableMobileRedirect={false}
															/>
														</div>
													</div>
												</div>


												<div className='text-center text-secondary mt-3 mb-3'>
													<span className="text-muted center">Already on Delivery Guys? <Link to={SERVERFOLDER + '/login'} className='text-orange-primary mb-3'>Sign in</Link></span>
												</div>
											</div>
										</div>
									</Col>
								</Row>
							</div>
						</Container>
					</div>
					{/*<FooterWelcome />*/}
				</div>

			</div>
		);
	}
}
MobileRegister.propTypes = {
	location: PropTypes.object,
	history: PropTypes.object,
	sessionTokenUpdate: PropTypes.func,
	handelUser: PropTypes.func,
	userType: PropTypes.string,
	cartItem: PropTypes.any,
	handleCart: PropTypes.func,
	categoryId: PropTypes.any,
	cart: PropTypes.any,
};

const mapStateToProps = (state) => {
	return {
		token: state.auth.token,
		authlogoutMsg: state.auth.logoutMsg,
		userType: state.user.user_type_name,
		cartItem: state.cart.cartItem,
		cart: state.cart,
		categoryId: state.servicecategory.categoryId,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		sessionTokenUpdate: (token) => dispatch(accessToken(token)),
		handelUser: (user) => dispatch(handelUser(user)),
		resetLogoutMsg: data => dispatch(logoutMsg(data)),
		handleCart: (data) => dispatch(handleCart(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileRegister);

