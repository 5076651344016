import { AUTH_START, AUTH_FAIL, AUTH_SUCCESS, AUTH_LOGOUT, ACCESS_TOKEN, AUTH_LOGOUT_MSG, AUTH_ALL_DATA_UPDATE } from '../actions';

const initialState = {
    token: null,
    logoutMsg: null
};

const authStart = (state) => {
    return {
        ...state,
        loading: true
    };
};

const authFail = (state, action) => {
    return {
        ...state,
        loading: false,
        error: action.error
    };
};

const authSuccess = (state, action) => {
    return {
        ...state,
        loading: false,
        token: action.token,
        userId: action.id
    };
};

const authLogout = (state) => {
    return {
        ...state,
        token: null,
    };
};

const logoutMsg = (state, {logoutMsg}) => {
    return {
        ...state,
        logoutMsg
    };
};

const authToken = (state, {token}) => {
    console.log('--------------------token', token);
    return {
        ...state,
        token
    };
};

const authAllDataUpdate = (state, action) => {
    return {
        ...state,
        ...action.data
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case AUTH_START:
        return authStart(state, action);
    case AUTH_FAIL:
        return authFail(state, action);
    case AUTH_SUCCESS:
        return authSuccess(state, action);
    case AUTH_LOGOUT:
        return authLogout(state, action);
    case ACCESS_TOKEN:
        return authToken(state, action);
    case AUTH_LOGOUT_MSG:
        return logoutMsg(state, action);
    case AUTH_ALL_DATA_UPDATE:
        return authAllDataUpdate(state, action);
    default:
        return state;
    }
};

export default reducer;
