import { USER } from '../actions';

const initialState = {
    order_token: null,
    first_name: null,
    last_name: null,
    timeStamp: null,
    id: null,
    profile_pic: null,
    gender: null,
    dob: null,
    user_type_name: null,
    stripe_customer_id: null,
    email: null,
    phone: null,
    about: null,
    register_with: null
};

const handelUser = (state, {order_token, first_name, last_name, timeStamp, id, profile_pic, gender, dob, user_type_name, stripe_customer_id, email, phone, about, register_with}) => {
    return {
        ...state,
        order_token,
        first_name,
        last_name,
        timeStamp,
        id,
        profile_pic,
        gender,
        dob,
        user_type_name,
        stripe_customer_id,
        email,
        phone,
        about,
        register_with
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case USER:
        return handelUser(state, action);
    default:
        return state;
    }
};

export default reducer;
